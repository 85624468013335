/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactDataType = "EMAIL" | "NONE" | "TEL" | "WWW" | "%future added value";
export type TenantStatusType = "NONE" | "STATUS_ACTIVE" | "STATUS_MOVED_OUT" | "STATUS_NOT_YET_MOVED_IN" | "%future added value";
export type GetTenantFromUsageUnitQueryVariables = {|
  companyId: string,
  id: string,
|};
export type GetTenantFromUsageUnitQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +UsageUnit: ?{|
        +tableId: number,
        +data: $ReadOnlyArray<?{|
          +tableId: ?number,
          +firstname: string,
          +lastname: string,
          +status: TenantStatusType,
          +contact: $ReadOnlyArray<?{|
            +type: ContactDataType,
            +value: string,
          |}>,
        |}>,
      |}
    |}
  |}
|};
export type GetTenantFromUsageUnitQuery = {|
  variables: GetTenantFromUsageUnitQueryVariables,
  response: GetTenantFromUsageUnitQueryResponse,
|};
*/


/*
query GetTenantFromUsageUnitQuery(
  $companyId: ID!
  $id: ID!
) {
  Me {
    Company(id: $companyId) {
      UsageUnit(id: $id) {
        tableId
        data: tenants {
          tableId
          firstname
          lastname
          status
          contact {
            type
            value
          }
          id
        }
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactData",
  "kind": "LinkedField",
  "name": "contact",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetTenantFromUsageUnitQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "UsageUnit",
                "kind": "LinkedField",
                "name": "UsageUnit",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": "data",
                    "args": null,
                    "concreteType": "Tenant",
                    "kind": "LinkedField",
                    "name": "tenants",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetTenantFromUsageUnitQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "UsageUnit",
                "kind": "LinkedField",
                "name": "UsageUnit",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": "data",
                    "args": null,
                    "concreteType": "Tenant",
                    "kind": "LinkedField",
                    "name": "tenants",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2aa2cdceae95a059924282b1bb2be278",
    "id": null,
    "metadata": {},
    "name": "GetTenantFromUsageUnitQuery",
    "operationKind": "query",
    "text": "query GetTenantFromUsageUnitQuery(\n  $companyId: ID!\n  $id: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      UsageUnit(id: $id) {\n        tableId\n        data: tenants {\n          tableId\n          firstname\n          lastname\n          status\n          contact {\n            type\n            value\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '149cd4504b8eab031fdb2166be96ec9f';

module.exports = node;
