/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConditionType = "contains" | "endsWith" | "equals" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "lessThan" | "lessThanOrEqual" | "notContains" | "notEqual" | "startsWith" | "%future added value";
export type Condition = {|
  value: $ReadOnlyArray<?string>,
  type: ConditionType,
|};
export type TicketsOverviewQueryVariables = {|
  companyId: string,
  dateFilter: $ReadOnlyArray<?Condition>,
  channelFilter: $ReadOnlyArray<?Condition>,
|};
export type TicketsOverviewQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +OrderWizard: ?{|
        +tickets: ?$ReadOnlyArray<?{|
          +creationDate: ?any
        |}>,
        +channels: ?$ReadOnlyArray<?{|
          +tableId: number,
          +name: string,
        |}>,
        +closed: ?number,
        +open: ?number,
      |}
    |}
  |}
|};
export type TicketsOverviewQuery = {|
  variables: TicketsOverviewQueryVariables,
  response: TicketsOverviewQueryResponse,
|};
*/


/*
query TicketsOverviewQuery(
  $companyId: ID!
  $dateFilter: [Condition]!
  $channelFilter: [Condition]!
) {
  Me {
    Company(id: $companyId) {
      OrderWizard {
        tickets {
          creationDate
          id
        }
        channels {
          tableId
          name
          id
        }
        closed: countTickets(options: {filters: [{name: "status", filterType: text, operator: AND, conditions: [{value: "CLOSED", type: equals}]}, {name: "creationDate", filterType: date, operator: AND, conditions: $dateFilter}, {name: "channel.id", filterType: text, operator: AND, conditions: $channelFilter}]})
        open: countTickets(options: {filters: [{name: "status", filterType: text, operator: AND, conditions: [{value: "OPEN", type: equals}]}, {name: "creationDate", filterType: date, operator: AND, conditions: $dateFilter}, {name: "channel.id", filterType: text, operator: AND, conditions: $channelFilter}]})
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "channelFilter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dateFilter"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creationDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "kind": "Literal",
  "name": "operator",
  "value": "AND"
},
v8 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "conditions",
      "variableName": "dateFilter"
    },
    {
      "kind": "Literal",
      "name": "filterType",
      "value": "date"
    },
    {
      "kind": "Literal",
      "name": "name",
      "value": "creationDate"
    },
    (v7/*: any*/)
  ],
  "kind": "ObjectValue",
  "name": "filters.1"
},
v9 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "conditions",
      "variableName": "channelFilter"
    },
    {
      "kind": "Literal",
      "name": "filterType",
      "value": "text"
    },
    {
      "kind": "Literal",
      "name": "name",
      "value": "channel.id"
    },
    (v7/*: any*/)
  ],
  "kind": "ObjectValue",
  "name": "filters.2"
},
v10 = {
  "alias": "closed",
  "args": [
    {
      "fields": [
        {
          "items": [
            {
              "kind": "Literal",
              "name": "filters.0",
              "value": {
                "conditions": [
                  {
                    "type": "equals",
                    "value": "CLOSED"
                  }
                ],
                "filterType": "text",
                "name": "status",
                "operator": "AND"
              }
            },
            (v8/*: any*/),
            (v9/*: any*/)
          ],
          "kind": "ListValue",
          "name": "filters"
        }
      ],
      "kind": "ObjectValue",
      "name": "options"
    }
  ],
  "kind": "ScalarField",
  "name": "countTickets",
  "storageKey": null
},
v11 = {
  "alias": "open",
  "args": [
    {
      "fields": [
        {
          "items": [
            {
              "kind": "Literal",
              "name": "filters.0",
              "value": {
                "conditions": [
                  {
                    "type": "equals",
                    "value": "OPEN"
                  }
                ],
                "filterType": "text",
                "name": "status",
                "operator": "AND"
              }
            },
            (v8/*: any*/),
            (v9/*: any*/)
          ],
          "kind": "ListValue",
          "name": "filters"
        }
      ],
      "kind": "ObjectValue",
      "name": "options"
    }
  ],
  "kind": "ScalarField",
  "name": "countTickets",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TicketsOverviewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderWizardQueries",
                "kind": "LinkedField",
                "name": "OrderWizard",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderWizardTicket",
                    "kind": "LinkedField",
                    "name": "tickets",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderWizardChannel",
                    "kind": "LinkedField",
                    "name": "channels",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TicketsOverviewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderWizardQueries",
                "kind": "LinkedField",
                "name": "OrderWizard",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderWizardTicket",
                    "kind": "LinkedField",
                    "name": "tickets",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderWizardChannel",
                    "kind": "LinkedField",
                    "name": "channels",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7a79f9cc0caa5a60a18d3b2c98e3d20e",
    "id": null,
    "metadata": {},
    "name": "TicketsOverviewQuery",
    "operationKind": "query",
    "text": "query TicketsOverviewQuery(\n  $companyId: ID!\n  $dateFilter: [Condition]!\n  $channelFilter: [Condition]!\n) {\n  Me {\n    Company(id: $companyId) {\n      OrderWizard {\n        tickets {\n          creationDate\n          id\n        }\n        channels {\n          tableId\n          name\n          id\n        }\n        closed: countTickets(options: {filters: [{name: \"status\", filterType: text, operator: AND, conditions: [{value: \"CLOSED\", type: equals}]}, {name: \"creationDate\", filterType: date, operator: AND, conditions: $dateFilter}, {name: \"channel.id\", filterType: text, operator: AND, conditions: $channelFilter}]})\n        open: countTickets(options: {filters: [{name: \"status\", filterType: text, operator: AND, conditions: [{value: \"OPEN\", type: equals}]}, {name: \"creationDate\", filterType: date, operator: AND, conditions: $dateFilter}, {name: \"channel.id\", filterType: text, operator: AND, conditions: $channelFilter}]})\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b10837f6ace977470b092b18f4504c3a';

module.exports = node;
