import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { ButtonSecondary } from 'pyrexx-react-library'

const QuotaButtonRenderer = (props) => {
  const { data, setModal, setAppointmentId, triggerQuotaReleaseMutation } =
    props

  const { t } = useTranslation()

  const modalMessage =
    t('AFTER CONFIRMATION A SINGLE SERVICE INVOICE INVOICE IS CREATED.') +
    '\n' +
    t('THE INVOICE IS DISPATCHED WITH THE NEXT INVOICE RUN.') +
    '\n\n' +
    t('THE RELIEVED AMOUNTS ARE ADDED BACK TO THE QUOTA.')

  const modalButtonText = t('YES, PLEASE')

  const handleClick = () => {
    setModal({
      show: true,
      message: modalMessage,
      buttonMessage: modalButtonText,
      primaryButton: {
        usePrimaryButton: true,
        useFunction: () => triggerQuotaReleaseMutation(),
      },
      showButtonClose: true,
    })

    setAppointmentId(data?.appointment?.tableId)
  }

  return (
    <StyledButtonSecondary onClick={handleClick}>
      {t('RELEASE FROM QUOTA')}
    </StyledButtonSecondary>
  )
}

const StyledButtonSecondary = styled(ButtonSecondary)`
  height: 30px;
  padding-left: 2rem;
  padding-right: 2rem;
`
export default QuotaButtonRenderer
