import { useTranslation } from 'react-i18next'

import MyOpenEnquiriesQuery from '../queries/MyOpenEnquiriesQuery'
import generateSharedColumnDefs from './generateSharedColumnDefs'
import CloseTicketRenderer from './renderers/CloseTicketRenderer'

import { useUserContext } from '../../../../helper/userContext'

const useMyOpenEnquiriesGrid = ({ refreshGrid }) => {
  const { t } = useTranslation()
  const { user, currentLanguage } = useUserContext()

  const userId = user.userId.replace('pom::pomuser::', '')

  const MyOpenEnquiriesColumnDefs = generateSharedColumnDefs(
    t,
    currentLanguage,
    [
      {
        headerName: t('CHANGE STATUS'),
        sortable: false,
        filter: false,
        cellRenderer: (props) => CloseTicketRenderer({ ...props, refreshGrid }),
      },
    ]
  )

  const getParams = () => {
    return {
      query: MyOpenEnquiriesQuery,
      columnDefs: MyOpenEnquiriesColumnDefs,
      // customFilters: [],
      extractor: (response) => response.Me?.Company?.OrderWizard?.data,
      // customGridOptions: {},
      customVariables: { userId },
    }
  }

  return { getParams }
}

export default useMyOpenEnquiriesGrid
