import { useCallback, useContext } from 'react'
import { withFilterParams } from '../../../../../../../../components/AgGrid/columnDefs'
import {
  CountHouseEntrancesLinkIdsQuery,
  HouseEntrancesLinkIdsGrid,
} from '../queries'
import UserContext from '../../../../../../../../helper/userContext'
import { useTranslation } from 'react-i18next'
import { Routes } from '../../../../../../../../configs/RouteConfig'
import PropertyUnitIdRenderer from '../renderer/PropertyUnitIdRenderer'
import RemoveLink from '../../renderers/RemoveLink'

export const useHouseEntrancesGrid = (setConnectedIds, noRemove) => {
  const { redirectRoute } = useContext(UserContext)
  const { t } = useTranslation()

  const getParams = useCallback(
    (currentService, filterVariables) => {
      const { houseEntrancesIds, connectedIds } = filterVariables
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          default:
            return params.value
        }
      }
      const PropertyManagementHouseEntranceColumnDefs = [
        {
          headerName: t('PYREXX PROPERTY ID'),
          field: 'tableId',
          width: 50,
          cellRenderer: PropertyUnitIdRenderer,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('STREET'),
          field: 'address.street',
          width: 100,
          ...withFilterParams,
        },
        {
          headerName: t('STREET NR.'),
          field: 'address.number',
          width: 70,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('ZIP'),
          field: 'address.zip',
          width: 70,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('CITY'),
          field: 'address.city',
          width: 70,
          ...withFilterParams,
        },
      ]
      if (!noRemove) {
        PropertyManagementHouseEntranceColumnDefs.push({
          headerName: t('REMOVE FROM LINKS'),
          field: 'tableId',
          hide: false,
          width: 150,
          type: 'rightAligned',
          cellRenderer: RemoveLink,
          cellRendererParams: {
            setConnectedIds: setConnectedIds,
            removeLinkIdFromState: (value) => {
              console.log('DEBUG FUNCTION REMOVE connectedIds', connectedIds)
              setConnectedIds({
                ...connectedIds,
                propertyUnit: connectedIds.propertyUnit.filter(
                  (id) => id !== value
                ),
              })
            },
          },
        })
      }
      const PropertyManagementHouseEntranceCustomFilter = [
        {
          name: 'tableId',
          filterType: 'text',
          operator: 'AND',
          conditions: [
            {
              value: houseEntrancesIds,
              type: 'equals',
            },
          ],
        },
      ]
      return {
        query: HouseEntrancesLinkIdsGrid,
        columnDefs: PropertyManagementHouseEntranceColumnDefs,
        customFilters: PropertyManagementHouseEntranceCustomFilter,
        countQuery: CountHouseEntrancesLinkIdsQuery,
        extractor: (response) => response.Me?.Company?.data,
        customGridOptions: {
          getContextMenuItems: (params) => {
            return [
              {
                // custom item
                name: t('GO TO PROPERTY {TABLEID}', {
                  TABLEID: params?.node?.data?.tableId,
                }),
                action: function () {
                  redirectRoute(
                    Routes.propertyUnit + '/' + params?.node?.data?.tableId
                  )
                },
              },
              'copy',
            ]
          },
          frameworkComponents: {},
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
          },
        },
        customVariables: {},
      }
    },
    [noRemove, redirectRoute, setConnectedIds, t]
  )
  return { getParams }
}
