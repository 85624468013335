import React from 'react'

import { PyrexxOverviewContext } from './PyrexxOverviewContext'

import useFilter from '../hooks/useFilter'

const PyrexxOverviewProvider = ({
  initialStateUiFilters = [],
  initialStateBoxFilters = [],
  children,
}) => {
  // Filter bar elements
  const {
    filters: uiFilters,
    saveFilters: saveUiFilters,
    removeFilter: removeUiFilter,
  } = useFilter(initialStateUiFilters)

  // Status box elements
  const {
    filters: boxFilters,
    saveFilters: saveBoxFilters,
    removeFilter: removeBoxFilter,
  } = useFilter(initialStateBoxFilters)

  return (
    <PyrexxOverviewContext.Provider
      value={{
        uiFilters,
        saveUiFilters,
        removeUiFilter,
        boxFilters,
        saveBoxFilters,
        removeBoxFilter,
        uiboxFilters: [...uiFilters, ...boxFilters],
      }}
    >
      {children}
    </PyrexxOverviewContext.Provider>
  )
}

export default PyrexxOverviewProvider
