import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query InvoiceDownloadQuery($invoiceId: ID!, $companyId: ID!) {
    Me {
      Company(id: $companyId) {
        id
        Invoice(id: $invoiceId) {
          id
          download {
            name
            path
            token
          }
        }
      }
    }
  }
`
