import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { DropdownAria } from '../../components/DropdownAria'
import SimpleButton from '../../components/Simple/SimpleButton'
import Spacer from '../../components/Spacer'
import InvoicesServiceCostsGrid from './InvoicesServiceCostsGrid'

import { pushNewUrlToHistory } from '../../helper/urlHelpers'
import getSearchParamsFromUrl from '../../helper/getSearchParamsFromUrl'
import { CONTRACT, TIMEFRAME } from '../../configs/FilterConfig'

const InvoicesServiceCosts = (props) => {
  const { contractsProps = {} } = props

  const { t } = useTranslation()

  const { contract: initialContract = '', timeframe: initialTimeframe = '' } =
    getSearchParamsFromUrl() || {}

  const [selectedContract, setSelectedContract] = useState(initialContract)
  const [selectedTimeframe, setSelectedTimeframe] = useState(initialTimeframe)

  const updateContract = (value) => {
    pushNewUrlToHistory(CONTRACT, value)
    setSelectedContract(value)
  }

  const updateTimeframe = (value) => {
    pushNewUrlToHistory(TIMEFRAME, value)
    setSelectedTimeframe(value)
  }

  const clearFilters = () => {
    setSelectedContract('')
    pushNewUrlToHistory(CONTRACT, '', 'delete')

    setSelectedTimeframe('')
    pushNewUrlToHistory(TIMEFRAME, '', 'delete')
  }

  const loadGrid = selectedContract && selectedTimeframe

  return (
    <>
      <p>{t('SELECT A CONTRACT AND A TIMEFRAME TO SEE THE GRID')}</p>

      <FilterWrapper>
        <DropdownAria
          label={t('CONTRACTS')}
          items={contractsProps.contractsList}
          value={selectedContract}
          setValue={updateContract}
        />

        <DropdownAria
          label={t('TIMEFRAMES')}
          items={contractsProps.timeframesList}
          value={selectedTimeframe}
          setValue={updateTimeframe}
        />

        {loadGrid && (
          <SimpleButton onClick={clearFilters} style={{ marginTop: '1.5rem' }}>
            {t('CLEAR FILTERS')}
          </SimpleButton>
        )}
      </FilterWrapper>

      <Spacer />

      {loadGrid && (
        <InvoicesServiceCostsGrid
          contractId={selectedContract}
          timeframeId={selectedTimeframe}
        />
      )}
    </>
  )
}

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

export default InvoicesServiceCosts
