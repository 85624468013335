import { useCallback, useContext } from 'react'
import { withHiddenParams } from '../../../../../../../../components/AgGrid/columnDefs'
import {
  InvoiceNumberRenderer,
  InvoiceStatusRenderer,
  InvoiceTypeRenderer,
  RemoveLink,
} from '../renderers'
import { CountInvoicesLinkIdsQuery, InvoicesLinkIdGridQuery } from '../queries'
import {
  dateTimeValueGetter,
  invoiceStatusValueGetter,
  invoiceTypeValueGetter,
} from '../valueGetter'
import UserContext from '../../../../../../../../helper/userContext'
import { useTranslation } from 'react-i18next'
import { Routes } from '../../../../../../../../configs/RouteConfig'
import {
  DateRenderer,
  PriceRenderer,
} from '../../../../../../../../components/AgGrid/renderers'
import InvoiceDateRenderer from '../renderers/InvoiceDateRenderer'
import formatPrice from '../../../../../../../../helper/formatPrice'
import StatusFilter from '../filters/StatusFilter'
import InvoiceTypeFilter from '../filters/InvoiceTypeFilter'
import SimpleGridFilter from '../../../../../../../../components/AgGrid/filters/SimpleGridFilter'

export const useInvoicesGrid = (setConnectedIds, noRemove) => {
  const { currentLanguage, redirectRoute } = useContext(UserContext)
  const { t } = useTranslation()

  const getParams = useCallback(
    (currentService, filterVariables) => {
      const { invoiceIds, connectedIds } = filterVariables
      console.log('DEBUG FUNCTION REMOVE connectedIds', connectedIds)

      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          case 'status':
            return t(invoiceStatusValueGetter(params))
          case 'invoiceDate':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'P',
            })
          case 'type':
            return t(invoiceTypeValueGetter(params))
          case 'price.net':
            return formatPrice(params.value)
          case 'paymentTarget':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'P',
            })
          case 'value':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'P',
            })
          default:
            return params.value
        }
      }
      const InvoicesGridColumnDefs = [
        {
          field: 'contract.serviceCategory.tableId',
          width: 50,
          filter: 'SimpleGridFilter',
          ...withHiddenParams,
        },
        {
          colId: 'contract',
          field: 'contract.tableId',
          width: 50,
          filter: 'SimpleGridFilter',
          ...withHiddenParams,
        },
        {
          headerName: t('STATUS'),
          field: 'status',
          width: 100,
          filter: 'StatusFilter',
          cellRenderer: InvoiceStatusRenderer,
        },
        {
          headerName: t('INVOICE NUMBER'),
          field: 'invoiceNumber',
          cellRenderer: InvoiceNumberRenderer,
          width: 150,
          filterParams: {
            filterOptions: ['contains'],
            suppressAndOrCondition: true,
            debounceMs: 200,
          },
        },
        {
          headerName: t('INVOICE DATE'),
          field: 'invoiceDate',
          width: 150,
          filter: 'agDateColumnFilter',
          filterParams: {
            buttons: ['apply', 'clear', 'reset'],
            closeOnApply: true,
            suppressAndOrCondition: true,
          },
          sort: 'desc',
          cellRenderer: InvoiceDateRenderer,
        },
        {
          headerName: t('INVOICE TYPE'),
          field: 'type',
          width: 200,
          filter: 'InvoiceTypeFilter',
          cellRenderer: InvoiceTypeRenderer,
        },
        {
          headerName: t('NET TOTAL'),
          field: 'price.net',
          cellRendererFramework: PriceRenderer,
          filter: 'agNumberColumnFilter',
          width: 150,
          type: 'rightAligned',
        },
        {
          headerName: t('VAT'),
          field: 'price.vat',
          cellRendererFramework: PriceRenderer,
          filter: 'agNumberColumnFilter',
          hide: true,
          width: 150,
          type: 'rightAligned',
        },
        {
          headerName: t('GROSS TOTAL'),
          field: 'price.gross',
          cellRenderer: PriceRenderer,
          filter: 'agNumberColumnFilter',
          width: 150,
          type: 'rightAligned',
        },
        {
          headerName: t('PAYMENT TARGET'),
          field: 'paymentTarget',
          filter: 'agDateColumnFilter',
          hide: true,
          width: 150,
          cellRenderer: DateRenderer,
        },
        {
          headerName: t('BENEFICIARY'),
          field: 'name',
          width: 300,
          hide: true,
          // suppressSizeToFit: true
        },
        {
          headerName: t('DESCRIPTION'),
          field: 'description',
          width: 300,
          hide: true,
          // suppressSizeToFit: true
        },
        {
          headerName: t('VALUE DATE'),
          field: 'value',
          filter: 'agDateColumnFilter',
          hide: true,
          width: 150,
          cellRenderer: DateRenderer,
        },
      ]
      if (!noRemove) {
        InvoicesGridColumnDefs.push({
          headerName: t('REMOVE FROM LINKS'),
          field: 'tableId',
          hide: false,
          width: 150,
          cellRenderer: RemoveLink,
          type: 'rightAligned',
          cellRendererParams: {
            setConnectedIds: setConnectedIds,
            removeLinkIdFromState: (value) => {
              console.log('DEBUG FUNCTION REMOVE connectedIds', connectedIds)
              setConnectedIds({
                ...connectedIds,
                invoice: connectedIds.invoice.filter((id) => id !== value),
              })
            },
          },
        })
      }
      console.log(
        'DEBUG USEHOOK: invoiceIds, connectedIds',
        invoiceIds,
        connectedIds
      )
      const InvoicesGridCustomFilter = [
        {
          name: 'tableId',
          filterType: 'text',
          operator: 'AND',
          conditions: [
            {
              value: invoiceIds,
              type: 'equals',
            },
          ],
        },
      ]
      return {
        query: InvoicesLinkIdGridQuery,
        columnDefs: InvoicesGridColumnDefs,
        customFilters: InvoicesGridCustomFilter,
        countQuery: CountInvoicesLinkIdsQuery,
        extractor: (response) => response.Me?.Company?.data,
        customGridOptions: {
          getContextMenuItems: (params) => {
            return [
              {
                // custom item
                name: t('GO TO INVOICE {INVOICENUMBER}', {
                  INVOICENUMBER: params?.node?.data?.invoiceNumber,
                }),
                action: function () {
                  redirectRoute(
                    Routes.invoice + '/' + params?.node?.data?.tableId
                  )
                },
              },
              'copy',
              'chartRange',
            ]
          },
          frameworkComponents: {
            StatusFilter,
            InvoiceTypeFilter,
            SimpleGridFilter,
          },
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
            skipColumns: ['contract.serviceCategory.tableId', 'contract'],
          },
        },
        customVariables: {},
      }
    },
    [currentLanguage, noRemove, redirectRoute, setConnectedIds, t]
  )
  return { getParams }
}
