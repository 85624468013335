/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateOrderContainerQueryVariables = {|
  companyId: string
|};
export type CreateOrderContainerQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +OrderWizard: ?{|
        +channels: ?$ReadOnlyArray<?{|
          +name: string,
          +topics: ?$ReadOnlyArray<?{|
            +name: string,
            +reasons: $ReadOnlyArray<?{|
              +name: string,
              +pipeline: number,
              +id: string,
            |}>,
          |}>,
        |}>,
        +mostUsedReasons: ?$ReadOnlyArray<?{|
          +id: string,
          +tableId: number,
          +channel: ?{|
            +name: string
          |},
          +topic: ?{|
            +name: string
          |},
          +name: string,
          +pipeline: number,
        |}>,
        +recentlyCreatedTickets: ?$ReadOnlyArray<?{|
          +id: string,
          +tableId: number,
          +channel: ?{|
            +id: string,
            +tableId: number,
            +name: string,
          |},
          +topic: ?{|
            +id: string,
            +tableId: number,
            +name: string,
          |},
          +reason: ?{|
            +id: string,
            +tableId: number,
            +name: string,
          |},
          +creationDate: ?any,
          +user: ?{|
            +firstname: ?string,
            +lastname: ?string,
          |},
        |}>,
      |}
    |}
  |}
|};
export type CreateOrderContainerQuery = {|
  variables: CreateOrderContainerQueryVariables,
  response: CreateOrderContainerQueryResponse,
|};
*/


/*
query CreateOrderContainerQuery(
  $companyId: ID!
) {
  Me {
    Company(id: $companyId) {
      OrderWizard {
        channels {
          name
          topics {
            name
            reasons {
              name
              pipeline
              id
            }
            id
          }
          id
        }
        mostUsedReasons: mostUsed {
          id
          tableId
          channel {
            name
            id
          }
          topic {
            name
            id
          }
          name
          pipeline
        }
        recentlyCreatedTickets: tickets(options: {endRow: 2, sorting: [{colId: "creationDate", sort: "DESC"}]}) {
          id
          tableId
          channel {
            id
            tableId
            name
          }
          topic {
            id
            tableId
            name
          }
          reason {
            id
            tableId
            name
          }
          creationDate
          user {
            firstname
            lastname
            id
          }
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pipeline",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderWizardReason",
  "kind": "LinkedField",
  "name": "reasons",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v7 = [
  (v2/*: any*/)
],
v8 = [
  {
    "kind": "Literal",
    "name": "options",
    "value": {
      "endRow": 2,
      "sorting": [
        {
          "colId": "creationDate",
          "sort": "DESC"
        }
      ]
    }
  }
],
v9 = [
  (v4/*: any*/),
  (v6/*: any*/),
  (v2/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderWizardChannel",
  "kind": "LinkedField",
  "name": "channel",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderWizardTopic",
  "kind": "LinkedField",
  "name": "topic",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderWizardReason",
  "kind": "LinkedField",
  "name": "reason",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creationDate",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v16 = [
  (v2/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateOrderContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderWizardQueries",
                "kind": "LinkedField",
                "name": "OrderWizard",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderWizardChannel",
                    "kind": "LinkedField",
                    "name": "channels",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrderWizardTopic",
                        "kind": "LinkedField",
                        "name": "topics",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "mostUsedReasons",
                    "args": null,
                    "concreteType": "OrderWizardReason",
                    "kind": "LinkedField",
                    "name": "mostUsed",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrderWizardChannel",
                        "kind": "LinkedField",
                        "name": "channel",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrderWizardTopic",
                        "kind": "LinkedField",
                        "name": "topic",
                        "plural": false,
                        "selections": (v7/*: any*/),
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "recentlyCreatedTickets",
                    "args": (v8/*: any*/),
                    "concreteType": "OrderWizardTicket",
                    "kind": "LinkedField",
                    "name": "tickets",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserInfo",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "tickets(options:{\"endRow\":2,\"sorting\":[{\"colId\":\"creationDate\",\"sort\":\"DESC\"}]})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateOrderContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderWizardQueries",
                "kind": "LinkedField",
                "name": "OrderWizard",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderWizardChannel",
                    "kind": "LinkedField",
                    "name": "channels",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrderWizardTopic",
                        "kind": "LinkedField",
                        "name": "topics",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v5/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "mostUsedReasons",
                    "args": null,
                    "concreteType": "OrderWizardReason",
                    "kind": "LinkedField",
                    "name": "mostUsed",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrderWizardChannel",
                        "kind": "LinkedField",
                        "name": "channel",
                        "plural": false,
                        "selections": (v16/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrderWizardTopic",
                        "kind": "LinkedField",
                        "name": "topic",
                        "plural": false,
                        "selections": (v16/*: any*/),
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "recentlyCreatedTickets",
                    "args": (v8/*: any*/),
                    "concreteType": "OrderWizardTicket",
                    "kind": "LinkedField",
                    "name": "tickets",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserInfo",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "tickets(options:{\"endRow\":2,\"sorting\":[{\"colId\":\"creationDate\",\"sort\":\"DESC\"}]})"
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "444c047cec0f976e9540d274e234ecbe",
    "id": null,
    "metadata": {},
    "name": "CreateOrderContainerQuery",
    "operationKind": "query",
    "text": "query CreateOrderContainerQuery(\n  $companyId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      OrderWizard {\n        channels {\n          name\n          topics {\n            name\n            reasons {\n              name\n              pipeline\n              id\n            }\n            id\n          }\n          id\n        }\n        mostUsedReasons: mostUsed {\n          id\n          tableId\n          channel {\n            name\n            id\n          }\n          topic {\n            name\n            id\n          }\n          name\n          pipeline\n        }\n        recentlyCreatedTickets: tickets(options: {endRow: 2, sorting: [{colId: \"creationDate\", sort: \"DESC\"}]}) {\n          id\n          tableId\n          channel {\n            id\n            tableId\n            name\n          }\n          topic {\n            id\n            tableId\n            name\n          }\n          reason {\n            id\n            tableId\n            name\n          }\n          creationDate\n          user {\n            firstname\n            lastname\n            id\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '78467828b16bec7564417a9ae2617c7e';

module.exports = node;
