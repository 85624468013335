import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAgGridUltra } from '../../../../../../../components/AgGrid/AgGridUltra/hooks/useAgGridUltra'
import { useUsageUnitsGrid } from './gridConfigHooks/useUsageUnitsGrid'

const UsageUnitsGrid = (props) => {
  const {
    usageUnitsIds,
    setConnectedIds = () => {},
    connectedIds,
    noRemove = false,
  } = props
  const { t } = useTranslation()

  const grids = [
    {
      id: 'link-ids-usage-unit-grid',
      label: t('USAGE UNITS'),
      downloadFileName: t('USAGE UNITS'),
      useGetParams: useUsageUnitsGrid(setConnectedIds, noRemove),
    },
  ]

  const { generateGrid, setFilterVariables, filterVariables, setSelectedRows } =
    useAgGridUltra(
      'LinkIdsUSageUnits',
      grids,
      { usageUnitsIds, connectedIds },
      false,
      false,
      3,
      true,
      true
    )
  useEffect(() => {
    console.log('REFRESH force', connectedIds, usageUnitsIds)
    setSelectedRows([])
    setFilterVariables({
      usageUnitsIds,
      connectedIds,
    })
  }, [connectedIds, usageUnitsIds, setFilterVariables, setSelectedRows])

  return generateGrid(!filterVariables?.usageUnitsIds)
}

export default UsageUnitsGrid
