function dummyTranslate(text) {
  return text
}

const appointmentTypeEnumList = (value) => {
  switch (value) {
    case 'REGULAR':
    case 'T1':
    case 'T2':
    case 'T':
      return dummyTranslate('REGULAR APPOINTMENT')

    case 'SERVICE':
    case 'TI':
    case 'TS':
      return dummyTranslate('SERVICE APPOINTMENT')

    case 'SPECIAL':
    case 'TX':
    case 'TA':
      return dummyTranslate('SPECIAL APPOINTMENTS')

    default:
      return ''
  }
}

export default appointmentTypeEnumList
