import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line
import styled from 'styled-components/macro'

import { Icon, portalTheme } from 'pyrexx-react-library'

import InvoicesGrid from './grids/InvoicesGrid/InvoicesGrid'
import HouseEntrancesGrid from './grids/HouseEntrancesGrid/HouseEntrancesGrid'
import TenantsGrid from './grids/TenantsGrid/TenantsGrid'
import UsageUnitsGrid from './grids/UsageUnitsGrid/UsageUnitsGrid'

import { translateOrderWizard } from '../../../Enquiries/translations/translateOrderWizard'
import { StyledContainerLink } from '../LinkIds.styles'

export const ConnectedIds = (props) => {
  const {
    connectedIds,
    setConnectedIds,
    setSubStep,
    countDataLink,
    completeCatObject,
  } = props

  const { t } = useTranslation()

  return (
    <Container fluid>
      <Row
        style={{
          backgroundColor: 'white',
          paddingTop: '1rem',
          paddingBottom: '1rem',
          marginBottom: '1rem',
        }}
        justify='center'
      >
        <Col>
          <Row>
            <Col>
              <strong>{t('CATEGORY')}</strong>
            </Col>
          </Row>
          <Row>
            <Col>{translateOrderWizard(completeCatObject.mainCat.name, t)}</Col>
          </Row>
        </Col>

        <Col>
          <Row>
            <Col>
              <strong>{t('REASON FOR COMMISSIONING')}</strong>
            </Col>
          </Row>
          <Row>
            <Col>{translateOrderWizard(completeCatObject.reason.name, t)}</Col>
          </Row>
        </Col>

        <Col>
          <Row>
            <Col>
              <strong>{t('LINKED ORDER DATA')}</strong>
            </Col>
          </Row>
          <Row>
            <Col>{countDataLink}</Col>
          </Row>
        </Col>

        <Col>
          <StyledContainerLink
            key='addMoreDataLinks'
            fluid
            onClick={() => {
              setSubStep(1)
              // handleModalClose()
            }}
          >
            <Row>
              <Col
                css={`
                  display: flex;
                  gap: 12px;
                  align-items: center;
                `}
              >
                <Icon
                  icon='add-item'
                  size='30px'
                  color={portalTheme.color.linkColorPrimary}
                />
                {t('ADD MORE LINKS')}
              </Col>
            </Row>
          </StyledContainerLink>
        </Col>
      </Row>

      {connectedIds?.invoice?.length > 0 && (
        <Row>
          <Col>
            <InvoicesGrid
              invoiceIds={connectedIds.invoice}
              setConnectedIds={setConnectedIds}
              connectedIds={connectedIds}
            />
          </Col>
        </Row>
      )}

      {connectedIds?.propertyUnit?.length > 0 && (
        <Row>
          <Col>
            <HouseEntrancesGrid
              houseEntrancesIds={connectedIds.propertyUnit}
              setConnectedIds={setConnectedIds}
              connectedIds={connectedIds}
            />
          </Col>
        </Row>
      )}

      {connectedIds?.usageUnit?.length > 0 && (
        <Row>
          <Col>
            <UsageUnitsGrid
              usageUnitsIds={connectedIds.usageUnit}
              setConnectedIds={setConnectedIds}
              connectedIds={connectedIds}
            />
          </Col>
        </Row>
      )}

      {connectedIds?.tenant?.length > 0 && (
        <Row>
          <Col>
            <TenantsGrid
              setConnectedIds={setConnectedIds}
              connectedIds={connectedIds}
            />
          </Col>
        </Row>
      )}
    </Container>
  )
}
