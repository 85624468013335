import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useHistory } from 'react-router-dom'

import {
  ButtonPrimary,
  Icon,
  SimpleModal,
  SimpleNote,
} from 'pyrexx-react-library'
import SimpleButton from '../../../../components/Simple/SimpleButton'
import StatusLightWithLabel from '../../../../components/Status/StatusLightWithLabel'
import {
  Box,
  CustomButtonPrimary,
  CustomButtonSecondary,
  Label,
  Text,
} from '../shared'

import { AppointmentStatusMap } from '../../../../constants/colorMap'
import { appointmentStatusEnumList } from '../../../../components/AgGrid/enumLists'
import { translateOrderWizard } from '../../Enquiries/translations/translateOrderWizard'
import CloseTicketMutation from '../../Enquiries/mutations/CloseTicketMutation'
import DiscardTicketMutation from '../../Enquiries/mutations/DiscardTicketMutation'
import { useUserContext } from '../../../../helper/userContext'
import { Routes } from '../../../../configs/RouteConfig'
import { ButtonRow } from '../../../Admin/UserManagement/styles'

const TicketStatus = ({ ticket = {} }) => {
  const {
    tableId: ticketId,
    status = '---',
    zammadTicketId = '---',
    referenceNumber = '---',
    creationDate = '---',
    lastUpdate = '---',
    channel = {},
    reason = {},
    user = {
      firstname: '---',
      lastname: '',
      email: '',
      phone: '',
    },
  } = ticket

  const { t } = useTranslation()
  const history = useHistory()
  const {
    user: { companyId, accessToken },
    currentLanguage,
  } = useUserContext()

  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState({
    loading: false,
    text: '',
    confirmOperation: () => {},
  })

  const discardTicket = () => {
    setLoading(true)

    DiscardTicketMutation(
      { companyId, ticketId },
      accessToken,
      () => {
        closeModal()
        history.push('/' + currentLanguage + Routes.support + Routes.enquiries)
      },
      () => {
        closeModal()
      }
    )
  }

  const closeTicket = () => {
    setLoading(true)

    CloseTicketMutation(
      { companyId, ticketId },
      accessToken,
      () => {
        closeModal()
        history.push('/' + currentLanguage + Routes.support + Routes.enquiries)
      },
      () => {
        closeModal()
      }
    )
  }

  const openModalDiscard = () => {
    setModal({
      isOpen: true,
      text: t('ARE YOU SURE YOU WANT TO DISCARD THE TICKET?'),
      confirmOperation: discardTicket,
    })
  }

  const openModalClose = () => {
    setModal({
      isOpen: true,
      text: t('ARE YOU SURE YOU WANT TO CLOSE THE TICKET?'),
      confirmOperation: closeTicket,
    })
  }

  const closeModal = () => {
    setModal({
      isOpen: false,
      text: '',
      confirmOperation: () => {},
    })
    setLoading(false)
  }

  const seeHistory = () => {}

  const channelName = channel?.name || '---'
  const reasonName = reason?.name || '---'

  const isTicketOpen = status !== 'CLOSED'

  return (
    <Wrapper>
      <Column>
        <Row>
          <Label>{t('TICKET STATUS')}</Label>
          <StatusLightWithLabel
            status={AppointmentStatusMap[status]}
            label={t(appointmentStatusEnumList(status))}
          />
        </Row>

        <Row>
          <Label>{t('TICKET NUMBER')}</Label>
          <Text>{zammadTicketId}</Text>
        </Row>

        <Row>
          {isTicketOpen && (
            <CustomButtonSecondary onClick={openModalDiscard}>
              {t('DISCARD TICKET')}
            </CustomButtonSecondary>
          )}
        </Row>

        <Row>
          {isTicketOpen && (
            <CustomButtonPrimary onClick={openModalClose}>
              {t('CLOSE TICKET')}
            </CustomButtonPrimary>
          )}
          {isTicketOpen && <AutocloseMsg>autoclose in X days</AutocloseMsg>}
        </Row>
      </Column>

      <Column>
        <Row>
          <Label>{t('CREATION DATE')}</Label>
          <Text>{creationDate}</Text>
        </Row>

        <Row>
          <Label>{t('LAST UPDATED')}</Label>
          <Text>{lastUpdate || '---'}</Text>
        </Row>

        <Row>
          <SimpleButton
            onClick={seeHistory}
            css={`
              display: flex;
              justify-content: flex-end;
              gap: 8px;
              width: 100%;
            `}
          >
            <Icon icon='info' size='20' /> {t('SEE HISTORY')}
          </SimpleButton>
        </Row>
      </Column>

      <Column>
        <Row>
          <Label>{t('CATEGORY')}</Label>
          <Text>{translateOrderWizard(channelName, t)}</Text>
        </Row>

        <Row>
          <Label>{t('COMMISION NUMBER')}</Label>
          <Text>{referenceNumber}</Text>
        </Row>

        <Row>
          <Label>{t('COMMISION REASON')}</Label>
          <Text>{translateOrderWizard(reasonName, t)}</Text>
        </Row>
      </Column>

      <Column>
        <Row>
          <Label>{t('CONTRACT GIVER')}</Label>
          <Text>
            {user?.firstname} {user?.lastname}
          </Text>
          <Text>{user?.email}</Text>
          <Text>{user?.phone}</Text>
        </Row>
      </Column>

      <SimpleModal handleModalClose={closeModal} isOpen={modal.isOpen}>
        <SimpleNote text={modal.text} />
        <hr />
        <ButtonRow>
          <ButtonPrimary onClick={modal.confirmOperation} isLoading={loading}>
            {t('CONFIRM')}
          </ButtonPrimary>
        </ButtonRow>
      </SimpleModal>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  display: flex;
  padding: 24px 0;
`

const Column = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 0 20px;
  border-right: 1px solid gray;

  &:last-child {
    border-right: none;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const AutocloseMsg = styled.span`
  font-size: 0.85rem;
  text-align: center;

  transform: translateY(-5px);
`

export default TicketStatus
