import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { ButtonPrimary } from 'pyrexx-react-library'
import ArrowWithBadge from './ArrowWithBadge'
import Dropleft from './Dropleft'
import { DropleftBadge } from '../Badges'

import { updateUrls } from '../../helper/updateUrl'

const Dropdown = ({
  isDropdownOpen = false, // Comes from DropdownWithLabel because of passPropsToChildren prop
  setIsDropdownOpen = () => {}, // Comes from DropdownWithLabel because of passPropsToChildren prop
  ...props
}) => {
  const {
    overviewFilterDefinitions = [],
    overviewFilterData = {},
    saveFilters = () => {},
    filters = [],
    align = true,
  } = props
  const { t } = useTranslation()

  const closeDropdown = () => setIsDropdownOpen(false)

  const [dropleft, setDropleft] = useState('')
  const toggleDropleft = (filterId) => {
    setDropleft((prev) => (prev && prev === filterId ? '' : filterId))
  }
  const closeDropleft = () => setDropleft('')

  const [innerFilters, setInnerFilters] = useState([])
  const saveInnerFilters = (f) => {
    setInnerFilters((prevState) => {
      const filteredState = prevState.filter(
        (ff) => ff?.filterId !== f?.filterId
      )
      return [...filteredState, f]
    })
  }

  useEffect(() => {
    if (!isDropdownOpen) {
      closeDropleft()
    }
  }, [isDropdownOpen])

  useEffect(() => {
    setInnerFilters(filters)
  }, [filters])

  const url = new URL(window.location)

  if (overviewFilterDefinitions.length <= 0) {
    return <DropdownWrapper>{t('NO FILTER AVAILABLE')}</DropdownWrapper>
  }

  return (
    <DropdownWrapper>
      {overviewFilterDefinitions.map(
        ({
          filterId,
          title,
          placeholderLabel,
          filterModelGenerator = () => {},
        }) => {
          const currentInnerFilter = innerFilters.find(
            (f) => f?.filterId === filterId
          )
          const data = overviewFilterData[filterId] || []

          const hasValue = currentInnerFilter?.label ?? false

          return (
            <DropdownItem key={filterId}>
              <span>{title}</span>
              <ArrowWithBadge
                isActive={dropleft === filterId}
                handleClick={() => toggleDropleft(filterId)}
                noValue={!hasValue}
              >
                {currentInnerFilter?.label || placeholderLabel}
              </ArrowWithBadge>

              <Dropleft
                tabIndex='-1'
                filterId={filterId}
                dropleft={dropleft}
                align={align}
              >
                {data.map((d) => {
                  const currentDropleft = filterModelGenerator(d) || {}

                  const specialCase = d?.specialCase || false

                  if (specialCase) {
                    return (
                      <div key={d?.id} style={{ paddingTop: '8px' }}>
                        <span
                          style={{
                            fontSize: '12px',
                            fontStyle: 'italic',
                            color: '#999',
                          }}
                        >
                          {t('SINGLE GRAFFITI REMOVAL')}
                        </span>
                        <DropleftBadge
                          onClick={() => {
                            saveInnerFilters(currentDropleft)
                            closeDropleft()
                          }}
                          selected={
                            currentInnerFilter?.tableId ===
                            currentDropleft?.tableId
                          }
                        >
                          {currentDropleft?.label}
                        </DropleftBadge>
                      </div>
                    )
                  }

                  return (
                    <DropleftBadge
                      key={d?.id}
                      onClick={() => {
                        saveInnerFilters(currentDropleft)
                        closeDropleft()
                      }}
                      selected={
                        currentInnerFilter?.tableId === currentDropleft?.tableId
                      }
                    >
                      {currentDropleft?.label}
                    </DropleftBadge>
                  )
                })}
              </Dropleft>
            </DropdownItem>
          )
        }
      )}

      <ButtonPrimary
        onClick={() => {
          updateUrls(innerFilters, url, 'set')
          saveFilters(innerFilters)
          closeDropdown()
        }}
      >
        {t('APPLY FILTER')}
      </ButtonPrimary>
    </DropdownWrapper>
  )
}

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 335px;

  background-color: #fff;
  box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);

  padding: 16px;
`

export const DropdownItem = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;

  padding: 16px;
  margin: 0 -16px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  & > span {
    margin-bottom: 8px;
  }
`

Dropdown.propTypes = {
  overviewFilterDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      filterId: PropTypes.string,
      title: PropTypes.string,
      placeholderLabel: PropTypes.string,
      // data: PropTypes.array,
      filterModelGenerator: PropTypes.func,
    })
  ),
  overviewFilterData: PropTypes.object,
}

export default Dropdown
