import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'

import { Icon, SimpleModal, SimpleNote } from 'pyrexx-react-library'
import SimpleButton from '../../../components/Simple/SimpleButton'
import handleLocalStorage from '../../../helper/handleLocalStorage'

const Dropzone = styled.div`
  background-color: var(--pyrexx-dropdown-badge-color, #c5cfda);
  height: 240px;
  border-radius: 19px;

  padding: 16px;
`

const DropzoneInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  border-width: 4px;
  border-style: dashed;
  border-color: transparent;
  border-radius: 19px;

  transition: border-color 200ms ease-out;

  ${(props) =>
    props.isDragActive &&
    css`
      border-color: #fff;
      transition: border-color 200ms ease-in;
    `}
`

export const StyledSimpleModal = styled(SimpleModal)`
  user-select: auto;

  & [data-simple-modal-content] {
    max-width: 800px;
    user-select: auto;
  }

  & [data-simple-modal-button-close] {
    position: absolute;
  }

  & h4,
  & p,
  & button {
    margin: 8px 0;
    text-align: left;
  }
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const UploadImageModal = ({ isOpen, handleModalClose, setImgSrc }) => {
  const { t } = useTranslation()
  // eslint-disable-next-line
  const [uploadedImage, setUploadedImage] = useState(null)
  const [uploadImageError, setUploadImageError] = useState(null)

  const onDropAccepted = useCallback((acceptedFiles) => {
    console.log(acceptedFiles)
    setUploadedImage(acceptedFiles[0])
    setUploadImageError(null)

    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        handleLocalStorage('set', 'temp-img-src', reader.result)
        setImgSrc(reader.result)
        handleModalClose()
      }

      reader.readAsDataURL(file)
    })
    // eslint-disable-next-line
  }, [])

  const onDropRejected = useCallback((rejectedFiles) => {
    console.log(rejectedFiles)
    setUploadedImage(null)
    setUploadImageError(rejectedFiles[0].errors[0])
  }, [])

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDropAccepted,
    onDropRejected,
    noClick: true,
    noKeyboard: true,
    maxSize: 2000000,
    maxFiles: 1,
    accept: '.jpg, .jpeg, .png',
  })

  return (
    <StyledSimpleModal isOpen={isOpen} handleModalClose={handleModalClose}>
      <h4>{t('UPLOAD PROFILE PICTURE')}</h4>
      <p>{t('FILE EXTENSIONS: JPG, JPEG, PNG. MAXIMUM FILE SIZE 2MB')}</p>
      <SimpleButton font='quicksand' onClick={open}>
        {t('CLICK HERE TO UPLOAD')}
      </SimpleButton>
      <Row>
        <p>{t('OR USE DRAG AND DROP')}</p>

        {uploadImageError?.message.length > 0 && (
          <SimpleNote text={t(uploadImageError?.message)} noteStatus='error' />
        )}
      </Row>

      <Dropzone {...getRootProps()}>
        <DropzoneInner isDragActive={isDragActive}>
          <input {...getInputProps()} />
          <Icon icon='drag-and-drop' size='80px' color='#fff' />
        </DropzoneInner>
      </Dropzone>

      {/* {uploadedImage?.name.length > 0 && ( */}
      {/*  <Row> */}
      {/*    <p> */}
      {/*      {t('UPLOADED IMAGE')}: {uploadedImage?.name} -{' '} */}
      {/*      {(uploadedImage?.size / 1000).toFixed(2)} KB */}
      {/*    </p> */}
      {/*    <SimpleButton font='quicksand' onClick={() => setUploadedImage(null)}> */}
      {/*      {t('REMOVE')} */}
      {/*    </SimpleButton> */}
      {/*  </Row> */}
      {/* )} */}
    </StyledSimpleModal>
  )
}

UploadImageModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
}

export default UploadImageModal
