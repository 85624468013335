import { useCallback, useContext } from 'react'
import {
  columnCheckbox,
  withFilterParams,
} from '../../../components/AgGrid/columnDefs'
import { CountInventoryQuery, InventoryGridQuery } from '../queries'
import {
  dateTimeValueGetter,
  roomTypeValueGetter,
  tenantFullNameValueGetter,
} from '../valueGetter'
import UserContext from '../../../helper/userContext'
import { useTranslation } from 'react-i18next'
import { Routes } from '../../../configs/RouteConfig'
import {
  RoomTypeRenderer,
  TenantFullNameRenderer,
  UsageUnitLinkRenderer,
} from '../renderers'
import { DateTimeRenderer } from '../../../components/AgGrid/renderers'
import RoomTypeFilter from '../../UsageUnit/filters/RoomTypeFilter'

export const useInventoryGrid = () => {
  const { redirectRoute, currentLanguage } = useContext(UserContext)
  const { t } = useTranslation()
  const getParams = useCallback(
    (currentService, filterVariables) => {
      const { propertyUnitId } = filterVariables
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          case 'position.room.type':
            return t(roomTypeValueGetter(params))
          case 'tenants':
            return tenantFullNameValueGetter(params)
          case 'installationDate':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'Pp',
            })
          default:
            return params.value
        }
      }
      const InventoryColumnDefs = [
        columnCheckbox,
        {
          headerName: t('CUSTOMERS OWN USAGE UNIT NUMBER'),
          field: 'referenceNumber',
          width: 70,
          cellRenderer: UsageUnitLinkRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('ROOM'),
          field: 'position.room.type',
          width: 100,
          filter: 'RoomTypeFilter',
          cellRenderer: RoomTypeRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('LAST RESIDENT'),
          field: 'tenants',
          width: 70,
          cellRenderer: TenantFullNameRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('QUANTITY'),
          field: 'quantity',
          width: 70,
          filter: false,
          type: 'rightAligned',
        },
        {
          headerName: t('PRODUCT'),
          field: 'product.name',
          width: 100,
          ...withFilterParams,
        },
        {
          headerName: t('INSTALLATION DATE'),
          field: 'installationDate',
          width: 70,
          filter: 'agDateColumnFilter',
          ...withFilterParams,
          sort: 'desc',
          cellRenderer: DateTimeRenderer,
        },
      ]
      const InventoryCustomFilter = []
      return {
        query: InventoryGridQuery,
        columnDefs: InventoryColumnDefs,
        customFilters: InventoryCustomFilter,
        countQuery: CountInventoryQuery,
        countExtractor: (response) => response.Me?.Company?.HouseEntrance?.data,
        extractor: (response) => {
          const data = response.Me?.Company?.HouseEntrance?.data.map(
            (usageUnit) => {
              return usageUnit.inventory.map((inventar) => {
                return {
                  ...inventar,
                  referenceNumber: usageUnit.referenceNumber,
                  tenants: usageUnit.tenants,
                  tableId: usageUnit.tableId,
                }
              })
            }
          )
          return data.flat(1)
        },
        customGridOptions: {
          serverSideInfiniteScroll: false,
          getContextMenuItems: (params) => {
            return [
              {
                // custom item
                name: t('GO TO USAGE UNIT {TABLEID}', {
                  TABLEID: params?.node?.data?.tableId,
                }),
                action: function () {
                  redirectRoute(
                    Routes.usageUnit + '/' + params?.node?.data?.tableId
                  )
                },
              },
              'copy',
            ]
          },
          components: { RoomTypeFilter },
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
          },
        },
        customVariables: { propertyUnitId },
      }
    },
    [currentLanguage, redirectRoute, t]
  )
  return { getParams }
}
