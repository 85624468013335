import React, { useCallback, useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { lowerCase } from 'lodash'
import {
  ButtonIcon,
  ButtonSecondary,
  Icon,
  portalTheme,
  SimpleNote,
  StyledInput,
} from 'pyrexx-react-library'
import ComponentHandler from '../../components/ComponentHandler'
import FileUpload from '../../../../../components/FileUpload/FileUpload'
import Tippy from '@tippyjs/react'
import FormField from './FormField'

export const FormFabricator = (props) => {
  const {
    pipelineObject,
    componentData,
    setComponentData,
    setCurrentStep,
    connectedIds,
  } = props
  const { t } = useTranslation()
  const generateFieldsObject = useCallback(
    (pipelineObjectFields = {}, first = true) => {
      const fieldsObject = {}
      Object.keys(pipelineObjectFields).forEach((fieldKey) => {
        if (lowerCase(pipelineObjectFields[fieldKey].type) !== 'object') {
          if (pipelineObjectFields[fieldKey]?.multiple === true) {
            fieldsObject[fieldKey] = componentData[fieldKey] || []
          } else {
            fieldsObject[fieldKey] = componentData[fieldKey] || ''
          }
        } else {
          if (pipelineObjectFields[fieldKey]?.multiple === true) {
            fieldsObject[fieldKey] = componentData[fieldKey] || [
              generateFieldsObject(
                pipelineObjectFields[fieldKey].properties,
                false
              ),
            ]
          } else {
            fieldsObject[fieldKey] =
              componentData[fieldKey] ||
              generateFieldsObject(
                pipelineObjectFields[fieldKey].properties,
                false
              )
          }
        }
      })
      console.log(
        'DEBUGYYY1 finished fieldsObject, componentData',
        fieldsObject,
        componentData
      )

      // CREATE ORDER STEP 1
      const addComponentInitValues = (fieldsObjectTmp) => {
        if (pipelineObject?.components) {
          Object.keys(pipelineObject?.components).forEach((componentName) => {
            switch (componentName) {
              case 'preferredDate':
                fieldsObjectTmp.components = {
                  ...fieldsObjectTmp.components,
                  preferredDate: {
                    date:
                      componentData?.components?.preferredDate?.date || 'ASAP',
                  },
                }
                break

              case 'inhabitantChange':
                fieldsObjectTmp.components = {
                  ...fieldsObjectTmp.components,
                  inhabitantChange: {
                    tenantId:
                      componentData?.components?.inhabitantChange?.tenantId ||
                      '',
                    moveInDate:
                      componentData?.components?.inhabitantChange?.moveInDate ||
                      '',
                    previousInhabitantMoveOutDate:
                      componentData?.components?.inhabitantChange
                        ?.previousInhabitantMoveOutDate || '',
                    inhabitant:
                      componentData?.components?.inhabitantChange.inhabitant
                        .length > 0
                        ? componentData?.components?.inhabitantChange?.inhabitant.map(
                            (tenant) => {
                              return {
                                firstname: tenant.firstname || '',
                                lastname: tenant.lastname || '',
                                contact: tenant.contact || '',
                              }
                            }
                          )
                        : [
                            {
                              firstname: '',
                              lastname: '',
                              contact: '',
                            },
                          ],
                  },
                }
                break

              case 'usageUnitLock':
                fieldsObjectTmp.components = {
                  ...fieldsObjectTmp.components,
                  usageUnitLock: {
                    lockReason:
                      componentData?.components?.usageUnitLock?.lockReason ||
                      '',
                    lockDate:
                      componentData?.components?.usageUnitLock?.lockDate || '',
                    serviceCategory:
                      componentData?.components?.usageUnitLock
                        ?.serviceCategory || '',
                    additionalNotes:
                      componentData?.components?.usageUnitLock
                        ?.additionalNotes || '',
                  },
                }
                break

              case 'usageUnitUnlock':
                fieldsObjectTmp.components = {
                  ...fieldsObjectTmp.components,
                  usageUnitUnlock: {
                    serviceCategory:
                      componentData?.components?.usageUnitUnlock
                        ?.serviceCategory || '',
                    additionalNotes:
                      componentData?.components?.usageUnitUnlock
                        ?.additionalNotes || '',
                  },
                }
                break

              default:
            }
          })

          /**
           Object.keys(pipelineObject?.components).forEach((key) => {
            fieldsObjectTmp[key] = componentData[key] || ''
          })
           **/
        }
        return fieldsObjectTmp
      }
      // return fieldsObject
      if (first) {
        fieldsObject.note = componentData?.note || ''
        fieldsObject.referenceNumber = componentData?.referenceNumber || ''
        fieldsObject.files = componentData?.files || []
        return addComponentInitValues(fieldsObject)
      } else {
        return fieldsObject
      }
    },
    [componentData, pipelineObject.components]
  )

  const generateSchema = useCallback(
    (pipelineObjectFields = {}, first = true) => {
      const schemaObject = {}
      console.log('DEBUGYYY start generateSchema')

      const setRule = (objectField, rule) => {
        console.log(
          'DEBUGYYY start setRule = object, rule',
          rule,
          lowerCase(rule.split(':')[0])
        )
        switch (lowerCase(rule.split(':')[0])) {
          case 'min':
            console.log(
              "DEBUGYYY rules lowerCase(rule.split(':')[0]) for Object",
              lowerCase(rule.split(':')[0]),
              rule.split(':')[1]
            )
            objectField = objectField.min(
              rule.split(':')[1],
              t('MIN. {COUNT} CHARACTERS', { COUNT: rule.split(':')[1] })
            )
            break
          case 'max':
            objectField.max(
              rule.split(':')[1],
              t('MAX. {COUNT} CHARACTERS', { COUNT: rule.split(':')[1] })
            )
            break
          case 'after':
            // date validation need TODO
            break
          case 'after_or_equal':
            // date validation need TODO
            break
          case 'alpha_dash':
            objectField.matches(
              /^([-a-z0-9_ ])+$/i,
              t(
                'ONLY CHARACTERS, AS WELL AS DASHES AND UNDERSCORES ARE ALLOWED FOR THIS FIELD'
              )
            )
            break
          case 'alpha_numeric':
            objectField.matches(
              /^([-a-z0-9 ])+$/i,
              t('ONLY CHARACTERS, AS WELL AS DASHES ARE ALLOWED FOR THIS FIELD')
            )
            break
          case 'before':
            // date validation need TODO
            break
          case 'before_or_equal':
            // date validation need TODO
            break
          case 'between':
            // The field under validation must have a size between the given min and max. Strings, numerics, arrays, and files are evaluated in the same fashion as the size rule. TODO
            break
          case 'greater_then':
            objectField.moreThen(
              rule.split(':')[1],
              t('NEED GREATER THEN < {COUNT}', { COUNT: rule.split(':')[1] })
            )
            break
          case 'greater_then_or_equal':
            objectField.min(
              rule.split(':')[1],
              t('MIN. {COUNT}', { COUNT: rule.split(':')[1] })
            )
            break
          case 'less_than':
            objectField.lessThen(
              rule.split(':')[1],
              t('NEED LESSER THEN < {COUNT}', { COUNT: rule.split(':')[1] })
            )
            break
          case 'less_then_or_equal':
            objectField.max(
              rule.split(':')[1],
              t('MAX. {COUNT}', { COUNT: rule.split(':')[1] })
            )
            break
          default:
        }
        return objectField
      }

      const getTypeVal = (fieldKey, type, multiple = false, required) => {
        console.log('DEBUGYYY start getTypeVal', type, lowerCase(type))
        switch (lowerCase(type)) {
          case 'object':
            if (multiple) {
              schemaObject[fieldKey] = Yup.array().of(
                Yup.object().shape(
                  generateSchema(
                    pipelineObjectFields[fieldKey].properties,
                    false
                  )
                )
              )
            } else {
              schemaObject[fieldKey] = Yup.object().shape(
                generateSchema(pipelineObjectFields[fieldKey].properties, false)
              )
            }

            break
          case 'string':
            console.log(
              'DEBUGYYY return  STRING!!! getTypeVal Yup.string()',
              type
            )
            schemaObject[fieldKey] = Yup.string()
            break
          case 'date':
            schemaObject[fieldKey] = Yup.date()
            break
          case 'email':
            schemaObject[fieldKey] = Yup.string().email()
            break
          case 'enum':
            schemaObject[fieldKey] = Yup.string()
            break
          case 'file':
            schemaObject[fieldKey] = Yup.string() // need to be changed to correct validation
            break
          case 'numeric':
            schemaObject[fieldKey] = Yup.number()
              .typeError(t('ONLY NUMBERS'))
              .transform((value, originalValue) =>
                /\s/.test(originalValue) ? NaN : value
              )
              .positive()
              .nullable(true)
            break
          case 'url':
            schemaObject[fieldKey] = Yup.string().url(t('NOT VALID URL'))
            break
          default:
            console.log(
              'DEBUGYYY return  STRING!!! default getTypeVal Yup.string()',
              type
            )
            schemaObject[fieldKey] = Yup.mixed()
            break
        }
        if (pipelineObjectFields[fieldKey]?.rules) {
          pipelineObjectFields[fieldKey].rules.forEach((rule) => {
            schemaObject[fieldKey] = setRule(schemaObject[fieldKey], rule)
          })
        }

        if (required) {
          schemaObject[fieldKey] = schemaObject[fieldKey].required(
            t('FIELD IS REQUIRED')
          )
        }
        schemaObject[fieldKey] = schemaObject[fieldKey].meta({
          type: pipelineObjectFields[fieldKey]?.type,
          multiple: pipelineObjectFields[fieldKey]?.multiple,
          haystack: pipelineObjectFields[fieldKey]?.haystack,
          label: pipelineObjectFields[fieldKey]?.label,
          isLabel: lowerCase(type) === 'object',
        })
      }

      console.log(
        'DEBUGYYY CALL BEFORE pipelineObjectFields, pipelineObjectFields[fieldKey].type',
        pipelineObjectFields
      )
      Object.keys(pipelineObjectFields).forEach((fieldKey) => {
        getTypeVal(
          fieldKey,
          pipelineObjectFields[fieldKey].type,
          pipelineObjectFields[fieldKey]?.multiple,
          pipelineObjectFields[fieldKey]?.required
        )
        console.log(
          'DEBUGYYY AFTER required is set for field',
          schemaObject[fieldKey]
        )
      })
      console.log('DEBUGYYY finished schemaObject', schemaObject)

      // STEP 2
      const addComponentSchema = (schemaObjectTmp) => {
        if (pipelineObject?.components) {
          let componentObject = {}
          Object.keys(pipelineObject?.components).forEach((componentName) => {
            switch (componentName) {
              case 'preferredDate':
                componentObject = {
                  ...componentObject,
                  preferredDate: Yup.object().shape({
                    date: Yup.mixed().required(t('FIELD IS REQUIRED')),
                  }),
                }
                break

              case 'inhabitantChange':
                componentObject = {
                  ...componentObject,
                  inhabitantChange: Yup.lazy(() => {
                    return Yup.object()
                      .shape({
                        tenantId: Yup.mixed(),
                        moveInDate: Yup.date(),
                        previousInhabitantMoveOutDate: Yup.date(),
                        inhabitant: Yup.array().of(
                          Yup.object().shape({
                            firstname: Yup.string(),
                            lastname: Yup.string(),
                            contact: Yup.string(),
                          })
                        ),
                      })
                      .when(
                        [
                          'inhabitantChange',
                          'components',
                          'moveInDate',
                          'previousInhabitantMoveOutDate',
                          'inhabitant',
                        ],
                        {
                          is: (
                            inhabitantChange,
                            components,
                            moveInDate,
                            previousInhabitantMoveOutDate,
                            inhabitant
                          ) => {
                            console.log(
                              'DEBUG LAZY before',
                              inhabitantChange,
                              components,
                              moveInDate,
                              previousInhabitantMoveOutDate,
                              inhabitant
                            )
                            if (
                              inhabitantChange?.tenantId ||
                              inhabitantChange?.moveInDate ||
                              inhabitantChange?.previousInhabitantMoveOutDate ||
                              (inhabitantChange?.inhabitant?.length > 0 &&
                                inhabitantChange?.inhabitant[0].firstname) ||
                              (inhabitantChange?.inhabitant?.length > 0 &&
                                inhabitantChange?.inhabitant[0].lastname) ||
                              (inhabitantChange?.inhabitant?.length > 0 &&
                                inhabitantChange?.inhabitant[0].contact) ||
                              (inhabitantChange?.inhabitant?.length > 1 &&
                                inhabitantChange?.inhabitant[1].contact) ||
                              (inhabitantChange?.inhabitant?.length > 1 &&
                                inhabitantChange?.inhabitant[1].firstname) ||
                              (inhabitantChange?.inhabitant?.length > 1 &&
                                inhabitantChange?.inhabitant[1].lastname)
                            ) {
                              console.log('DEBUG LAZY true', inhabitant)
                              return true
                            } else {
                              console.log('DEBUG LAZY false', inhabitant)
                              return false
                            }
                          },
                          then: Yup.object({
                            tenantId: Yup.mixed().required(
                              t('FIELD IS REQUIRED')
                            ),
                            moveInDate: Yup.date().required(
                              t('FIELD IS REQUIRED')
                            ),
                            previousInhabitantMoveOutDate: Yup.date().required(
                              t('FIELD IS REQUIRED')
                            ),
                            inhabitant: Yup.array().of(
                              Yup.object().shape({
                                firstname: Yup.string().required(
                                  t('FIELD IS REQUIRED')
                                ),
                                lastname: Yup.string().required(
                                  t('FIELD IS REQUIRED')
                                ),
                                contact: Yup.string().required(
                                  t('FIELD IS REQUIRED')
                                ),
                              })
                            ),
                          }),
                        }
                      )
                  }),
                }
                break

              case 'usageUnitLock':
                componentObject = {
                  ...componentObject,
                  usageUnitLock: Yup.object({
                    lockReason: Yup.string().required(t('FIELD IS REQUIRED')),
                    lockDate: Yup.date().required(t('FIELD IS REQUIRED')),
                    serviceCategory: Yup.string().required(
                      t('FIELD IS REQUIRED')
                    ),
                    additionalNotes: Yup.string(),
                  }),
                }
                break

              case 'usageUnitUnlock':
                componentObject = {
                  ...componentObject,
                  usageUnitUnlock: Yup.object({
                    serviceCategory: Yup.string().required(
                      t('FIELD IS REQUIRED')
                    ),
                    additionalNotes: Yup.string(),
                  }),
                }
                break

              case 'inhabitantChangeXX':
                schemaObjectTmp.components = Yup.object({
                  ...schemaObjectTmp.components,
                  inhabitantChange: Yup.object({
                    tenantId: Yup.mixed().required(t('FIELD IS REQUIRED')),
                    moveInDate: Yup.date().required(t('FIELD IS REQUIRED')),
                    previousInhabitantMoveOutDate: Yup.date().required(
                      t('FIELD IS REQUIRED')
                    ),
                    inhabitant: Yup.lazy(() => {
                      return Yup.array().of(
                        Yup.object().shape({
                          firstname: Yup.string().required(
                            t('FIELD IS REQUIRED')
                          ),
                          lastname: Yup.string().required(
                            t('FIELD IS REQUIRED')
                          ),
                          contact: Yup.string().required(
                            t('FIELD IS REQUIRED')
                          ),
                        })
                      )
                    }),
                  }),
                })
                break
              default:
            }
          })

          schemaObjectTmp.components = Yup.object().shape(componentObject)

          /**
           Object.keys(pipelineObject?.components).forEach((key) => {
            schemaObjectTmp[key] = getTypeVal(
              key,
              pipelineObject?.components[key].properties[0]
            )
            if (pipelineObject?.components[key].required) {
              schemaObjectTmp[key] = schemaObjectTmp[key].required(
                t("FIELD IS REQUIRED")
              )
            }
          })
           **/
        }
        return schemaObject
      }
      // return schemaObject
      if (first) {
        schemaObject.note = Yup.string()
        schemaObject.referenceNumber = Yup.string().required(
          t('FIELD IS REQUIRED')
        )
        schemaObject.files = Yup.array()
        return addComponentSchema(schemaObject)
      } else {
        return schemaObject
      }
    },
    [pipelineObject.components, t]
  )

  console.log('DEBUGX9 pipelineObject', pipelineObject)
  const schema = Yup.object().shape(generateSchema(pipelineObject.params))

  const initValues = generateFieldsObject(pipelineObject.params)
  console.log('DEBUGTOTAL schema', schema, schema.constructor.name)
  console.log('DEBUGTOTAL initValues', initValues)

  const formik = useFormik({
    initialValues: generateFieldsObject(pipelineObject.params),
    validationSchema: Yup.object(generateSchema(pipelineObject.params)),
    onSubmit: (values, formikb) => {
      console.log('DEBUGTOTAL 10 SUBMIT OKKKKKKK', values)
      setComponentData(values)
      setCurrentStep(4)
    },
  })

  const generateInputFieldsNames = useCallback(
    (object, prefix, fieldNameArrayTmp) => {
      let name = ''
      Object.keys(object).forEach((fieldKey, index) => {
        if (
          fieldKey !== 'note' &&
          fieldKey !== 'files' &&
          fieldKey !== 'referenceNumber' &&
          fieldKey !== 'components'
        ) {
          console.log(
            'DEBUG ERROR FIND (object,fieldKey, object[fieldKey]):',
            object,
            fieldKey,
            object[fieldKey]
          )
          switch (lowerCase(object[fieldKey].type)) {
            case 'object':
              if (prefix === '') {
                name = fieldKey
              } else {
                name = prefix + '.' + fieldKey
              }
              if (object[fieldKey]?.spec?.meta?.isLabel) {
                fieldNameArrayTmp.push({
                  name: name,
                  type: lowerCase(object[fieldKey]?.spec?.meta?.type),
                  multiple: object[fieldKey]?.spec?.meta?.multiple,
                  haystack: object[fieldKey]?.spec?.meta?.haystack,
                  label: object[fieldKey]?.spec?.meta?.label,
                  isLabel: object[fieldKey]?.spec?.meta?.isLabel,
                })
              }
              fieldNameArrayTmp = generateInputFieldsNames(
                object[fieldKey].fields,
                name,
                fieldNameArrayTmp
              )
              break
            case 'array':
              {
                if (prefix === '') {
                  name = fieldKey
                } else {
                  name = prefix + '.' + fieldKey
                }
                console.log('DEBUGTOTAL name before split', name)
                const arrayNames = name.split('.')
                console.log('DEBUGTOTAL arrayNames split only', arrayNames)
                const arrayNamesReduce = arrayNames.reduce(
                  (accumulator, currentValue) => {
                    return accumulator[currentValue]
                  },
                  formik?.values
                )
                console.log(
                  'DEBUGTOTAL arrayNamesReduce reduce',
                  arrayNamesReduce
                )
                console.log('DEBUGTOTAL arrayNames', arrayNames)
                if (object[fieldKey]?.spec?.meta?.isLabel) {
                  fieldNameArrayTmp.push({
                    name: name,
                    type: lowerCase(object[fieldKey]?.spec?.meta?.type),
                    multiple: object[fieldKey]?.spec?.meta?.multiple,
                    haystack: object[fieldKey]?.spec?.meta?.haystack,
                    label: object[fieldKey]?.spec?.meta?.label,
                    isLabel: object[fieldKey]?.spec?.meta?.isLabel,
                  })
                }

                arrayNamesReduce.forEach((singleValue, index) => {
                  fieldNameArrayTmp = generateInputFieldsNames(
                    object[fieldKey].innerType.fields,
                    name + '.' + index,
                    fieldNameArrayTmp
                  )
                })
              }

              break
            default:
              if (prefix === '') {
                name = fieldKey
              } else {
                name = prefix + '.' + fieldKey
              }
              fieldNameArrayTmp.push({
                name: name,
                type: lowerCase(object[fieldKey]?.spec?.meta?.type),
                multiple: object[fieldKey]?.spec?.meta?.multiple,
                haystack: object[fieldKey]?.spec?.meta?.haystack,
                label: object[fieldKey]?.spec?.meta?.label,
                isLabel: false,
              })
          }
        }
      })
      return fieldNameArrayTmp
    },
    [formik.values]
  )

  const getFieldNameArray = useCallback(() => {
    return generateInputFieldsNames(schema.fields, '', [])
  }, [generateInputFieldsNames, schema])

  const [uploadStatusMsg, setUploadStatusMsg] = useState({
    open: false,
    msg: '',
    status: 'success',
  })

  const generateFields = useCallback(() => {
    let usedLabels = []
    const fieldArray = []
    getFieldNameArray().forEach((fieldObject, index) => {
      if (
        fieldObject.isLabel &&
        !usedLabels.find((needle) => fieldObject.name === needle.name)
      ) {
        console.log(
          'DEBUGTOTAL 7 usedLabels before usedLabels.find((needle) => fieldObject.name === needle',
          usedLabels,
          usedLabels.find((needle) => fieldObject.name === needle.name)
        )
        usedLabels = [
          ...usedLabels,
          {
            name: fieldObject.name,
            multiple: fieldObject.name,
            count: fieldObject.count,
          },
        ]
        console.log(
          'DEBUGTOTAL 7 usedLabels after usedLabels.find((needle) => fieldObject.name === needle',
          usedLabels,
          usedLabels.find((needle) => fieldObject.name === needle.name)
        )
        const filteredArray = getFieldNameArray().filter((item) => {
          console.log(
            'DEBUGTOTAL 7 filter item, tem.name.includes(fieldObject.name), fieldObject.isLabel',
            item,
            item.name.includes(fieldObject.name),
            item.isLabel
          )
          if (
            item.name.split('.').includes(fieldObject.name) &&
            !item.isLabel
          ) {
            console.log("'DEBUGTOTAL 7 filter item HIT!!!!!!")
            return true
          } else {
            return false
          }
        })
        console.log('DEBUGTOTAL 7 filteredArray', filteredArray)
        fieldArray.push(
          <Row key={fieldObject.name + index}>
            <Col>
              <Row justify='between'>
                <Col
                  xs='content'
                  style={{ fontSize: '1.1em', fon8Weight: 'bold' }}
                >
                  {t(fieldObject.label)}
                </Col>
                <Col xs='content'>
                  <ButtonIcon
                    icon='add-item'
                    size='22px'
                    style={{
                      marginLeft: '1em',
                      color: portalTheme.color.linkColorPrimary,
                    }}
                    handleClick={() => {}}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Container>
                    {filteredArray.map((filteredItem) => {
                      return (
                        <FormField
                          formik={formik}
                          key={filteredItem.name}
                          nameRoot={filteredItem.name}
                          typeRoot={filteredItem.type}
                          labelRoot={filteredItem.label}
                          haystackRoot={filteredItem.haystack}
                          isLabelRoot={filteredItem.isLabel}
                        />
                      )
                    })}
                  </Container>
                </Col>
              </Row>
            </Col>
          </Row>
        )
      }
      console.log(
        "DEBUGXTOTAL 6 START fieldObject.name, fieldObject.name.split('.'),  usedLabels, fieldObject.name.split('.').includes(inhabitant)",
        fieldObject.name,
        usedLabels,
        fieldObject.name.split('.'),
        fieldObject.name.split('.').includes('inhabitant')
      )

      if (
        !fieldObject.isLabel &&
        !usedLabels.find((needle) =>
          fieldObject.name.split('.').includes(needle.name)
        )
      ) {
        fieldArray.push(
          <FormField
            formik={formik}
            key={fieldObject.name}
            nameRoot={fieldObject.name}
            typeRoot={fieldObject.type}
            labelRoot={fieldObject.label}
            haystackRoot={fieldObject.haystack}
            isLabelRoot={fieldObject.isLabel}
          />
        )
      }
    })
    return fieldArray
  }, [formik, getFieldNameArray, t])

  return (
    <>
      <Row>
        <Col xs={3} />
        <Col
          xs={6}
          style={{
            backgroundColor: portalTheme.color.basicColorWhite,
          }}
        >
          <Row justify='between' style={{ paddingTop: '10px' }}>
            <Col xs='content' style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
              {t('COMMISSION DETAILS')}
            </Col>
            <Col xs='content'>
              <Tippy
                delay={[null, 16000]}
                offset={[0, 0]}
                maxWidth='600'
                content={
                  <SimpleNote
                    noteStatus='info'
                    text={t('PLEASE FILL ALL NECESSARY FIELDS')}
                  />
                }
              >
                <div>
                  <Icon
                    icon='info'
                    size='22px'
                    color={portalTheme.color.linkColorPrimary}
                  />
                </div>
              </Tippy>
            </Col>
          </Row>
          {generateFields()}
          <ComponentHandler
            components={pipelineObject?.components}
            connectedIds={connectedIds}
            formik={formik}
            position='center'
          />
          <Row>
            <Col style={{ fontSize: '1.1em', fon8Weight: 'bold' }}>
              {t('REMARKS')}
            </Col>
          </Row>
          <Row key='formRowNote'>
            <Col>
              <StyledInput
                style={{ minHeight: '200px' }}
                type='textarea'
                name='note'
                id='note'
                onChange={formik.handleChange}
                value={formik.values.note}
                label=''
                feedbackMessage={formik.touched?.note && formik.errors?.note}
                feedbackStyle='invalid'
                setFieldValue={formik.setFieldValue}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={3}>
          <Row style={{ marginBottom: '1em' }}>
            <Col>
              <Container style={{ backgroundColor: 'white' }}>
                <Row justify='between' style={{ paddingTop: '10px' }}>
                  <Col xs='content' style={{ fontSize: '0.8em' }}>
                    {t('ASSIGNMENT / REFERENCE NR.')}
                  </Col>
                  <Col xs='content'>
                    <Tippy
                      delay={[null, 16000]}
                      offset={[0, 0]}
                      maxWidth='600'
                      content={
                        <SimpleNote
                          noteStatus='info'
                          text={t(
                            'THIS NUMBER IS ALSO GIVEN ON OUR INVOICES SO THAT YOU CAN KEEP TRACK OF THEM.'
                          )}
                        />
                      }
                    >
                      <div>
                        <Icon
                          icon='info'
                          size='22px'
                          color={portalTheme.color.linkColorPrimary}
                        />
                      </div>
                    </Tippy>
                  </Col>
                </Row>
                <Row style={{ marginTop: '1em' }}>
                  <Col>
                    <StyledInput
                      type='text'
                      name='referenceNumber'
                      id='referenceNumber'
                      onChange={formik.handleChange}
                      value={formik.values.referenceNumber}
                      label={t('REFERENCE NUMBER')}
                      feedbackMessage={
                        formik.touched?.referenceNumber &&
                        formik.errors?.referenceNumber
                      }
                      feedbackStyle='invalid'
                      setFieldValue={formik.setFieldValue}
                    />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <ComponentHandler
            components={pipelineObject?.components}
            formik={formik}
            connectedIds={connectedIds}
            position='right'
          />
          <Row style={{ marginBottom: '1em' }}>
            <Col>
              <Container style={{ backgroundColor: 'white' }}>
                <Row justify='between' style={{ paddingTop: '10px' }}>
                  <Col xs='content' style={{ fontSize: '0.8em' }}>
                    {t('FILE UPLOAD')}
                  </Col>
                  <Col xs='content'>
                    <Tippy
                      delay={[null, 16000]}
                      offset={[0, 0]}
                      maxWidth='600'
                      content={
                        <SimpleNote
                          noteStatus='info'
                          text={t(
                            'WOULD YOU LIKE TO PROVIDE US WITH ADDITIONAL DOCUMENTS?'
                          )}
                        />
                      }
                    >
                      <div>
                        <Icon
                          icon='info'
                          size='22px'
                          color={portalTheme.color.linkColorPrimary}
                        />
                      </div>
                    </Tippy>
                  </Col>
                </Row>
                <Row style={{ marginTop: '1em' }}>
                  <Col>
                    <FileUpload
                      uploadStatusMsg={uploadStatusMsg}
                      setUploadStatusMsg={setUploadStatusMsg}
                      setFieldValue={formik.setFieldValue}
                      onUpload={formik.handleChange}
                      files={formik.values?.files}
                      multiFileMode={false}
                      name='files'
                      supportedFileTypes='application/pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                      maxsizeKb={10000}
                      formik={formik}
                      labels={{
                        maxSizeReached: t(
                          'SIZE OF FILES EXCEEDS THE ALLOWED MAXIMUM'
                        ),
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify='center' style={{ marginTop: '1rem' }}>
        <Col xs='content'>
          <ButtonSecondary
            type='button'
            onClick={() => {
              setCurrentStep(2)
            }}
            style={{
              margin: '0px',
              height: '3rem',
              paddingLeft: '4rem',
              paddingRight: '4rem',
            }}
          >
            {t('BACK')}
          </ButtonSecondary>
        </Col>
        <Col xs='content'>
          <ButtonSecondary
            type='button'
            onClick={() => {
              formik.handleSubmit()
            }}
            style={{
              margin: '0px',
              height: '3rem',
              paddingLeft: '4rem',
              paddingRight: '4rem',
            }}
          >
            {t('NEXT')}
          </ButtonSecondary>
        </Col>
      </Row>
    </>
  )
}
