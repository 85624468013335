/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConditionType = "contains" | "endsWith" | "equals" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "lessThan" | "lessThanOrEqual" | "notContains" | "notEqual" | "startsWith" | "%future added value";
export type FilterType = "date" | "number" | "text" | "%future added value";
export type OperatorType = "AND" | "OR" | "%future added value";
export type Sort = {|
  colId?: ?string,
  sort?: ?string,
|};
export type Filter = {|
  name: string,
  filterType: FilterType,
  operator: OperatorType,
  denySelection?: ?boolean,
  conditions: $ReadOnlyArray<?Condition>,
|};
export type Condition = {|
  value: $ReadOnlyArray<?string>,
  type: ConditionType,
|};
export type RecipientsGridQueryVariables = {|
  companyId: string,
  letterId: string,
  startRow?: ?number,
  endRow?: ?number,
  sortModel?: ?$ReadOnlyArray<?Sort>,
  filters?: ?$ReadOnlyArray<?Filter>,
|};
export type RecipientsGridQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +businessPost: ?{|
        +letter: ?{|
          +data: ?$ReadOnlyArray<?{|
            +id: string,
            +uniqueReference: string,
            +salutation: string,
            +title: string,
            +firstname: string,
            +lastname: string,
            +street: string,
            +streetNumber: string,
            +city: string,
            +zip: string,
            +rawData: ?any,
          |}>
        |}
      |}
    |}
  |}
|};
export type RecipientsGridQuery = {|
  variables: RecipientsGridQueryVariables,
  response: RecipientsGridQueryResponse,
|};
*/


/*
query RecipientsGridQuery(
  $companyId: ID!
  $letterId: ID!
  $startRow: Int
  $endRow: Int
  $sortModel: [Sort]
  $filters: [Filter]
) {
  Me {
    Company(id: $companyId) {
      businessPost {
        letter(id: $letterId) {
          data: recipients(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {
            id
            uniqueReference
            salutation
            title
            firstname
            lastname
            street
            streetNumber
            city
            zip
            rawData
          }
          id
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endRow"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "letterId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortModel"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startRow"
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "letterId"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": "data",
  "args": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "endRow",
          "variableName": "endRow"
        },
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters"
        },
        {
          "kind": "Variable",
          "name": "sorting",
          "variableName": "sortModel"
        },
        {
          "kind": "Variable",
          "name": "startRow",
          "variableName": "startRow"
        }
      ],
      "kind": "ObjectValue",
      "name": "options"
    }
  ],
  "concreteType": "LetterRecipient",
  "kind": "LinkedField",
  "name": "recipients",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uniqueReference",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salutation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "street",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "streetNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zip",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rawData",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RecipientsGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BusinessPost",
                "kind": "LinkedField",
                "name": "businessPost",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v7/*: any*/),
                    "concreteType": "Letter",
                    "kind": "LinkedField",
                    "name": "letter",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "RecipientsGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BusinessPost",
                "kind": "LinkedField",
                "name": "businessPost",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v7/*: any*/),
                    "concreteType": "Letter",
                    "kind": "LinkedField",
                    "name": "letter",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7fa216736bdf7cc77c28560365f0da7f",
    "id": null,
    "metadata": {},
    "name": "RecipientsGridQuery",
    "operationKind": "query",
    "text": "query RecipientsGridQuery(\n  $companyId: ID!\n  $letterId: ID!\n  $startRow: Int\n  $endRow: Int\n  $sortModel: [Sort]\n  $filters: [Filter]\n) {\n  Me {\n    Company(id: $companyId) {\n      businessPost {\n        letter(id: $letterId) {\n          data: recipients(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {\n            id\n            uniqueReference\n            salutation\n            title\n            firstname\n            lastname\n            street\n            streetNumber\n            city\n            zip\n            rawData\n          }\n          id\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9db2cab0b048bffc03d25b2158b7d166';

module.exports = node;
