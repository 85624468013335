import React from 'react'

import QueryRendererContainer from '../../../components/QueryRendererContainer/QueryRendererContainer'
import CreateOrder from './CreateOrder'

import CreateOrderContainerQuery from './queries/CreateOrderContainer'
import { useUserContext } from '../../../helper/userContext'

const CreateOrderContainerContainer = () => {
  const { user } = useUserContext()

  const variables = {
    companyId: user.companyId,
  }

  return (
    <QueryRendererContainer
      query={CreateOrderContainerQuery}
      variables={variables}
      loading={<CreateOrder loading />}
      render={(data) => {
        const channels = data.Me?.Company?.OrderWizard.channels
        const mostUsedReasons = data.Me?.Company?.OrderWizard.mostUsedReasons
        const recentlyCreatedTickets =
          data.Me?.Company?.OrderWizard.recentlyCreatedTickets

        return (
          <CreateOrder
            channels={channels}
            mostUsedReasons={mostUsedReasons}
            recentlyCreatedTickets={recentlyCreatedTickets}
          />
        )
      }}
    />
  )
}

export default CreateOrderContainerContainer
