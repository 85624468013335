import React, { forwardRef, useImperativeHandle, useState } from 'react'

import GridEnumFilter from '../../../components/GridEnumFilter'
import { appointmentAccessReasonEnumList } from '../../../components/AgGrid/enumLists'

import useUpdateEffect from '../../../hooks/useUpdateEffect'

const map = {
  ACCESS: ['ACCESS'],
  NO_ACCESS_AWAY: ['NO_ACCESS_AWAY'],
  NO_ACCESS_DENIED: ['NO_ACCESS_DENIED'],
  NO_ACCESS_VACANT_NO_KEY: ['NO_ACCESS_VACANT_NO_KEY'],
  NO_ACCESS_TENANT_OWNS_RM: ['NO_ACCESS_TENANT_OWNS_RM'],
  NO_ACCESS_RENOVATION: ['NO_ACCESS_RENOVATION'],
  NO_ACCESS_RAMSHACKLE_SURFACE: ['NO_ACCESS_RAMSHACKLE_SURFACE'],
  NO_ACCESS_HYGIENICALLY_INFEASIBLE: ['NO_ACCESS_HYGIENICALLY_INFEASIBLE'],
  NO_ACCESS_DENIED_NEED_RESPITE: ['NO_ACCESS_DENIED_NEED_RESPITE'],
  NO_ACCESS_INFEASIBLE: ['NO_ACCESS_INFEASIBLE'],
  NO_ACCESS_TENANT_THERE_INPUT_IMPOSSIBLE: [
    'NO_ACCESS_TENANT_THERE_INPUT_IMPOSSIBLE',
  ],
  NO_ACCESS_PARTLY_DONE_SOME_LOCATIONS_LOCKED: [
    'NO_ACCESS_PARTLY_DONE_SOME_LOCATIONS_LOCKED',
  ],
  NO_ACCESS_NO_ADULT_ON_SITE: ['NO_ACCESS_NO_ADULT_ON_SITE'],
  NO_ACCESS_FOREIGN_SD_INSTALLED_NO_MAINTENANCE: [
    'NO_ACCESS_FOREIGN_SD_INSTALLED_NO_MAINTENANCE',
  ],
  NO_ACCESS_VACANT_KEY_DOES_NOT_FIT: ['NO_ACCESS_VACANT_KEY_DOES_NOT_FIT'],
  NO_ACCESS_JUNCTION_BOX_DESTROYED: ['NO_ACCESS_JUNCTION_BOX_DESTROYED'],
  NO_ACCESS_JUNCTION_BOX_OPENED: ['NO_ACCESS_JUNCTION_BOX_OPENED'],
  NO_ACCESS_JUNCTION_BOX_BROKEN: ['NO_ACCESS_JUNCTION_BOX_BROKEN'],
  NO_ACCESS_JUNCTION_BOX_MISSING: ['NO_ACCESS_JUNCTION_BOX_MISSING'],
  NO_ACCESS_JUNCTION_BOX_GRAFFITI_FOUND: [
    'NO_ACCESS_JUNCTION_BOX_GRAFFITI_FOUND',
  ],
  NO_ACCESS_JUNCTION_BOX_INACCESSIBLE: ['NO_ACCESS_JUNCTION_BOX_INACCESSIBLE'],
}

// const accessReasonList = [
//   {
//     accessReason: {
//       tableId: 18,
//       msgId: 'NO_ACCESS_JUNCTION_BOX_BROKEN',
//     },
//   },
//   {
//     accessReason: {
//       tableId: 13,
//       msgId: 'NO_ACCESS_NO_ADULT_ON_SITE',
//     },
//   },
//   {
//     accessReason: {
//       tableId: 6,
//       msgId: 'NO_ACCESS_RENOVATION',
//     },
//   },
//   {
//     accessReason: {
//       tableId: 7,
//       msgId: 'NO_ACCESS_RAMSHACKLE_SURFACE',
//     },
//   },
//   {
//     accessReason: {
//       tableId: 11,
//       msgId: 'NO_ACCESS_TENANT_THERE_INPUT_IMPOSSIBLE',
//     },
//   },
//   {
//     accessReason: {
//       tableId: 6,
//       msgId: 'NO_ACCESS_RENOVATION',
//     },
//   },
//   {
//     accessReason: {
//       tableId: 11,
//       msgId: 'NO_ACCESS_TENANT_THERE_INPUT_IMPOSSIBLE',
//     },
//   },
//   {
//     accessReason: {
//       tableId: 16,
//       msgId: 'NO_ACCESS_JUNCTION_BOX_DESTROYED',
//     },
//   },
//   {
//     accessReason: {
//       tableId: 9,
//       msgId: 'NO_ACCESS_DENIED_NEED_RESPITE',
//     },
//   },
//   {
//     accessReason: {
//       tableId: 9,
//       msgId: 'NO_ACCESS_DENIED_NEED_RESPITE',
//     },
//   },
// ]

// const getAccessReasonIdMap = (accessReasonList = []) => {
//   return accessReasonList.reduce((prev, curr) => {
//     return {
//       ...prev,
//       [curr?.accessReason?.msgId]: [curr?.accessReason?.tableId.toString()],
//     }
//   }, {})
// }

export default forwardRef((props, ref) => {
  const { filterChangedCallback } = props

  const [filterModel, setFilterModel] = useState({})

  useUpdateEffect(() => {
    filterChangedCallback()
  }, [filterModel])

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return filterModel != null && Object.keys(filterModel).length > 0
      },
      getModel() {
        if (filterModel == null || Object.keys(filterModel).length <= 0) {
          return null
        }

        return filterModel
      },
      setModel(model) {
        setFilterModel(model)
      },
    }
  })

  // TODO fetch real list of accessReasons
  // const map = getAccessReasonIdMap(accessReasonList)

  return (
    <GridEnumFilter
      map={map}
      translateLabel={(value, t) => {
        return t(appointmentAccessReasonEnumList(value))
      }}
      setFilterModel={setFilterModel}
    />
  )
})
