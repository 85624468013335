import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { Box } from '../shared'
import SimpleLink from '../../../../components/Simple/SimpleLink'

const getValidLinks = (links = []) => {
  const validKeys = []

  for (const [key, value] of Object.entries(links)) {
    if (value != null) {
      validKeys.push({ type: key, data: value[0] })
    }
  }

  return validKeys
}

const LinkItem = ({ color = '', label = '', type = '', content = [] }) => {
  const { t } = useTranslation()

  return (
    <LinkWrapper>
      <Header color={color}>{label}</Header>
      {content.map((c, index) => {
        if (type === 'usage-unit' && index === 0) {
          return (
            <>
              <span>{t('PYREXX USAGE UNIT')}: </span>
              <SimpleLink key={c} to={'/:lang/usage-unit/' + c}>
                {c}
              </SimpleLink>
            </>
          )
        } else if (type === 'invoice' && index === 0) {
          return (
            <>
              <span>{t('INVOICE NUMBER')}: </span>
              <SimpleLink key={c} to={'/:lang/invoice/' + c}>
                {c}
              </SimpleLink>
            </>
          )
        } else {
          return <ContentItem key={c}>{c}</ContentItem>
        }
      })}
    </LinkWrapper>
  )
}

const LinkSelector = ({ link }) => {
  const { t } = useTranslation()

  const { type = '', data = {} } = link

  switch (type) {
    case 'invoice':
      return (
        <LinkItem
          color={'#aaccef'}
          label={t('INVOICE')}
          type='invoice'
          content={[data.invoiceNumber]}
        />
      )

    case 'houseEntrance':
      return (
        <LinkItem
          color={'#d6c2c1'}
          label={t('PROPERTY UNIT')}
          content={[
            data?.address?.street + ' ' + data?.address?.number,
            data?.address?.zip + ' ' + data?.address?.city,
          ]}
        />
      )

    case 'usageUnit': {
      const activeTenants = []
      data.tenants.forEach((tenant) => {
        if (tenant.status === 'STATUS_ACTIVE') {
          activeTenants.push(tenant.firstname + ' ' + tenant.lastname)
        }
      })

      return (
        <LinkItem
          color={'#d6c2c1'}
          label={t('USAGE UNIT')}
          type='usage-unit'
          content={[
            data.tableId,
            ...activeTenants,
            data?.address?.street + ' ' + data?.address?.number,
            data?.address?.zip + ' ' + data?.address?.city,
          ]}
        />
      )
    }

    case 'tenant':
      return (
        <LinkItem
          color={'#aaccef'}
          label={t('INHABITANT')}
          content={[
            t('CUSTOMER NUMBER: {CUSTOMERENUMBER}', {
              CUSTOMERENUMBER: data?.usageUnit?.referenceNumber,
            }),
            data ? data.tenant?.fullname : t('VACANCY'),
            data?.usageUnit?.address?.street +
              ' ' +
              data?.usageUnit?.address?.number,
            data?.usageUnit?.address?.zip +
              ' ' +
              data?.usageUnit?.address?.city,
          ]}
        />
      )

    default:
      return null
  }
}

const TicketLinking = ({ links = [] }) => {
  const validLinks = getValidLinks(links)

  if (validLinks.length <= 0) {
    return (
      <Box>
        <p>No links</p>
      </Box>
    )
  }

  console.log(validLinks)

  return (
    <Box>
      {validLinks.map((link) => {
        return <LinkSelector key={link.type} link={link} />
      })}
    </Box>
  )
}

const LinkWrapper = styled.div`
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`

const Header = styled.p`
  background-color: ${(props) => props.color};
  width: fit-content;

  padding: 2px;
  margin: 0.25rem 0;
  font-weight: bold;
}
`
const ContentItem = styled.p`
  margin: 0;
`

export default TicketLinking
