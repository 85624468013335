import React, { useRef } from 'react'
import { useListBox, useOption } from 'react-aria'
import styled from 'styled-components/macro'

function Option({ item, state }) {
  const ref = useRef(null)
  const { optionProps, isSelected, isFocused, isDisabled } = useOption(
    { key: item.key },
    state,
    ref
  )

  let backgroundColor
  let color = '#000'

  if (isSelected) {
    backgroundColor = 'var(--pyrexx-dropdown-badge-color)'
  } else if (isFocused) {
    backgroundColor = 'var(--pyrexx-dropdown-badge-color)'
  } else if (isDisabled) {
    backgroundColor = 'transparent'
    color = 'gray'
  }

  return (
    <ListItem
      {...optionProps}
      ref={ref}
      style={{
        background: backgroundColor,
        color: color,
      }}
    >
      {item.rendered}
    </ListItem>
  )
}

export function ListBox(props) {
  const ref = useRef(null)

  const { listBoxRef = ref, state } = props
  const { listBoxProps } = useListBox(props, state, listBoxRef)

  return (
    <UnorderedList {...listBoxProps} ref={listBoxRef}>
      {[...state.collection].map((item) => (
        <Option key={item.key} item={item} state={state} />
      ))}
    </UnorderedList>
  )
}

const UnorderedList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 400px;
  overflow: auto;
  min-width: 300px;
`

const ListItem = styled.li`
  padding: 8px 16px;
  outline: none;
  cursor: pointer;
`
