import formatDateLocale from './formatDateLocale'

/**
 * Transforms an array of contracts with billingPeriods into an array of pure
 * unrepeated billingPeriods
 *
 * @param {[]} contracts
 * @param {string} currentLanguage
 * @returns {{id: String, tableId: Number, from: String, to: String}[]}
 */
export default (contracts = [], currentLanguage = 'de') =>
  contracts
    .reduce((acc, curr) => {
      let currentBillingPeriods = curr?.billingPeriods

      currentBillingPeriods =
        Array.isArray(currentBillingPeriods) && currentBillingPeriods != null
          ? currentBillingPeriods
          : []

      return [...acc, ...currentBillingPeriods]
    }, [])
    .filter(
      (contract, idx, self) =>
        idx ===
          self.findIndex(
            (c) => c?.from === contract?.from && c?.to === contract?.to
          ) && contract?.tableId != null
    )
    .sort((a, b) => {
      if (a?.from < b?.from) {
        return 1
      } else if (a?.from > b?.from) {
        return -1
      }
      return 0
    })
    .map((tf) => {
      const formattedFrom = formatDateLocale(currentLanguage, tf.from, 'P')
      const formattedTo = formatDateLocale(currentLanguage, tf.to, 'P')

      return {
        ...tf,
        value: [tf.from, tf.to],
        label: formattedFrom + ' - ' + formattedTo,
        name: formattedFrom + ' - ' + formattedTo,
      }
    })
