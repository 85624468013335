import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { portalTheme } from 'pyrexx-react-library'
import {
  ExternalLinkToOrderWizard,
  LinksTitle,
} from '../components/ExternalLinkToOrderWizard'
import {
  OW_CATEGORY,
  OW_TOPIC,
  OW_REASON,
  OW_REASON_ID,
  OW_CURRENT_STEP,
} from '../../../../configs/FilterConfig'

export const MostUsedReasons = ({ mostUsedReasons = [] }) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <LinksTitle title={t('SELECT FREQUENT ASSIGNMENTS DIRECTLY')} />

      <Border />

      <List>
        {mostUsedReasons.map((reason) => (
          <ExternalLinkToOrderWizard
            key={reason.id}
            to={`/de/support/createOrder?${OW_CATEGORY}=${reason.channel.name}&${OW_TOPIC}=${reason.topic.name}&${OW_REASON}=${reason?.name}&${OW_REASON_ID}=${reason?.pipeline}&${OW_CURRENT_STEP}=2`}
            label={reason?.name}
          />
        ))}
      </List>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #fff;
  padding: 1rem;
`

const Border = styled.div`
  padding-top: 1rem;
  border-bottom: 1.3px solid ${portalTheme.color.basicColorLightGrey2};
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  padding-top: 0.5rem;
`
