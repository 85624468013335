import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../../../../../environments/pom/Environment'
import { isArray } from 'leaflet/src/core/Util'

const mutation = graphql`
  mutation CreateTicketMutation(
    $companyId: ID!
    $pipeline: Int!
    $links: OrderWizardRequestLinks
    $params: JSON
    $components: JSON
    $note: String
    $files: [Upload]
    $referenceNumber: String!
  ) {
    Me {
      Company(id: $companyId) {
        OrderWizard {
          createTicket(
            pipeline: $pipeline
            links: $links
            params: $params
            components: $components
            note: $note
            files: $files
            referenceNumber: $referenceNumber
          )
        }
      }
    }
  }
`

export default (variables, accessToken, files, callBack, errorCallBack) => {
  let uploadables

  if (files) {
    if (isArray(files)) {
      uploadables = {
        files,
      }
    } else {
      uploadables = {
        file: files,
      }
    }
  }

  commitMutation(environment(accessToken), {
    mutation,
    variables,
    uploadables,
    onCompleted: callBack,
    onError: errorCallBack,
  })
}
/**
 export default (variables, accessToken, callBack, errorCallBack) => {
  commitMutation(environment(accessToken), {
    mutation,
    variables,
    onCompleted: callBack,
    onError: errorCallBack,
  })
}
 **/
