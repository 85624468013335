import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import graphql from 'babel-plugin-relay/macro'
import { ErrorBoundary } from 'react-error-boundary'

import { ServiceCard } from 'pyrexx-react-library'
import StatusFilterButton from '../Status/StatusFilterButton'
import WithSkeleton from './WithSkeleton'
import SimpleLink from '../Simple/SimpleLink'
import QueryRendererContainer from '../QueryRendererContainer'
import NotAvailableContent from './NotAvailableContent'

import { Routes } from '../../configs/RouteConfig'
import { useUserContext } from '../../helper/userContext'
import { BOX_FILTER, DATE_ALL } from '../../configs/FilterConfig'

import invoiceImg from '../../assets/overview/invoice.png'
import invoiceInactiveImg from '../../assets/overview/invoice-inactive.png'

const InvoicesCardContent = (props) => {
  const { data = {}, loading = false } = props

  const { t } = useTranslation()
  const { generateUrl } = useUserContext()

  const boxFilterUrl = (filter) => {
    return generateUrl(
      Routes.invoices + '?' + DATE_ALL + '&' + BOX_FILTER + '=' + filter
    )
  }

  return (
    <Row align='start' style={{ width: '100%' }}>
      <Col align='center'>
        <WithSkeleton variant='lg' loading={loading}>
          <p style={{ marginTop: '0' }}>{t('OPEN')}</p>
        </WithSkeleton>

        <WithSkeleton loading={loading}>
          <Row justify='center'>
            <StatusFilterButton
              variant='link'
              to={boxFilterUrl('open')}
              status='warning'
              label={data?.open}
              border={false}
            />
          </Row>
        </WithSkeleton>
      </Col>

      <Col align='center'>
        <WithSkeleton variant='lg' loading={loading}>
          <p style={{ marginTop: '0' }}>{t('DUNNING LETTER')}</p>
        </WithSkeleton>

        <WithSkeleton loading={loading}>
          <Row justify='center'>
            <StatusFilterButton
              variant='link'
              to={boxFilterUrl('reminded')}
              status='error'
              label={data?.reminded}
              border={false}
            />
          </Row>
        </WithSkeleton>
      </Col>
    </Row>
  )
}

export const InvoicesCard = ({ data = {}, loading = false, error = false }) => {
  const { currentLanguage } = useUserContext()
  const { t } = useTranslation()

  if (error) {
    return (
      <ServiceCard
        imgSrc={invoiceInactiveImg}
        title={t('INVOICES')}
        content={<NotAvailableContent imgSrc={invoiceInactiveImg} />}
        isDisabled
      />
    )
  }

  return (
    <ServiceCard
      imgSrc={invoiceImg}
      title={t('INVOICES')}
      content={<InvoicesCardContent data={data} loading={loading} />}
      link={
        !loading && (
          <SimpleLink
            to={'/' + currentLanguage + Routes.invoices}
            font='quicksand'
          >
            {t('ALL') + ' >'}
          </SimpleLink>
        )
      }
    />
  )
}

InvoicesCard.propTypes = {
  data: PropTypes.shape({
    open: PropTypes.any,
    reminded: PropTypes.any,
  }),
  loading: PropTypes.bool,
  error: PropTypes.bool,
}

const query = graphql`
  query InvoicesCardQuery($companyId: ID!) {
    Me {
      invoicesStatus: Company(id: $companyId) {
        open: countInvoices(
          options: {
            filters: [
              {
                name: "status"
                filterType: number
                operator: AND
                conditions: [{ value: ["OPEN"], type: equals }]
              }
            ]
          }
        )
        reminded: countInvoices(
          options: {
            filters: [
              {
                name: "status"
                filterType: number
                operator: AND
                conditions: [{ value: ["REMINDED"], type: equals }]
              }
            ]
          }
        )
      }
    }
  }
`

export default ({ isHidden = true }) => {
  const { user } = useUserContext()

  const variables = {
    companyId: user.companyId,
  }

  if (isHidden) {
    return null
  }

  return (
    <ErrorBoundary fallbackRender={() => null}>
      <QueryRendererContainer
        query={query}
        variables={variables}
        loading={<InvoicesCard loading />}
        render={(data) => {
          const invoicesStatus = data?.Me?.invoicesStatus

          return <InvoicesCard data={invoicesStatus} />
        }}
      />
    </ErrorBoundary>
  )
}
