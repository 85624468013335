import React from 'react'
import { Item } from 'react-stately'

import { Select } from './Select'

export function DropdownAria({
  label = '',
  items = [],
  value = '',
  setValue = () => {},
}) {
  return (
    <Select
      label={label}
      items={items}
      selectedKey={value}
      onSelectionChange={(selected) => setValue(selected)}
    >
      {(item) => <Item key={item.tableId}>{item.name}</Item>}
    </Select>
  )
}
