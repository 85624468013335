import React from 'react'
import { useParams } from 'react-router-dom'

import QueryRendererContainer from '../../components/QueryRendererContainer/QueryRendererContainer'
import Appointment from './Appointment'
import NotFound from './NotFound'

import AppointmentQuery from './queries/Appointment'
import { useUserContext } from '../../helper/userContext'

const Container = () => {
  const { user } = useUserContext()
  const { appointmentId } = useParams()

  const variables = {
    companyId: user.companyId,
    appointmentId,
  }

  return (
    <QueryRendererContainer
      query={AppointmentQuery}
      variables={variables}
      loading={<Appointment loading />}
      render={(data) => {
        /**
         * @param {{Me:object}} data
         * @param {{Company:object}} data.Me
         * @param {{Appointment:object}} data.Me.Company
         * @param {{usageUnit:array}} data.Me.Company.Appointment
         */
        const appointment = data.Me?.Company?.Appointment

        if (appointment) {
          return <Appointment appointment={appointment} />
        } else {
          return <NotFound />
        }
      }}
    />
  )
}

export default Container
