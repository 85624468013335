import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query CreateOrderContainerQuery($companyId: ID!) {
    Me {
      Company(id: $companyId) {
        OrderWizard {
          channels {
            name
            topics {
              name
              reasons {
                name
                pipeline
                id
              }
            }
          }
          mostUsedReasons: mostUsed {
            id
            tableId
            channel {
              name
            }
            topic {
              name
            }
            name
            pipeline
          }
          recentlyCreatedTickets: tickets(
            options: {
              endRow: 2
              sorting: [{ colId: "creationDate", sort: "DESC" }]
            }
          ) {
            id
            tableId
            channel {
              id
              tableId
              name
            }
            topic {
              id
              tableId
              name
            }
            reason {
              id
              tableId
              name
            }
            creationDate
            user {
              firstname
              lastname
            }
          }
        }
      }
    }
  }
`
