import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import PageContainer from '../../components/PageContainer'
import NavBack from '../../components/NavBack'
import UsageUnitOverview from './UsageUnitOverview'
import Spacer from '../../components/Spacer'
import { useAgGridUltra } from '../../components/AgGrid/AgGridUltra/hooks/useAgGridUltra'
import { useStatusHistoryGrid } from './gridConfigHooks/useStatusHistoryGrid'
import { useAppointmentHistoryGrid } from './gridConfigHooks/useAppointmentHistoryGrid'
import { useRenderedServicesGrid } from './gridConfigHooks/useRenderedServicesGrid'
import { useInventoryGrid } from './gridConfigHooks/useInventoryGrid'
import { useCostsGrid } from './gridConfigHooks/useCostsGrid'
import { useTenantHistoryGrid } from './gridConfigHooks/useTenantHistoryGrid'

const UsageUnit = (props) => {
  const { usageUnit = {}, usageUnitId, loading = false, services = [] } = props
  const { t } = useTranslation()

  const grids = [
    {
      id: 'status-history',
      label: t('STATUS HISTORY'),
      useGetParams: useStatusHistoryGrid(),
    },
    {
      id: 'appointment-history',
      label: t('APPOINTMENT HISTORY'),
      useGetParams: useAppointmentHistoryGrid(),
    },
    {
      id: 'rendered-services',
      label: t('CONTRACTED SERVICES'),
      useGetParams: useRenderedServicesGrid(),
    },
    {
      id: 'inventory',
      label: t('INVENTORY'),
      useGetParams: useInventoryGrid(),
    },
    { id: 'document', label: t('DOCUMENT MANAGEMENT'), disabled: true },
    {
      id: 'costs',
      label: t('SERVICE COSTS'),
      useGetParams: useCostsGrid(),
    },
    { id: 'order', label: t('ORDER HISTORY'), disabled: true },
    {
      id: 'tenant-history',
      label: t('INHABITANT HISTORY'),
      useGetParams: useTenantHistoryGrid(),
    },
    { id: 'communication', label: t('COMMUNICATION'), disabled: true },
  ]

  const {
    generateServiceDropDown,
    generatePills,
    currentService,
    setCurrentService,
    generateGrid,
    setFilterVariables,
    filterVariables,
  } = useAgGridUltra('UsageUnit', grids)

  useEffect(() => {
    if (
      services.length > 0 &&
      !currentService &&
      usageUnitId &&
      usageUnit?.referenceNumber
    ) {
      setCurrentService(services[0])
      setFilterVariables({
        usageUnitId,
        referenceNumber: usageUnit?.referenceNumber,
      })
    }
  }, [
    currentService,
    usageUnitId,
    services,
    setCurrentService,
    setFilterVariables,
    usageUnit.referenceNumber,
  ])

  return (
    <PageContainer fluid>
      <NavBack
        title={t('USAGE UNIT VIEW')}
        historyBack
        style={{ marginBottom: '16px' }}
      />

      <UsageUnitOverview
        usageUnit={usageUnit}
        usageUnitId={usageUnitId}
        loading={loading}
      />

      <Spacer />
      {generateServiceDropDown(services)}
      {generatePills()}
      {generateGrid(
        !(!loading && currentService && filterVariables?.usageUnitId)
      )}
    </PageContainer>
  )
}

export default UsageUnit

UsageUnit.propTypes = {
  usageUnit: PropTypes.object,
  loading: PropTypes.bool,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.any,
      tableId: PropTypes.any,
      selected: PropTypes.any,
      disabled: PropTypes.any,
    })
  ),
}
