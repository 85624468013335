export function pushNewUrlToHistory(key, value, operation = 'set') {
  const url = new URL(window.location)

  if (operation === 'set') {
    url.searchParams.set(key, value)
  }

  if (operation === 'delete') {
    url.searchParams.delete(key)
  }

  window.history.pushState({}, '', url)
}

export function clearQueryParamsFromUrl() {
  const url = new URL(window.location)
  window.history.pushState({}, '', url.toString().split('?')[0])
}
