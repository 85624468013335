import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query QuotaCountQuery($filters: [Filter], $companyId: ID!) {
    Me {
      id
      Company(id: $companyId) {
        data: countQuotas(options: { filters: $filters })
      }
    }
  }
`
