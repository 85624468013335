import styled from 'styled-components/macro'
import { Col, Container, Row } from 'react-grid-system'
import { portalTheme, StyledInput } from 'pyrexx-react-library'

export const StyledColCenter = styled(Col)`
  display: flex;
  align-items: end;
  line-height: normal;
`

export const StyledRowShade = styled(Row)`
  padding-top: 5px;

  &:nth-child(even) {
    background-color: ${portalTheme.color.basicColorLightGrey2};
  }
`

export const StyledContainerLink = styled(Container)`
  padding: 0px !important;
  margin-bottom: 1rem;
  cursor: pointer;
  max-width: 100%;
`

export const CustomStyledInput = styled(StyledInput)`
  height: 45px;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-bottom: 5px;
  padding-left: 4px;
  ${(props) =>
    props.value ? 'background-color: #c6cfd9;' : 'border: solid 1px #000;'};
`

export const StyledColColor = styled(Col)`
  background-color: ${(props) => props.color};
  line-height: normal;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  border-radius: 3px;
  font-size: 14px;
  font-weight: ${portalTheme.font.weight.bold};
`

export const StyledColSearchResultItem = styled(Col)`
  background-color: ${(props) => props.color};
  line-height: normal;
  font-size: 14px;
  font-weight: ${portalTheme.font.weight.regular};
`

export const StyledColResultsOverflow = styled.div`
  width: 100%;
  max-height: calc((100vh - 200px));
  overflow-y: auto;
  overflow-x: hidden;
`

export const StyledErrorContainer = styled.div`
  height: 12px;
  font-size: 12px;
  color: ${portalTheme.color.errorColor};
`
