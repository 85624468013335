import React, { lazy, useCallback } from 'react'
import { Col, Row } from 'react-grid-system'

import InhabitantChange from './InhabitantChange/InhabitantChange'
import UsageUnitLock from './UsageUnitLock/UsageUnitLock'
import UsageUnitUnlock from './UsageUnitUnlock/UsageUnitUnlock'

const PreferredDate = lazy(() => import('./PreferredDate'))

// STEP 4
const ComponentHandler = (props) => {
  const {
    components = {},
    formik,
    position = 'center',
    connectedIds = [],
    componentData = {},
    summaryView = false,
  } = props

  const getComponent = useCallback(
    (componentName) => {
      switch (componentName) {
        case 'preferredDate':
          return <PreferredDate formik={formik} />

        case 'inhabitantChange':
          return (
            <InhabitantChange
              formik={formik}
              connectedIds={connectedIds}
              componentData={componentData}
            />
          )

        case 'usageUnitLock':
          return <UsageUnitLock formik={formik} componentData={componentData} />

        case 'usageUnitUnlock':
          return (
            <UsageUnitUnlock formik={formik} componentData={componentData} />
          )

        default:
          return 'default'
      }
    },
    [componentData, connectedIds, formik]
  )

  const getComponentSummaryView = useCallback(
    (componentName) => {
      switch (componentName) {
        case 'inhabitantChange':
          return (
            <InhabitantChange
              formik={formik}
              connectedIds={connectedIds}
              componentData={componentData}
              showSummaryView
            />
          )
        case 'usageUnitLock':
          return (
            <UsageUnitLock
              formik={formik}
              componentData={componentData}
              showSummaryView
            />
          )

        case 'usageUnitUnlock':
          return (
            <UsageUnitUnlock
              formik={formik}
              componentData={componentData}
              showSummaryView
            />
          )

        default:
          return 'default'
      }
    },
    [componentData, connectedIds, formik]
  )

  return Object.keys(components)
    .filter((componentName) => components[componentName].position === position)
    .map((componentName, index) => {
      return (
        <Row
          key={'name_' + componentName + index}
          style={{ marginBottom: '1em' }}
        >
          <Col>
            {!summaryView
              ? getComponent(componentName)
              : getComponentSummaryView(componentName)}
          </Col>
        </Row>
      )
    })
}
export default ComponentHandler
