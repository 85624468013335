const calculateIndexes = (chatLength = 0, condition = {}) => {
  const { begin = 0, end = 0 } = condition

  const indexBegin = begin
  const indexEnd = chatLength - 1 - end
  const renderButtons = begin + end < chatLength

  const defaultNumberOfChatMsgs = chatLength - begin - end

  return { indexBegin, indexEnd, renderButtons, defaultNumberOfChatMsgs }
}

export default calculateIndexes
