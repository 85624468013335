/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LinkIdsContainerQueryVariables = {|
  companyId: string,
  pipelineId: string,
|};
export type LinkIdsContainerQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +OrderWizard: ?{|
        +pipeline: ?any
      |}
    |}
  |}
|};
export type LinkIdsContainerQuery = {|
  variables: LinkIdsContainerQueryVariables,
  response: LinkIdsContainerQueryResponse,
|};
*/


/*
query LinkIdsContainerQuery(
  $companyId: ID!
  $pipelineId: ID!
) {
  Me {
    Company(id: $companyId) {
      OrderWizard {
        pipeline(id: $pipelineId)
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pipelineId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderWizardQueries",
  "kind": "LinkedField",
  "name": "OrderWizard",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "pipelineId"
        }
      ],
      "kind": "ScalarField",
      "name": "pipeline",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LinkIdsContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LinkIdsContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "857eb47e064a2c724414bff1a8e4e193",
    "id": null,
    "metadata": {},
    "name": "LinkIdsContainerQuery",
    "operationKind": "query",
    "text": "query LinkIdsContainerQuery(\n  $companyId: ID!\n  $pipelineId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      OrderWizard {\n        pipeline(id: $pipelineId)\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9b7a37503fc0a333d0f0bfd9a641cfad';

module.exports = node;
