import { fetchQuery } from 'react-relay'
import FilterModel from './FilterModel'
import * as Throttle from 'promise-parallel-throttle'
import generateEnvironment from '../../utils/generateEnvironment'

export default class DownloadDatasource {
  constructor(
    serverSideDataSource,
    countQuery,
    countExtractor,
    loaded,
    setDownloadLoad,
    withPDFDownload,
    chunkSize,
    startRow,
    maxRowsPerFile
  ) {
    this.setDownloadLoad = setDownloadLoad
    this.withPDFDownload = withPDFDownload
    this.origin = serverSideDataSource
    this.query = serverSideDataSource.query
    this.extractor = serverSideDataSource.extractor
    this.params = serverSideDataSource.params
    this.countQuery = countQuery
    this.countExtractor = countExtractor
    this.loaded = loaded
    this.startRow = startRow
    this.progressEndCount = 0
    this.progressPositionCount = 0
    this.environment = generateEnvironment(
      process.env.REACT_APP_API_URL,
      this.origin.user.accessToken
    )
    this.chunkSize = chunkSize || 1000
    this.maxRowsPerFile = maxRowsPerFile || 5000
  }

  getRows(params) {
    const {
      startRow = 0,
      endRow = 0,
      sortModel = {},
      filterModel = {},
      companyId = this.origin.user.companyId,
    } = params.request
    const RemoveDefaultCustomFilterWithSameUserFilters = (
      userFilters,
      defaultCustomFilters
    ) => {
      let newDefaultCustomFilters = defaultCustomFilters
      userFilters.forEach((singleFilter, index) => {
        const sameFilter = defaultCustomFilters.find(
          (element) => element.name === singleFilter.name
        )
        if (sameFilter) {
          newDefaultCustomFilters = newDefaultCustomFilters.filter(
            (element) => element.name !== singleFilter.name
          )
        }
      })
      return newDefaultCustomFilters
    }
    const filters = FilterModel(filterModel)
    const chunkSize = this.chunkSize
    const promises = []
    const variables = this.origin.variables
    const customFilters = this.origin.customFilters || []
    const newDefaultCustomFilters =
      RemoveDefaultCustomFilterWithSameUserFilters(filters, customFilters)
    fetchQuery(
      this.environment,
      this.countQuery,
      {
        ...this.params,
        startRow,
        endRow,
        sortModel,
        filters: [...filters, ...newDefaultCustomFilters],
        companyId,
        ...variables,
      },
      {
        force: true,
      }
    )
      .then(this.countExtractor)
      .then((pageSizeTmp) => {
        if (!pageSizeTmp) {
          return []
        }
        let pageSize
        console.log(
          'pageSizeTmp - this.startRow > this.maxRowsPerFile',
          pageSizeTmp,
          this.startRow,
          this.maxRowsPerFile
        )
        if (pageSizeTmp - this.startRow > this.maxRowsPerFile) {
          console.log(
            'Case 1 pageSize = this.maxRowsPerFile + this.startRow',
            this.maxRowsPerFile + this.startRow
          )
          pageSize = this.maxRowsPerFile + this.startRow
        } else {
          console.log('Case 2 pageSize = pageSizeTmp', pageSizeTmp)
          pageSize = pageSizeTmp
        }
        let current = 0
        let remaining = pageSize - this.startRow
        console.log(
          'remaining = pageSize - this.startRow',
          remaining,
          pageSize,
          this.startRow
        )
        this.progressPositionCount = Math.floor(this.startRow / chunkSize)
        this.progressEndCount = Math.ceil(pageSizeTmp / chunkSize)
        while (remaining > 0) {
          const position = current + 1
          const startRow = pageSize - remaining
          let endRow = pageSize - remaining + chunkSize
          console.log(
            'endRow = pageSize - remaining + chunkSize',
            endRow,
            pageSize,
            remaining,
            chunkSize
          )

          if (endRow > pageSize) {
            endRow = pageSize
            console.log('endRow = pageSize', endRow, pageSize)
          }

          promises.push(() => {
            return fetchQuery(
              this.environment,
              this.query,
              {
                ...this.params,
                startRow,
                endRow,
                sortModel,
                filters: [...filters, ...customFilters],
                companyId,
                ...variables,
              },
              {
                force: true,
              }
            )
              .then((data) => {
                this.progressPositionCount += 1
                this.setDownloadLoad(
                  Math.ceil(
                    (this.progressPositionCount / this.progressEndCount) * 100
                  )
                )
                return {
                  position,
                  rows: this.extractor(data),
                }
              })
              .catch(params.failCallback)
          })

          remaining = remaining - chunkSize
          current++
        }
        Throttle.all(promises)
          .then((values) => {
            values.sort((a, b) => {
              if (a.position > b.position) {
                return -1
              }
              if (a.position < b.position) {
                return 1
              }
              return 0
            })
            return values
          })
          .then((values) => {
            let rows = []
            for (let i = values.length - 1; i >= 0; i--) {
              rows = rows.concat(values[i].rows.slice(0, chunkSize))
            }
            return rows
          })
          .then((rows) => {
            params.successCallback(
              rows,
              rows.length <= pageSize
                ? params.request.startRow + rows.length
                : -1
            )
            return rows.length
          })
          .then((count) => {
            this.loaded({
              finished: pageSizeTmp === pageSize,
              startRow: pageSize,
              maxFiles: Math.ceil(pageSizeTmp / this.maxRowsPerFile),
            })
          })
          .catch(params.failCallback)
      })
  }

  getServerSideDataSource() {
    return this.origin
  }
}
