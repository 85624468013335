import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'react-grid-system'

import { ButtonSecondary, ModalHint, portalTheme } from 'pyrexx-react-library'
import AgGrid from '../../components/AgGrid'
import ServiceDetailsQuery from './queries/ServiceDetails'

import ServerSideDatasource from '../../environments/api/serverSideDataSource'
import {
  columnCheckbox,
  withFilterParams,
} from '../../components/AgGrid/columnDefs'
import defaultGridOptions from '../../components/AgGrid/defaultGridOptions'
import ReleaseQuotaMutation from '../../mutations/ReleaseQuotaMutation'

import { useUserContext } from '../../helper/userContext'

const ServiceDetails = (props) => {
  const { appointmentId } = props

  const { user } = useUserContext()
  const { t } = useTranslation()

  const [modal, setModal] = useState({
    show: false,
    message: '',
    buttonMessage: '',
    primaryButton: {
      usePrimaryButton: true,
      useFunction: () => {},
    },
    showButtonClose: true,
  })
  // eslint-disable-next-line no-unused-vars
  const [gridApi, setGridApi] = useState(null)

  const ServiceDetailsColumnDefs = [
    columnCheckbox,
    {
      headerName: t('ACTION POSITION'),
      field: 'actionPosition.name',
      flex: 1,
      ...withFilterParams,
    },
    {
      headerName: t('CAUSE OF ORDER'),
      field: 'actionReason.name',
      flex: 1,
      ...withFilterParams,
    },
    {
      headerName: t('SERVICE REASON'),
      field: 'actionReason.msgId',
      flex: 1,
      valueGetter: ({ data }) => {
        const { msgId = '', name = '' } = data.actionReason

        return msgId || name
      },
      ...withFilterParams,
    },
    {
      headerName: t('TYPE OF USE'),
      field: 'position.room.type',
      flex: 1,
      ...withFilterParams,
    },
  ]

  const serverSideDatasource = new ServerSideDatasource(
    ServiceDetailsQuery,
    (response) => response.Me?.Company?.Appointment?.data,
    user,
    { appointmentId },
    []
  )

  const onGridReady = (params) => {
    setGridApi(params.api)

    params.api.sizeColumnsToFit()
  }

  const gridOptions = {
    ...defaultGridOptions,
    serverSideDatasource,
    columnDefs: ServiceDetailsColumnDefs,
  }

  const triggerQuotaReleaseMutation = () => {
    ReleaseQuotaMutation(
      {
        appointmentId,
        companyId: user.companyId,
      },
      user.accessToken,
      () => {
        setModal({
          show: true,
          message: t('WE HAVE RELEASED YOUR QUOTA'),
          buttonMessage: t('UNDERSTOOD'),
          primaryButton: {
            usePrimaryButton: true,
            useFunction: () => {},
          },
          showButtonClose: false,
        })
      },
      () => {
        setModal({
          show: true,
          message: t('ERROR WITH YOUR REQUEST. PLEASE TRY AGAIN'),
          buttonMessage: t('UNDERSTOOD'),
          showButtonClose: false,
          primaryButton: {
            usePrimaryButton: true,
            useFunction: () => {},
          },
        })
      }
    )
  }

  return (
    <>
      <Row
        justify='between'
        align='end'
        style={{ marginTop: '2rem', paddingBottom: '0.5rem' }}
      >
        <Col xs='content'>{t('SERVICE DETAILS')}</Col>
        <Col xs={9}>
          <Row justify='end' align='end'>
            <Col
              xs={6}
              style={{ fontSize: portalTheme.font.size.headingTable }}
            >
              {t(
                'THIS SERVICE IS INCLUDED IN A FREE VOLUME. TO RELEASE IT FROM THE FREE VOLUME YOU CAN ORDER A SINGLE INVOICE.'
              )}
            </Col>
            <Col xs='content'>
              <ButtonSecondary
                onClick={() => {
                  setModal({
                    show: true,
                    message: t(
                      'PLEASE CONFIRM THAT YOU WANT TO RELEASE YOUR QUOTA'
                    ),
                    buttonMessage: t('CONFIRM'),
                    primaryButton: {
                      usePrimaryButton: true,
                      useFunction: () => triggerQuotaReleaseMutation(),
                    },
                    showButtonClose: true,
                  })
                }}
                style={{
                  height: '2.5rem',
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                }}
              >
                {t('RELEASE FROM FREE VOLUME')}
              </ButtonSecondary>
            </Col>
          </Row>
        </Col>
      </Row>

      <AgGrid onGridReady={onGridReady} gridOptions={gridOptions} />

      <ModalHint
        isOpen={modal.show}
        handleModalClose={() => setModal({ show: false })}
        message={modal.message}
        buttonCloseMessage={modal.buttonMessage}
        title=''
        showButtonClose={modal.showButtonClose}
        primaryButton={modal.primaryButton}
      />
    </>
  )
}

ServiceDetails.propTypes = {
  appointmentId: PropTypes.any.isRequired,
}

export default ServiceDetails
