import React from 'react'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import {
  ButtonSecondary,
  LoadingSpinner,
  portalTheme,
  Searchbox,
  SimpleNote,
} from 'pyrexx-react-library'

import { StyledErrorContainer } from '../../../../../components/Layout/Header/SearchContainer/SearchContainer.styles'
import { StyledMountingAnimation } from '../../../../../styles/HelperStylesComponents.styles'
import { pushNewUrlToHistory } from '../../../../../helper/urlHelpers'

export const SearchInputBox = (props) => {
  const {
    handleChange,
    querySearchVariables,
    loading,
    setShowSearchTextError,
    formik,
    refX,
    showSearchTextError,
  } = props

  const { t } = useTranslation()

  const handleSubmit = () => {
    pushNewUrlToHistory('linkedSearchText', formik.values.searchText)

    if (formik.errors.searchText) {
      setShowSearchTextError(true)
      return
    }
    formik.handleSubmit()
  }

  return (
    <Row style={{ paddingTop: '1rem', paddingBottom: '1rem' }} nogutter>
      <Col xs={4}>
        <SimpleNote
          text={t('PLEASE LINK THE NECESSARY DATA RECORDS TO YOUR REQUEST')}
          noteStatus='info'
          style={{ fontSize: '14px' }}
        />
      </Col>
      <Col>
        <CustomLinkIdSearchBox
          disabledborder
          disabled={loading}
          searchBoxContainerStyle={{ maxWidth: '100%' }}
          disabledComponent={
            <LoadingSpinner
              theme={{
                components: {
                  spinner: {
                    /**
                     * Has to be a { css } oject from styled-components
                     */
                    fontSize: portalTheme.font.size.bodySmall,
                    fontWeight: portalTheme.font.weight.regular,
                    size: '20px',
                  },
                },
              }}
              style={{
                position: 'unset',
                backgroundColor: 'white',
              }}
            />
          }
          style={{ width: '100%' }}
          id='searchText'
          clearSearchAfterSubmit={false}
          handleSubmit={handleSubmit}
          handleChange={(search) => {
            refX.current.triggerCheckbox = false
            handleChange(search)
            formik.setFieldValue('searchText', search)
          }}
          placeholder={t('SEARCH')}
          searchText={querySearchVariables.searchText}
          data-search-box-container='linkId'
        />
        <StyledErrorContainer>
          <StyledMountingAnimation
            transitionName='ShowSearchTextError'
            transitionEnterTimeout={500}
            transitionLeaveTimeout={2000}
          >
            {showSearchTextError && <span>{formik.errors.searchText}</span>}
          </StyledMountingAnimation>
        </StyledErrorContainer>
      </Col>

      <Col xs='content' style={{ display: 'flex', justifyItems: 'start' }}>
        <ButtonSecondary
          disabled={loading}
          style={{
            height: '35px',
            paddingLeft: '2rem',
            paddingRight: '2rem',
            fontSize: '14px',
          }}
          onClick={handleSubmit}
        >
          {t('SEARCH')}
        </ButtonSecondary>
      </Col>
    </Row>
  )
}

export const CustomLinkIdSearchBox = styled(Searchbox)`
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #666;
  padding: 0;
  padding-bottom: 2px;
  margin-top: 5px;
  margin-bottom: 5px;

  & input {
    padding-left: 0.5rem;
  }

  height: unset;
`
