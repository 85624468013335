import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import DropdownWithLabel from '../DropdownWithLabel'
import Dropdown from './Dropdown'

import generateFilterDefinitions from '../../helper/generateFilterDefinitions'
import { useUserContext } from '../../helper/userContext'
import { MoreActionIcon } from '../../styles/HelperStylesComponents.styles'

const OverviewFilter = (props) => {
  const {
    overviewFilterType = '',
    overviewFilterData = {},
    customKeys = {},
    saveFilters = () => {},
    filters = [],
    openFromStart = false,
    align = 'right',
    keepOpen = false,
    ...rest
  } = props
  const { currentLanguage } = useUserContext()
  const { t } = useTranslation()

  // console.log('filters', filters)

  const overviewFilterDefinitions = generateFilterDefinitions({
    types: overviewFilterType,
    customKeys,
    t,
    currentLanguage,
  })

  return (
    <DropdownWithLabel
      openFromStart={openFromStart}
      align={align}
      keepOpen={keepOpen}
      passPropsToChildren
      renderLabel={(isDropdownOpen) => (
        <Label isDropdownOpen={isDropdownOpen}>
          <span>{t('FILTER BY')}</span>
          <MoreActionIcon />
        </Label>
      )}
    >
      <Dropdown
        overviewFilterDefinitions={overviewFilterDefinitions}
        overviewFilterData={overviewFilterData}
        saveFilters={saveFilters}
        filters={filters}
        align={align}
        {...rest}
      />
    </DropdownWithLabel>
  )
}

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  background-color: #fff;

  padding: 0 16px;

  transition: 0.2s ease-out;

  &:hover,
  &:focus {
    background-color: #dbe7f3;
  }

  ${(props) => props.isDropdownOpen && `background-color: #dbe7f3;`};

  // Styles from mockup
  border-radius: 10px;
`

OverviewFilter.propTypes = {
  overviewFilterType: PropTypes.string,
  overviewFilterData: PropTypes.object,
  customKeys: PropTypes.object,
  saveFilters: PropTypes.func,
  filters: PropTypes.array,
  openFromStart: PropTypes.bool,
  align: PropTypes.oneOf(['right', 'left']),
  keepOpen: PropTypes.bool,
}

export default OverviewFilter
