import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query QuotaGridQuery(
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
    $companyId: ID!
  ) {
    Me {
      Company(id: $companyId) {
        id
        Quotas(
          options: {
            startRow: $startRow
            endRow: $endRow
            sorting: $sortModel
            filters: $filters
          }
        ) {
          id
          name
          appointment {
            id
            date
            forname
            name
            tableId
            usageUnit {
              id
              referenceNumber
              tableId
            }
            houseEntrance {
              id
              address {
                street
                city
                number
              }
            }
          }
        }
      }
    }
  }
`
