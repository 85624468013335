/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AppointmentStatusType = "CANCELLED" | "CHECKED" | "CLOSED" | "DEACTIVATED" | "DIAGNOSE_PENDING" | "DONE" | "NONE" | "OPEN" | "PLANNED" | "TEMP_DEFERRED" | "UNTERMINATED" | "%future added value";
export type AppointmentTypeType = "P" | "REMOTE_DATA_COLLECTION" | "T" | "TA" | "TI" | "TS" | "TX" | "%future added value";
export type AppointmentQueryVariables = {|
  companyId: string,
  appointmentId: string,
|};
export type AppointmentQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +Appointment: ?{|
        +id: ?string,
        +tableId: ?number,
        +status: ?AppointmentStatusType,
        +usageUnit: ?{|
          +id: string,
          +tableId: number,
          +floor: number,
          +position: string,
          +tenants: $ReadOnlyArray<?{|
            +id: ?string,
            +tableId: ?number,
            +firstname: string,
            +lastname: string,
            +begin: ?any,
            +end: ?any,
          |}>,
        |},
        +referenceNumber: ?string,
        +address: ?{|
          +street: string,
          +zip: string,
          +city: string,
          +number: string,
        |},
        +date: ?any,
        +timeframe: ?string,
        +type: ?AppointmentTypeType,
        +accessReason: ?{|
          +tableId: number,
          +msgId: ?string,
        |},
        +executionDate: ?any,
        +images: ?$ReadOnlyArray<?{|
          +tableId: number,
          +name: string,
          +description: string,
          +url: ?string,
        |}>,
        +registrations: $ReadOnlyArray<?{|
          +id: string,
          +images: ?$ReadOnlyArray<?{|
            +id: string,
            +tableId: number,
            +name: string,
            +description: string,
            +url: ?string,
          |}>,
        |}>,
        +previousAppointments: ?$ReadOnlyArray<?{|
          +id: ?string,
          +tableId: ?number,
          +date: ?any,
          +timeframe: ?string,
          +type: ?AppointmentTypeType,
          +accessReason: ?{|
            +tableId: number,
            +msgId: ?string,
          |},
          +executionDate: ?any,
        |}>,
      |}
    |}
  |}
|};
export type AppointmentQuery = {|
  variables: AppointmentQueryVariables,
  response: AppointmentQueryResponse,
|};
*/


/*
query AppointmentQuery(
  $companyId: ID!
  $appointmentId: ID!
) {
  Me {
    Company(id: $companyId) {
      Appointment(id: $appointmentId) {
        id
        tableId
        status
        usageUnit {
          id
          tableId
          floor
          position
          tenants(options: {endRow: -1, filters: [{filterType: number, operator: AND, name: "status", conditions: [{value: ["STATUS_ACTIVE"], type: equals}]}]}) {
            id
            tableId
            firstname
            lastname
            begin
            end
          }
        }
        referenceNumber
        address {
          street
          zip
          city
          number
        }
        date
        timeframe
        type
        accessReason {
          tableId
          msgId
          id
        }
        executionDate
        images {
          tableId
          name
          description
          url
          id
        }
        registrations {
          id
          images {
            id
            tableId
            name
            description
            url
          }
        }
        previousAppointments {
          id
          tableId
          date
          timeframe
          type
          accessReason {
            tableId
            msgId
            id
          }
          executionDate
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "appointmentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "appointmentId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "UsageUnit",
  "kind": "LinkedField",
  "name": "usageUnit",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "floor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "options",
          "value": {
            "endRow": -1,
            "filters": [
              {
                "conditions": [
                  {
                    "type": "equals",
                    "value": [
                      "STATUS_ACTIVE"
                    ]
                  }
                ],
                "filterType": "number",
                "name": "status",
                "operator": "AND"
              }
            ]
          }
        }
      ],
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenants",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "begin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "end",
          "storageKey": null
        }
      ],
      "storageKey": "tenants(options:{\"endRow\":-1,\"filters\":[{\"conditions\":[{\"type\":\"equals\",\"value\":[\"STATUS_ACTIVE\"]}],\"filterType\":\"number\",\"name\":\"status\",\"operator\":\"AND\"}]})"
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referenceNumber",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "address",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "street",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zip",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeframe",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "msgId",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Access",
  "kind": "LinkedField",
  "name": "accessReason",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v13/*: any*/)
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "executionDate",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "Registration",
  "kind": "LinkedField",
  "name": "registrations",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        (v5/*: any*/),
        (v16/*: any*/),
        (v17/*: any*/),
        (v18/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "Access",
  "kind": "LinkedField",
  "name": "accessReason",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v13/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppointmentQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "Appointment",
                "kind": "LinkedField",
                "name": "Appointment",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Image",
                    "kind": "LinkedField",
                    "name": "images",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Appointment",
                    "kind": "LinkedField",
                    "name": "previousAppointments",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AppointmentQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "Appointment",
                "kind": "LinkedField",
                "name": "Appointment",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v20/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Image",
                    "kind": "LinkedField",
                    "name": "images",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Appointment",
                    "kind": "LinkedField",
                    "name": "previousAppointments",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v20/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "33176a0c45d861dd7909507c73dfa7b0",
    "id": null,
    "metadata": {},
    "name": "AppointmentQuery",
    "operationKind": "query",
    "text": "query AppointmentQuery(\n  $companyId: ID!\n  $appointmentId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      Appointment(id: $appointmentId) {\n        id\n        tableId\n        status\n        usageUnit {\n          id\n          tableId\n          floor\n          position\n          tenants(options: {endRow: -1, filters: [{filterType: number, operator: AND, name: \"status\", conditions: [{value: [\"STATUS_ACTIVE\"], type: equals}]}]}) {\n            id\n            tableId\n            firstname\n            lastname\n            begin\n            end\n          }\n        }\n        referenceNumber\n        address {\n          street\n          zip\n          city\n          number\n        }\n        date\n        timeframe\n        type\n        accessReason {\n          tableId\n          msgId\n          id\n        }\n        executionDate\n        images {\n          tableId\n          name\n          description\n          url\n          id\n        }\n        registrations {\n          id\n          images {\n            id\n            tableId\n            name\n            description\n            url\n          }\n        }\n        previousAppointments {\n          id\n          tableId\n          date\n          timeframe\n          type\n          accessReason {\n            tableId\n            msgId\n            id\n          }\n          executionDate\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '83224e85e8d73ab8cf4eff53c3c9aaec';

module.exports = node;
