import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  columnCheckbox,
  withFilterParams,
} from '../../../components/AgGrid/columnDefs'
import {
  ContractPartitionRenderer,
  NotEquippedUsageUnitAppointmentAccessDateRenderer,
  NotEquippedUsageUnitAppointmentAccessRenderer,
  NotEquippedUsageUnitAppointmentTypeRenderer,
  // NotEquippedUsageUnitStatusRenderer,
  TenantFullNameRenderer,
  UsageUnitReferenceNumberRenderer,
} from '../renderers'
import {
  Filter1NotEquippedUsageUnitsCountQuery,
  Filter1NotEquippedUsageUnitsQuery,
} from '../queries'
import {
  contractPartitionValueGetter,
  dateTimeValueGetter,
  notEquippedUsageUnitAppointmentAccessReasonValueGetter,
  notEquippedUsageUnitAppointmentTypeValueGetter,
  notEquippedUsageUnitStatusValueGetter,
  tenantFullNameValueGetter,
} from '../valueGetter'

import { useUserContext } from '../../../helper/userContext'
import { Routes } from '../../../configs/RouteConfig'
import {
  UsageUnitLinkRenderer,
  UsageUnitLockPeriodReasonRenderer,
} from '../../PropertyUnit/renderers'
import {
  UsageUnitLockPeriodStatusRenderer,
  UsageUnitLockPeriodTimeOfLockRenderer,
} from '../../PropertyManagement/renderer'

export const useFilter1NotEquippedUsageUnitGrid = () => {
  const { currentLanguage, redirectRoute } = useUserContext()
  const { t } = useTranslation()

  const getParams = useCallback(
    (currentService) => {
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          case 'appointments.status':
            return t(
              notEquippedUsageUnitStatusValueGetter({
                value: params.value,
                data: params.node.data,
              })?.msg
            )
          case 'appointments.accessReason':
            return t(
              notEquippedUsageUnitAppointmentAccessReasonValueGetter(params)
            )
          case 'appointments.type':
            return t(notEquippedUsageUnitAppointmentTypeValueGetter(params))
          case 'tenants':
            return tenantFullNameValueGetter(params)
          case 'appointments.executionDate':
            return dateTimeValueGetter({
              value:
                params?.value && params?.value[0]
                  ? params.value[0].executionDate
                  : '',
              currentLanguage: currentLanguage,
              formatStr: 'Pp',
            })
          case 'billingPartitions.name':
            return contractPartitionValueGetter(params)
          default:
            return params.value
        }
      }

      const Filter1NotEquippedUsageUnitsColumnDefs = [
        columnCheckbox,
        {
          headerName: t('CITY'),
          field: 'address.city',
          width: 70,
          ...withFilterParams,
          sort: 'desc',
        },
        {
          headerName: t('STREET'),
          field: 'address.street',
          width: 100,
          ...withFilterParams,
          // sort: 'desc',
        },
        {
          headerName: t('STREET NR.'),
          field: 'address.number',
          width: 70,
          ...withFilterParams,
          // sort: 'desc',
          type: 'rightAligned',
        },
        {
          headerName: t('ALLOCATION NUMBER'),
          field: 'referenceNumber',
          cellRenderer: UsageUnitReferenceNumberRenderer,
          width: 100,
          ...withFilterParams,
        },
        {
          headerName: t('USAGE UNIT TYPE'),
          field: 'type',
          ...withFilterParams,
        },
        {
          headerName: t('STATUS'),
          colId: 'lockStatus',
          field: 'lockStatus',
          cellRenderer: UsageUnitLockPeriodStatusRenderer,
          sortable: false, // TODO enable sorting if possible
          // filter: 'PropertyManagementUsageUnitStatusFilter',
          ...withFilterParams,
        },
        // {
        //   headerName: t('STATUS'),
        //   colId: 'appointments.status',
        //   field: 'appointments',
        //   width: 150,
        //   cellRenderer: NotEquippedUsageUnitStatusRenderer,
        //   filter: false, // TODO: Make filterable?
        // },
        // {
        //   headerName: t('ALLOCATION NUMBER {count}', { count: 1 }),
        //   field: 'CAN.one',
        //   width: 100,
        //   hide: true,
        //   type: 'rightAligned',
        //   ...withFilterParams,
        // },
        // {
        //   headerName: t('ALLOCATION NUMBER {count}', { count: 2 }),
        //   field: 'CAN.two',
        //   width: 100,
        //   hide: true,
        //   type: 'rightAligned',
        //   ...withFilterParams,
        // },
        // {
        //   headerName: t('ALLOCATION NUMBER {count}', { count: 3 }),
        //   field: 'CAN.three',
        //   width: 100,
        //   hide: true,
        //   type: 'rightAligned',
        //   ...withFilterParams,
        // },
        // {
        //   headerName: t('ALLOCATION NUMBER {count}', { count: 4 }),
        //   field: 'CAN.four',
        //   width: 100,
        //   hide: true,
        //   type: 'rightAligned',
        //   ...withFilterParams,
        // },
        // {
        //   headerName: t('ALLOCATION NUMBER {count}', { count: 5 }),
        //   field: 'CAN.five',
        //   width: 100,
        //   hide: true,
        //   type: 'rightAligned',
        //   ...withFilterParams,
        // },
        {
          headerName: t('RESIDENT'),
          field: 'tenants',
          width: 110,
          ...withFilterParams,
          cellRenderer: TenantFullNameRenderer,
        },
        {
          headerName: t('LOCK REASON'),
          colId: 'lockPeriod.reason',
          field: 'lockPeriods',
          cellRenderer: UsageUnitLockPeriodReasonRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('LOCKED FROM'),
          colId: 'lockPeriod.begin',
          field: 'lockPeriods',
          cellRenderer: UsageUnitLockPeriodTimeOfLockRenderer,
          width: 70,
          filter: 'agDateColumnFilter',
          ...withFilterParams,
        },
        {
          headerName: t('PYREXX USAGE UNIT NUMBER'),
          field: 'tableId',
          width: 70,
          cellRenderer: UsageUnitLinkRenderer,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('ACCESS'),
          colId: 'appointments.accessReason',
          field: 'appointments',
          width: 80,
          cellRenderer: NotEquippedUsageUnitAppointmentAccessRenderer,
          filter: false, // TODO: Make filterable?
          sortable: false,
        },
        {
          headerName: t('ACCESS DATE'),
          colId: 'appointments.executionDate',
          field: 'appointments',
          hide: true,
          cellRenderer: NotEquippedUsageUnitAppointmentAccessDateRenderer,
          filter: false, // TODO: Make filterable?
          sortable: false, // TODO
        },
        {
          headerName: t('APPOINTMENT TYPE'),
          colId: 'appointments.type',
          field: 'appointments',
          hide: true,
          cellRenderer: NotEquippedUsageUnitAppointmentTypeRenderer,
          filter: false, // TODO: Make filterable?
          sortable: false,
        },
        {
          headerName: t('CONTRACT PARTITION'),
          colId: 'billingPartitions.name',
          field: 'billingPartitions',
          hide: true,
          cellRenderer: ContractPartitionRenderer,
          filter: false, // TODO: Make filterable?
          sortable: false,
        },
        {
          headerName: t('LOCK INITIATOR'),
          colId: 'lockPeriod.initiator',
          field: 'lockPeriods',
          hide: true,
          valueGetter: ({ data }) => data.lockPeriods?.[0]?.initiator,
          ...withFilterParams,
        },
      ]

      const Filter1NotEquippedUsageUnitsCustomFilter = [
        {
          filterType: 'text',
          operator: 'AND',
          name: 'installedSmokeDetectors',
          conditions: [
            {
              type: 'equals',
              value: '0',
            },
          ],
        },
        {
          name: 'contract.serviceCategory.tableId',
          filterType: 'text',
          operator: 'AND',
          conditions: [
            {
              value: [currentService.tableId],
              type: 'equals',
            },
          ],
        },
        {
          filterType: 'text',
          operator: 'AND',
          name: 'appointment.status',
          conditions: [
            {
              type: 'equals',
              value: ['OPEN', 'DONE', 'CLOSED', 'DEACTIVATED', 'CHECKED'],
            },
          ],
        },
      ]

      return {
        query: Filter1NotEquippedUsageUnitsQuery,
        columnDefs: Filter1NotEquippedUsageUnitsColumnDefs,
        customFilters: Filter1NotEquippedUsageUnitsCustomFilter,
        countQuery: Filter1NotEquippedUsageUnitsCountQuery,
        extractor: (response) => response.Me?.Company?.data,
        customGridOptions: {
          getContextMenuItems: (params) => {
            return [
              {
                // custom item
                name: t('GO TO USAGE UNIT {REFERENCENUMBER}', {
                  REFERENCENUMBER: params?.node?.data?.referenceNumber,
                }),
                action: function () {
                  redirectRoute(
                    Routes.usageUnit + '/' + params?.node?.data?.tableId
                  )
                },
              },
              'copy',
              'chartRange',
            ]
          },
          components: {},
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
          },
        },
        customVariables: {},
      }
    },
    [currentLanguage, redirectRoute, t]
  )
  return { getParams }
}
