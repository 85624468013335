import { useCallback } from 'react'
import { ReferenceNumberRenderer } from '../renderers'
import RemoveLink from '../../renderers/RemoveLink'
import { useTranslation } from 'react-i18next'
import SimpleGridFilter from '../../../../../../../../components/AgGrid/filters/SimpleGridFilter'
import { withFilterParams } from '../../../../../../../../components/AgGrid/columnDefs'
import { Routes } from '../../../../../../../../configs/RouteConfig'

export const useTenantsGrid = (setConnectedIds, noRemove) => {
  const { t, redirectRoute } = useTranslation()

  const getParams = useCallback(
    (currentService, filterVariables) => {
      const connectedIds = filterVariables.connectedIds
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          default:
            return params.value
        }
      }
      console.log('DEBUGXXX 2 connectedIds', connectedIds)
      const TenantsGridColumnDefs = [
        {
          headerName: t('ALLOCATION NUMBER'),
          field: 'referenceNumber',
          cellRenderer: ReferenceNumberRenderer,
          width: 110,
          ...withFilterParams,
        },
        {
          headerName: t('FIRST NAME'),
          field: 'tenant.firstname',
          hide: false,
          width: 150,
          ...withFilterParams,
        },
        {
          headerName: t('LAST NAME'),
          field: 'tenant.firstname',
          hide: false,
          width: 150,
          ...withFilterParams,
        },
        {
          headerName: t('STREET'),
          field: 'address.street',
          hide: false,
          width: 150,
          ...withFilterParams,
        },
        {
          headerName: t('HOUSE NUMBER'),
          field: 'address.number',
          hide: false,
          width: 150,
          ...withFilterParams,
        },
        {
          headerName: t('CITY'),
          field: 'address.city',
          hide: false,
          width: 150,
          ...withFilterParams,
        },
        {
          headerName: t('ZIP'),
          field: 'address.zip',
          hide: false,
          width: 150,
          ...withFilterParams,
        },
      ]

      if (!noRemove) {
        TenantsGridColumnDefs.push({
          headerName: t('REMOVE FROM LINKS'),
          field: 'tableId',
          hide: false,
          width: 150,
          cellRenderer: RemoveLink,
          type: 'rightAligned',
          cellRendererParams: {
            setConnectedIds: setConnectedIds,
            removeLinkIdFromState: (value) => {
              console.log('DEBUG FUNCTION REMOVE connectedIds', connectedIds)
              setConnectedIds({
                ...connectedIds,
                tenant: connectedIds.tenant.filter(
                  (object) => object.tableId !== value
                ),
              })
            },
          },
        })
      }

      return {
        clientData: connectedIds.tenant,
        columnDefs: TenantsGridColumnDefs,
        customGridOptions: {
          getContextMenuItems: (params) => {
            return [
              {
                // custom item
                name: t('GO TO USAGE UNIT {REFERENCENUMBER}', {
                  REFERENCENUMBER: params?.node?.data?.referenceNumber,
                }),
                action: function () {
                  redirectRoute(
                    Routes.usageUnit + '/' + params?.node?.data?.tableId
                  )
                },
              },
              'copy',
              'chartRange',
            ]
          },
          frameworkComponents: {
            SimpleGridFilter,
          },
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
            skipColumns: [],
          },
        },
        customVariables: {},
      }
    },
    [noRemove, redirectRoute, setConnectedIds, t]
  )
  return { getParams }
}
