const filterKeysFromRawData = (rawString = '{}', notAllowedKeys = []) => {
  if (rawString.length <= 0) {
    return {}
  }

  const rawObject = JSON.parse(rawString)

  return Object.keys(rawObject)
    .filter((key) => !notAllowedKeys.includes(key))
    .reduce((obj, key) => {
      obj[key] = rawObject[key]
      return obj
    }, {})
}

const generateColumns = (newColumns = {}) => {
  const hasValues = Object.entries(newColumns).length > 0

  if (hasValues) {
    return Object.entries(newColumns).map(([key, value]) => {
      return {
        headerName: key,
        field: key,
      }
    })
  } else {
    return []
  }
}

export { filterKeysFromRawData, generateColumns }
