import React from 'react'
import { useTranslation } from 'react-i18next'

import SimpleLink from '../../../components/Simple/SimpleLink'

import { InvoicesServiceCostsQuery } from '../queries/InvoicesServiceCostsQuery'
import { CountInvoicesServiceCostsQuery } from '../queries/CountInvoicesServiceCosts'

import {
  columnCheckbox,
  withFilterParams,
  withHiddenParams,
} from '../../../components/AgGrid/columnDefs'
// import {
//   ContractPartitionRenderer,
//   ContractPerformanceRenderer,
// } from '../../Contracts/renderers'
// import { AppointmentIdRenderer } from '../../UsageUnit/renderers'

import { useUserContext } from '../../../helper/userContext'
import formatDateLocale from '../../../helper/formatDateLocale'
import { Routes } from '../../../configs/RouteConfig'

export const useInvoicesServiceCostsGrid = (props) => {
  const { contractId, timeframeId } = props

  const { currentLanguage } = useUserContext()
  const { t } = useTranslation()

  const getParams = React.useCallback(() => {
    const InvoicesServiceCostsColumnDefs = [
      columnCheckbox,
      {
        colId: 'status',
        filter: 'SimpleGridFilter',
        ...withHiddenParams,
      },
      {
        colId: 'contract',
        filter: 'SimpleGridFilter',
        ...withHiddenParams,
      },
      {
        colId: 'contract.billingPeriod',
        filter: 'SimpleGridFilter',
        ...withHiddenParams,
      },
      {
        headerName: t('PYREXX USAGE UNIT NUMBER'),
        field: 'usageUnit.tableId',
        cellRenderer: ({ value }) => (
          <SimpleLink
            to={'/' + currentLanguage + Routes.usageUnit + '/' + value}
            style={{ fontSize: 'inherit' }}
          >
            {value}
          </SimpleLink>
        ),
        ...withFilterParams,
        type: 'rightAligned',
      },
      {
        headerName: t('CONTRACT PARTITION'),
        colId: 'contract.billingPartitions.name',
        field: 'contract.billingPartitions',
        // cellRenderer: ContractPartitionRenderer,
        // TODO FLAT DATA
        valueGetter: ({ data }) => data?.contract?.billingPartitions[0]?.name,
        filter: false, // TODO: Make filterable?
        sortable: false,
      },
      {
        headerName: t('CONTRACT PERFORMANCE'),
        field: 'registrations',
        // cellRenderer: ContractPerformanceRenderer,
        // TODO FLAT DATA
        valueGetter: ({ data }) =>
          data?.appointments[0]?.registrations[0]?.actionPosition?.name,
        filter: false, // TODO: Make filterable?
        sortable: false,
      },
      {
        headerName: t('PYREXX PERFORMANCE NUMBER'),
        field: 'appointment.tableId',
        // cellRenderer: AppointmentIdRenderer,
        // TODO FLAT DATA
        valueGetter: ({ data }) => data?.appointments[0]?.tableId,
        cellRenderer: ({ value }) => (
          <SimpleLink
            to={{
              pathname:
                '/' + currentLanguage + Routes.appointment + '/' + value,
            }}
            style={{ fontSize: 'inherit' }}
          >
            {value}
          </SimpleLink>
        ),
        ...withFilterParams,
        type: 'rightAligned',
      },
      {
        headerName: t('VALUE'),
        field: 'value',
        valueGetter: ({ data }) => data?.value?.toFixed(2),
        ...withFilterParams,
        type: 'rightAligned',
      },
      {
        headerName: t('IS GROSS'),
        field: 'isGross',
        valueGetter: ({ data }) => (data?.isGross ? t('YES') : t('NO')),
        ...withFilterParams,
      },
      {
        headerName: t('INHABITANT'),
        field: 'tenant.fullname',
        ...withFilterParams,
      },
      {
        headerName: t('STREET'),
        field: 'tenant.usageUnit.address.street',
        ...withFilterParams,
      },
      {
        headerName: t('HOUSE NUMBER'),
        field: 'tenant.usageUnit.address.number',
        type: 'rightAligned',
        ...withFilterParams,
      },
      {
        headerName: t('CITY'),
        field: 'tenant.usageUnit.address.city',
        type: 'rightAligned',
        ...withFilterParams,
      },
      {
        headerName: t('ZIP'),
        field: 'tenant.usageUnit.address.zip',
        ...withFilterParams,
        hide: true,
      },
      {
        headerName: t('RECEIVABLE FROM'),
        field: 'fromDate',
        valueGetter: ({ data }) =>
          formatDateLocale(currentLanguage, data?.fromDate, 'P'),
        ...withFilterParams,
        hide: true,
      },
      {
        headerName: t('RECEIVABLE TO'),
        field: 'toDate',
        valueGetter: ({ data }) =>
          formatDateLocale(currentLanguage, data?.toDate, 'P'),
        ...withFilterParams,
        hide: true,
      },
      {
        headerName: t('COST POSITION'),
        field: 'costPosition.name',
        ...withFilterParams,
        hide: true,
      },
    ]

    const InvoicesServiceCostsCustomFilters = [
      {
        name: 'status',
        filterType: 'text',
        operator: 'AND',
        conditions: [
          {
            value: ['SERVICE_RENDERED'],
            type: 'equals',
          },
        ],
      },
      {
        name: 'contract',
        filterType: 'text',
        operator: 'AND',
        conditions: [
          {
            value: [contractId],
            type: 'equals',
          },
        ],
      },
      {
        name: 'contract.billingPeriod',
        filterType: 'text',
        operator: 'AND',
        conditions: [
          {
            value: [timeframeId],
            type: 'equals',
          },
        ],
      },
    ]

    return {
      query: InvoicesServiceCostsQuery,
      columnDefs: InvoicesServiceCostsColumnDefs,
      customFilters: InvoicesServiceCostsCustomFilters,
      countQuery: CountInvoicesServiceCostsQuery,
      extractor: (response) => {
        const data = response.Me?.Company?.data

        // TODO get registrations
        // console.log(data)
        return data
      },
      customGridOptions: {
        defaultCsvExportParams: {
          skipColumns: [
            'checkbox',
            'status',
            'contract',
            'contract.billingPeriod',
          ],
        },
      },
      // customVariables: {},
    }
  }, [contractId, currentLanguage, t, timeframeId])

  return { getParams }
}
