import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../../../../environments/pom/Environment'

const mutation = graphql`
  mutation CloseTicketMutation($companyId: ID!, $ticketId: Int!) {
    Me {
      Company(id: $companyId) {
        OrderWizard {
          updateTicketStatus(ticketId: $ticketId, status: CLOSED)
        }
      }
    }
  }
`

export default ({ companyId, ticketId }, accessToken, onCompleted, onError) => {
  commitMutation(environment(accessToken), {
    mutation,
    variables: { companyId, ticketId },
    onCompleted,
    onError,
  })
}
