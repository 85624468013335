import isObjectValid from './isObjectValid'
import { DATE_FROM, DATE_TO } from '../configs/FilterConfig'

export const updateUrls = (filterModels = [], url, action) => {
  filterModels.forEach((filterModel) => {
    if (isObjectValid(filterModel) && filterModel.urlSearchParams) {
      switch (action) {
        case 'delete':
          if (filterModel?.filterId === 'date') {
            url.searchParams.set('date', 'all')
          }

          if (filterModel?.filterId === 'service') {
            url.searchParams.delete('serviceName')
          }

          if (filterModel?.filterId === 'contract') {
            url.searchParams.delete('contractName')
          }

          if (filterModel?.filterId === 'channel') {
            url.searchParams.delete('channelName')
          }

          filterModel.urlSearchParams.forEach((p) => url.searchParams.delete(p))
          break

        case 'set':
          if (filterModel?.filterId === 'date') {
            url.searchParams.delete('date')
            url.searchParams.set(DATE_FROM, filterModel?.value[0])
            url.searchParams.set(DATE_TO, filterModel?.value[1])
            break
          }

          if (filterModel?.filterId === 'service') {
            url.searchParams.set('serviceName', filterModel?.label)
          }

          if (filterModel?.filterId === 'contract') {
            url.searchParams.set('contractName', filterModel?.label)
          }

          if (filterModel?.filterId === 'channel') {
            url.searchParams.set('channelName', filterModel?.label)
          }

          filterModel.urlSearchParams.forEach((p) =>
            url.searchParams.set(p, filterModel?.value)
          )
          break

        default:
      }
    }
  })

  window.history.pushState({}, '', url)
}

const updateUrl = (filterModel = {}, url, action, newValue = '') => {
  if (isObjectValid(filterModel) && filterModel.urlSearchParams) {
    switch (action) {
      case 'delete':
        if (filterModel?.filterId === 'date') {
          url.searchParams.set('date', 'all')
        }

        if (filterModel?.filterId === 'service') {
          url.searchParams.delete('serviceName')
        }

        if (filterModel?.filterId === 'contract') {
          url.searchParams.delete('contractName')
        }

        if (filterModel?.filterId === 'channel') {
          url.searchParams.delete('channelName')
        }

        filterModel.urlSearchParams.forEach((p) => url.searchParams.delete(p))
        window.history.pushState({}, '', url)
        break

      case 'set':
        // THIS HANDLES CUSTOM ONES, like date, ...Name, etc
        if (filterModel?.filterId === 'date') {
          url.searchParams.delete('date')
          url.searchParams.set(DATE_FROM, newValue[0])
          url.searchParams.set(DATE_TO, newValue[1])
          window.history.pushState({}, '', url)
          break
        }

        if (filterModel?.filterId === 'service') {
          url.searchParams.set('serviceName', filterModel?.label)
          window.history.pushState({}, '', url)
        }

        if (filterModel?.filterId === 'contract') {
          url.searchParams.set('contractName', filterModel?.label)
          window.history.pushState({}, '', url)
        }

        if (filterModel?.filterId === 'channel') {
          url.searchParams.set('channelName', filterModel?.label)
          window.history.pushState({}, '', url)
        }

        // THIS HANDLES DEFAULT ONES, like service, contract, channel
        filterModel.urlSearchParams.forEach((p) =>
          url.searchParams.set(p, newValue)
        )
        window.history.pushState({}, '', url)
        break

      default:
    }
  }
}

export default updateUrl
