import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query GetTenantFromUsageUnitQuery($companyId: ID!, $id: ID!) {
    Me {
      Company(id: $companyId) {
        UsageUnit(id: $id) {
          tableId
          data: tenants {
            tableId
            firstname
            lastname
            status
            contact {
              type
              value
            }
          }
        }
      }
    }
  }
`
