import { useCallback, useContext } from 'react'
import {
  columnCheckbox,
  withFilterParams,
} from '../../../components/AgGrid/columnDefs'
import { CountPerformanceQuery, PerformanceGridQuery } from '../queries'
import {
  appointmentAccessReasonValueGetter,
  appointmentStatusLabelValueGetter,
  appointmentTypeValueGetter,
  contractPerformanceValueGetter,
  dateTimeValueGetter,
  tenantFullNameValueGetter,
} from '../valueGetter'
import UserContext from '../../../helper/userContext'
import { useTranslation } from 'react-i18next'
import { Routes } from '../../../configs/RouteConfig'
import {
  AppointmentAccessRenderer,
  AppointmentIdRenderer,
  AppointmentStatusRenderer,
  AppointmentTypeRenderer,
  ContractPerformanceRenderer,
  TenantFullNameRenderer,
} from '../renderers'
import { DateRenderer } from '../../../components/AgGrid/renderers'
import RoomTypeFilter from '../../UsageUnit/filters/RoomTypeFilter'
import { getPriorityHeightFromDisplayedColumns } from '../../../helper/calculateRowHeight'

export const usePerformanceOverviewGrid = () => {
  const { redirectRoute, currentLanguage } = useContext(UserContext)
  const { t } = useTranslation()
  const getParams = useCallback(
    (currentService, filterVariables) => {
      const { propertyUnitId } = filterVariables
      const getRowHeight = (params) => {
        const displayedColumns =
          params.node.columnApi?.getAllDisplayedVirtualColumns()
        const data = params?.data
        const getWidth = (field) =>
          params.node.columnApi?.getColumn?.(field)?.getActualWidth?.()

        const columnParams = [
          {
            field: 'registrations',
            value: data?.registrations,
            fieldProp: 'actionPosition',
            secondaryfieldProp: 'name',
            colWidth: getWidth('registrations'),
          },
        ]

        return getPriorityHeightFromDisplayedColumns({
          columnParams,
          displayedColumns,
        })
      }
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          case 'status':
            return t(appointmentStatusLabelValueGetter(params))
          case 'type':
            return t(appointmentTypeValueGetter(params))
          case 'registrations':
            return contractPerformanceValueGetter(params)
          case 'tenant':
            return tenantFullNameValueGetter(params)
          case 'accessReason':
            return t(appointmentAccessReasonValueGetter(params))
          case 'date':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'EP',
            })
          case 'executionDate':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'P',
            })
          case 'executionTime':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'p',
            })
          default:
            return params.value
        }
      }
      const PerformanceColumnDefs = [
        columnCheckbox,
        {
          headerName: t('STATUS'),
          field: 'status',
          width: 80,
          cellRenderer: AppointmentStatusRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('CONTRACT PARTITION'),
          field: 'billingPartition.name',
          width: 70,
          filter: false,
        },
        {
          headerName: t('CONTRACT PERFORMANCE'),
          field: 'registrations',
          width: 70,
          sortable: false,
          filter: false,
          cellRenderer: ContractPerformanceRenderer,
        },
        {
          headerName: t('PYREXX PERFORMANCE NUMBER'),
          field: 'tableId',
          width: 70,
          cellRenderer: AppointmentIdRenderer,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('PERFORMANCE REASON'),
          field: 'serviceReason.name',
          width: 70,
          ...withFilterParams,
        },
        {
          headerName: t('RESIDENT'),
          field: 'tenant',
          width: 70,
          cellRenderer: TenantFullNameRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('STREET'),
          field: 'address.street',
          width: 70,
          ...withFilterParams,
        },
        {
          headerName: t('STREET NR.'),
          field: 'address.number',
          width: 70,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('PLACE'),
          field: 'address.city',
          width: 70,
          ...withFilterParams,
        },
        {
          headerName: t('APPOINTMENT TYPE'),
          field: 'type',
          width: 70,
          cellRenderer: AppointmentTypeRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('ACCESS'),
          field: 'accessReason',
          valueGetter: ({ data = {} }) => data?.accessReason?.msgId,
          cellRenderer: AppointmentAccessRenderer,
          width: 70,
          ...withFilterParams,
        },
        {
          headerName: t('EXECUTED TIME'),
          field: 'executionDate',
          width: 70,
          filter: 'agDateColumnFilter',
          ...withFilterParams,
          sort: 'desc',
          cellRenderer: DateRenderer,
          cellRendererParams: {
            formatStr: 'P',
          },
        },
      ]
      const PerformanceCustomFilter = [
        {
          name: 'contract.serviceCategory.tableId',
          filterType: 'text',
          operator: 'AND',
          conditions: [
            {
              value: [currentService.tableId],
              type: 'equals',
            },
          ],
        },
        {
          name: 'houseEntrance',
          filterType: 'text',
          operator: 'AND',
          conditions: [{ value: [propertyUnitId.toString()], type: 'equals' }],
        },
      ]
      return {
        query: PerformanceGridQuery,
        columnDefs: PerformanceColumnDefs,
        customFilters: PerformanceCustomFilter,
        countQuery: CountPerformanceQuery,
        countExtractor: (response) => response.Me?.Company?.data,
        extractor: (response) => {
          const data = response.Me?.Company?.data.map((appointment) => {
            if (appointment.registrations.length > 0) {
              return appointment.registrations.map((register) => {
                return {
                  ...appointment,
                  ...register,
                }
              })
            } else {
              return appointment
            }
          })
          return data.flat(1)
        },
        customGridOptions: {
          getContextMenuItems: (params) => {
            return [
              {
                // custom item
                name: t('GO TO APPOINTMENT {TABLEID}', {
                  TABLEID: params?.node?.data?.tableId,
                }),
                action: function () {
                  redirectRoute(
                    Routes.appointment + '/' + params?.node?.data?.tableId
                  )
                },
              },
              'copy',
            ]
          },
          getRowHeight,
          components: { RoomTypeFilter },
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
          },
        },
        customVariables: { propertyUnitId },
      }
    },
    [currentLanguage, redirectRoute, t]
  )
  return { getParams }
}
