import React, { useContext } from 'react'

import SimpleLink from '../../../../../../../../components/Simple/SimpleLink'

import { Routes } from '../../../../../../../../configs/RouteConfig'
import UserContext from '../../../../../../../../helper/userContext'

const ReferenceNumberRenderer = (props) => {
  const { usageUnitId, referenceNumber } = props.data

  const { currentLanguage } = useContext(UserContext)

  return (
    <SimpleLink
      to={'/' + currentLanguage + Routes.usageUnit + '/' + usageUnitId}
      style={{ fontSize: 'inherit' }}
      target='_BLANK'
    >
      {referenceNumber}
    </SimpleLink>
  )
}
export default ReferenceNumberRenderer
