import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query OrderSelectorQuery($search: String!, $companyId: ID!) {
    Me {
      id
      Company(id: $companyId) {
        OrderWizard {
          search(query: $search) {
            channel
            topic
            reason
            pipeline
            tags
          }
        }
      }
    }
  }
`
