import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAgGridUltra } from '../../../../../../../components/AgGrid/AgGridUltra/hooks/useAgGridUltra'
import { useInvoicesGrid } from './gridConfigHooks/useInvoicesGrid'

const InvoicesGrid = (props) => {
  const {
    invoiceIds,
    setConnectedIds = () => {},
    connectedIds,
    noRemove = false,
  } = props
  const { t } = useTranslation()

  const grids = [
    {
      id: 'link-ids-invoices-grid',
      label: t('INVOICES'),
      downloadFileName: t('INVOICES'),
      useGetParams: useInvoicesGrid(setConnectedIds, noRemove),
    },
  ]

  const { generateGrid, setFilterVariables, filterVariables, setSelectedRows } =
    useAgGridUltra(
      'LinkIdsInvoices',
      grids,
      { invoiceIds, connectedIds },
      false,
      false,
      3,
      true,
      true
    )
  useEffect(() => {
    console.log('REFRESH force', connectedIds, invoiceIds)
    setSelectedRows([])
    setFilterVariables({
      invoiceIds,
      connectedIds,
    })
  }, [connectedIds, invoiceIds, setFilterVariables, setSelectedRows])

  return generateGrid(!filterVariables?.invoiceIds)
}

export default InvoicesGrid
