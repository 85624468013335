import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { FilterBadges } from '../Badges'
import OverviewFilter from '../OverviewFilter'
import StatusBox from '../StatusBox'

import { FilterWrapper } from './PyrexxOverview.styles'
import { usePyrexxOverview } from '../../providers/PyrexxOverviewContext'

const PyrexxOverview = (props) => {
  const {
    overviewFilterType = '',
    overviewFilterData = {},
    customKeys = {},
    titleElements = [],
    rowElements = [],
    setFilterModel = (filters) => {},
    setVariables = (apiFilters) => {},
    variant,
    loading = false,
  } = props

  const {
    uiFilters,
    saveUiFilters,
    removeUiFilter,
    boxFilters,
    saveBoxFilters,
    removeBoxFilter,
    uiboxFilters,
  } = usePyrexxOverview()

  useEffect(() => {
    // console.log('uiFilters', uiFilters)
    // console.log('boxFilters', boxFilters)

    const apiFilters = Object.assign(
      {},
      ...uiFilters.map((filter) => filter?.apiFilterModel)
    )
    setVariables(apiFilters)

    const agGridFilters = Object.assign(
      {},
      ...uiboxFilters.map((filter) => filter?.filterModel)
    )
    setFilterModel(agGridFilters)

    // console.log('apiFilters updated', apiFilters)
    // console.log('agGridFilters updated', agGridFilters)

    // IMPORTANT
    // eslint-disable-next-line
  }, [uiFilters, boxFilters])

  return (
    <div>
      <FilterWrapper>
        <FilterBadges filters={uiFilters} removeFilter={removeUiFilter} />

        <OverviewFilter
          overviewFilterType={overviewFilterType}
          overviewFilterData={overviewFilterData}
          filters={uiFilters}
          saveFilters={saveUiFilters}
          customKeys={customKeys}
        />
      </FilterWrapper>

      <StatusBox
        titleElements={titleElements}
        rowElements={rowElements}
        filters={boxFilters}
        saveFilters={saveBoxFilters}
        removeFilter={removeBoxFilter}
        variant={variant}
        loading={loading}
      />
    </div>
  )
}

PyrexxOverview.propTypes = {
  overviewFilterType: PropTypes.string,
  overviewFilterData: PropTypes.object,
  customKeys: PropTypes.object,
  titleElements: PropTypes.array,
  rowElements: PropTypes.array,
  setFilterModel: PropTypes.func,
  setVariables: PropTypes.func,
  variant: PropTypes.oneOf(['button', 'link', 'anchor', 'div']),
  loading: PropTypes.bool,
}

export default PyrexxOverview
