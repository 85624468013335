import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { ButtonPrimary, ButtonSecondary } from 'pyrexx-react-library'

export const Box = styled.div`
  padding: 16px;
  background-color: #fff;
`

export const Label = styled.p`
  font-weight: bold;
  margin: 0;
`

export const Text = styled.p`
  margin: 0;
`

export const CustomButtonPrimary = styled(ButtonPrimary)`
  margin: 0 auto;
  padding: 0.6rem 2rem;

  height: unset;
  width: fit-content;
`

export const CustomButtonSecondary = styled(ButtonSecondary)`
  margin: 0 auto;
  padding: 0.6rem 2rem;

  height: unset;
  width: fit-content;

  ${(props) =>
    props.isLoading &&
    css`
      min-width: 160px;
      padding: 0.2rem;
    `}
`
