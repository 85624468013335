import React from 'react'
import { FormFabricator } from './FormFabricator/FormFabricator'

export const CommissionDetails = (props) => {
  const {
    pipelineObject,
    componentData,
    setComponentData,
    completeCatObject,
    setCurrentStep,
    connectedIds,
    currentStep,
  } = props

  console.log('DEBUG Commission STORIES (pipelineObject)', pipelineObject)
  console.log('DEBUG Commission STORIES (componentData)', componentData)
  console.log('DEBUG Commission STORIES (completeCatObject)', completeCatObject)
  console.log('DEBUG Commission STORIES (connectedIds)', connectedIds)
  console.log('DEBUG Commission STORIES (currentStep)', currentStep)

  return (
    <FormFabricator
      pipelineObject={pipelineObject}
      connectedIds={connectedIds}
      componentData={componentData}
      setComponentData={setComponentData}
      completeCatObject={completeCatObject}
      setCurrentStep={setCurrentStep}
      currentStep={currentStep}
    />
  )
}
