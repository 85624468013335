import React from 'react'
import styled from 'styled-components/macro'

import { Box, Label, Text } from '../shared'

const TicketInfo = ({ info = {}, t = (v) => v }) => {
  const {
    ticketinfoowner = '',
    ticketinfodate = '-',
    ticketinfocompanyName = '-',
    ticketinfocompanyAdress = '-',
    ticketinfocompanyEmail = '-',
    ticketinfocompanyTelephone = '-',
    ticketinfoconsultationData: {
      ticketinfoconsultationfirstname = '-',
      ticketinfoconsultationlastname = '-',
      ticketinfoconsultationemail = '-',
      ticketinfoconsultationtelephone = '-',
    } = {},
  } = info

  return (
    <Wrapper>
      <div>
        <strong>Wer ist der neue Eigentümer?</strong>
        <InnerBox>{ticketinfoowner || t('NO NEW OWNER')}</InnerBox>
      </div>

      <div>
        <strong>Wer ist die neue Verwalter?</strong>
        <InnerBox>
          <div>
            <Label>seit/ab wann</Label>
            <Text>{ticketinfodate}</Text>
          </div>

          <div>
            <Label>Firma name</Label>
            <Text>{ticketinfocompanyName}</Text>
          </div>

          <div>
            <Label>Adresse</Label>
            <Text>{ticketinfocompanyAdress}</Text>
          </div>

          <Row>
            <Column>
              <Label>Email</Label>
              <Text>{ticketinfocompanyEmail}</Text>
            </Column>

            <Column>
              <Label>Telefon</Label>
              <Text>{ticketinfocompanyTelephone}</Text>
            </Column>
          </Row>
        </InnerBox>
      </div>

      <div>
        <strong>Bitte Teilen Sie Rückfrage-Data</strong>
        <InnerBox>
          <Row>
            <Column>
              <Label>Vorname</Label>
              <Text>{ticketinfoconsultationfirstname}</Text>
            </Column>

            <Column>
              <Label>Nachname</Label>
              <Text>{ticketinfoconsultationlastname}</Text>
            </Column>

            <Column
              css={`
                flex: 2;
              `}
            >
              <Label>Email</Label>
              <Text>{ticketinfoconsultationemail}</Text>
            </Column>

            <Column>
              <Label>Telefon</Label>
              <Text>{ticketinfoconsultationtelephone}</Text>
            </Column>
          </Row>
        </InnerBox>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const InnerBox = styled(Wrapper)`
  padding: 16px;
  background-color: #eee;
  border-radius: 5px;

  margin-top: 4px;
`

const Row = styled.div`
  display: flex;
  gap: 16px;
`

const Column = styled.div`
  flex: 1;
`

export default TicketInfo
