import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query LinkIdsQuery(
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $Invoicesfilters: [Filter]
    $SearchInvoices: Boolean!
    $SearchInvoicesCount: Boolean!
    $HouseEntrancesfilters: [Filter]
    $SearchHouseEntrances: Boolean!
    $SearchHouseEntrancesCount: Boolean!
    $UsageUnitsfilters: [Filter]
    $SearchUsageUnits: Boolean!
    $SearchUsageUnitsCount: Boolean!
    $Tenantsfilters: [Filter]
    $TenantsSubfilters: [Filter]
    $SearchTenants: Boolean!
    $SearchTenantsCount: Boolean!
    $companyId: ID!
  ) {
    Me {
      id
      Company(id: $companyId) {
        CountInvoicesSearch: countInvoices(
          options: { filters: $Invoicesfilters }
        ) @include(if: $SearchInvoicesCount)
        InvoicesSearch: Invoices(
          options: {
            startRow: $startRow
            endRow: $endRow
            sorting: $sortModel
            filters: $Invoicesfilters
          }
        ) @include(if: $SearchInvoices) {
          tableId
          invoiceNumber
          name
        }
        CountHouseEntrancesSearch: countHouseEntrances(
          options: { filters: $HouseEntrancesfilters }
        ) @include(if: $SearchHouseEntrancesCount)
        HouseEntrancesSearch: HouseEntrances(
          options: {
            startRow: $startRow
            endRow: $endRow
            sorting: $sortModel
            filters: $HouseEntrancesfilters
          }
        ) @include(if: $SearchHouseEntrances) {
          tableId
          address {
            street
            zip
            city
            number
          }
        }
        CountUsageUnitsSearch: countUsageUnits(
          options: { filters: $UsageUnitsfilters }
        ) @include(if: $SearchUsageUnitsCount)
        UsageUnitsSearch: UsageUnits(
          options: {
            startRow: $startRow
            endRow: $endRow
            sorting: $sortModel
            filters: $UsageUnitsfilters
          }
        ) @include(if: $SearchUsageUnits) {
          tableId
          referenceNumber
          tenants {
            tableId
            firstname
            lastname
            fullname
            status
          }
          address {
            street
            zip
            city
            number
          }
        }
        CountTenantsSearch: countUsageUnits(
          options: { filters: $Tenantsfilters }
        ) @include(if: $SearchTenantsCount)
        TenantsSearch: UsageUnits(
          options: {
            startRow: $startRow
            endRow: $endRow
            sorting: $sortModel
            filters: $Tenantsfilters
          }
        ) @include(if: $SearchTenants) {
          tableId
          referenceNumber
          tenants(
            options: {
              startRow: $startRow
              endRow: $endRow
              sorting: $sortModel
              filters: $TenantsSubfilters
            }
          ) {
            tableId
            firstname
            lastname
            fullname
          }
          address {
            street
            zip
            city
            number
          }
        }
      }
    }
  }
`
