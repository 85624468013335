import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query LockedUsageUnitsGridQuery(
    $companyId: ID!
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
  ) {
    Me {
      Company(id: $companyId) {
        data: UsageUnits(
          options: {
            startRow: $startRow
            endRow: $endRow
            sorting: $sortModel
            filters: $filters
          }
        ) {
          id
          tableId
          referenceNumber
          type
          tenants {
            firstname
            lastname
          }
          lockPeriods(
            options: {
              endRow: -1
              sorting: [{ colId: "begin", sort: "DESC" }]
              filters: [
                {
                  name: "status"
                  filterType: text
                  operator: AND
                  conditions: [{ value: "ACTIVE", type: equals }]
                }
              ]
            }
          ) {
            id
            begin
            end
            reason
            initiator
          }
        }
      }
      id
    }
  }
`
