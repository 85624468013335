import graphql from 'babel-plugin-relay/macro'

export const InvoicesServiceCostsDownloadsQuery = graphql`
  query InvoicesServiceCostsDownloadsQuery(
    $serviceCostsId: String!
    $companyId: ID!
  ) {
    Me {
      Company(id: $companyId) {
        id
        serviceCosts(
          options: {
            filters: [
              {
                filterType: text
                name: "tableId"
                operator: AND
                conditions: [{ type: equals, value: [$serviceCostsId] }]
              }
            ]
          }
        ) {
          download {
            path
            token
            name
          }
        }
      }
    }
  }
`
