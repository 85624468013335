import React, { useCallback, useRef, useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { fetchQuery } from 'react-relay'
import * as Yup from 'yup'

import {
  Icon,
  LoadingSpinner,
  portalTheme,
  SimpleNote,
} from 'pyrexx-react-library'
import { SearchInputBox } from '../SearchInputBox/SearchInputBox'

import { pushNewUrlToHistory } from '../../../../../helper/urlHelpers'
import { useUserContext } from '../../../../../helper/userContext'
import getSearchParamsFromUrl from '../../../../../helper/getSearchParamsFromUrl'

import {
  CustomStyledInput,
  StyledColCenter,
  StyledColResultsOverflow,
  StyledRowShade,
} from '../LinkIds.styles'
import { LinkButtonStyled } from '../../../../../styles/HelperStylesComponents.styles'
import environment from '../../../../../environments/pom/Environment'
import LinkIdsQuery from '../queries/LinkIds'

export const SearchLinkedIds = (props) => {
  const {
    initFiltersForSearch,
    pipeline,
    connectedIds,
    setConnectedIds,
    requiredWithout,
  } = props

  const { linkedSearchText: initLinkedSearchText = '' } =
    getSearchParamsFromUrl()

  const { t } = useTranslation()
  const { user } = useUserContext()

  const [loading, setLoading] = useState(false)
  const [searchResultsArray, setSearchResultsArray] = useState([])
  const [searchResultsCount, setSearchResultsCount] = useState(0)
  const [showSearchTextError, setShowSearchTextError] = useState(false)

  const [querySearchVariables, setQuerySearchVariables] = useState({
    searchText: initLinkedSearchText,
    filters: initFiltersForSearch
      .filter((filterObject) => pipeline?.linkables[filterObject.linkable])
      .map((filterObject) => filterObject.search),
  })

  const listInnerRef = useRef()

  const handleChange = (search) => {
    setQuerySearchVariables((state) => {
      return { ...state, searchText: search }
    })
  }

  const increaseCounter = 50

  const schema = Yup.object({
    searchText: Yup.string()
      .min(3, t('SEARCH TEXT MUST BE AT LEAST 3 CHARACTERS'))
      .required(t('SEARCH TEXT MUST BE AT LEAST 3 CHARACTERS')),
    filters: Yup.array()
      .min(1, t('AT LEAST ONE FILTER TYPE IS REQUIRED'))
      .required(t('AT LEAST ONE FILTER TYPE IS REQUIRED'))
      .nullable(),
  })

  const formik = useFormik({
    initialValues: {
      searchText: querySearchVariables.searchText,
      filters: querySearchVariables.filters,
    },
    validationSchema: schema,
    onSubmit: (values, formikb) => {
      ref.current.currentSearchText = querySearchVariables.searchText
      ref.current.searchCursor = 0
      ref.current.noMoreAddItems = false
      setSearchResultsArray([])
      getSearchData()
    },
  })

  useEffect(() => {
    if (showSearchTextError) {
      const timeOutId = setTimeout(() => {
        setShowSearchTextError(false)
      }, 3000)
      return () => clearTimeout(timeOutId)
    }
  }, [showSearchTextError])

  const ref = useRef({
    triggerCheckbox: false,
    searchCursor: 0,
    firstOpen: true,
    noMoreAddItems: false,
    currentSearchText: '',
  })

  useEffect(() => {
    if (querySearchVariables.searchText !== formik.values.searchText) {
      formik.setFieldValue('searchText', querySearchVariables.searchText)
    }
  }, [formik, querySearchVariables.searchText])

  const generateSearchResultArray = useCallback((data) => {
    let results = []
    let count = 0
    if (data?.InvoicesSearch) {
      /**
       results = [
       ...results,
       ...data?.InvoicesSearch.map((item, index) => {
            return generateSearchResultsItem('linkIdInvoice', item, index)
          }),
       ]
       **/
      results = [
        ...results,
        ...data?.InvoicesSearch.map((item, index) => {
          return { type: 'linkIdInvoice', data: item, index }
        }),
      ]
    }
    if (data?.CountInvoicesSearch) {
      count = count + data?.CountInvoicesSearch
    }

    if (data?.HouseEntrancesSearch) {
      /**
       results = [
       ...results,
       ...data?.HouseEntrancesSearch.map((item, index) => {
            return generateSearchResultsItem('linkIdHouseEntrance', item, index)
          }),
       ]
       **/
      results = [
        ...results,
        ...data?.HouseEntrancesSearch.map((item, index) => {
          return { type: 'linkIdHouseEntrance', data: item, index }
        }),
      ]
    }
    if (data?.CountHouseEntrancesSearch) {
      count = count + data?.CountHouseEntrancesSearch
    }

    if (data?.UsageUnitsSearch) {
      /**
       results = [
       ...results,
       ...data?.HouseEntrancesSearch.map((item, index) => {
            return generateSearchResultsItem('linkIdHouseEntrance', item, index)
          }),
       ]
       **/
      results = [
        ...results,
        ...data?.UsageUnitsSearch.map((item, index) => {
          return { type: 'linkIdUsageUnit', data: item, index }
        }),
      ]
    }
    if (data?.CountUsageUnitsSearch) {
      count = count + data?.CountUsageUnitsSearch
    }

    if (data?.TenantsSearch) {
      results = [
        ...results,
        ...data?.TenantsSearch.map((item, index) => {
          return item.tenants.map((tenant) => {
            const tempItem = {
              ...item,
              tenant,
              usageUnitId: item.tableId,
              tableId: tenant.tableId,
            }
            return { type: 'linkIdTenant', data: tempItem, index }
          })
        }),
      ].flat(1)
    }
    if (data?.CountTenantsSearch) {
      count = count + data?.CountTenantsSearch
    }

    if (ref.current.searchCursor === 0) {
      setSearchResultsCount(count)
    }
    return results
  }, [])

  const generateQueryVariable = useCallback(() => {
    return {
      companyId: user.companyId,
      startRow: ref.current.searchCursor,
      endRow: ref.current.searchCursor + increaseCounter,
      sortModel: [],
      SearchInvoices: querySearchVariables.filters.includes('linkIdInvoice'),
      SearchInvoicesCount:
        querySearchVariables.filters.includes('linkIdInvoice') &&
        ref.current.searchCursor === 0,
      Invoicesfilters: [
        {
          name: 'invoiceNumber',
          filterType: 'text',
          operator: 'AND',
          conditions: [
            {
              value: querySearchVariables.searchText,
              type: 'contains',
            },
          ],
        },
      ],
      SearchHouseEntrances: querySearchVariables.filters.includes(
        'linkIdHouseEntrance'
      ),
      SearchHouseEntrancesCount:
        querySearchVariables.filters.includes('linkIdHouseEntrance') &&
        ref.current.searchCursor === 0,
      HouseEntrancesfilters: [
        {
          name: 'address.street',
          filterType: 'text',
          operator: 'OR',
          conditions: [
            {
              value: querySearchVariables.searchText,
              type: 'contains',
            },
          ],
        },
        {
          name: 'address.zip',
          filterType: 'text',
          operator: 'OR',
          conditions: [
            {
              value: querySearchVariables.searchText,
              type: 'contains',
            },
          ],
        },
        {
          name: 'address.city',
          filterType: 'text',
          operator: 'OR',
          conditions: [
            {
              value: querySearchVariables.searchText,
              type: 'contains',
            },
          ],
        },
      ],

      SearchUsageUnits:
        querySearchVariables.filters.includes('linkIdUsageUnit'),
      SearchUsageUnitsCount:
        querySearchVariables.filters.includes('linkIdUsageUnit') &&
        ref.current.searchCursor === 0,
      UsageUnitsfilters: [
        {
          name: 'address.street',
          filterType: 'text',
          operator: 'OR',
          conditions: [
            {
              value: querySearchVariables.searchText,
              type: 'contains',
            },
          ],
        },
        {
          name: 'address.zip',
          filterType: 'text',
          operator: 'OR',
          conditions: [
            {
              value: querySearchVariables.searchText,
              type: 'contains',
            },
          ],
        },
        {
          name: 'address.city',
          filterType: 'text',
          operator: 'OR',
          conditions: [
            {
              value: querySearchVariables.searchText,
              type: 'contains',
            },
          ],
        },
        {
          name: 'referenceNumber',
          filterType: 'text',
          operator: 'OR',
          conditions: [
            {
              value: querySearchVariables.searchText,
              type: 'contains',
            },
          ],
        },
      ],

      SearchTenants: querySearchVariables.filters.includes('linkIdTenant'),
      SearchTenantsCount:
        querySearchVariables.filters.includes('linkIdTenant') &&
        ref.current.searchCursor === 0,
      Tenantsfilters: [
        {
          name: 'tenants.firstname',
          filterType: 'text',
          operator: 'OR',
          conditions: [
            {
              value: querySearchVariables.searchText,
              type: 'contains',
            },
          ],
        },
        {
          name: 'tenants.lastname',
          filterType: 'text',
          operator: 'OR',
          conditions: [
            {
              value: querySearchVariables.searchText,
              type: 'contains',
            },
          ],
        },
      ],
      TenantsSubfilters: [
        {
          name: 'fullname',
          filterType: 'text',
          operator: 'AND',
          conditions: [
            {
              value: querySearchVariables.searchText,
              type: 'contains',
            },
          ],
        },
      ],
    }
  }, [
    querySearchVariables.filters,
    querySearchVariables.searchText,
    user.companyId,
  ])

  const getSearchData = useCallback(
    (addItems = false) => {
      setLoading(true)
      return fetchQuery(
        environment(user.accessToken),
        LinkIdsQuery,
        generateQueryVariable(),
        {
          force: true,
        }
      )
        .then((data) => {
          const results = data.Me?.Company
          const resultArray = generateSearchResultArray(results)
          if (addItems) {
            if (resultArray.length === 0) {
              ref.current.noMoreAddItems = true
            } else {
              setSearchResultsArray((state) => {
                return [...state, ...resultArray]
              })
            }
          } else {
            setSearchResultsArray(resultArray)
          }
          setLoading(false)
        })
        .catch((e) => {
          console.log(e)
        })
    },
    [generateQueryVariable, generateSearchResultArray, user.accessToken]
  )

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setQuerySearchVariables((state) => {
        return { ...state, filters: formik.values.filters }
      })
    }, 1000)
    return () => clearTimeout(timeOutId)
  }, [formik.values.filters, setQuerySearchVariables])

  useEffect(() => {
    if (!formik.errors.filters && ref.current.triggerCheckbox) {
      if (formik.errors.searchText) {
        setShowSearchTextError(true)
        return
      }
      ref.current.searchCursor = 0
      getSearchData()
    }
  }, [formik.errors, getSearchData, querySearchVariables.filters])

  useEffect(() => {
    if (initLinkedSearchText.length > 0) {
      getSearchData()
    }
    // IMPORTANT
    // eslint-disable-next-line
  }, [])

  const generateSearchResultsItem = useCallback(
    (type, data, index) => {
      const generateItemRow = (
        table,
        tableId,
        color,
        label,
        content,
        clientSideData = false
      ) => {
        // console.log(
        //   'DEBUG pipeline pipeline',
        //   pipeline,
        //   pipeline?.linkables[table]?.multiple
        // )
        const multiple = pipeline?.linkables[table]?.multiple
        // console.log(
        //   'DEBUG pipeline pipeline END',
        //   pipeline,
        //   pipeline?.linkables[table]?.multiple,
        //   multiple
        // )

        const foundId = !clientSideData
          ? connectedIds[table].find((id) => id === tableId)
          : connectedIds[table].find((object) => object.tableId === tableId)

        const addItemToOrder = () => {
          console.log(
            'DEBUG ADD ITEM',
            table,
            connectedIds[table],
            clientSideData,
            tableId
          )
          let newConnectedIds = {}
          if (clientSideData) {
            if (multiple) {
              newConnectedIds = {
                ...connectedIds,
                [table]: [...connectedIds[table], clientSideData],
              }
            } else {
              newConnectedIds = {
                ...connectedIds,
                [table]: [clientSideData],
              }
            }
          } else {
            if (multiple) {
              newConnectedIds = {
                ...connectedIds,
                [table]: [...connectedIds[table], tableId],
              }
            } else {
              newConnectedIds = {
                ...connectedIds,
                [table]: [tableId],
              }
            }
          }
          // /
          // formikLinkIds.setValues(table, [
          //   ...formikLinkIds.values[table],
          //   tableId,
          // ])
          // formikLinkIds.validateForm().then((errors) => {
          //   if (errors) {
          //     console.log(
          //       'DEBUG formikLinkIds validation errors',
          //       errors
          //     )
          //   }
          // })
          if (requiredWithout) {
            requiredWithout[table].forEach((item) => {
              newConnectedIds = {
                ...newConnectedIds,
                [item]: [],
              }
            })
          }

          console.log({ newConnectedIds })

          pushNewUrlToHistory('connectedIds', JSON.stringify(newConnectedIds))
          setConnectedIds(newConnectedIds)
          // handleModalClose()
        }

        return (
          <StyledRowShade
            key={'row' + table + tableId}
            align='center'
            justify='between'
          >
            <Col>
              <Row nogutter>
                <Col
                  xs='content'
                  style={{ backgroundColor: color, fontWeight: 'bold' }}
                >
                  {label}
                </Col>
              </Row>
              <Row nogutter>
                <Col style={{ fontSize: '1em' }}>
                  {content.map((item, index) => {
                    return (
                      <Row key={type + index}>
                        <Col>
                          {getHighlightedText(
                            item,
                            querySearchVariables.searchText
                          )}
                        </Col>
                      </Row>
                    )
                  })}
                </Col>
              </Row>
            </Col>

            <Col xs='content'>
              {!foundId ? (
                <LinkButtonStyled
                  style={{ fontSize: '12px' }}
                  type='button'
                  onClick={addItemToOrder}
                >
                  <Row nogutter justify='center' align='center'>
                    <Col xs='content'>
                      <Icon
                        icon='add-item'
                        size='25px'
                        color={portalTheme.color.linkColorPrimary}
                      />
                    </Col>
                    <Col xs='content' style={{ marginLeft: '1em' }}>
                      {t('ADD TO ORDER')}
                    </Col>
                  </Row>
                </LinkButtonStyled>
              ) : (
                <LinkButtonStyled
                  style={{ fontSize: '12px' }}
                  type='button'
                  onClick={() => {
                    if (clientSideData) {
                      setConnectedIds({
                        ...connectedIds,
                        [table]: [
                          ...connectedIds[table].filter(
                            (object) => object.tableId !== tableId
                          ),
                        ],
                      })
                    } else {
                      setConnectedIds({
                        ...connectedIds,
                        [table]: [
                          ...connectedIds[table].filter((id) => id !== tableId),
                        ],
                      })
                    }
                    // handleModalClose()
                  }}
                >
                  <Row nogutter justify='center' align='center'>
                    <Col xs='content'>
                      <Icon
                        icon='trash'
                        size='25px'
                        color={portalTheme.color.errorColor}
                      />
                    </Col>
                    <Col xs='content' style={{ marginLeft: '1em' }}>
                      {t('REMOVE FROM ORDER')}
                    </Col>
                  </Row>
                </LinkButtonStyled>
              )}
            </Col>
          </StyledRowShade>
        )
      }

      const getHighlightedText = (text, highlight) => {
        // Split on highlight term and include term into parts, ignore case
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
        return (
          <span>
            {parts.map((part, i) => (
              <span
                key={i}
                style={
                  part.toLowerCase() === highlight.toLowerCase()
                    ? {
                        fontWeight: 'bold',
                        color: portalTheme.color.linkColorPrimary,
                      }
                    : {}
                }
              >
                {part}
              </span>
            ))}{' '}
          </span>
        )
      }
      switch (type) {
        case 'linkIdInvoice':
          return generateItemRow(
            'invoice',
            data.tableId,
            '#aaccef',
            t('INVOICE'),
            [
              t('INVOICE NUMBER: {INVOICENUMBER}', {
                INVOICENUMBER: data.invoiceNumber,
              }),
            ]
          )
        case 'linkIdHouseEntrance':
          return generateItemRow(
            'propertyUnit',
            data.tableId,
            '#d6c2c1',
            t('PROPERTY UNIT'),
            [
              data.address.street + ' ' + data.address.number,
              data.address.zip + ' ' + data.address.city,
            ]
          )
        case 'linkIdUsageUnit': {
          const tenants = []
          data.tenants.forEach((tenant) => {
            if (tenant.status === 'STATUS_ACTIVE') {
              tenants.push(tenant.firstname + ' ' + tenant.lastname)
            }
          })
          return generateItemRow(
            'usageUnit',
            data.tableId,
            '#d6c2c1',
            t('Usage UNIT'),
            [
              ...tenants,
              data.address.street + ' ' + data.address.number,
              data.address.zip + ' ' + data.address.city,
            ]
          )
        }

        case 'linkIdTenant':
          console.log('DEBUGXXX1 linkIdTenant triggered 1')
          return generateItemRow(
            'tenant',
            data.tableId,
            '#aaccef',
            t('INHABITANT'),
            [
              t('CUSTOMER NUMBER: {CUSTOMERENUMBER}', {
                CUSTOMERENUMBER: data.referenceNumber,
              }),
              data.tenant ? data.tenant.fullname : t('VACANCY'),
              data.address.street + ' ' + data.address.number,
              data.address.zip + ' ' + data.address.city,
            ],
            data
          )
        default:
          console.log('DEBUGXXX1 not triggered 1 type', type)
          return ''
      }
    },
    [
      connectedIds,
      pipeline,
      querySearchVariables.searchText,
      requiredWithout,
      setConnectedIds,
      t,
    ]
  )

  const generateFilters = useCallback(() => {
    const themeCheckBox = {
      components: {
        customCheckboxRadio: {
          icon: {
            fontSize: '18px',
          },
        },
        styledInput: {
          additionalStyles: css`
            line-height: normal;
          `,
        },
      },
    }

    return initFiltersForSearch
      .filter((filterObject) => pipeline?.linkables[filterObject.linkable])
      .map((filterObject) => {
        // console.log('DEBUG FILTERS', filterObject)
        return (
          <CustomStyledInput
            theme={themeCheckBox}
            key={filterObject.search}
            type='checkbox'
            disabled={loading}
            value={formik.values.filters.includes(filterObject.search)}
            onChange={() => {
              console.log('DEBUG trigger', filterObject.search)
              ref.current.triggerCheckbox = true
              if (formik.values.filters.includes(filterObject.search)) {
                const newFilterArray = formik.values.filters.filter(
                  (search) => search !== filterObject.search
                )
                formik.setFieldValue('filters', newFilterArray)
              } else {
                formik.setFieldValue('filters', [
                  ...formik.values.filters,
                  filterObject.search,
                ])
              }
            }}
            id={filterObject.search}
            name={filterObject.search}
            label={filterObject.label}
            setFieldValue={() => {}}
          />
        )
      })
  }, [formik, initFiltersForSearch, loading, pipeline.linkables])

  const generateSearchView = useCallback(() => {
    if (loading && searchResultsArray.length === 0) {
      return <span>{t('PLEASE WAIT')}</span>
    }
    if (searchResultsArray.length === 0) {
      return <span>{t('NO RESULTS')}</span>
    }

    const onScroll = () => {
      if (listInnerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
        if (scrollTop + clientHeight >= scrollHeight - 15) {
          // TO SOMETHING HERE
          if (!loading && !ref.current.noMoreAddItems) {
            if (
              ref.current.currentSearchText !== querySearchVariables.searchText
            ) {
              setQuerySearchVariables((state) => {
                return { ...state, searchText: ref.current.currentSearchText }
              })
            }
            ref.current.searchCursor =
              ref.current.searchCursor + increaseCounter + 1
            getSearchData(true)
          }
        }
      }
    }
    return (
      <>
        <Row style={{ marginBottom: '1rem' }} nogutter>
          <Col>
            {t('RESULT {COUNT}', {
              COUNT: '(' + searchResultsCount + ')',
            })}
          </Col>
        </Row>
        <Row nogutter>
          <StyledColResultsOverflow
            xs={12}
            onScroll={() => onScroll()}
            ref={listInnerRef}
          >
            {searchResultsArray.map((itemObject) => {
              return generateSearchResultsItem(
                itemObject.type,
                itemObject.data,
                itemObject.index
              )
            })}
            {loading && (
              <div style={{ height: '30px', width: '30px' }}>
                <LoadingSpinner
                  theme={{
                    components: {
                      spinner: {
                        /**
                         * Has to be a { css } oject from styled-components
                         */
                        fontSize: portalTheme.font.size.bodySmall,
                        fontWeight: portalTheme.font.weight.regular,
                        size: '20px',
                      },
                    },
                  }}
                  style={{
                    position: 'unset',
                    backgroundColor: 'white',
                  }}
                />
              </div>
            )}
          </StyledColResultsOverflow>
        </Row>
      </>
    )
  }, [
    generateSearchResultsItem,
    getSearchData,
    loading,
    querySearchVariables.searchText,
    searchResultsArray,
    searchResultsCount,
    t,
  ])

  return (
    <>
      <Row style={{ backgroundColor: 'white', marginTop: '1rem' }}>
        <Col style={{ paddingLeft: '0px' }}>
          <SearchInputBox
            querySearchVariables={querySearchVariables}
            setQuerySearchVariables={setQuerySearchVariables}
            handleChange={handleChange}
            formik={formik}
            setShowSearchTextError={setShowSearchTextError}
            refX={ref}
            loading={loading}
            showSearchTextError={showSearchTextError}
          />
        </Col>
      </Row>

      <Row style={{ marginTop: '1rem' }}>
        <Col xs={3}>
          <Container style={{ paddingLeft: '0px' }}>
            <Row
              style={{
                backgroundColor: 'white',
                paddingTop: '1rem',
                paddingBottom: '1rem',
              }}
            >
              <Col>
                <Row justify='between'>
                  <StyledColCenter xs='content'>{t('FILTER')}</StyledColCenter>
                  <StyledColCenter xs='content'>
                    {!loading && (
                      <LinkButtonStyled
                        disabled={loading}
                        type='button'
                        onClick={() => {
                          formik.setFieldValue(
                            'filters',
                            initFiltersForSearch
                              .filter(
                                (filterObject) =>
                                  pipeline?.linkables[filterObject.linkable]
                              )
                              .map((filterObject) => filterObject.search)
                          )
                          return false
                        }}
                      >
                        {t('RESET')}
                      </LinkButtonStyled>
                    )}
                  </StyledColCenter>
                </Row>

                <Row>
                  <Col>{generateFilters()}</Col>
                </Row>

                {formik.errors?.filters && (
                  <Row>
                    <Col>
                      <SimpleNote
                        noteStatus='warning'
                        text={formik.errors?.filters}
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Container>
        </Col>

        <Col xs={9}>
          <Row
            style={{
              backgroundColor: 'white',
              paddingTop: '1rem',
              paddingBottom: '1rem',
            }}
          >
            <Col>{generateSearchView()}</Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
