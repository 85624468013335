import { useState } from 'react'

function useFilter(initialState = []) {
  const [filters, setFilters] = useState(() =>
    initialState.filter((f) => f != null)
  )

  const saveFilters = (f) => {
    setFilters(f)
  }

  const saveFilter = (f) => {
    const filterFound = filters.some((ff) => ff?.filterId === f?.filterId)

    if (!filterFound) {
      setFilters((prevState) => [...prevState, f])
    }
  }

  const removeFilter = (f) => {
    setFilters((prevState) =>
      prevState.filter((ff) => (ff && ff.filterId) !== (f && f.filterId))
    )
  }

  const clearFilters = () => {
    setFilters([])
  }

  return { filters, saveFilters, saveFilter, removeFilter, clearFilters }
}

export default useFilter
