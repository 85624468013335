import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Spacer from '../../components/Spacer'

import { useAgGridUltra } from '../../components/AgGrid/AgGridUltra/hooks/useAgGridUltra'
import { usePropertyUnitGrid } from './gridConfigHooks/usePropertyUnitGrid'
import { useUsageUnitGrid } from './gridConfigHooks/useUsageUnitGrid'
import { useSmokeAlarmsInStorage } from './gridConfigHooks/useSmokeAlarmsInStorage'
import { useLockedUsageUnits } from './gridConfigHooks/useLockedUsageUnitsGrid'

const PortfolioGrids = (props) => {
  const { services = [], loading = false } = props

  const { t } = useTranslation()

  const grids = [
    {
      id: 'propertyUnit-list',
      label: t('PROPERTIES'),
      useGetParams: usePropertyUnitGrid(),
    },
    {
      id: 'usageUnit-list',
      label: t('USAGE UNITS'),
      useGetParams: useUsageUnitGrid(),
    },
    {
      id: 'smoke-alarm-in-storage',
      label: t('SMOKE ALARM DEVICES IN STORAGE'),
      useGetParams: useSmokeAlarmsInStorage(),
    },
    {
      id: 'locked-usage-units',
      label: t('LOCKED USAGE UNITS'),
      useGetParams: useLockedUsageUnits(),
    },
  ]

  const {
    generateServiceDropDown,
    generateTabs,
    currentService,
    setCurrentService,
    generateGrid,
  } = useAgGridUltra('propertyManagement', grids)

  useEffect(() => {
    if (services.length > 0 && !currentService) {
      const tmpService = services[0]
      setCurrentService(tmpService)
    }
  }, [currentService, services, setCurrentService])

  return (
    <>
      {generateServiceDropDown(services)}
      <Spacer />
      {generateTabs()}
      {generateGrid(!(!loading && currentService))}
    </>
  )
}

export default PortfolioGrids
