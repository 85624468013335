import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query RenderedServicesGridQuery(
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
    $companyId: ID!
    $usageUnitId: ID!
  ) {
    Me {
      Company(id: $companyId) {
        UsageUnit(id: $usageUnitId) {
          data: registrations(
            options: {
              startRow: $startRow
              endRow: $endRow
              sorting: $sortModel
              filters: $filters
            }
          ) {
            id
            actionPosition {
              id
              name
            }
            appointment {
              id
              status
              tableId
              date
              timeframe
              serviceReason {
                id
                name
              }
              tenant {
                id
                firstname
                lastname
              }
              type
              accessReason {
                tableId
                msgId
              }
              executionDate
            }
          }
        }
      }
    }
  }
`
