import styled from 'styled-components/macro'
import DropdownBadge from './DropdownBadge'

const DropleftBadge = styled(DropdownBadge)`
  background-color: #fff;
  cursor: pointer;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  transition: background-color 250ms ease-in-out;

  &:hover,
  &:focus {
    background-color: var(--pyrexx-dropdown-badge-color, #c5cfda);
  }

  ${(props) =>
    props.selected &&
    'background-color: var(--pyrexx-dropdown-badge-color, #c5cfda);'}
`

export default DropleftBadge
