import { useCallback, useContext } from 'react'
import {
  columnCheckbox,
  withFilterParams,
} from '../../../components/AgGrid/columnDefs'
import {
  AppointmentOverviewGridQuery,
  CountAppointmentHistoryQuery,
} from '../queries'
import {
  appointmentAccessReasonValueGetter,
  appointmentStatusLabelValueGetter,
  appointmentTypeValueGetter,
  dateTimeValueGetter,
  tenantFullNameValueGetter,
} from '../valueGetter'
import UserContext from '../../../helper/userContext'
import { useTranslation } from 'react-i18next'
import { Routes } from '../../../configs/RouteConfig'
import {
  AppointmentAccessRenderer,
  AppointmentIdRenderer,
  AppointmentStatusRenderer,
  AppointmentTypeRenderer,
  TenantFullNameRenderer,
  UsageUnitAppointmentLinkRenderer,
} from '../renderers'
import { DateRenderer } from '../../../components/AgGrid/renderers'

export const useAppointmentOverviewGrid = () => {
  const { redirectRoute, currentLanguage } = useContext(UserContext)
  const { t } = useTranslation()
  const getParams = useCallback(
    (currentService, filterVariables) => {
      const { propertyUnitId } = filterVariables
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          case 'status':
            return t(appointmentStatusLabelValueGetter(params))
          case 'tenant':
            return tenantFullNameValueGetter(params)
          case 'type':
            return t(appointmentTypeValueGetter(params))
          case 'accessReason':
            return t(appointmentAccessReasonValueGetter(params))
          case 'date':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'EP',
            })
          case 'executionDate':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'P',
            })
          case 'executionTime':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'p',
            })
          case 'lastSuccessfulAccess':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'p',
            })
          default:
            return params.value
        }
      }
      const AppointmentHistoryColumnDefs = [
        columnCheckbox,
        {
          headerName: t('STATUS'),
          field: 'status',
          width: 80,
          cellRenderer: AppointmentStatusRenderer,
          filter: false, // TODO add filter renderer
        },
        {
          headerName: t('ALLOCATION NUMBER'),
          field: 'referenceNumber',
          width: 70,
          cellRenderer: UsageUnitAppointmentLinkRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('PYREXX PERFORMANCE NUMBER'),
          field: 'tableId',
          width: 70,
          cellRenderer: AppointmentIdRenderer,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('LOCATION DESCRIPTION'),
          field: 'usageUnit.position',
          width: 70,
          ...withFilterParams,
        },
        {
          headerName: t('INHABITANT SURNAME'),
          field: 'tenant',
          width: 70,
          cellRenderer: TenantFullNameRenderer,
          filter: false, // TODO add Tenant filter from UsageUnits->Tenants
        },
        {
          headerName: t('ACCESS'),
          field: 'accessReason',
          width: 100,
          valueGetter: ({ data = {} }) => data?.accessReason?.msgId,
          cellRenderer: AppointmentAccessRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('APPOINTMENT TYPE'),
          field: 'type',
          width: 100,
          cellRenderer: AppointmentTypeRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('APPOINTMENT DATE'),
          field: 'date',
          width: 70,
          filter: 'agDateColumnFilter',
          ...withFilterParams,
          sort: 'desc',
          cellRenderer: DateRenderer,
          cellRendererParams: {
            formatStr: 'EP',
          },
        },
        {
          headerName: t('EXECUTED DATE'),
          field: 'executionDate',
          width: 70,
          filter: 'agDateColumnFilter',
          cellRenderer: DateRenderer,
          cellRendererParams: {
            formatStr: 'P',
          },
          ...withFilterParams,
        },
        {
          headerName: t('LAST SUCCESSFUL ACCESS'),
          colId: 'lastSuccessfulAccess',
          field: 'lastSuccessfulAccess',
          width: 70,
          sortable: false,
          filter: false,
          cellRenderer: DateRenderer,
          cellRendererParams: {
            formatStr: 'p',
          },
        },
      ]
      const AppointmentHistoryCustomFilter = [
        {
          name: 'contract.serviceCategory.tableId',
          filterType: 'text',
          operator: 'AND',
          conditions: [
            {
              value: [currentService.tableId],
              type: 'equals',
            },
          ],
        },
        {
          name: 'status',
          filterType: 'text',
          operator: 'AND',
          conditions: [
            {
              value: 'DEACTIVATED',
              type: 'notEqual',
            },
          ],
        },
        {
          name: 'houseEntrance.tableId',
          filterType: 'text',
          operator: 'AND',
          conditions: [{ value: [propertyUnitId.toString()], type: 'equals' }],
        },
      ]
      return {
        query: AppointmentOverviewGridQuery,
        columnDefs: AppointmentHistoryColumnDefs,
        customFilters: AppointmentHistoryCustomFilter,
        countQuery: CountAppointmentHistoryQuery,
        extractor: (response) => response.Me?.Company?.data,
        customGridOptions: {
          getContextMenuItems: (params) => {
            return [
              {
                // custom item
                name: t('GO TO APPOINTMENT {TABLEID}', {
                  TABLEID: params?.node?.data?.tableId,
                }),
                action: function () {
                  redirectRoute(
                    Routes.appointment + '/' + params?.node?.data?.tableId
                  )
                },
              },
              'copy',
            ]
          },
          components: {},
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
          },
        },
        customVariables: { propertyUnitId },
      }
    },
    [currentLanguage, redirectRoute, t]
  )
  return { getParams }
}
