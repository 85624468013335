/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactDataType = "EMAIL" | "NONE" | "TEL" | "WWW" | "%future added value";
export type TenantStatusType = "NONE" | "STATUS_ACTIVE" | "STATUS_MOVED_OUT" | "STATUS_NOT_YET_MOVED_IN" | "%future added value";
export type GetTenantIdQueryVariables = {|
  companyId: string,
  id: string,
|};
export type GetTenantIdQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +data: ?{|
        +tableId: ?number,
        +firstname: string,
        +lastname: string,
        +status: TenantStatusType,
        +contact: $ReadOnlyArray<?{|
          +type: ContactDataType,
          +value: string,
        |}>,
        +usageUnit: ?{|
          +tableId: number
        |},
      |}
    |}
  |}
|};
export type GetTenantIdQuery = {|
  variables: GetTenantIdQueryVariables,
  response: GetTenantIdQueryResponse,
|};
*/


/*
query GetTenantIdQuery(
  $companyId: ID!
  $id: ID!
) {
  Me {
    Company(id: $companyId) {
      data: tenant(id: $id) {
        tableId
        firstname
        lastname
        status
        contact {
          type
          value
        }
        usageUnit {
          tableId
          id
        }
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactData",
  "kind": "LinkedField",
  "name": "contact",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetTenantIdQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": "data",
                "args": (v2/*: any*/),
                "concreteType": "Tenant",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UsageUnit",
                    "kind": "LinkedField",
                    "name": "usageUnit",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetTenantIdQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": "data",
                "args": (v2/*: any*/),
                "concreteType": "Tenant",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UsageUnit",
                    "kind": "LinkedField",
                    "name": "usageUnit",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "88a11c8aef657666999b1e6359c0f502",
    "id": null,
    "metadata": {},
    "name": "GetTenantIdQuery",
    "operationKind": "query",
    "text": "query GetTenantIdQuery(\n  $companyId: ID!\n  $id: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      data: tenant(id: $id) {\n        tableId\n        firstname\n        lastname\n        status\n        contact {\n          type\n          value\n        }\n        usageUnit {\n          tableId\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '83246ce151422308c0d157534de10e34';

module.exports = node;
