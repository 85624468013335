import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import PageContainer from '../../components/PageContainer'
import PreviousAppointments from './PreviousAppointments'
import NavBack from '../../components/NavBack'
import Overview from './Overview'
import Thumbnails from './Thumbnails'
import ServiceDetails from './ServiceDetails'
import { AppointmentTickets } from './AppointmentTickets'

import { Routes } from '../../configs/RouteConfig'
import { useUserContext } from '../../helper/userContext'

const Appointment = (props) => {
  const { appointment = {}, loading = false } = props

  const { currentLanguage } = useUserContext()
  const { t } = useTranslation()
  const location = useLocation()

  const previousRoute =
    location?.state?.from || '/' + currentLanguage + Routes.appointments

  const previousAppointments = appointment?.previousAppointments || []
  const images = appointment?.images || []

  return (
    <PageContainer>
      <NavBack
        title={t('APPOINTMENT DETAILS')}
        to={previousRoute}
        style={{ marginBottom: '16px' }}
      />
      <Overview appointment={appointment} loading={loading} />

      {!loading && (
        <>
          <div style={{ marginTop: '2rem', paddingBottom: '0.5rem' }}>
            {t('MIT DIESEM TERMIN VERBUNDENE BEAUFTRAGUNGEN')}
          </div>
          <AppointmentTickets appointmentId={appointment?.tableId} />

          <div style={{ marginTop: '2rem', paddingBottom: '0.5rem' }}>
            {t('PREVIOUS APPOINTMENTS')}
          </div>
          <PreviousAppointments previousAppointments={previousAppointments} />

          <div style={{ marginTop: '2rem', paddingBottom: '0.5rem' }}>
            {t('FILES')} / {t('PICTURES')}
          </div>
          <Thumbnails images={images} />

          <ServiceDetails appointmentId={appointment?.tableId} />
        </>
      )}
    </PageContainer>
  )
}

Appointment.propTypes = {
  appointment: PropTypes.object,
  loading: PropTypes.bool,
}

export default Appointment
