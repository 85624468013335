/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RowGroup = {|
  id?: ?string,
  displayName?: ?string,
  field?: ?string,
|};
export type QuotaOverviewQueryVariables = {|
  companyId: string,
  rowGroupCols?: ?$ReadOnlyArray<?RowGroup>,
|};
export type QuotaOverviewQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +Quotas: ?$ReadOnlyArray<?{|
        +id: string,
        +contract: {|
          +id: string,
          +name: ?string,
          +date: ?any,
          +endDate: ?any,
          +tableId: number,
        |},
        +quota: ?{|
          +id: string,
          +name: ?string,
          +initCreditCache: ?number,
          +usedCreditCache: ?number,
          +unit: ?number,
          +quantity: ?number,
        |},
      |}>
    |}
  |}
|};
export type QuotaOverviewQuery = {|
  variables: QuotaOverviewQueryVariables,
  response: QuotaOverviewQueryResponse,
|};
*/


/*
query QuotaOverviewQuery(
  $companyId: ID!
  $rowGroupCols: [RowGroup]
) {
  Me {
    Company(id: $companyId) {
      Quotas(options: {rowGroupCols: $rowGroupCols}) {
        id
        contract {
          id
          name
          date
          endDate
          tableId
        }
        quota {
          id
          name
          initCreditCache
          usedCreditCache
          unit
          quantity
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "rowGroupCols"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "rowGroupCols",
          "variableName": "rowGroupCols"
        }
      ],
      "kind": "ObjectValue",
      "name": "options"
    }
  ],
  "concreteType": "InvoiceItem",
  "kind": "LinkedField",
  "name": "Quotas",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Contract",
      "kind": "LinkedField",
      "name": "contract",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tableId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Quota",
      "kind": "LinkedField",
      "name": "quota",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "initCreditCache",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "usedCreditCache",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantity",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuotaOverviewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuotaOverviewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0f62f5d3ebd7fb093fa33e5994d48c47",
    "id": null,
    "metadata": {},
    "name": "QuotaOverviewQuery",
    "operationKind": "query",
    "text": "query QuotaOverviewQuery(\n  $companyId: ID!\n  $rowGroupCols: [RowGroup]\n) {\n  Me {\n    Company(id: $companyId) {\n      Quotas(options: {rowGroupCols: $rowGroupCols}) {\n        id\n        contract {\n          id\n          name\n          date\n          endDate\n          tableId\n        }\n        quota {\n          id\n          name\n          initCreditCache\n          usedCreditCache\n          unit\n          quantity\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '58854bbf8ddca114f9957673ccffaead';

module.exports = node;
