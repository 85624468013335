import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query Filter1NotEquippedUsageUnitsQuery(
    $companyId: ID!
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
  ) {
    Me {
      Company(id: $companyId) {
        data: UsageUnits(
          options: {
            startRow: $startRow
            endRow: $endRow
            sorting: $sortModel
            filters: $filters
          }
#          context: RecentRegularAppointment
        ) {
          id
          tableId
          lockStatus
          referenceNumber
          address {
            city
            street
            number
          }
          tenants {
            id
            firstname
            lastname
          }
          registrations {
            id
            actionPosition {
              id
              name
            }
          }
          type
          billingPartitions {
            id
            name
          }
          CAN {
            one
            two
            three
            four
            five
          }
          lockPeriods(
            options: {
              startRow: 0
              endRow: 0
              sorting: { colId: "begin", sort: "DESC" }
              filters: [
                {
                  filterType: text
                  operator: AND
                  name: "status"
                  conditions: [{ type: equals, value: ["ACTIVE"] }]
                }
                {
                  filterType: text
                  operator: AND
                  name: "reason"
                  conditions: [
                    {
                      type: equals
                      value: [
                        "TEMP_FOR_RENOVATION"
                        "TEMP_FOR_CLEARIFICATION"
                        "CUSTOMER_TEMP_DEFERRED_INSTALLATION"
                      ]
                    }
                  ]
                }
              ]
            }
          ) {
            id
            begin
            end
            status
            reason
            initiator
          }
          appointments(
            options: {
              startRow: 0
              endRow: 0
              sorting: [{ colId: "date", sort: "DESC" }]
              filters: [
                {
                  filterType: text
                  operator: AND
                  name: "type"
                  conditions: [{ type: equals, value: ["T", "TI"] }]
                }
              ]
            }
          ) {
            id
            type
            status
            accessReason {
              tableId
              msgId
            }
            executionDate
          }
        }
      }
    }
  }
`
