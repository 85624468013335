import React, { useContext } from 'react'

import SimpleLink from '../../../components/Simple/SimpleLink'

import { Routes } from '../../../configs/RouteConfig'
import UserContext from '../../../helper/userContext'

const AppointmentPropertyNumberRenderer = (props) => {
  const { referenceNumber, houseEntrance } = props.data

  const { currentLanguage } = useContext(UserContext)

  return (
    <SimpleLink
      to={
        '/' +
        currentLanguage +
        Routes.propertyUnit +
        '/' +
        houseEntrance?.tableId +
        '?gridId=appointment-overview'
      }
      style={{ fontSize: 'inherit' }}
    >
      {referenceNumber}
    </SimpleLink>
  )
}

export default AppointmentPropertyNumberRenderer
