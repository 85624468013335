import React, { useRef } from 'react'
import { Overlay, DismissButton, usePopover } from 'react-aria'
import styled from 'styled-components/macro'

export function Popover({ children, state, ...props }) {
  const popoverRef = useRef(null)
  const { popoverProps, underlayProps } = usePopover(
    {
      ...props,
      popoverRef,
    },
    state
  )

  return (
    <Overlay>
      <div {...underlayProps} style={{ position: 'fixed', inset: 0 }} />
      <Wrapper
        {...popoverProps}
        ref={popoverRef}
        style={{
          ...popoverProps.style,
        }}
      >
        <DismissButton onDismiss={state.close} />
        {children}
        <DismissButton onDismiss={state.close} />
      </Wrapper>
    </Overlay>
  )
}

const Wrapper = styled.div`
  background-color: #fff;
  box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);

  margin-top: 8px;
`
