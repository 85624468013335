/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConditionType = "contains" | "endsWith" | "equals" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "lessThan" | "lessThanOrEqual" | "notContains" | "notEqual" | "startsWith" | "%future added value";
export type FilterType = "date" | "number" | "text" | "%future added value";
export type OperatorType = "AND" | "OR" | "%future added value";
export type RoomUsageType = "BEDROOM" | "CHILDRENS_BEDROOM" | "COMMON_SPACES" | "COMMON_SPACES_ATTIC" | "COMMON_SPACES_BASEMENT" | "CORRIDOR" | "DININGROOM" | "GUESTROOM" | "KITCHEN" | "LIVINGROOM" | "LIVINGROOM_BEDROOM" | "LOGGIA" | "NONE" | "OFFICE_WORKROOM" | "ROOM" | "WALK_THROUGH_ROOM" | "WC_BATHROOM" | "%future added value";
export type Sort = {|
  colId?: ?string,
  sort?: ?string,
|};
export type Filter = {|
  name: string,
  filterType: FilterType,
  operator: OperatorType,
  denySelection?: ?boolean,
  conditions: $ReadOnlyArray<?Condition>,
|};
export type Condition = {|
  value: $ReadOnlyArray<?string>,
  type: ConditionType,
|};
export type ServiceDetailsQueryVariables = {|
  startRow?: ?number,
  endRow?: ?number,
  sortModel?: ?$ReadOnlyArray<?Sort>,
  filters?: ?$ReadOnlyArray<?Filter>,
  companyId: string,
  appointmentId: string,
|};
export type ServiceDetailsQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +Appointment: ?{|
        +data: $ReadOnlyArray<?{|
          +id: string,
          +actionPosition: ?{|
            +id: string,
            +name: ?string,
          |},
          +actionReason: ?{|
            +id: string,
            +name: ?string,
            +msgId: ?string,
          |},
          +position: ?{|
            +id: string,
            +room: ?{|
              +id: string,
              +type: ?RoomUsageType,
            |},
          |},
        |}>,
        +id: ?string,
      |},
      +id: string,
    |},
    +id: string,
  |}
|};
export type ServiceDetailsQuery = {|
  variables: ServiceDetailsQueryVariables,
  response: ServiceDetailsQueryResponse,
|};
*/


/*
query ServiceDetailsQuery(
  $startRow: Int
  $endRow: Int
  $sortModel: [Sort]
  $filters: [Filter]
  $companyId: ID!
  $appointmentId: ID!
) {
  Me {
    Company(id: $companyId) {
      Appointment(id: $appointmentId) {
        data: registrations(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {
          id
          actionPosition {
            id
            name
          }
          actionReason {
            id
            name
            msgId
          }
          position {
            id
            room {
              id
              type
            }
          }
        }
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "appointmentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endRow"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortModel"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startRow"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "Me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "Company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "appointmentId"
              }
            ],
            "concreteType": "Appointment",
            "kind": "LinkedField",
            "name": "Appointment",
            "plural": false,
            "selections": [
              {
                "alias": "data",
                "args": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "endRow",
                        "variableName": "endRow"
                      },
                      {
                        "kind": "Variable",
                        "name": "filters",
                        "variableName": "filters"
                      },
                      {
                        "kind": "Variable",
                        "name": "sorting",
                        "variableName": "sortModel"
                      },
                      {
                        "kind": "Variable",
                        "name": "startRow",
                        "variableName": "startRow"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "options"
                  }
                ],
                "concreteType": "Registration",
                "kind": "LinkedField",
                "name": "registrations",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActionPosition",
                    "kind": "LinkedField",
                    "name": "actionPosition",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ActionReason",
                    "kind": "LinkedField",
                    "name": "actionReason",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "msgId",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Position",
                    "kind": "LinkedField",
                    "name": "position",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Room",
                        "kind": "LinkedField",
                        "name": "room",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      (v6/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ServiceDetailsQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ServiceDetailsQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "45bff954feb45ca353554a575dd8d998",
    "id": null,
    "metadata": {},
    "name": "ServiceDetailsQuery",
    "operationKind": "query",
    "text": "query ServiceDetailsQuery(\n  $startRow: Int\n  $endRow: Int\n  $sortModel: [Sort]\n  $filters: [Filter]\n  $companyId: ID!\n  $appointmentId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      Appointment(id: $appointmentId) {\n        data: registrations(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {\n          id\n          actionPosition {\n            id\n            name\n          }\n          actionReason {\n            id\n            name\n            msgId\n          }\n          position {\n            id\n            room {\n              id\n              type\n            }\n          }\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9e038422d4eca28166c5fdda29f51445';

module.exports = node;
