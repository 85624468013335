import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import QueryRendererContainer from '../../components/QueryRendererContainer'
import PyrexxOverview from '../../components/PyrexxOverview'

import { InvoicesOverviewQuery } from './queries'
import { useUserContext } from '../../helper/userContext'
import { getInvoicesFilterModel } from '../../helper/getFilterModel'

const InvoicesOverview = (props) => {
  const { setFilterModel, contractsProps = {} } = props

  const { user } = useUserContext()
  const { t } = useTranslation()

  const defaultFilters = {
    companyId: user.companyId,
    dateFilter: [],
    serviceFilter: [],
    contractFilter: [],
  }

  const customKeys = { date: 'invoiceDate' }

  const [variables, setVariables] = useState({})

  const { titleElements, rowElements } = getElements(null, t)

  const overviewFilterData = {
    date: contractsProps.timeframesList,
    service: contractsProps.servicesList,
    contract: contractsProps.contractsList,
  }

  return (
    <QueryRendererContainer
      query={InvoicesOverviewQuery}
      variables={{
        ...defaultFilters,
        ...variables,
      }}
      loading={
        <PyrexxOverview
          overviewFilterType='date-service-contract'
          overviewFilterData={overviewFilterData}
          titleElements={titleElements}
          rowElements={rowElements}
          setFilterModel={setFilterModel}
          setVariables={setVariables}
          customKeys={customKeys}
          loading
        />
      }
      render={(data) => {
        const statuses = data?.Me?.Company

        const { titleElements, rowElements } = getElements(statuses, t)

        return (
          <PyrexxOverview
            overviewFilterType='date-service-contract'
            overviewFilterData={overviewFilterData}
            titleElements={titleElements}
            rowElements={rowElements}
            setFilterModel={setFilterModel}
            setVariables={setVariables}
            customKeys={customKeys}
          />
        )
      }}
    />
  )
}

export default InvoicesOverview

const getElements = (statuses = {}, t = (v) => v) => {
  const { paid = 0, open = 0, reminded = 0 } = statuses || {}

  const titleElements = [
    { id: 'paid', label: t('PAID') },
    { id: 'open', label: t('OPEN') },
    { id: 'reminded', label: t('DUNNING LETTER') },
  ]

  const rowElements = [
    {
      id: 'default',
      columns: [
        {
          id: 'paid',
          label: paid,
          status: 'success',
          filter: getInvoicesFilterModel('paid', t('PAID')),
        },
        {
          id: 'open',
          label: open,
          status: 'warning',
          filter: getInvoicesFilterModel('open', t('OPEN')),
        },
        {
          id: 'reminded',
          label: reminded,
          status: 'error',
          filter: getInvoicesFilterModel('reminded', t('DUNNING LETTER')),
        },
      ],
    },
  ]

  return {
    titleElements,
    rowElements,
  }
}
