import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query AppointmentTicketsQuery(
    $companyId: ID!
    $appointmentId: ID! #    $startRow: Int #    $endRow: Int #    $sortModel: [Sort] #    $filters: [Filter]
  ) {
    Me {
      Company(id: $companyId) {
        Appointment(id: $appointmentId) {
          data: tickets {
            #          ) #            } #              filters: $filters #              sorting: $sortModel #              endRow: $endRow #              startRow: $startRow #            options: { #          (
            id
            tableId
            status
            zammadTicketId
            creationDate
            reason {
              id
              name
            }
            channel {
              id
              name
            }
            user {
              tableId
              name
              firstname
              lastname
            }
          }
        }
      }
    }
  }
`
