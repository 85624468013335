import React, { lazy, useCallback, useContext, useEffect } from 'react'
import { Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { withTranslation } from 'react-i18next'
import { RumRoute as Route } from 'pyrexx-react-library'

// eslint-disable-next-line import/no-unresolved
import CompanySwitcher from './CompanySwitcher'
import Overview from './Overview/'
import Appointments from './Appointments'
import Appointment from './Appointment'
import PropertyUnit from './PropertyUnit'
import UsageUnit from './UsageUnit'
import Contracts from './Contracts'
import Invoices from './Invoices'
import Invoice from './Invoice'
import PropertyManagement from './PropertyManagement'
import Newsletter from './Newsletter'
import Support from './Support'
import NotFound from './NotFound'
import Login from './Login'
import Register from './Register'
import ErrorInfo from './ErrorInfo'
import Privacy from './Privacy'
import Imprint from './Imprint/'
import User from './User'
import UserEditEmail from './User/UserEditEmail'
import Contact from './Contact'
import AuthRedirection from './AuthRedirection'
import {
  ForgotPassword,
  RequestPassword,
  ResetPassword,
} from './PasswordManagement'
import Logout from './Logout'
import Admin from './Admin'
import { CreateUser, UpdateUser } from './Admin/UserManagement'
import PrivateRoute from './PrivateRoute'
import { Routes } from '../configs/RouteConfig'
import UserContext from '../helper/userContext'
import CreateCompanyLayout from './CreateCompanyLayout/CreateCompanyLayout'
import CreateCompanyLayoutAdd from './CreateCompanyLayoutAdd/CreateCompanyLayoutAdd'
import CreateCompanyLayoutEdit from './CreateCompanyLayoutEdit/CreateCompanyLayoutEdit'
import BusinessMailContainer from './BusinessMail/BusinessMailContainer'
import LetterContainer from './BusinessMail/Letter/LetterContainer'
import BusinessMailNotFound from './BusinessMail/BusinessMailNotFound'
import Ticket from './Support/Ticket'

const UserEditPassword = lazy(() => import('./User/UserEditPassword'))

const AppRouter = (props) => {
  const {
    currentLanguage,
    getValidUrlForLang,
    getValidLangAndSite,
    updateLastAction,
  } = useContext(UserContext)
  const siteParams = getValidLangAndSite()

  const checkForAutoLogout = useCallback(() => {
    updateLastAction()
  }, [updateLastAction])

  useEffect(() => {
    checkForAutoLogout()
  }, [
    siteParams.site,
    siteParams.lang,
    siteParams.id,
    siteParams.extra,
    checkForAutoLogout,
  ])

  return (
    <Switch>
      <Route path={'/:lang' + Routes.companySwitcher}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_COMPANY_SELECTOR')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_COMPANY_SELECTOR')}
          />
        </Helmet>
        <CompanySwitcher />
      </Route>

      <Route path={'/:lang' + Routes.overview}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_OVERVIEW')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_OVERVIEW')}
          />
        </Helmet>
        <Overview currentLanguage={currentLanguage} />
      </Route>

      <Route path={'/:lang' + Routes.appointments}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_APPOINTMENTS')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_APPOINTMENTS')}
          />
        </Helmet>
        <Appointments />
      </Route>

      <Route path={'/:lang' + Routes.appointment + '/:appointmentId'}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>
            {props.t('POM_SITE_TITLE_APPOINTMENT {ENTITY_NUMBER}', {
              ENTITY_NUMBER: siteParams.extra,
            })}
          </title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_APPOINTMENT')}
          />
        </Helmet>
        <Appointment />
      </Route>

      <Route path={'/:lang' + Routes.contracts} exact>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_CONTRACTS')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_CONTRACTS')}
          />
        </Helmet>
        <Contracts />
      </Route>

      <Route path={'/:lang' + Routes.contracts + Routes.contractPerformance}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_CONTRACTS')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_CONTRACTS')}
          />
        </Helmet>
        <Contracts pageSection='performance' />
      </Route>

      <Route path={'/:lang' + Routes.contracts + Routes.contractControl}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_CONTRACTS')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_CONTRACTS')}
          />
        </Helmet>
        <Contracts pageSection='control' />
      </Route>

      <Route path={'/:lang' + Routes.contracts + Routes.contractQuota}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_CONTRACTS')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_CONTRACTS')}
          />
        </Helmet>
        <Contracts pageSection='quota' />
      </Route>

      <Route path={'/:lang' + Routes.invoices} exact>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_INVOICES')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_INVOICES')}
          />
        </Helmet>
        <Invoices />
      </Route>

      <Route path={'/:lang' + Routes.invoicesOverview}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_INVOICES_OVERVIEW')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_INVOICES_OVERVIEW')}
          />
        </Helmet>
        <Invoices pageSection='overview' />
      </Route>

      <Route path={'/:lang' + Routes.invoicesProofOfPerformance}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>
            {props.t('POM_SITE_TITLE_INVOICES_PROOF_OF_PERFORMANCE')}
          </title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t(
              'POM_SITE_META_DESCRIPTION_INVOICES_PROOF_OF_PERFORMANCE'
            )}
          />
        </Helmet>
        <Invoices pageSection='service-costs' />
      </Route>

      <Route path={'/:lang' + Routes.businessMail} exact>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_BUSINESS_MAIL')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_BUSINESS_MAIL')}
          />
        </Helmet>
        <BusinessMailContainer currentLanguage={currentLanguage} />
      </Route>

      <Route
        path={'/:lang' + Routes.businessMail + Routes.businessMailsGrid}
        exact
      >
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_BUSINESS_MAIL')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_BUSINESS_MAIL')}
          />
        </Helmet>
        <BusinessMailContainer
          currentLanguage={currentLanguage}
          pageSection='businessMailsGrid'
        />
      </Route>

      <Route
        path={'/:lang' + Routes.businessMail + Routes.businessMailNotFound}
        exact
      >
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_BUSINESS_MAIL_NOT_FOUND')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_BUSINESS_MAIL')}
          />
        </Helmet>
        <BusinessMailNotFound currentLanguage={currentLanguage} />
      </Route>

      <Route
        path={'/:lang' + Routes.businessMail + Routes.letter + '/:id'}
        exact
      >
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_TECHNICAL_LETTER_VIEW')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_TECHNICAL_LETTER_VIEW')}
          />
        </Helmet>
        <LetterContainer currentLanguage={currentLanguage} />
      </Route>

      <Route
        path={'/:lang' + Routes.businessMail + Routes.businessMailCreate}
        exact
      >
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_BUSINESS_MAIL')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_BUSINESS_MAIL')}
          />
        </Helmet>
        <BusinessMailContainer
          currentLanguage={currentLanguage}
          pageSection='add'
        />
      </Route>

      <Route
        path={'/:lang' + Routes.businessMail + Routes.businessMailEdit + '/:id'}
        exact
      >
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_BUSINESS_MAIL')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_BUSINESS_MAIL')}
          />
        </Helmet>
        <BusinessMailContainer
          currentLanguage={currentLanguage}
          pageSection='edit'
        />
      </Route>

      <Route path={'/:lang' + Routes.propertyManagement} exact>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_PORTFOLIO_MANAGEMENT')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_PORTFOLIO_MANAGEMENT')}
          />
        </Helmet>
        <PropertyManagement currentLanguage={currentLanguage} />
      </Route>

      <Route
        path={
          '/:lang' +
          Routes.propertyManagement +
          Routes.propertyManagementPortfolioOverview
        }
        exact
      >
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_PORTFOLIO_MANAGEMENT')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_PORTFOLIO_MANAGEMENT')}
          />
        </Helmet>
        <PropertyManagement
          currentLanguage={currentLanguage}
          pageSection='portfolioOverview'
        />
      </Route>

      <Route
        path={
          '/:lang' +
          Routes.propertyManagement +
          Routes.propertyManagementPortfolioDataUpload
        }
        exact
      >
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_PORTFOLIO_MANAGEMENT')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_PORTFOLIO_MANAGEMENT')}
          />
        </Helmet>
        <PropertyManagement
          currentLanguage={currentLanguage}
          pageSection='portfolioDataUpload'
        />
      </Route>

      <Route
        path={
          '/:lang' +
          Routes.propertyManagement +
          Routes.propertyManagementOperations
        }
        exact
      >
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_PORTFOLIO_MANAGEMENT')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_PORTFOLIO_MANAGEMENT')}
          />
        </Helmet>
        <PropertyManagement
          currentLanguage={currentLanguage}
          pageSection='operations'
        />
      </Route>

      <Route path={'/:lang' + Routes.invoice + '/:invoiceNumber'}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>
            {props.t('POM_SITE_TITLE_INVOICE {ENTITY_NUMBER}', {
              ENTITY_NUMBER: siteParams.extra,
            })}
          </title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_INVOICE')}
          />
        </Helmet>
        <Invoice currentLanguage={currentLanguage} />
      </Route>

      <Route path={'/:lang' + Routes.usageUnit + '/:usageUnitId'}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>
            {props.t('POM_SITE_TITLE_USAGE_UNIT {ENTITY_NUMBER}', {
              ENTITY_NUMBER: siteParams.extra,
            })}
          </title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_USAGE_UNIT')}
          />
        </Helmet>
        <UsageUnit currentLanguage={currentLanguage} />
      </Route>

      <Route path={'/:lang' + Routes.propertyUnit + '/:propertyUnitId'}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_PROPERTY')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_PROPERTY')}
          />
        </Helmet>
        <PropertyUnit currentLanguage={currentLanguage} />
      </Route>

      <AuthRedirection path={'/:lang' + Routes.login}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_LOGIN')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_LOGIN')}
          />
        </Helmet>
        <Login currentLanguage={currentLanguage} />
      </AuthRedirection>

      <Route path={'/:lang' + Routes.support} exact>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_SUPPORT')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_SUPPORT')}
          />
        </Helmet>
        <Support />
      </Route>

      <Route path={'/:lang' + Routes.support + Routes.enquiries}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_ENQUIRIES')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_ENQUIRIES')}
          />
        </Helmet>
        <Support pageSection='enquiries' />
      </Route>

      <Route path={'/:lang' + Routes.support + Routes.notifications}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_NOTIFICATIONS')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_NOTIFICATIONS')}
          />
        </Helmet>
        <Support pageSection='notifications' />
      </Route>

      {/* <Route path={'/:lang' + Routes.support + Routes.status}> */}
      {/*  <Helmet htmlAttributes={{ lang: currentLanguage }}> */}
      {/*    <title>{props.t('POM_SITE_TITLE_POM_STATUS')}</title> */}
      {/*    <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' /> */}
      {/*    <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' /> */}
      {/*    <meta name='robots' content='noindex,follow' /> */}
      {/*    <meta */}
      {/*      name='description' */}
      {/*      content={props.t('POM_SITE_META_DESCRIPTION_POM_STATUS')} */}
      {/*    /> */}
      {/*  </Helmet> */}
      {/*  <Support pageSection='status' /> */}
      {/* </Route> */}

      <Route path={'/:lang' + Routes.support + Routes.faq}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_FAQ')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_FAQ')}
          />
        </Helmet>
        <Support pageSection='faq' />
      </Route>

      <Route path={'/:lang' + Routes.support + Routes.downloadCenter}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_DOWNLOAD_CENTER')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_DOWNLOAD_CENTER')}
          />
        </Helmet>
        <Support pageSection='faq' />
      </Route>

      <Route path={'/:lang' + Routes.support + Routes.contact}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_CONTACT')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_CONTACT')}
          />
        </Helmet>
        <Support pageSection='contact' />
      </Route>

      <Route
        path={
          '/:lang' + Routes.support + Routes.ticketSingleView + '/:ticketNumber'
        }
      >
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_TICKET_SINGLE_VIEW')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_TICKET_SINGLE_VIEW')}
          />
        </Helmet>
        <Ticket />
      </Route>

      <Route path={'/:lang' + Routes.support + Routes.createOrder}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_CREATE_ORDER')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_CREATE_ORDER')}
          />
        </Helmet>
        <Support pageSection='createOrder' />
      </Route>

      <Route path={'/:lang' + Routes.contact}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_CONTACT')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_CONTACT')}
          />
        </Helmet>
        <Contact />
      </Route>

      <Route path={'/:lang' + Routes.user} exact>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_USER')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_USER')}
          />
        </Helmet>
        <User />
      </Route>

      <Route path={'/:lang' + Routes.editEmail}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_USER_EMAIL')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_USER_EMAIL')}
          />
        </Helmet>
        <UserEditEmail />
      </Route>

      <Route path={'/:lang' + Routes.editPassword}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_USER_PASSWORD')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_USER_PASSWORD')}
          />
        </Helmet>
        <UserEditPassword />
      </Route>

      {/* BETA */}
      <PrivateRoute path={'/:lang' + Routes.admin} exact>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_ADMIN')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_ADMIN')}
          />
        </Helmet>
        <Admin />
      </PrivateRoute>

      <PrivateRoute path={'/:lang' + Routes.admin + Routes.userList}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_USER_LIST')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_USER_LIST')}
          />
        </Helmet>
        <Admin pageSection='user-list' />
      </PrivateRoute>

      <PrivateRoute path={'/:lang' + Routes.admin + Routes.roleInformation}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_ROLE_INFORMATION')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_ROLE_INFORMATION')}
          />
        </Helmet>
        <Admin pageSection='role-information' />
      </PrivateRoute>

      <PrivateRoute path={'/:lang' + Routes.admin + Routes.logoUpload}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_LOGO_UPLOAD')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_LOGO_UPLOAD')}
          />
        </Helmet>
        <Admin pageSection='logo-upload' />
      </PrivateRoute>

      <PrivateRoute path={'/:lang' + Routes.admin + Routes.createUser}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_CREATE_USER')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_CREATE_USER')}
          />
        </Helmet>
        <CreateUser />
      </PrivateRoute>

      <PrivateRoute
        path={'/:lang' + Routes.admin + Routes.companyLayouts}
        exact
      >
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_COMPANY_LAYOUTS_OVERVIEW')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t(
              'POM_SITE_META_DESCRIPTION_COMPANY_LAYOUTS_OVERVIEW'
            )}
          />
        </Helmet>
        <Admin pageSection='companyLayouts' />
      </PrivateRoute>

      <PrivateRoute
        path={'/:lang' + Routes.admin + Routes.createCompanyLayout}
        exact
      >
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_CREATE_COMPANY_LAYOUT')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_CREATE_COMPANY_LAYOUT')}
          />
        </Helmet>
        <CreateCompanyLayout currentLanguage={currentLanguage} />
      </PrivateRoute>

      <PrivateRoute
        path={'/:lang' + Routes.admin + Routes.createCompanyLayoutAdd}
      >
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_CREATE_COMPANY_LAYOUT_ADD')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_COMPANY_LAYOUTS_ADD')}
          />
        </Helmet>
        <CreateCompanyLayoutAdd currentLanguage={currentLanguage} />
      </PrivateRoute>

      <PrivateRoute
        path={'/:lang' + Routes.admin + Routes.createCompanyLayoutEdit + '/:id'}
        exact
      >
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_CREATE_COMPANY_LAYOUT_EDIT')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_COMPANY_LAYOUTS_EDIT')}
          />
        </Helmet>
        <CreateCompanyLayoutEdit currentLanguage={currentLanguage} />
      </PrivateRoute>

      <PrivateRoute path={'/:lang' + Routes.admin + '/:userId'} exact>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_UPDATE_USER')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_UPDATE_USER')}
          />
        </Helmet>
        <UpdateUser />
      </PrivateRoute>

      <Route path={'/:lang' + Routes.newsletter}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_NEWSLETTER_SUBSCRIPTION')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t(
              'POM_SITE_META_DESCRIPTION_NEWSLETTER_SUBSCRIPTION'
            )}
          />
        </Helmet>
        <Newsletter currentLanguage={currentLanguage} />
      </Route>

      <Route path={'/:lang' + Routes.privacy}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_PRIVACY')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_PRIVACY')}
          />
        </Helmet>
        <Privacy />
      </Route>

      <Route path={'/:lang' + Routes.imprint}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_IMPRINT')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_IMPRINT')}
          />
        </Helmet>
        <Imprint />
      </Route>

      <Route path={'/:lang' + Routes.register}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_REGISTER')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_REGISTER')}
          />
        </Helmet>
        <Register />
      </Route>

      <Route path={'/:lang' + Routes.errorInfo}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_ERROR')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,nofollow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_ERROR')}
          />
        </Helmet>
        <ErrorInfo />
      </Route>

      {/* <Route path={'/:lang' + Routes.livechat} exact> */}
      {/*  <Helmet htmlAttributes={{ lang: currentLanguage }}> */}
      {/*    <title>{props.t('POM_SITE_TITLE_LIVECHAT')}</title> */}
      {/*    <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' /> */}
      {/*    <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' /> */}
      {/*    <meta */}
      {/*      name='description' */}
      {/*      content={props.t('POM_SITE_META_DESCRIPTION_LIVECHAT')} */}
      {/*    /> */}
      {/*  </Helmet> */}
      {/*  <Livechat /> */}
      {/* </Route> */}

      <AuthRedirection path={'/:lang' + Routes.forgotPassword}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_FORGOT_PASSWORD')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,nofollow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_FORGOT_PASSWORD')}
          />
        </Helmet>
        <ForgotPassword />
      </AuthRedirection>

      <AuthRedirection path={'/:lang' + Routes.resetPassword}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_RESET_PASSWORD')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='referrer' content='no-referrer' />
          <meta name='robots' content='noindex,nofollow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_RESET_PASSWORD')}
          />
        </Helmet>
        <ResetPassword />
      </AuthRedirection>

      <AuthRedirection path={'/:lang' + Routes.requestPassword}>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_REQUEST_PASSWORD')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,nofollow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_REQUEST_PASSWORD')}
          />
        </Helmet>
        <RequestPassword />
      </AuthRedirection>

      <Route path={'/:lang' + Routes.logout}>
        <Logout />
      </Route>

      <Route>
        <Helmet htmlAttributes={{ lang: currentLanguage }}>
          <title>{props.t('POM_SITE_TITLE_NOT_FOUND')}</title>
          <link rel='alternate' href={getValidUrlForLang('de')} hrefLang='de' />
          <link rel='alternate' href={getValidUrlForLang('en')} hrefLang='en' />
          <meta name='robots' content='noindex,follow' />
          <meta
            name='description'
            content={props.t('POM_SITE_META_DESCRIPTION_NOT_FOUND')}
          />
        </Helmet>
        <NotFound />
      </Route>
    </Switch>
  )
}

export default withTranslation('translation')(AppRouter)
