import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query MyOpenEnquiriesQuery(
    $companyId: ID!
    $userId: String
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort] #    $filters: [Filter]
  ) {
    Me {
      Company(id: $companyId) {
        OrderWizard {
          data: tickets(
            options: {
              startRow: $startRow
              endRow: $endRow
              sorting: $sortModel
              filters: [
                {
                  filterType: text
                  operator: AND
                  name: "status"
                  conditions: [{ type: equals, value: "OPEN" }]
                }
                {
                  filterType: text
                  operator: AND
                  name: "user"
                  conditions: [{ type: equals, value: [$userId] }]
                }
              ]
            }
          ) {
            id
            tableId
            zammadTicketId
            creationDate
            lastUpdate
            status
            channel {
              id
              name
            }
            topic {
              id
              name
            }
            reason {
              id
              name
            }
            subject
            content
            user {
              tableId
              firstname
              lastname
              name
            }
          }
        }
      }
    }
  }
`
