import { useCallback, useContext } from 'react'
import {
  columnCheckbox,
  withFilterParams,
} from '../../../components/AgGrid/columnDefs'
import {
  CountUsageUnitsQuery,
  PropertyManagementUsageUnitGridQuery,
} from '../queries'
import {
  dateTimeValueGetter,
  propertyUnitBillingPartitionValueGetter,
  usageUnitBillingPartitionValueGetter,
  usageUnitInventoryQuantityValueGetter,
  usageUnitLockPeriodReasonValueGetter,
  usageUnitLockPeriodStatusValueGetter,
  usageUnitTypeValueGetter,
} from '../valueGetter'
import UserContext from '../../../helper/userContext'
import { useTranslation } from 'react-i18next'
import { Routes } from '../../../configs/RouteConfig'
import {
  UsageUnitBillingPartitionRenderer,
  UsageUnitInventoryQuantityRenderer,
  UsageUnitLockPeriodReasonRenderer,
  UsageUnitLockPeriodStatusRenderer,
  UsageUnitLockPeriodTimeOfLockRenderer,
  UsageUnitReferenceNumberRenderer,
  UsageUnitTypeRenderer,
} from '../renderer'

import PropertyManagementUsageUnitStatusFilter from '../filters/PropertyManagementUsageUnitStatusFilter'

export const useUsageUnitGrid = () => {
  const { currentLanguage, redirectRoute } = useContext(UserContext)
  const { t } = useTranslation()

  const getParams = useCallback(
    (currentService) => {
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          case 'lockPeriod.status':
            return t(usageUnitLockPeriodStatusValueGetter(params))
          case 'type':
            return t(usageUnitTypeValueGetter(params))
          case 'billingPartition':
            return usageUnitBillingPartitionValueGetter(params)
          case 'lockPeriod.reason':
            return t(usageUnitLockPeriodReasonValueGetter(params))
          case 'lockPeriod.begin':
            return dateTimeValueGetter({
              value:
                params?.value && params?.value[0] ? params.value[0].begin : '',
              currentLanguage: currentLanguage,
            })
          case 'billingPartitions':
            return propertyUnitBillingPartitionValueGetter(params)
          case 'inventory':
            return usageUnitInventoryQuantityValueGetter(params)
          default:
            return params.value
        }
      }
      const PropertyManagementUsageUnitColumnDefs = [
        columnCheckbox,
        {
          headerName: t('STATUS'),
          colId: 'lockStatus',
          field: 'lockStatus',
          cellRenderer: UsageUnitLockPeriodStatusRenderer,
          width: 70,
          sortable: false, // TODO enable sorting if possible
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
          filter: 'PropertyManagementUsageUnitStatusFilter',
          ...withFilterParams,
        },
        {
          headerName: t('TYPE'),
          field: 'type',
          cellRenderer: UsageUnitTypeRenderer,
          width: 70,
          ...withFilterParams,
        },
        {
          headerName: t('ALLOCATION NUMBER'),
          field: 'referenceNumber',
          cellRenderer: UsageUnitReferenceNumberRenderer,
          width: 110,
          ...withFilterParams,
        },
        {
          headerName: t('ALLOCATION NUMBER {count}', { count: 1 }),
          field: 'CAN.one',
          width: 100,
          hide: true,
          type: 'rightAligned',
          ...withFilterParams,
        },
        {
          headerName: t('ALLOCATION NUMBER {count}', { count: 2 }),
          field: 'CAN.two',
          width: 100,
          hide: true,
          type: 'rightAligned',
          ...withFilterParams,
        },
        {
          headerName: t('ALLOCATION NUMBER {count}', { count: 3 }),
          field: 'CAN.three',
          width: 100,
          hide: true,
          type: 'rightAligned',
          ...withFilterParams,
        },
        {
          headerName: t('ALLOCATION NUMBER {count}', { count: 4 }),
          field: 'CAN.four',
          width: 100,
          hide: true,
          type: 'rightAligned',
          ...withFilterParams,
        },
        {
          headerName: t('ALLOCATION NUMBER {count}', { count: 5 }),
          field: 'CAN.five',
          width: 100,
          hide: true,
          type: 'rightAligned',
          ...withFilterParams,
        },
        {
          headerName: t('STREET'),
          field: 'address.street',
          width: 70,
          sortable: true,
          ...withFilterParams,
        },
        {
          headerName: t('STREET NR.'),
          field: 'address.number',
          width: 70,
          sortable: true,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('ZIP'),
          field: 'address.zip',
          width: 70,
          sortable: true,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('CITY'),
          field: 'address.city',
          width: 70,
          sortable: true,
          ...withFilterParams,
        },
        {
          headerName: t('CONTRACT PARTITION'),
          field: 'billingPartitions',
          width: 140,
          sortable: false,
          filter: false, // TODO do we need filter?
          cellRenderer: UsageUnitBillingPartitionRenderer,
        },
        {
          headerName: t('LOCK REASON'),
          colId: 'lockPeriod.reason',
          field: 'lockPeriods',
          cellRenderer: UsageUnitLockPeriodReasonRenderer,
          filter: false, // TODO ADD REASON FILTER
          width: 70,
        },
        {
          headerName: t('LOCKED FROM'),
          colId: 'lockPeriod.begin',
          field: 'lockPeriods',
          cellRenderer: UsageUnitLockPeriodTimeOfLockRenderer,
          width: 70,
          filter: 'agDateColumnFilter',
          ...withFilterParams,
          sort: 'desc',
        },
        {
          headerName: t('AMOUNT OF USAGE UNIT'),
          field: 'inventory',
          width: 70,
          sortable: false,
          filter: false,
          cellRenderer: UsageUnitInventoryQuantityRenderer,
          type: 'rightAligned',
        },
      ]
      const PropertyManagementUsageUnitCustomFilter = [
        {
          name: 'contract.serviceCategory.tableId',
          filterType: 'text',
          operator: 'AND',
          conditions: [
            {
              value: [currentService.tableId],
              type: 'equals',
            },
          ],
        },
      ]
      return {
        query: PropertyManagementUsageUnitGridQuery,
        columnDefs: PropertyManagementUsageUnitColumnDefs,
        customFilters: PropertyManagementUsageUnitCustomFilter,
        countQuery: CountUsageUnitsQuery,
        extractor: (response) => response.Me?.Company?.data,
        customGridOptions: {
          getContextMenuItems: (params) => {
            return [
              {
                // custom item
                name: t('GO TO USAGE UNIT {REFERENCENUMBER}', {
                  REFERENCENUMBER: params?.node?.data?.referenceNumber,
                }),
                action: function () {
                  redirectRoute(
                    Routes.usageUnit + '/' + params?.node?.data?.tableId
                  )
                },
              },
              'copy',
              'chartRange',
            ]
          },
          components: {
            PropertyManagementUsageUnitStatusFilter,
          },
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
          },
        },
        customVariables: {},
      }
    },
    [currentLanguage, redirectRoute, t]
  )
  return { getParams }
}
