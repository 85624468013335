import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAgGridUltra } from '../../../../../../../components/AgGrid/AgGridUltra/hooks/useAgGridUltra'
import { useHouseEntrancesGrid } from './gridConfigHooks/useHouseEntrancesGrid'

const HouseEntrancesGrid = (props) => {
  const {
    houseEntrancesIds,
    setConnectedIds = () => {},
    noRemove = false,
    connectedIds,
  } = props
  const { t } = useTranslation()

  const grids = [
    {
      id: 'link-ids-house-entrances-grid',
      label: t('PROPERTY UNITS'),
      downloadFileName: t('PROPERTY UNITS'),
      useGetParams: useHouseEntrancesGrid(setConnectedIds, noRemove),
    },
  ]

  const { generateGrid, setFilterVariables, filterVariables, setSelectedRows } =
    useAgGridUltra(
      'LinkIdsHouseEntrances',
      grids,
      { houseEntrancesIds, connectedIds },
      false,
      false,
      3,
      true,
      true
    )
  useEffect(() => {
    console.log('REFRESH force', connectedIds, houseEntrancesIds)
    setSelectedRows([])
    setFilterVariables({
      houseEntrancesIds,
      connectedIds,
    })
  }, [connectedIds, houseEntrancesIds, setFilterVariables, setSelectedRows])

  return generateGrid(!filterVariables?.houseEntrancesIds)
}

export default HouseEntrancesGrid
