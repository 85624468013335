import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query AppointmentsGridQuery(
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
    $groupKeys: [String]
    $rowGroupCols: [RowGroup]
    $companyId: ID!
  ) {
    Me {
      id
      Company(id: $companyId) {
        data: Appointments(
          options: {
            startRow: $startRow
            endRow: $endRow
            sorting: $sortModel
            filters: $filters
            groupKeys: $groupKeys
            rowGroupCols: $rowGroupCols
          }
        ) {
          id
          tableId
          type
          date
          timeframe
          accessReason {
            tableId
            msgId
          }
          status
          referenceNumber
          billingPartition {
            id
            name
          }
          contract {
            tableId
            serviceCategory {
              id
              tableId
              name
            }
          }
          usageUnit {
            id
            tableId
            referenceNumber
            floor
          }
          houseEntrance {
            id
            tableId
            number
          }
          address {
            street
            zip
            city
            number
          }
          bulletin {
            id
            tableId
          }
          count
          tenant {
            id
            firstname
            lastname
          }
        }
      }
    }
  }
`
