import React, { useState, useContext } from 'react'
import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { TitleModal, TransferPurpose } from './Invoice.styles'

import {
  Icon,
  SimpleModal,
  SimpleNote,
  helperStyles,
  ButtonSecondary,
  ButtonPrimary,
  MessageBox,
} from 'pyrexx-react-library'
import { Container, Row, Col } from 'react-grid-system'
import PageContainer from '../../components/PageContainer'
import SinglePdfDownload from '../../components/SinglePdfDownload/SinglePdfDownload'
import InvoiceInfo from './InvoiceInfo'
import InvoiceDetails from './InvoiceDetails'
import NavBack from '../../components/NavBack'
import SimpleButton from '../../components/Simple/SimpleButton'

import { Routes } from '../../configs/RouteConfig'
import UserContext from '../../helper/userContext'

const GridContainer = styled.div`
  /* https://grid.layoutit.com/?id=M7rP3nj */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto auto auto 1fr;
  gap: 1em 1em;
  grid-template-areas:
    'nav nav nav nav'
    'info details details details'
    'download details details details'
    'question details details details'
    '. details details details';

  @media screen and (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'nav'
      'info'
      'download'
      'details'
      'question';
  }

  @media all and (-ms-high-contrast: none) {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto auto auto auto 1fr;
    gap: 1em 1em;
  }
`

const InvoiceCardCSS = css`
  background-color: #fff;
  border-radius: 0.5em;
  padding: 1.5rem;
`

const GridNav = styled(NavBack)`
  grid-area: nav;

  @media all and (-ms-high-contrast: none) {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
  }
`

const GridInvoiceInfo = styled.div`
  grid-area: info;

  ${InvoiceCardCSS};

  @media all and (-ms-high-contrast: none) {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 0;
  }
`

const GridInvoiceDownload = styled.div`
  position: relative;
  grid-area: download;

  ${InvoiceCardCSS};
  padding: 0;

  @media all and (-ms-high-contrast: none) {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: -1;
  }
`

const GridInvoiceQuestion = styled.div`
  grid-area: question;

  ${InvoiceCardCSS};
  padding: 0;

  @media all and (-ms-high-contrast: none) {
    -ms-grid-row: 4;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: -2;
  }
`

const GridInvoiceDetails = styled.div`
  grid-area: details;

  ${InvoiceCardCSS};

  @media all and (-ms-high-contrast: none) {
    -ms-grid-row: 2;
    -ms-grid-row-span: 4;
    -ms-grid-column: 2;
    -ms-grid-column-span: 3;
  }
`

const QuestionButton = styled(SimpleButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #fff;
  padding: 1.5rem;
  width: 100%;
  text-align: left;
`

const DownloadButton = styled.button`
  ${helperStyles.buttonRemoveBrowserStylesCss}
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  border: none;
  cursor: pointer;
  padding: 1.5rem 1rem;
  width: 100%;

  font-family: inherit;
  font-size: 16px;
`

const Invoice = (props) => {
  const { info = {} } = props
  const [MessageBoxVisible, setMessageBoxVisible] = useState(false)

  const { t } = useTranslation()

  const { currentLanguage } = useContext(UserContext)

  const [modalOpen, setModalOpen] = useState(false)

  const handleModalOpen = () => setModalOpen(true)
  const handleModalClose = () => setModalOpen(false)

  return (
    <PageContainer>
      <GridContainer>
        <GridNav
          title={t('DETAILS')}
          to={'/' + currentLanguage + Routes.invoices}
        />

        <GridInvoiceInfo>
          <InvoiceInfo info={info} />
        </GridInvoiceInfo>

        <GridInvoiceDownload>
          <SinglePdfDownload value={info.invoiceId} type='invoice'>
            <DownloadButton>
              <Icon
                icon='download'
                style={{ marginBottom: '.5rem' }}
                size='25px'
              />
              {t('DOWNLOAD INVOICE')}
            </DownloadButton>
          </SinglePdfDownload>
        </GridInvoiceDownload>

        <GridInvoiceQuestion>
          <QuestionButton onClick={handleModalOpen}>
            {t('QUESTIONS ABOUT THIS INVOICE?')}
            <Icon icon='arrow-right' size='16px' />
          </QuestionButton>
        </GridInvoiceQuestion>

        <GridInvoiceDetails>
          <InvoiceDetails info={info} />
        </GridInvoiceDetails>

        <div style={{ position: 'absolute', zIndex: '1001' }}>
          <SimpleModal isOpen={modalOpen} handleModalClose={handleModalClose}>
            <Container fluid>
              <Row align='center'>
                <Col xs={12}>
                  <SimpleNote
                    noteStatus='warning'
                    text={t(
                      'INVOICE QUESTIONS NOT IMPLEMENTED PLEASE CONTACT SUPPORT'
                    )}
                  />
                </Col>
              </Row>
              <Row xs={5} align='start'>
                <Col>
                  <TitleModal>{t('INVOICE NUMBER')}</TitleModal>
                </Col>
              </Row>
              <Row>
                <Col xs={7}>
                  <TransferPurpose>
                    <Row
                      justify='center'
                      align='center'
                      style={{ height: '100%' }}
                    >
                      <Col xs='content'>{info.invoiceNumber}</Col>
                    </Row>
                  </TransferPurpose>
                </Col>
                <Col xs={3}>
                  <ButtonSecondary
                    onClick={() => {
                      navigator.clipboard.writeText(info.invoiceNumber)
                      setMessageBoxVisible(true)
                    }}
                  >
                    {t('COPY')}
                  </ButtonSecondary>
                </Col>
              </Row>
              <Row justify='center' style={{ marginTop: '1rem' }}>
                <Col xs='content'>
                  <ButtonPrimary>{t('CREATE ENQUIRY')}</ButtonPrimary>
                </Col>
              </Row>
            </Container>
            <MessageBox
              isVisible={MessageBoxVisible}
              closeMessageBox={() => {
                setMessageBoxVisible(false)
              }}
              statusColor='success-mark'
              updated={new Date()}
            >
              {t('INVOICE NUMBER WAS COPIED TO CLIPBOARD')}
            </MessageBox>
          </SimpleModal>
        </div>
      </GridContainer>
    </PageContainer>
  )
}

export default Invoice
