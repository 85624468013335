import getSearchParamsFromUrl from './getSearchParamsFromUrl'
import getCurrentDateFilter from './getCurrentDateFilter'

// TO-ADD-NEW-FILTER
const handleSearchParamsFromUrl = () => {
  // This must match the url search parameters
  const {
    date = '',
    dateFrom = '',
    dateTo = '',
    service,
    contract,
    boxFilter,
    contractName,
    serviceName,
    channel,
    channelName,
  } = getSearchParamsFromUrl() || {}
  const { currentFrom, currentTo } = getCurrentDateFilter().value || {}

  // Decides between 'defined date filter' and 'current year date filter'
  const [from, to] =
    date === 'all' || (dateFrom && dateTo)
      ? [dateFrom, dateTo]
      : [currentFrom, currentTo]

  return {
    from,
    to,
    service,
    contract,
    boxFilter,
    contractName,
    serviceName,
    channel,
    channelName,
  }
}

export default handleSearchParamsFromUrl
