import React from 'react'
import { columnCheckbox } from '../../../../components/AgGrid/columnDefs'

import SimpleLink from '../../../../components/Simple/SimpleLink'

import TicketStatusRenderer from './renderers/TicketStatusRenderer'
import ChannelFilter from './filters/ChannelFilter'
import formatDateLocale from '../../../../helper/formatDateLocale'
import { translateOrderWizard } from '../translations/translateOrderWizard'

const generateSharedColumnDefs = (
  t = (v) => v,
  currentLanguage = 'de',
  extraColumns = []
) => {
  return [
    columnCheckbox,
    {
      headerName: t('STATUS'),
      field: 'status',
      sortable: false,
      filter: false,
      cellRenderer: TicketStatusRenderer,
    },
    {
      headerName: t('TICKET NUMBER'),
      field: 'zammadTicketId',
      sortable: false,
      filter: false,
      type: 'rightAligned',
      cellRenderer: ({ value }) => (
        <SimpleLink to={`/${currentLanguage}/support/ticket/${value}`}>
          {value}
        </SimpleLink>
      ),
    },
    {
      headerName: t('CATEGORY'),
      field: 'channel',
      sortable: false,
      filter: ChannelFilter,
      valueGetter: (params) =>
        t(translateOrderWizard(params.data.channel.name, t)),
    },
    {
      headerName: t('ENQUIRY REASON'),
      field: 'reason',
      sortable: false,
      filter: false,
      valueGetter: (params) =>
        t(translateOrderWizard(params.data.reason.name, t)),
    },
    {
      headerName: t('CREATOR'),
      field: 'user.tableId',
      sortable: false,
      filter: false,
      valueFormatter: (params) => 'u_' + params.value,
    },
    {
      headerName: t('CREATED DATE'),
      field: 'creationDate',
      filter: 'agDateColumnFilter',
      sort: 'desc',
      filterParams: {
        buttons: ['apply', 'clear', 'reset'],
        closeOnApply: true,
        suppressAndOrCondition: true,
      },
      valueFormatter: (params) =>
        params.value
          ? formatDateLocale(currentLanguage, params.value, 'Pp')
          : '',
    },
    {
      headerName: t('LAST CHANGE DATE'),
      field: 'lastUpdate',
      filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['apply', 'clear', 'reset'],
        closeOnApply: true,
        suppressAndOrCondition: true,
      },
      valueFormatter: (params) =>
        params.value
          ? formatDateLocale(currentLanguage, params.value, 'Pp')
          : '',
    },
    ...extraColumns,
    {
      headerName: t('CREATOR NAME'),
      hide: true,
      sortable: false,
      filter: false,
      valueGetter: (params) =>
        params.data.user.firstname + ' ' + params.data.user.lastname,
    },
    {
      headerName: t('CREATOR ADDRESS'),
      field: 'user.address',
      hide: true,
      sortable: false,
      filter: false,
    },
  ]
}

export default generateSharedColumnDefs
