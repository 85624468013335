import React from 'react'
import { DateRenderer } from '../../../../../../../../components/AgGrid/renderers'

const InvoiceDateRenderer = (props) => {
  const { value } = props
  if (!value || !value.length > 0) {
    return ''
  }
  return <DateRenderer value={value} />
}

export default InvoiceDateRenderer
