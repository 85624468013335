import React from 'react'
import { useTranslation } from 'react-i18next'

import { useInvoicesServiceCostsGrid } from './gridConfigHooks/useInvoicesServiceCostsGrid'
import { useAgGridUltra } from '../../components/AgGrid/AgGridUltra/hooks/useAgGridUltra'
import { handleServiceCostsPDFPromises } from './downloads/handleServiceCostsPDFPromises'

const InvoicesServiceCostsGrid = (props) => {
  const { contractId, timeframeId } = props

  const { t } = useTranslation()

  const grids = [
    {
      id: 'invoices-service-costs-grid',
      label: t('SERVICE COSTS'),
      downloadFileName: t('SERVICE COSTS'),
      useGetParams: useInvoicesServiceCostsGrid({
        contractId,
        timeframeId,
      }),
    },
  ]

  const { generateGrid, setForceRefresh } = useAgGridUltra(
    'InvoicesServiceCosts',
    grids,
    {},
    false,
    false,
    false,
    false,
    false,
    false,
    {
      withPDFDownload: true,
      handlePDFPromises: handleServiceCostsPDFPromises,
    }
  )

  React.useEffect(() => {
    setForceRefresh(true)
  }, [setForceRefresh, contractId, timeframeId])

  return generateGrid()
}

export default InvoicesServiceCostsGrid
