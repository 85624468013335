import React, { useContext } from 'react'

import SimpleLink from '../../../components/Simple/SimpleLink'

import { Routes } from '../../../configs/RouteConfig'
import UserContext from '../../../helper/userContext'

const AppointmentUsageUnitReferenceNumberLinkRenderer = (props) => {
  const { referenceNumber, usageUnit } = props.data

  const { currentLanguage } = useContext(UserContext)

  return (
    <SimpleLink
      to={
        '/' +
        currentLanguage +
        Routes.usageUnit +
        '/' +
        usageUnit?.tableId +
        '?gridId=appointment-history'
      }
      style={{ fontSize: 'inherit' }}
    >
      {referenceNumber}
    </SimpleLink>
  )
}

export default AppointmentUsageUnitReferenceNumberLinkRenderer
