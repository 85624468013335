import React from 'react'
import { useTranslation } from 'react-i18next'

import StatusLightWithLabel from '../../../../../../../../components/Status/StatusLightWithLabel'

import { InvoiceStatusMap } from '../../../../../../../../constants/colorMap'
import { invoiceStatusValueGetter } from '../valueGetter'

export default function InvoiceStatusRenderer(props) {
  const { value } = props
  const { t } = useTranslation()
  return (
    <StatusLightWithLabel
      status={InvoiceStatusMap[value]}
      label={t(invoiceStatusValueGetter(props))}
    />
  )
}
