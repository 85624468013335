import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-grid-system'
import styled from 'styled-components/macro'

const QuotaOverviewTable = (props) => {
  const { quotas = [], loading = false } = props

  const { t } = useTranslation()

  if (loading) {
    return <p>{t('LOADING')}</p>
  }

  if (quotas.length <= 0) {
    return <p>{t('NO QUOTA INFORMATION')}</p>
  }

  return (
    <div>
      <HeaderRow>
        <Row>
          <StyledCol xs={2}>{t('NAME')}</StyledCol>
          <StyledCol xs={3}>{t('CONTRACT')}</StyledCol>
          <StyledCol xs={1} style={{ textAlign: 'right' }}>
            {t('QUANTITY PER YEAR')}
          </StyledCol>
          <StyledCol xs={3}>{t('UNIT')}</StyledCol>
          <StyledCol xs={1} style={{ textAlign: 'right' }}>
            {t('CONTINGENT')}
          </StyledCol>
          <StyledCol xs={2} style={{ textAlign: 'right' }}>
            {t('ALREADY USED CONTINGENT')}
          </StyledCol>
        </Row>
      </HeaderRow>

      {quotas.map((quota) => (
        <DataRow key={quota?.id}>
          <Row>
            <Col xs={2}> {quota?.quota?.name} </Col>
            <Col xs={3}> {quota?.contract?.name} </Col>
            <Col xs={1} style={{ textAlign: 'right' }}>
              {quota?.quota?.quantity}
            </Col>
            <Col xs={3}> {RenderUnit(quota?.quota, t)} </Col>
            <Col xs={1} style={{ textAlign: 'right' }}>
              {quota?.quota?.initCreditCache}
            </Col>
            <Col xs={2} style={{ textAlign: 'right' }}>
              {quota?.quota?.usedCreditCache}
            </Col>
          </Row>
        </DataRow>
      ))}
    </div>
  )
}

const RenderUnit = (data = {}, t = (v) => v) => {
  const { name = '', unit = '' } = data

  if (name === 'Freivolumen Ersatz') {
    return unit + t('% VON RWM')
  }

  if (name === 'Freivolumen Anfahrt') {
    return unit + t('% VON NE (EXKL. GESPERRTE)')
  }

  return unit
}

const HeaderRow = styled(Container)`
  background-color: #fff;
  padding: 8px;

  font-size: 15px;
  font-weight: bold;
`

const DataRow = styled(Container)`
  background-color: #fff;

  margin-top: 8px;
  padding: 8px;
`

const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
`

export default QuotaOverviewTable
