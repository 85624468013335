import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import QueryRendererContainer from '../../components/QueryRendererContainer'
import PyrexxOverview from '../../components/PyrexxOverview'

import { AppointmentsOverviewQuery } from './queries'
import { useUserContext } from '../../helper/userContext'
import { getAppointmentsFilterModel } from '../../helper/getFilterModel'
import getTimeframes from '../../helper/getTimeframes'
import getServices from '../../helper/getServices'
import getContracts from '../../helper/getContracts'

const AppointmentsOverview = (props) => {
  const { setFilterModel } = props

  const { user } = useUserContext()
  const { t } = useTranslation()

  const defaultFilters = {
    companyId: user.companyId,
    dateFilter: [],
    serviceFilter: [],
    contractFilter: [],
  }

  const [variables, setVariables] = useState({})

  const { titleElements, rowElements } = getElements(null, t)

  return (
    <QueryRendererContainer
      query={AppointmentsOverviewQuery}
      variables={{
        ...defaultFilters,
        ...variables,
      }}
      loading={
        <PyrexxOverview
          overviewFilterType='date-service-contract'
          titleElements={titleElements}
          rowElements={rowElements}
          setFilterModel={setFilterModel}
          setVariables={setVariables}
          loading
        />
      }
      render={(data) => {
        const contracts = data?.Me?.Company?.Contracts
        const statuses = data?.Me?.Company

        const { titleElements, rowElements } = getElements(statuses)

        const timeframesList = getTimeframes(contracts)
        const servicesList = getServices(contracts)
        const contractsList = getContracts(contracts)

        const overviewFilterData = {
          date: timeframesList,
          service: servicesList,
          contract: contractsList,
        }

        return (
          <PyrexxOverview
            overviewFilterType='date-service-contract'
            overviewFilterData={overviewFilterData}
            titleElements={titleElements}
            rowElements={rowElements}
            setFilterModel={setFilterModel}
            setVariables={setVariables}
          />
        )
      }}
    />
  )
}

export default AppointmentsOverview

const getElements = (statuses = {}, t = (v) => v) => {
  const {
    regularExecuted = 0,
    serviceExecuted = 0,
    specialExecuted = 0,
    regularPending = 0,
    servicePending = 0,
    specialPending = 0,
    regularNotExecuted = 0,
    serviceNotExecuted = 0,
    specialNotExecuted = 0,
  } = statuses || {}

  const titleElements = [
    {
      id: 'executed',
      label: t('EXECUTED'),
      filter: getAppointmentsFilterModel('executed', t('EXECUTED')),
    },
    {
      id: 'pending',
      label: t('PENDING'),
      filter: getAppointmentsFilterModel('pending', t('PENDING')),
    },
    {
      id: 'not-executed',
      label: t('NOT EXECUTED'),
      filter: getAppointmentsFilterModel('not-executed', t('NOT EXECUTED')),
    },
  ]

  const rowElements = [
    {
      id: 'regular',
      label: t('REGULAR APPOINTMENTS'),
      filter: getAppointmentsFilterModel('regular', t('REGULAR')),
      columns: [
        {
          id: 'regularExecuted',
          label: regularExecuted,
          status: 'success',
          filter: getAppointmentsFilterModel(
            'regularExecuted',
            t('REGULAR') + '/' + t('EXECUTED')
          ),
        },
        {
          id: 'regularPending',
          label: regularPending,
          status: 'warning',
          filter: getAppointmentsFilterModel(
            'regularPending',
            t('REGULAR') + '/' + t('PENDING')
          ),
        },
        {
          id: 'regularNotExecuted',
          label: regularNotExecuted,
          status: 'error',
          filter: getAppointmentsFilterModel(
            'regularNotExecuted',
            t('REGULAR') + '/' + t('NOT EXECUTED')
          ),
        },
      ],
    },
    {
      id: 'service',
      label: t('SERVICE APPOINTMENTS'),
      filter: getAppointmentsFilterModel('service', t('SERVICE')),
      columns: [
        {
          id: 'serviceExecuted',
          label: serviceExecuted,
          status: 'success',
          filter: getAppointmentsFilterModel(
            'serviceExecuted',
            t('SERVICE') + '/' + t('EXECUTED')
          ),
        },
        {
          id: 'servicePending',
          label: servicePending,
          status: 'warning',
          filter: getAppointmentsFilterModel(
            'servicePending',
            t('SERVICE') + '/' + t('PENDING')
          ),
        },
        {
          id: 'serviceNotExecuted',
          label: serviceNotExecuted,
          status: 'error',
          filter: getAppointmentsFilterModel(
            'serviceNotExecuted',
            t('SERVICE') + '/' + t('NOT EXECUTED')
          ),
        },
      ],
    },
    {
      id: 'special',
      label: t('SPECIAL APPOINTMENTS'),
      filter: getAppointmentsFilterModel('special', t('SPECIAL')),
      columns: [
        {
          id: 'specialExecuted',
          label: specialExecuted,
          status: 'success',
          filter: getAppointmentsFilterModel(
            'specialExecuted',
            t('SPECIAL') + '/' + t('EXECUTED')
          ),
        },
        {
          id: 'specialPending',
          label: specialPending,
          status: 'warning',
          filter: getAppointmentsFilterModel(
            'specialPending',
            t('SPECIAL') + '/' + t('PENDING')
          ),
        },
        {
          id: 'specialNotExecuted',
          label: specialNotExecuted,
          status: 'error',
          filter: getAppointmentsFilterModel(
            'specialNotExecuted',
            t('SPECIAL') + '/' + t('NOT EXECUTED')
          ),
        },
      ],
    },
  ]

  return {
    titleElements,
    rowElements,
  }
}
