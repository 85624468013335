import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { withFilterParams } from '../../../../../components/AgGrid/columnDefs'
import { RecipientsGridQuery } from '../queries'

import defaultColumns from '../helpers/defaultColumns'

export const useRecipientsGrid = (letterId) => {
  const { t } = useTranslation()

  const getParams = useCallback(
    (customService, filterVariables, gridFunctions, customGridFields) => {
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          default:
            return params.value
        }
      }

      const LetterColumnDefs = [
        {
          headerName: t('UNIQUE REF.'),
          colId: 'uniqueReference',
          field: 'uniqueReference',
          sortable: true,
          ...withFilterParams,
        },
        {
          headerName: t('SALUTATION'),
          field: 'salutation',
          sortable: true,
          hide: true,
          ...withFilterParams,
        },
        {
          headerName: t('TITLE'),
          field: 'title',
          hide: true,
          ...withFilterParams,
        },
        {
          headerName: t('FIRST NAME'),
          field: 'firstname',
          sortable: true,
          ...withFilterParams,
        },
        {
          headerName: t('LAST NAME'),
          field: 'lastname',
          sortable: true,
          ...withFilterParams,
        },
        {
          headerName: t('STREET'),
          field: 'street',
          sortable: true,
          ...withFilterParams,
        },
        {
          headerName: t('STREET NR.'),
          field: 'streetNumber',
          sortable: true,
          ...withFilterParams,
        },
        {
          headerName: t('ZIP'),
          field: 'zip',
          sortable: true,
          ...withFilterParams,
        },
        {
          headerName: t('CITY'),
          field: 'city',
          sortable: true,
          ...withFilterParams,
        },
        ...customGridFields,
      ]

      const LettersCustomFilter = []

      return {
        query: RecipientsGridQuery,
        columnDefs: LetterColumnDefs,
        customFilters: LettersCustomFilter,
        countQuery: false,
        extractor: (response) => {
          const data = response.Me?.Company?.businessPost?.letter?.data

          const extractedData = data.map((item) => {
            const rawDataObject = JSON.parse(item?.rawData)

            let variables = {}

            Object.keys(rawDataObject).forEach((key) => {
              if (!defaultColumns.includes(key)) {
                variables = { ...variables, [key]: rawDataObject[key] }
              }
            })

            return { ...variables, ...item }
          })

          return extractedData
        },
        customGridOptions: {
          getContextMenuItems: (params) => {
            return ['copy']
          },
          components: {},
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
          },
        },
        customVariables: { letterId: letterId },
      }
    },
    [letterId, t]
  )
  return { getParams }
}
