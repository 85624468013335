import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { fetchQuery } from 'react-relay'
import { trim } from 'lodash/string'

import { ButtonIcon, portalTheme, StyledInput } from 'pyrexx-react-library'
import Skeleton from '../../../../../components/Skeleton'

import environment from '../../../../../environments/pom/Environment'
import { GetTenantFromUsageUnitQuery, GetTenantIdQuery } from './queries'
import useUpdateEffect from '../../../../../hooks/useUpdateEffect'
import UserContext from '../../../../../helper/userContext'
import formatDateLocale from '../../../../../helper/formatDateLocale'

const InhabitantChange = (props) => {
  const {
    formik,
    connectedIds = [],
    componentData = {},
    showSummaryView = false,
  } = props
  console.log('DEBUGXA100 AND ERRORS values=', formik.values)
  console.log('DEBUGXA100 AND ERRORS errors=', formik.errors)
  console.log('DEBUGXA100 AND ERRORS touched=', formik.touched)
  console.log(
    'DEBUGXA100 AND ERRORS touched explizit(formik.touched.components?.inhabitantChange?.inhabitant?.[0])=',
    formik.touched.components?.inhabitantChange?.inhabitant?.[0]
  )
  console.log(
    'DEBUGXA100 AND ERRORS error explizit(formik.errors.components?.inhabitantChange?.inhabitant?.[0])=',
    formik.errors.components?.inhabitantChange?.inhabitant?.[0]
  )

  console.log(
    'DEBUGXA100 AND ERRORS show message=',
    formik.touched.components?.inhabitantChange?.inhabitant?.[0]?.lastname &&
      formik.errors.components?.inhabitantChange?.inhabitant?.[0]?.lastname
  )

  const [tenantCount, setTenantCount] = useState(
    componentData.components?.inhabitantChang?.inhabitant.length > 0
      ? componentData.components?.inhabitantChang?.inhabitant.length
      : 1
  )

  const { t } = useTranslation()
  const { user, currentLanguage } = useContext(UserContext)
  const [tenants, setTenants] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)

  const getTenantsFromUsageUnit = useCallback(() => {
    setFirstLoad(false)

    console.log(
      'DEBUG USEING ID THIS???????',
      connectedIds,
      connectedIds?.tenant?.[0]
        ? connectedIds?.tenant?.[0]
        : connectedIds?.usageUnit?.[0]
    )
    fetchQuery(
      environment(user.accessToken),
      connectedIds?.tenant?.[0]
        ? GetTenantIdQuery
        : GetTenantFromUsageUnitQuery,
      {
        id: connectedIds?.tenant?.[0]
          ? connectedIds?.tenant?.[0].tableId
          : connectedIds?.usageUnit?.[0],
        companyId: user.companyId,
      },
      {
        force: true,
      }
    )
      .then((data) => {
        if (data.Me?.Company?.UsageUnit) {
          const tenantFilter = data.Me?.Company?.UsageUnit.data.filter(
            (item) => item.status === 'STATUS_ACTIVE'
          )
          if (tenantFilter.length === 1) {
            formik.setFieldValue(
              'components.inhabitantChange.tenantId',
              tenantFilter[0].tableId
            )
          }
          setTenants(tenantFilter)
        } else {
          formik.setFieldValue(
            'components.inhabitantChange.tenantId',
            data.Me?.Company?.data.tableId
          )
          setTenants([data.Me?.Company?.data])
        }

        // setCustomVariables(data.Me?.Company?.businessPost?.letter?.variables)
      })
      .catch((e) => {
        console.log('GetTenant Error: CATCH', e)
      })
  }, [connectedIds, formik, user.accessToken, user.companyId])

  useEffect(() => {
    console.log('DEBUG connectedIds useEffect', connectedIds)
    if (firstLoad && tenants === false) {
      getTenantsFromUsageUnit()
    }
  }, [
    connectedIds,
    connectedIds.length,
    firstLoad,
    getTenantsFromUsageUnit,
    tenants,
  ])

  const generateTenantsRows = useCallback(
    (formikTmp) => {
      const tenantsRows = []
      console.log(
        'DEBUG generateTenantsRows (formik.values, formik.errors)',
        formikTmp.values,
        formikTmp.touched,
        formikTmp.errors
      )
      for (let i = 0; i < tenantCount; i++) {
        tenantsRows.push(
          <Row key={'tenantRow_' + (i + 1)}>
            <Col>
              <Row
                style={{
                  marginTop: '1em',
                  fontSize: '0.8em',
                  fontWeight: 'bold',
                }}
              >
                <Col xs='content'>{t('INHABITANT') + ' ' + (i + 1)}</Col>
                {i > 0 && i === tenantCount - 1 ? (
                  <Col xs='content'>
                    <ButtonIcon
                      icon='close'
                      style={{ color: portalTheme.color.errorColor }}
                      onClick={() => {
                        setTenantCount(tenantCount - 1)
                      }}
                    />
                  </Col>
                ) : (
                  ''
                )}
                {i < 1 && i === tenantCount - 1 ? (
                  <Col xs='content'>
                    <ButtonIcon
                      icon='add-item'
                      style={{ color: portalTheme.color.successColor }}
                      onClick={() => {
                        setTenantCount(tenantCount + 1)
                      }}
                    />
                  </Col>
                ) : (
                  ''
                )}
              </Row>
              <Row>
                <Col>
                  <StyledInput
                    type='input'
                    name={
                      'components.inhabitantChange.inhabitant.' +
                      i +
                      '.firstname'
                    }
                    id={
                      'components.inhabitantChange.inhabitant.' +
                      i +
                      '.firstname'
                    }
                    onChange={formikTmp.handleChange}
                    value={
                      formikTmp.values.components.inhabitantChange
                        ?.inhabitant?.[i]?.firstname
                    }
                    label={t('FIRST NAME')}
                    feedbackMessage={
                      formikTmp.touched.components?.inhabitantChange
                        ?.inhabitant?.[i]?.firstname &&
                      formikTmp.errors.components?.inhabitantChange
                        ?.inhabitant?.[i]?.firstname
                    }
                    feedbackStyle='invalid'
                    setFieldValue={formikTmp.setFieldValue}
                  />
                </Col>
                <Col>
                  <StyledInput
                    type='input'
                    name={
                      'components.inhabitantChange.inhabitant.' +
                      i +
                      '.lastname'
                    }
                    id={
                      'components.inhabitantChange.inhabitant.' +
                      i +
                      '.lastname'
                    }
                    onChange={formikTmp.handleChange}
                    value={
                      formikTmp.values.components.inhabitantChange
                        ?.inhabitant?.[i]?.lastname
                    }
                    label={t('LAST NAME')}
                    feedbackMessage={
                      formikTmp.touched.components?.inhabitantChange
                        ?.inhabitant?.[i]?.lastname &&
                      formikTmp.errors.components?.inhabitantChange
                        ?.inhabitant?.[i]?.lastname
                    }
                    feedbackStyle='invalid'
                    setFieldValue={formikTmp.setFieldValue}
                  />
                </Col>
                <Col>
                  <StyledInput
                    type='input'
                    name={
                      'components.inhabitantChange.inhabitant.' + i + '.contact'
                    }
                    id={
                      'components.inhabitantChange.inhabitant.' + i + '.contact'
                    }
                    onChange={formikTmp.handleChange}
                    value={
                      formikTmp.values.components.inhabitantChange
                        ?.inhabitant?.[i]?.contact
                    }
                    label={t('TELEPHONE NUMBER')}
                    feedbackMessage={
                      formikTmp.touched.components?.inhabitantChange
                        ?.inhabitant?.[i]?.contact &&
                      formikTmp.errors.components?.inhabitantChange
                        ?.inhabitant?.[i]?.contact
                    }
                    feedbackStyle='invalid'
                    setFieldValue={formikTmp.setFieldValue}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )
      }
      return tenantsRows
    },
    [t, tenantCount]
  )

  useUpdateEffect(() => {
    const arrayNew = formik.values?.components?.inhabitantChange?.inhabitant
    const filteredArray = arrayNew.filter(
      (tenantObject, index) => index < tenantCount
    )
    if (filteredArray.length < tenantCount) {
      filteredArray.push({
        firstname: '',
        lastname: '',
        contact: '',
      })
    }
    formik.setFieldValue(
      'components.inhabitantChange.inhabitant',
      filteredArray
    )
  }, [tenantCount])

  const formView = useCallback(() => {
    return (
      <Container
        style={{
          backgroundColor: portalTheme.color.basicColorWhite,
          padding: '1rem',
        }}
      >
        <Row justify='between'>
          <Col xs='content'>{t('INHABITANT INFORMATION')}</Col>
        </Row>
        <Row>
          <Col>
            {!tenants ? (
              <Skeleton full />
            ) : tenants.length > 0 ? (
              <StyledInput
                type='reactSelect'
                name='components.inhabitantChange.tenantId'
                id='components.inhabitantChange.tenantId'
                onChange={formik.handleChange}
                value={formik.values.components.inhabitantChange.tenantId}
                label={t('OLD TENANT')}
                feedbackMessage={
                  formik.touched.components?.inhabitantChange.tenantId &&
                  formik.errors.components?.inhabitantChange.tenantId
                }
                feedbackStyle='invalid'
                setFieldValue={formik.setFieldValue}
                reactSelectOptions={tenants.map((optionElement, index) => {
                  console.log('DEBUG RT', optionElement)
                  return {
                    value: optionElement.tableId,
                    label: trim(
                      optionElement.firstname + ' ' + optionElement.lastname
                    ),
                  }
                })}
              />
            ) : (
              <span>{t('VACANCY')}</span>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <StyledInput
              locale={currentLanguage}
              type='dateUi'
              name='components.inhabitantChange.moveInDate'
              id='components.inhabitantChange.moveInDate'
              onChange={formik.handleChange}
              value={formik.values.components.inhabitantChange?.moveInDate}
              label={t('RENTAL START')}
              feedbackMessage={
                formik.touched.components?.inhabitantChange?.moveInDate &&
                formik.errors.components?.inhabitantChange?.moveInDate
              }
              feedbackStyle='invalid'
              setFieldValue={formik.setFieldValue}
            />
          </Col>
          <Col>
            <StyledInput
              locale={currentLanguage}
              type='dateUi'
              name='components.inhabitantChange.previousInhabitantMoveOutDate'
              id='components.inhabitantChange.previousInhabitantMoveOutDate'
              onChange={formik.handleChange}
              value={
                formik.values.components.inhabitantChange
                  .previousInhabitantMoveOutDate
              }
              label={t('PREVIOUS RESIDENT RENTAL END')}
              feedbackMessage={
                formik.touched.components?.inhabitantChange
                  .previousInhabitantMoveOutDate &&
                formik.errors.components?.inhabitantChange
                  .previousInhabitantMoveOutDate
              }
              feedbackStyle='invalid'
              setFieldValue={formik.setFieldValue}
            />
          </Col>
        </Row>
        {generateTenantsRows(formik)}
      </Container>
    )
  }, [currentLanguage, formik, generateTenantsRows, t, tenants])

  const summaryView = useCallback(() => {
    return (
      <Container>
        <Row style={{ fontWeight: 'bold' }}>
          <Col>
            <strong>{t('INHABITANT - INFORMATION')}</strong>
          </Col>
        </Row>

        <Row>
          <Col
            style={{
              backgroundColor: portalTheme.color.basicColorLightGrey2,
            }}
          >
            <Row style={{ fontWeight: 'bold' }}>
              <Col>{t('RENTAL START')}</Col>
              <Col>{t('PREVIOUS RESIDENT RENTAL END')}</Col>
            </Row>
            <Row>
              <Col>
                {formatDateLocale(
                  currentLanguage,
                  componentData?.components?.inhabitantChange?.moveInDate,
                  'E P'
                )}
              </Col>
              <Col>
                {formatDateLocale(
                  currentLanguage,
                  componentData?.components?.inhabitantChange
                    ?.previousInhabitantMoveOutDate,
                  'E P'
                )}
              </Col>
            </Row>

            {componentData?.components?.inhabitantChange?.inhabitant.map(
              (tenant, index) => {
                return (
                  <>
                    <Row style={{ fontWeight: 'bold', marginTop: '1em' }}>
                      <Col>
                        {t('INHABITANT {number}', { number: index + 1 })}
                      </Col>
                    </Row>
                    <Row style={{ fontWeight: 'bold' }}>
                      <Col>{t('FIRST NAME')}</Col>
                      <Col>{t('LAST NAME')}</Col>
                      <Col>{t('CONTACT')}</Col>
                    </Row>
                    <Row>
                      <Col
                        style={{
                          wordBreak: 'break-word',
                          hyphens: 'auto',
                        }}
                      >
                        {tenant.firstname}
                      </Col>
                      <Col
                        style={{
                          wordBreak: 'break-word',
                          hyphens: 'auto',
                        }}
                      >
                        {tenant.lastname}
                      </Col>
                      <Col
                        style={{
                          wordBreak: 'break-word',
                          hyphens: 'auto',
                        }}
                      >
                        {tenant.contact}
                      </Col>
                    </Row>
                  </>
                )
              }
            )}
          </Col>
        </Row>
      </Container>
    )
  }, [componentData.components, currentLanguage, t])

  if (showSummaryView) {
    return summaryView()
  } else {
    return formView()
  }
}

export default InhabitantChange
