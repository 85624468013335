import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query AppointmentDownloadQuery($appointmentId: ID!, $companyId: ID!) {
    Me {
      Company(id: $companyId) {
        Appointment(id: $appointmentId) {
          id
          download {
            name
            path
            token
          }
        }
      }
    }
  }
`
