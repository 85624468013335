/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OrderWizardRequestLinks = {|
  tenant?: ?$ReadOnlyArray<?number>,
  usageUnit?: ?$ReadOnlyArray<?number>,
  invoice?: ?$ReadOnlyArray<?number>,
  contract?: ?$ReadOnlyArray<?number>,
  appointment?: ?$ReadOnlyArray<?number>,
  houseEntrance?: ?$ReadOnlyArray<?number>,
|};
export type CreateTicketMutationVariables = {|
  companyId: string,
  pipeline: number,
  links?: ?OrderWizardRequestLinks,
  params?: ?any,
  components?: ?any,
  note?: ?string,
  files?: ?$ReadOnlyArray<?any>,
  referenceNumber: string,
|};
export type CreateTicketMutationResponse = {|
  +Me: ?{|
    +Company: ?{|
      +OrderWizard: ?{|
        +createTicket: ?boolean
      |}
    |}
  |}
|};
export type CreateTicketMutation = {|
  variables: CreateTicketMutationVariables,
  response: CreateTicketMutationResponse,
|};
*/


/*
mutation CreateTicketMutation(
  $companyId: ID!
  $pipeline: Int!
  $links: OrderWizardRequestLinks
  $params: JSON
  $components: JSON
  $note: String
  $files: [Upload]
  $referenceNumber: String!
) {
  Me {
    Company(id: $companyId) {
      OrderWizard {
        createTicket(pipeline: $pipeline, links: $links, params: $params, components: $components, note: $note, files: $files, referenceNumber: $referenceNumber)
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "components"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "files"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "links"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "note"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "params"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pipeline"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "referenceNumber"
},
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MeMutations",
    "kind": "LinkedField",
    "name": "Me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": "CompanyMutations",
        "kind": "LinkedField",
        "name": "Company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrderWizardMutations",
            "kind": "LinkedField",
            "name": "OrderWizard",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "components",
                    "variableName": "components"
                  },
                  {
                    "kind": "Variable",
                    "name": "files",
                    "variableName": "files"
                  },
                  {
                    "kind": "Variable",
                    "name": "links",
                    "variableName": "links"
                  },
                  {
                    "kind": "Variable",
                    "name": "note",
                    "variableName": "note"
                  },
                  {
                    "kind": "Variable",
                    "name": "params",
                    "variableName": "params"
                  },
                  {
                    "kind": "Variable",
                    "name": "pipeline",
                    "variableName": "pipeline"
                  },
                  {
                    "kind": "Variable",
                    "name": "referenceNumber",
                    "variableName": "referenceNumber"
                  }
                ],
                "kind": "ScalarField",
                "name": "createTicket",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateTicketMutation",
    "selections": (v8/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v6/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateTicketMutation",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "4de278cadc58292e20e9dcb29f5fef58",
    "id": null,
    "metadata": {},
    "name": "CreateTicketMutation",
    "operationKind": "mutation",
    "text": "mutation CreateTicketMutation(\n  $companyId: ID!\n  $pipeline: Int!\n  $links: OrderWizardRequestLinks\n  $params: JSON\n  $components: JSON\n  $note: String\n  $files: [Upload]\n  $referenceNumber: String!\n) {\n  Me {\n    Company(id: $companyId) {\n      OrderWizard {\n        createTicket(pipeline: $pipeline, links: $links, params: $params, components: $components, note: $note, files: $files, referenceNumber: $referenceNumber)\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a948f20a519134702a92bd3bcad44b50';

module.exports = node;
