import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query InvoicesOverviewQuery(
    $companyId: ID!
    $dateFilter: [Condition]!
    $serviceFilter: [Condition]!
    $contractFilter: [Condition]!
  ) {
    Me {
      Company(id: $companyId) {
        paid: countInvoices(
          options: {
            filters: [
              {
                name: "status"
                filterType: number
                operator: AND
                conditions: [{ value: ["PAID"], type: equals }]
              }
              {
                name: "invoiceDate"
                filterType: date
                operator: AND
                conditions: $dateFilter
              }
              {
                name: "contract.serviceCategory.tableId"
                filterType: text
                operator: AND
                conditions: $serviceFilter
              }
              {
                name: "contract"
                filterType: number
                operator: AND
                conditions: $contractFilter
              }
            ]
          }
        )
        open: countInvoices(
          options: {
            filters: [
              {
                name: "status"
                filterType: number
                operator: AND
                conditions: [{ value: ["OPEN"], type: equals }]
              }
              {
                name: "invoiceDate"
                filterType: date
                operator: AND
                conditions: $dateFilter
              }
              {
                name: "contract.serviceCategory.tableId"
                filterType: text
                operator: AND
                conditions: $serviceFilter
              }
              {
                name: "contract"
                filterType: number
                operator: AND
                conditions: $contractFilter
              }
            ]
          }
        )
        reminded: countInvoices(
          options: {
            filters: [
              {
                name: "status"
                filterType: number
                operator: AND
                conditions: [{ value: ["REMINDED"], type: equals }]
              }
              {
                name: "invoiceDate"
                filterType: date
                operator: AND
                conditions: $dateFilter
              }
              {
                name: "contract.serviceCategory.tableId"
                filterType: text
                operator: AND
                conditions: $serviceFilter
              }
              {
                name: "contract"
                filterType: number
                operator: AND
                conditions: $contractFilter
              }
            ]
          }
        )
      }
    }
  }
`
