/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OrderSelectorQueryVariables = {|
  search: string,
  companyId: string,
|};
export type OrderSelectorQueryResponse = {|
  +Me: ?{|
    +id: string,
    +Company: ?{|
      +OrderWizard: ?{|
        +search: ?$ReadOnlyArray<?{|
          +channel: string,
          +topic: string,
          +reason: string,
          +pipeline: string,
          +tags: $ReadOnlyArray<?string>,
        |}>
      |}
    |},
  |}
|};
export type OrderSelectorQuery = {|
  variables: OrderSelectorQueryVariables,
  response: OrderSelectorQueryResponse,
|};
*/


/*
query OrderSelectorQuery(
  $search: String!
  $companyId: ID!
) {
  Me {
    id
    Company(id: $companyId) {
      OrderWizard {
        search(query: $search) {
          channel
          topic
          reason
          pipeline
          tags
          id
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "search"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "channel",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "topic",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reason",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pipeline",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tags",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrderSelectorQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderWizardQueries",
                "kind": "LinkedField",
                "name": "OrderWizard",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "OrderWizardSearchResult",
                    "kind": "LinkedField",
                    "name": "search",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrderSelectorQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderWizardQueries",
                "kind": "LinkedField",
                "name": "OrderWizard",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "OrderWizardSearchResult",
                    "kind": "LinkedField",
                    "name": "search",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2ff744f348bc8ffd240de92b488e9848",
    "id": null,
    "metadata": {},
    "name": "OrderSelectorQuery",
    "operationKind": "query",
    "text": "query OrderSelectorQuery(\n  $search: String!\n  $companyId: ID!\n) {\n  Me {\n    id\n    Company(id: $companyId) {\n      OrderWizard {\n        search(query: $search) {\n          channel\n          topic\n          reason\n          pipeline\n          tags\n          id\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4de4236d0d7c9895ffe8771a98200a8d';

module.exports = node;
