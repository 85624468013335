import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAgGridUltra } from '../../../components/AgGrid/AgGridUltra/hooks/useAgGridUltra'
import { useQuotaGrid } from './gridConfigHooks/useQuotaGrid'
import ReleaseQuotaMutation from '../../../mutations/ReleaseQuotaMutation'

import { useUserContext } from '../../../helper/userContext'
import { ModalHint } from 'pyrexx-react-library'

const QuotaGrid = (props) => {
  const { t } = useTranslation()
  const { user } = useUserContext()

  const [appointmentId, setAppointmentId] = useState(0)

  const [modal, setModal] = useState({
    show: false,
    message: '',
    buttonMessage: '',
    primaryButton: {
      usePrimaryButton: true,
      useFunction: () => {},
    },
    showButtonClose: true,
  })

  const handleModalClose = () => {
    setModal({
      show: false,
    })
  }

  const triggerQuotaReleaseMutation = () => {
    ReleaseQuotaMutation(
      {
        appointmentId: appointmentId,
        companyId: parseInt(user.companyId.split('::')[2]),
      },
      user.accessToken,
      () => {
        setModal({
          show: true,
          message: t('WE HAVE RELEASED YOUR QUOTA'),
          buttonMessage: t('UNDERSTOOD'),
          primaryButton: {
            usePrimaryButton: true,
            useFunction: () => {},
          },
          showButtonClose: false,
        })
      },
      () => {
        setModal({
          show: true,
          message: t('ERROR WITH YOUR REQUEST. PLEASE TRY AGAIN'),
          buttonMessage: t('UNDERSTOOD'),
          primaryButton: {
            usePrimaryButton: true,
            useFunction: () => {},
          },
          showButtonClose: false,
        })
      }
    )
  }

  const grids = [
    {
      id: 'free-volume-grid',
      label: '',
      useGetParams: useQuotaGrid(
        setModal,
        setAppointmentId,
        triggerQuotaReleaseMutation
      ),
    },
  ]

  const { generateGrid } = useAgGridUltra('Quota', grids)

  return (
    <>
      {generateGrid()}

      <ModalHint
        isOpen={modal.show}
        handleModalClose={handleModalClose}
        message={modal.message}
        buttonCloseMessage={modal.buttonMessage}
        title=''
        showButtonClose={modal.showButtonClose}
        primaryButton={modal.primaryButton}
      />
    </>
  )
}

export default QuotaGrid
