import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Col, Container, Row } from 'react-grid-system'
import { trim } from 'lodash/string'
import moment from 'moment'
import styled from 'styled-components'

import {
  ButtonPrimary,
  ButtonSecondary,
  Icon,
  LoadingSpinner,
  portalTheme,
  StyledInput,
} from 'pyrexx-react-library'
import InvoicesGrid from '../Step2Connections/ConnectedIds/grids/InvoicesGrid/InvoicesGrid'
import HouseEntrancesGrid from '../Step2Connections/ConnectedIds/grids/HouseEntrancesGrid/HouseEntrancesGrid'
import UsageUnitsGrid from '../Step2Connections/ConnectedIds/grids/UsageUnitsGrid/UsageUnitsGrid'
import TenantsGrid from '../Step2Connections/ConnectedIds/grids/TenantsGrid/TenantsGrid'
import ComponentHandler from '../components/ComponentHandler'

import CreateTicketMutation from './mutations/CreateTicketMutation'
import { translateOrderWizard } from '../../Enquiries/translations/translateOrderWizard'
import { useUserContext } from '../../../../helper/userContext'

const StyledIcon = styled(Icon)``

export const SendOrder = (props) => {
  const {
    setCurrentStep,
    componentData,
    connectedIds,
    completeCatObject,
    pipelineId,
    pipeline,
  } = props

  const { t } = useTranslation()
  const { user } = useUserContext()

  console.log('DEBUG STORY (componentData)', componentData)
  console.log('DEBUG STORY (connectedIds)', connectedIds)
  console.log('DEBUG STORY (completeCatObject)', completeCatObject)
  console.log('DEBUG STORY (pipelineId)', pipelineId)
  console.log('DEBUG STORY (pipeline)', pipeline)

  const [loading, setLoading] = useState(false)
  const [ticketCreated, setTicketCreated] = useState(false)

  const schema = Yup.object({
    clientFeedBackToOtherPerson: Yup.bool(),
    clientNotificationUpdate: Yup.bool(),
    clientFirstName: Yup.string().required(t('IS REQUIRED')),
    clientLastName: Yup.string().required(t('IS REQUIRED')),
    clientContactTel: Yup.string().required(t('IS REQUIRED')),
    clientEmail: Yup.string().email(t('INVALID EMAIL')),
  })

  // const findKeyInPipeline = useCallback(
  //   (searchName, object) => {
  //     Object.keys(pipeline.params).find((element) => element === searchName)
  //   },
  //   [pipeline.params]
  // )

  /**
   const convertParamsObjectToArrayNew = (tmpComponentData = {}) => {
    console.log('DEBUGYYY1 BEFORE tmpComponentData', tmpComponentData)
    Object.keys(tmpComponentData).forEach((key, index) => {
      if (key !== 'note' && key !== 'referenceNumber' && key !== 'files') {
        if (tmpComponentData[key] === 'object')
          const fieldAttribs = findKeyInPipeline(pipeline.params)
      }
    })
    console.log('DEBUGYYY1 tmpComponentData', tmpComponentData)
    return tmpComponentData
  }
   **/
  const cleanParams = (tmpComponentData) => {
    const isObject = (obj) => {
      return typeof obj === 'object' && obj !== null && !Array.isArray(obj)
    }
    let newComponentData = {}
    const deepConvert = (tmpComponentDataInside) => {
      let newComponentDataIntern = {}
      if (isObject(tmpComponentDataInside)) {
        Object.keys(tmpComponentDataInside).forEach((key, index) => {
          newComponentDataIntern = {
            ...newComponentDataIntern,
            [key]: deepConvert(tmpComponentDataInside[key]),
          }
        })
      }
      if (Array.isArray(tmpComponentDataInside)) {
        newComponentDataIntern = []
        tmpComponentDataInside.forEach((value, index) => {
          if (isObject(value)) {
            newComponentDataIntern.push(deepConvert(value))
          }
        })
      }

      if (tmpComponentDataInside instanceof Date) {
        return moment(tmpComponentDataInside).format('YYYY-MM-DD')
      }
      if (
        !Array.isArray(tmpComponentDataInside) &&
        !isObject(tmpComponentDataInside)
      ) {
        return tmpComponentDataInside
      }

      return newComponentDataIntern
    }

    Object.keys(tmpComponentData).forEach((key, index) => {
      if (key !== 'note' && key !== 'files' && key !== 'referenceNumber') {
        newComponentData = {
          ...newComponentData,
          [key]: deepConvert(tmpComponentData[key]),
        }
      }
    })
    return newComponentData
  }

  const generateLinksForSendData = useCallback(() => {
    const links = {}
    console.log('DEBUGXXX1 connectedIds', connectedIds)
    if (pipeline?.linkables) {
      Object.keys(pipeline.linkables).forEach((key) => {
        if (
          typeof connectedIds[key]?.[0] === 'object' &&
          connectedIds[key]?.[0] !== null &&
          !Array.isArray(connectedIds[key]?.[0])
        ) {
          links[key] = connectedIds[key]?.map((item) => item.tableId)
        } else {
          if (connectedIds[key].length > 0) {
            links[key] = connectedIds[key]
          }
        }
      })
    }
    return links
  }, [connectedIds, pipeline.linkables])
  console.log(
    'DEBUGTOTAL cleanParams, componentData',
    cleanParams(componentData),
    componentData
  )

  const cleanData = cleanParams(componentData)
  console.log('DEBUGTOTAL HIER START')
  const paramsSend = Object.keys(cleanData).reduce(
    (accumulator, currentValue) => {
      console.log('DEBUGTOTAL HIER accumulator', accumulator)
      console.log('DEBUGTOTAL HIER currentValue', currentValue)
      if (currentValue !== 'components') {
        return {
          ...accumulator,
          [currentValue]: cleanData[currentValue],
        }
      } else {
        return { ...accumulator }
      }
    },
    {}
  )
  console.log('DEBUGTOTAL HIER ENDE paramsSend', paramsSend)
  const componentsSend = cleanData?.components || {}

  const cleanUpComponentValues = (data) => {
    const newData = {}
    Object.keys(data).forEach((key) => {
      switch (key) {
        case 'inhabitantChange':
          if (data[key].tenantId) {
            newData[key] = data[key]
          }

          break
        default:
          newData[key] = data[key]
      }
    })
    return newData
  }

  const links = generateLinksForSendData()
  const formik = useFormik({
    initialValues: {
      clientFeedBackToOtherPerson: false,
      clientSendNotificationUpdate: false,
      clientFirstName: user.firstname,
      clientLastName: user.lastname,
      clientContactTel: '',
      clientEmail: user.email,
    },
    validationSchema: schema,
    onSubmit: (values, formikb) => {
      const sendObject = {
        companyId: user.companyId,
        pipeline: parseInt(pipelineId),
        links: links,
        params: JSON.stringify(paramsSend),
        components: JSON.stringify(cleanUpComponentValues(componentsSend)),
        note: componentData.note,
        referenceNumber: componentData.referenceNumber,
        files: null,
      }
      setLoading(true)
      CreateTicketMutation(
        sendObject,
        user.accessToken,
        componentData.files,
        (data) => {
          if (data?.Me?.Company?.OrderWizard?.createTicket) {
            setTicketCreated(true)
            console.log('data', data)
          }
          setLoading(false)
        },
        (error) => {
          console.log('DEBUG ERROR CALLBACK', error)
          setLoading(false)
        }
      )

      console.log('DEBUGXXX1 sendObject', sendObject)
    },
  })

  return (
    <>
      <Container>
        <Row
          style={{
            backgroundColor: portalTheme.color.basicColorWhite,
            padding: '1rem',
          }}
        >
          <Col xs={3}>
            <Row style={{ fontWeight: 'bold' }}>
              <Col>{t('FEEDBACK TO CLIENT')}</Col>
            </Row>
            <Row style={{ marginTop: '1em' }}>
              <Col>
                <StyledInput
                  type='checkbox'
                  name='clientFeedBackToOtherPerson'
                  id='clientFeedBackToOtherPerson'
                  onChange={formik.handleChange}
                  value={formik.values.clientFeedBackToOtherPerson}
                  label={t('FEEDBACK TO OTHER PERSON')}
                  setFieldValue={formik.setFieldValue}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={4} style={{ borderLeft: 'solid 1px black' }}>
            <Row>
              <Col>
                <StyledInput
                  type='text'
                  name='clientFirstName'
                  id='clientFirstName'
                  onChange={formik.handleChange}
                  value={formik.values.clientFirstName}
                  label={t('FIRST NAME')}
                  feedbackMessage={
                    formik.touched.clientFirstName &&
                    formik.errors.clientFirstName
                  }
                  feedbackStyle='invalid'
                  setFieldValue={formik.setFieldValue}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <StyledInput
                  type='text'
                  name='clientLastName'
                  id='clientLastName'
                  onChange={formik.handleChange}
                  value={formik.values.clientLastName}
                  label={t('LAST NAME')}
                  feedbackMessage={
                    formik.touched.clientLastName &&
                    formik.errors.clientLastName
                  }
                  feedbackStyle='invalid'
                  setFieldValue={formik.setFieldValue}
                />
              </Col>
            </Row>
          </Col>

          <Col style={{ borderLeft: 'solid 1px black' }}>
            <Row>
              <Col>
                <StyledInput
                  type='text'
                  name='clientContactTel'
                  id='clientContactTel'
                  onChange={formik.handleChange}
                  value={formik.values.clientContactTel}
                  label={t('PHONE NUMBER FOR QUESTIONS ')}
                  feedbackMessage={
                    formik.touched.clientContactTel &&
                    formik.errors.clientContactTel
                  }
                  feedbackStyle='invalid'
                  setFieldValue={formik.setFieldValue}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <StyledInput
                  type='text'
                  name='clientEmail'
                  id='clientEmail'
                  onChange={formik.handleChange}
                  value={formik.values.clientEmail}
                  label={t('EMAIL')}
                  feedbackMessage={
                    formik.touched.clientEmail && formik.errors.clientEmail
                  }
                  feedbackStyle='invalid'
                  setFieldValue={formik.setFieldValue}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '1em' }}>
              <Col>
                <StyledInput
                  type='checkbox'
                  name='clientSendNotificationUpdate'
                  id='clientSendNotificationUpdate'
                  onChange={formik.handleChange}
                  value={formik.values.clientSendNotificationUpdate}
                  label={t('NOTIFICATION ABOUT THE STATUS OF THE ORDER')}
                  setFieldValue={formik.setFieldValue}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: '1rem', fontWeight: 'bold' }}>
          <Col>{t('SUMMARY')}</Col>
        </Row>
        <Row style={{ backgroundColor: 'white', padding: '1rem' }}>
          <Col>
            <Row>
              <Col>
                <strong>{t('CATEGORY')}</strong>
              </Col>
            </Row>
            <Row>
              <Col>
                {translateOrderWizard(completeCatObject.mainCat.name, t)}
              </Col>
            </Row>
            <Row style={{ marginTop: '1em' }}>
              <Col>
                <strong>{t('REASON FOR ORDER')}</strong>
              </Col>
            </Row>
            <Row>
              <Col>
                {translateOrderWizard(completeCatObject.reason.name, t)}
              </Col>
            </Row>
          </Col>
          <Col style={{ borderLeft: 'solid 1px black' }}>
            <Row>
              <Col>
                <strong>{t('CLIENT')}</strong>
              </Col>
            </Row>
            <Row>
              <Col>{trim(user.firstname + ' ' + user.lastname)}</Col>
            </Row>
            <Row>
              <Col>{user.email}</Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: '1rem' }}>
          <Col>
            <strong>{t('CONNECTED LINKS')}</strong>
          </Col>
        </Row>

        <Row>
          {Object.keys(links).length > 0 ? (
            <Col>
              {connectedIds?.invoice?.length > 0 && (
                <Row>
                  <Col>
                    <InvoicesGrid
                      invoiceIds={connectedIds.invoice}
                      noRemove
                      connectedIds={connectedIds}
                    />
                  </Col>
                </Row>
              )}

              {connectedIds?.propertyUnit?.length > 0 && (
                <Row>
                  <Col>
                    <HouseEntrancesGrid
                      houseEntrancesIds={connectedIds.propertyUnit}
                      noRemove
                      connectedIds={connectedIds}
                    />
                  </Col>
                </Row>
              )}

              {connectedIds?.usageUnit?.length > 0 && (
                <Row>
                  <Col>
                    <UsageUnitsGrid
                      usageUnitsIds={connectedIds.usageUnit}
                      noRemove
                      connectedIds={connectedIds}
                    />
                  </Col>
                </Row>
              )}

              {connectedIds?.tenant?.length > 0 && (
                <Row>
                  <Col>
                    <TenantsGrid noRemove connectedIds={connectedIds} />
                  </Col>
                </Row>
              )}
            </Col>
          ) : (
            <Col xs='content'>{t('NO CONNECTED LINKS')}</Col>
          )}
        </Row>

        <Row style={{ marginTop: '1rem' }}>
          <Col>
            <strong>{t('ORDER DETAILS')}</strong>
          </Col>
        </Row>

        <Row style={{ backgroundColor: 'white', padding: '1rem' }}>
          <Col>
            <ComponentHandler
              components={pipeline?.components}
              componentData={componentData}
              connectedIds={connectedIds}
              formik={formik}
              position='center'
              summaryView
            />
          </Col>
          <Col>
            <Row>
              <Col>
                <strong>{t('REFERENCE NUMBER')}</strong>
              </Col>
            </Row>
            <Row>
              <Col
                style={{
                  backgroundColor: portalTheme.color.basicColorLightGrey2,
                }}
              >
                {componentData.referenceNumber}
              </Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: 'bold', marginTop: '1em' }}>
                {t('NOTES')}
              </Col>
            </Row>
            <Row>
              <Col
                style={{
                  backgroundColor: portalTheme.color.basicColorLightGrey2,
                }}
              >
                {componentData.note}
              </Col>
            </Row>
            <Row>
              <Col style={{ fontWeight: 'bold', marginTop: '1em' }}>
                {t('FILES')}
              </Col>
            </Row>
            <Row>
              <Col
                style={{
                  backgroundColor: portalTheme.color.basicColorLightGrey2,
                }}
              >
                {componentData?.files?.map((file, index) => {
                  return (
                    <Row
                      key={index + 'key'}
                      align='center'
                      style={{ paddingTop: '1em', paddingBottom: '1em' }}
                    >
                      <Col xs='content'>
                        <StyledIcon
                          icon='file'
                          size='40px'
                          color={portalTheme.color.basicColorBlack}
                        />
                      </Col>
                      <Col>{file.path}</Col>
                    </Row>
                  )
                })}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Row
        justify='center'
        style={{
          position: 'sticky',
          bottom: '0',
          borderTop: 'solid 2px black',
          backgroundColor: 'white',
          paddingTop: '1em',
          paddingBottom: '1em',
          zIndex: '5',
          margin: '0 calc(((100vw - 100% - 1rem) / 2) * -1)',
        }}
      >
        <Col xs='content'>
          {!loading && (
            <ButtonSecondary
              onClick={() => {
                setCurrentStep(3)
              }}
              style={{
                margin: '0px',
                height: '3rem',
                paddingLeft: '4rem',
                paddingRight: '4rem',
              }}
            >
              {t('BACK')}
            </ButtonSecondary>
          )}
        </Col>

        <Col xs='content'>
          {!ticketCreated && !loading && (
            <ButtonPrimary
              type='button'
              onClick={() => {
                formik.handleSubmit()
              }}
              style={{
                margin: '0px',
                height: '3rem',
                paddingLeft: '1rem',
                paddingRight: '1rem',
              }}
            >
              {t('SEND COMMISSIONING')}
            </ButtonPrimary>
          )}

          {!ticketCreated && loading && (
            <LoadingSpinner
              theme={{
                components: {
                  spinner: {
                    /**
                     * Has to be a { css } oject from styled-components
                     */
                    fontSize: portalTheme.font.size.bodySmall,
                    fontWeight: portalTheme.font.weight.regular,
                    size: '30px',
                  },
                },
              }}
              style={{
                position: 'unset',
                backgroundColor: 'white',
              }}
            />
          )}

          {ticketCreated && !loading && setCurrentStep('success')}
        </Col>
      </Row>
    </>
  )
}
