import React from 'react'
import styled from 'styled-components/macro'

import pyrexxLogo from '../../../../assets/pyrexx_logo.svg'

const ProfilePic = ({ sender = 'pyrexx' }) => {
  if (sender !== 'pyrexx') {
    return <Placeholder>AB</Placeholder>
  }

  return (
    <Wrapper>
      <img src={pyrexxLogo} alt='user pic' />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: none;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 60px;
  height: 60px;

  padding: 4px;
  border: 2px solid #eee;
  border-radius: 5px;

  font-size: 1.5rem;
  color: #000;
`

const Placeholder = styled(Wrapper)``

export default ProfilePic
