import React, { useState } from 'react'
import styled from 'styled-components/macro'
// import { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
// import { useDropzone } from 'react-dropzone'
import { useFormik } from 'formik'
import * as Yup from 'yup'

// import { Icon, portalTheme } from 'pyrexx-react-library'
import ProfilePic from './ProfilePic'
import { Box, CustomButtonPrimary } from '../shared'
import FileUpload from '../../../../components/FileUpload/FileUpload'
import CreateTicketMutation from '../../CreateOrder/Step4SendOrder/mutations/CreateTicketMutation'
import { useUserContext } from '../../../../helper/userContext'

const TicketProcessing = ({ ticket = {} }) => {
  const { t } = useTranslation()
  const { user } = useUserContext()

  const {
    reason: { pipeline },
    referenceNumber,
  } = ticket
  // const [ticketMsg, setTicketMsg] = useState('')
  // const [files, setFiles] = useState([
  //   { id: '1', content: '', name: 'File 1' },
  //   { id: '2', content: '', name: 'File 2' },
  // ])
  const [uploadStatusMsg, setUploadStatusMsg] = useState({
    open: false,
    msg: '',
    status: 'success',
  })

  const [loading, setLoading] = useState(false)
  // const [ticketCreated, setTicketCreated] = useState(false)

  // const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
  //   // Disable click and keydown behavior
  //   // noClick: false,
  //   // noKeyboard: true,
  //   multiple: true,
  //   accept:
  //     '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  //   onDrop: (acceptedFiles) => {
  //     const checkedFiles = []
  //     acceptedFiles.forEach((singleNewFile) => {
  //       if (
  //         !files.find(
  //           (oldFiles) =>
  //             oldFiles.path + oldFiles.lastModified + singleNewFile.size ===
  //             singleNewFile.path +
  //               singleNewFile.lastModified +
  //               singleNewFile.size
  //         )
  //       ) {
  //         checkedFiles.push(singleNewFile)
  //       }
  //     })
  //     const newUpload = [...files, ...checkedFiles]
  //     // onUpload(name, newUpload)
  //     // setFieldValue(name, newUpload)
  //   },
  // })

  // const uploadFiles = () => {
  //   // setFiles()
  // }

  // const updateText = (e) => {
  //   setTicketMsg(e.target.value)
  // }
  //
  // const sendText = (text) => {
  //   console.log('Sending text: ', text)
  // }

  const formik = useFormik({
    initialValues: { ticketMsg: '', files: [] },
    validationSchema: Yup.object({
      ticketMsg: Yup.string(),
      files: Yup.array(),
    }),
    onSubmit: (values, formikb) => {
      console.log(values)

      CreateTicketMutation(
        {
          companyId: user.companyId,
          pipeline: parseInt(pipeline),
          referenceNumber,
          // links: links,
          // params: JSON.stringify(paramsSend),
          // components: JSON.stringify(cleanUpComponentValues(componentsSend)),
          // note: componentData.note,
          // files: null,
        },
        user.accessToken,
        formik.values.files,
        (data) => {
          if (data?.Me?.Company?.OrderWizard?.createTicket) {
            // setTicketCreated(true)
            console.log('data', data)
          }
          setLoading(false)
        },
        (error) => {
          console.log('DEBUG ERROR CALLBACK', error)
          setLoading(false)
        }
      )
    },
  })

  const isButtonActive =
    formik.values.ticketMsg.length > 0 || formik.values.files.length > 0

  return (
    <Wrapper>
      <Title>
        <strong>{t('DO YOU WANT TO SAY ANYTHING?')}</strong>
      </Title>

      <ImageContainer>
        <ProfilePic sender='user' />
      </ImageContainer>

      <TextAreaContainer>
        <TextArea
          id='ticketMsg'
          name='ticketMsg'
          placeholder='...'
          value={formik.values.ticketMsg}
          onChange={formik.handleChange}
        />
      </TextAreaContainer>

      {/* <Files> */}
      {/*  <strong>{t('ADD FILES')}</strong> */}
      {/*  <FilesList>*/}
      {/*    {files.map((file) => (*/}
      {/*      <File key={file.id}>{file.name}</File>*/}
      {/*    ))}*/}
      {/*  </FilesList>*/}
      {/*</Files>*/}

      <DragAndDropContainer>
        <FileUpload
          uploadStatusMsg={uploadStatusMsg}
          setUploadStatusMsg={setUploadStatusMsg}
          setFieldValue={formik.setFieldValue}
          onUpload={formik.handleChange}
          files={formik.values?.files}
          // multiFileMode={false}
          name='files'
          supportedFileTypes='application/pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
          maxsizeKb={10000}
          formik={formik}
          labels={{
            maxSizeReached: t('SIZE OF FILES EXCEEDS THE ALLOWED MAXIMUM'),
          }}
        />

        {/*  <Dropzone {...getRootProps()}>*/}
        {/*    <DropzoneInner isDragActive={isDragActive}>*/}
        {/*      <input {...getInputProps()} /> */}
        {/*      <span>Drag & Drop</span> */}
        {/*      <Icon icon='drag-and-drop' size='60px' color='#d8d8d8' />*/}
        {/*    </DropzoneInner>*/}
        {/*  </Dropzone>*/}
      </DragAndDropContainer>

      <SendButton>
        {isButtonActive && (
          <CustomButtonPrimary
            onClick={() => formik.handleSubmit()}
            isLoading={loading}
          >
            {t('SEND')}
          </CustomButtonPrimary>
        )}
      </SendButton>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  // https://grid.layoutit.com/?id=FSOKk6H
  display: grid;
  grid-template-columns: auto 1.5fr 1fr 1fr;
  grid-template-rows: auto 1.5fr 1.5fr auto;
  gap: 16px;
  grid-template-areas:
    'Title Title Title Title'
    'ImageContainer TextAreaContainer TextAreaContainer TextAreaContainer'
    'ImageContainer DragAndDropContainer DragAndDropContainer DragAndDropContainer'
    'ImageContainer . . SendButton';

  & div {
    //border: 1px solid #ddd;
  }
`

const Title = styled.div`
  grid-area: Title;
`
const ImageContainer = styled.div`
  grid-area: ImageContainer;
`
const TextAreaContainer = styled.div`
  grid-area: TextAreaContainer;
`
// const Files = styled.div`
//   grid-area: Files;
// `
const DragAndDropContainer = styled.div`
  grid-area: DragAndDropContainer;

  display: flex;
  gap: 16px;
  justify-content: space-between;
`
const SendButton = styled.div`
  grid-area: SendButton;

  display: flex;
  justify-content: flex-end;
`

const TextArea = styled.textarea`
  padding: 8px;
  width: 100%;
  height: 100%;

  border: 1px solid #aaa;
  border-radius: 5px;

  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
`

// const FilesList = styled.ul``
// const File = styled.li`
//   list-style: none;
// `
//
// const Dropzone = styled.div`
//   background-color: #e9edee;
//   height: 120px;
//   width: 100%;
//   border-radius: 19px;
//   z-index: 1;
//
//   padding: 12px;
//
//   cursor: pointer;
// `
//
// const DropzoneInner = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-evenly;
//   gap: 24px;
//
//   width: 100%;
//   height: 100%;
//
//   border-width: 2px;
//   border-style: dashed;
//   border-color: transparent;
//   border-radius: 19px;
//
//   transition: border-color 200ms ease-out;
//
//   ${(props) =>
//     props.isDragActive &&
//     css`
//       border-color: #fff;
//       transition: border-color 200ms ease-in;
//     `}
// `
//
// const StyledSelectFile = styled.div`
//   height: 120px;
//   width: 100%;
//   border-radius: 19px;
//
//   &:hover,
//   &:visited,
//   &:focus {
//     background-color: ${portalTheme.color.basicColorLightGrey2};
//     cursor: pointer;
//     outline: 0;
//     text-decoration: none;
//   }
// `
export default TicketProcessing
