import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { Icon, SimpleModal, StyledInput } from 'pyrexx-react-library'

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  border-radius: 10px;

  background-color: #fff;
  padding: 24px;

  height: 100%;

  ${(props) =>
    !props.fullWidth &&
    css`
      width: 75%;
      margin: 0 auto;
    `}
`

export const BoxInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
`

// ForgotPassword styles
export const StyledSimpleModal = styled(SimpleModal)`
  & [data-simple-modal-content] {
    display: block;
  }

  & > [data-simple-modal-content] > button {
    margin: 0 auto;
    margin-top: 1rem;
  }
`

export const EmailInput = styled(StyledInput)`
  min-width: 300px;
`

// RequestPassword styles

export const CheckIcon = styled(Icon)`
  color: var(--pyrexx-success, green);
`
