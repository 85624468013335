import React, { useContext } from 'react'
import styled from 'styled-components/macro'

import { Icon } from 'pyrexx-react-library'
import AgGridDownload from '../../../components/AgGridDownload'
import { AppointmentGetBulletinDownloadPathQuery } from '../queries'
import UserContext from '../../../helper/userContext'

const BulletinDownloadRenderer = (props) => {
  const { value, data } = props
  const { user } = useContext(UserContext)

  const appointmentId = data?.tableId

  const variables = {
    companyId: user.companyId,
    appointmentId,
  }

  if (!value || !appointmentId) {
    return ''
  }

  return (
    <Wrapper>
      <span>{value}</span>

      <AgGridDownload
        variables={variables}
        query={AppointmentGetBulletinDownloadPathQuery}
        extractor={(res) => res.Me?.Company?.Appointment?.data?.download}
      >
        <AnchorStyled>
          <Icon icon='download' size={20} />
        </AnchorStyled>
      </AgGridDownload>
    </Wrapper>
  )
}

export default BulletinDownloadRenderer

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`

const AnchorStyled = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--pyrexx-link, #4AB4E2);
`
