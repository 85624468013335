import React from 'react'
import styled from 'styled-components/macro'
import { css } from 'styled-components'

import StatusLight from '../../../components/Status/StatusLight'
import EmptyButton from '../../../components/Simple/EmptyButton'

const NotificationItem = ({
  notification = {},
  isSelected = false,
  onNotificationClick = () => {},
  className,
}) => {
  const {
    category = '',
    subject = '',
    content = '',
    datetime = '',
    wasRead = false,
  } = notification

  return (
    <Wrapper
      onClick={onNotificationClick}
      className={className}
      isSelected={isSelected}
    >
      <StatusLightColumn>
        <StatusLight status={wasRead ? 'success' : 'error'} />
      </StatusLightColumn>

      <InfoColumn>
        <Category>{category}</Category>
        <strong>{subject}</strong>
        <span>{content}</span>
      </InfoColumn>

      <DatetimeColumn>
        <span>{datetime}</span>
      </DatetimeColumn>
    </Wrapper>
  )
}

const Wrapper = styled(EmptyButton)`
  display: flex;
  gap: 16px;

  padding: 16px 24px;
  background-color: #fff;

  ${(props) =>
    props.isSelected &&
    css`
      background-color: var(--pyrexx-dropdown-badge-color, #c5cfda);
    `}
`

const StatusLightColumn = styled.div`
  padding-top: 5px;
`

const InfoColumn = styled.div`
  flex: 1;

  display: flex;
  align-items: flex-start;
  flex-flow: column nowrap;
`

const Category = styled.span`
  color: #a6a6a6;
`

const DatetimeColumn = styled.div``

NotificationItem.propTypes = {}

export default NotificationItem
