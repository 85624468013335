import React, { useEffect } from 'react'
import { Container } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import DataUpload from './DataUpload'

import { useAgGridUltra } from '../../components/AgGrid/AgGridUltra/hooks/useAgGridUltra'
import { useSyncListGrid } from './gridConfigHooks/useSyncListGrid'

const InventoryDataUpload = (props) => {
  const { contracts, loading = true } = props

  const { t } = useTranslation()

  const generateComponent = (componentProps) => {
    return (
      <DataUpload
        contracts={componentProps.contracts}
        loading={componentProps.loading}
      />
    )
  }

  const grids = [
    {
      id: 'sync-list',
      label: t('UPLOADS'),
      useGetParams: useSyncListGrid(),
    },
    {
      id: 'sync-data-upload',
      label: t('DATA UPLOAD'),
      component: generateComponent,
    },
  ]

  const { generateGrid, generatePills, setComponentProps } = useAgGridUltra(
    'Operations',
    grids
  )

  useEffect(() => {
    setComponentProps({ contracts, loading })
  }, [loading, setComponentProps, contracts])

  return (
    <Container fluid>
      {generatePills()}
      {generateGrid()}
    </Container>
  )
}

export default InventoryDataUpload
