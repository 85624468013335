const defaultColumns = [
  'Anrede',
  'Hausnummer',
  'KZN',
  'Nachname',
  'PLZ',
  'Stadt',
  'Straße',
  'Titel',
  'Vorname',
]

export default defaultColumns
