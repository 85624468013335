/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AppointmentStatusType = "CANCELLED" | "CHECKED" | "CLOSED" | "DEACTIVATED" | "DIAGNOSE_PENDING" | "DONE" | "NONE" | "OPEN" | "PLANNED" | "TEMP_DEFERRED" | "UNTERMINATED" | "%future added value";
export type AppointmentTypeType = "P" | "REMOTE_DATA_COLLECTION" | "T" | "TA" | "TI" | "TS" | "TX" | "%future added value";
export type ConditionType = "contains" | "endsWith" | "equals" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "lessThan" | "lessThanOrEqual" | "notContains" | "notEqual" | "startsWith" | "%future added value";
export type FilterType = "date" | "number" | "text" | "%future added value";
export type OperatorType = "AND" | "OR" | "%future added value";
export type Sort = {|
  colId?: ?string,
  sort?: ?string,
|};
export type Filter = {|
  name: string,
  filterType: FilterType,
  operator: OperatorType,
  denySelection?: ?boolean,
  conditions: $ReadOnlyArray<?Condition>,
|};
export type Condition = {|
  value: $ReadOnlyArray<?string>,
  type: ConditionType,
|};
export type PerformanceGridQueryVariables = {|
  startRow?: ?number,
  endRow?: ?number,
  sortModel?: ?$ReadOnlyArray<?Sort>,
  filters?: ?$ReadOnlyArray<?Filter>,
  companyId: string,
|};
export type PerformanceGridQueryResponse = {|
  +Me: ?{|
    +id: string,
    +Company: ?{|
      +data: ?$ReadOnlyArray<?{|
        +id: ?string,
        +tableId: ?number,
        +type: ?AppointmentTypeType,
        +date: ?any,
        +timeframe: ?string,
        +accessReason: ?{|
          +tableId: number,
          +msgId: ?string,
        |},
        +status: ?AppointmentStatusType,
        +initiator: ?string,
        +billingPartition: ?{|
          +id: string,
          +name: string,
        |},
        +serviceReason: ?{|
          +id: string,
          +name: ?string,
        |},
        +executionDate: ?any,
        +tenant: ?{|
          +id: ?string,
          +firstname: string,
          +lastname: string,
        |},
        +contract: ?{|
          +id: string,
          +serviceCategory: ?{|
            +id: string,
            +tableId: number,
            +name: string,
          |},
        |},
        +address: ?{|
          +street: string,
          +zip: string,
          +city: string,
          +number: string,
        |},
        +registrations: $ReadOnlyArray<?{|
          +id: string,
          +actionPosition: ?{|
            +id: string,
            +name: ?string,
          |},
        |}>,
      |}>
    |},
  |}
|};
export type PerformanceGridQuery = {|
  variables: PerformanceGridQueryVariables,
  response: PerformanceGridQueryResponse,
|};
*/


/*
query PerformanceGridQuery(
  $startRow: Int
  $endRow: Int
  $sortModel: [Sort]
  $filters: [Filter]
  $companyId: ID!
) {
  Me {
    id
    Company(id: $companyId) {
      data: Appointments(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {
        id
        tableId
        type
        date
        timeframe
        accessReason {
          tableId
          msgId
          id
        }
        status
        initiator
        billingPartition {
          id
          name
        }
        serviceReason {
          id
          name
        }
        executionDate
        tenant {
          id
          firstname
          lastname
        }
        contract {
          id
          serviceCategory {
            id
            tableId
            name
          }
        }
        address {
          street
          zip
          city
          number
        }
        registrations(options: {rowGroupCols: [{id: "actionPosition.id"}]}) {
          id
          actionPosition {
            id
            name
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endRow"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortModel"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startRow"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v7 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "endRow",
        "variableName": "endRow"
      },
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      },
      {
        "kind": "Variable",
        "name": "sorting",
        "variableName": "sortModel"
      },
      {
        "kind": "Variable",
        "name": "startRow",
        "variableName": "startRow"
      }
    ],
    "kind": "ObjectValue",
    "name": "options"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeframe",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "msgId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "initiator",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v16 = [
  (v5/*: any*/),
  (v15/*: any*/)
],
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "BillingPartition",
  "kind": "LinkedField",
  "name": "billingPartition",
  "plural": false,
  "selections": (v16/*: any*/),
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "ServiceReason",
  "kind": "LinkedField",
  "name": "serviceReason",
  "plural": false,
  "selections": (v16/*: any*/),
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "executionDate",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenant",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastname",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "Contract",
  "kind": "LinkedField",
  "name": "contract",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ServiceCategory",
      "kind": "LinkedField",
      "name": "serviceCategory",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        (v8/*: any*/),
        (v15/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "address",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "street",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zip",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "options",
      "value": {
        "rowGroupCols": [
          {
            "id": "actionPosition.id"
          }
        ]
      }
    }
  ],
  "concreteType": "Registration",
  "kind": "LinkedField",
  "name": "registrations",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ActionPosition",
      "kind": "LinkedField",
      "name": "actionPosition",
      "plural": false,
      "selections": (v16/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": "registrations(options:{\"rowGroupCols\":[{\"id\":\"actionPosition.id\"}]})"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PerformanceGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": "data",
                "args": (v7/*: any*/),
                "concreteType": "Appointment",
                "kind": "LinkedField",
                "name": "Appointments",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Access",
                    "kind": "LinkedField",
                    "name": "accessReason",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PerformanceGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": "data",
                "args": (v7/*: any*/),
                "concreteType": "Appointment",
                "kind": "LinkedField",
                "name": "Appointments",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Access",
                    "kind": "LinkedField",
                    "name": "accessReason",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v12/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b7a9ae29714d9381081bae5e2693f033",
    "id": null,
    "metadata": {},
    "name": "PerformanceGridQuery",
    "operationKind": "query",
    "text": "query PerformanceGridQuery(\n  $startRow: Int\n  $endRow: Int\n  $sortModel: [Sort]\n  $filters: [Filter]\n  $companyId: ID!\n) {\n  Me {\n    id\n    Company(id: $companyId) {\n      data: Appointments(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {\n        id\n        tableId\n        type\n        date\n        timeframe\n        accessReason {\n          tableId\n          msgId\n          id\n        }\n        status\n        initiator\n        billingPartition {\n          id\n          name\n        }\n        serviceReason {\n          id\n          name\n        }\n        executionDate\n        tenant {\n          id\n          firstname\n          lastname\n        }\n        contract {\n          id\n          serviceCategory {\n            id\n            tableId\n            name\n          }\n        }\n        address {\n          street\n          zip\n          city\n          number\n        }\n        registrations(options: {rowGroupCols: [{id: \"actionPosition.id\"}]}) {\n          id\n          actionPosition {\n            id\n            name\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '60d8dd5d588b2b65b4905a725bb37b9c';

module.exports = node;
