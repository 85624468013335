// FILTERS
export const DATE_ALL = 'date=all'
export const DATE_FROM = 'dateFrom'
export const DATE_TO = 'dateTo'

export const SERVICE = 'service'
export const SERVICE_NAME = 'serviceName'
export const CONTRACT = 'contract'
export const CONTRACT_NAME = 'contractName'
export const CHANNEL = 'channel'
export const CHANNEL_NAME = 'channelName'
export const TIMEFRAME = 'timeframe'

export const BOX_FILTER = 'boxFilter'
export const GRID_ID = 'gridId'

// ORDER WIZARD
export const OW_CATEGORY = 'category'
export const OW_TOPIC = 'topic'
export const OW_REASON = 'reason'
export const OW_REASON_ID = 'reasonId'
export const OW_CURRENT_STEP = 'currentStep'
export const OW_CONNECTED_IDS = 'connectedIds'
export const OW_PIPELINE = 'pipeline'
export const OW_SEARCH_TEXT = 'searchText'
