import { getDateFilterModel } from './getFilterModel'
import { DATE_FROM, DATE_TO } from '../configs/FilterConfig'

const getCurrentDateFilter = (customDateFilterKey) => {
  const currentYear = new Date().getFullYear()
  const periodStart = `${currentYear}-01-01`
  const periodEnd = `${currentYear}-12-31`

  const filterModel =
    getDateFilterModel(
      {
        from: periodStart,
        to: periodEnd,
      },
      null,
      customDateFilterKey
    ) || {}

  const value =
    {
      currentFrom: periodStart,
      currentTo: periodEnd,
    } || {}

  const string = DATE_FROM + '=' + periodStart + '&' + DATE_TO + '=' + periodEnd

  return { filterModel, value, string }
}

export default getCurrentDateFilter
