import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query Filter2AccessNotGrantedQuery(
    $companyId: ID!
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
  ) {
    Me {
      Company(id: $companyId) {
        data: Appointments(
          options: {
            startRow: $startRow
            endRow: $endRow
            sorting: $sortModel
            filters: $filters
          }
        ) {
          id
          tableId
          usageUnit {
            id
            referenceNumber
            tableId
          }
          address {
            city
            street
            number
          }
          tenant {
            id
            firstname
            lastname
          }
          referenceNumber
          accessReason {
            tableId
            msgId
          }
          executionDate
          type
          billingPartition {
            id
            name
          }
        }
      }
    }
  }
`
