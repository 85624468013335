import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query ServiceDetailsQuery(
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
    $companyId: ID!
    $appointmentId: ID!
  ) {
    Me {
      Company(id: $companyId) {
        Appointment(id: $appointmentId) {
          data: registrations(
            options: {
              startRow: $startRow
              endRow: $endRow
              sorting: $sortModel
              filters: $filters
            }
          ) {
            id
            actionPosition {
              id
              name
            }
            actionReason {
              id
              name
              msgId
            }
            position {
              id
              room {
                id
                type
              }
            }
          }
          id
        }
        id
      }
      id
    }
  }
`
