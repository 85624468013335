import styled from 'styled-components/macro'
import { css } from 'styled-components'
import Badge from './Badge'

const DropdownBadge = styled(Badge)`
  background-color: var(--pyrexx-dropdown-badge-color, #c5cfda);
  width: 100%;

  padding: 8px 12px;

  color: #000;

  text-overflow: ellipsis;
  overflow: hidden;

  ${({ noValue }) =>
    noValue &&
    css`
      background-color: #fff;
      border: 1px solid var(--pyrexx-dropdown-badge-color, #c5cfda);
      color: #000;
    `}
`

export default DropdownBadge
