/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type InvoicesServiceCostsDownloadsQueryVariables = {|
  serviceCostsId: string,
  companyId: string,
|};
export type InvoicesServiceCostsDownloadsQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +id: string,
      +serviceCosts: $ReadOnlyArray<?{|
        +download: ?{|
          +path: string,
          +token: string,
          +name: string,
        |}
      |}>,
    |}
  |}
|};
export type InvoicesServiceCostsDownloadsQuery = {|
  variables: InvoicesServiceCostsDownloadsQueryVariables,
  response: InvoicesServiceCostsDownloadsQueryResponse,
|};
*/


/*
query InvoicesServiceCostsDownloadsQuery(
  $serviceCostsId: String!
  $companyId: ID!
) {
  Me {
    Company(id: $companyId) {
      id
      serviceCosts(options: {filters: [{filterType: text, name: "tableId", operator: AND, conditions: [{type: equals, value: [$serviceCostsId]}]}]}) {
        download {
          path
          token
          name
        }
        id
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "serviceCostsId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "fields": [
      {
        "items": [
          {
            "fields": [
              {
                "items": [
                  {
                    "fields": [
                      {
                        "kind": "Literal",
                        "name": "type",
                        "value": "equals"
                      },
                      {
                        "items": [
                          {
                            "kind": "Variable",
                            "name": "value.0",
                            "variableName": "serviceCostsId"
                          }
                        ],
                        "kind": "ListValue",
                        "name": "value"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "conditions.0"
                  }
                ],
                "kind": "ListValue",
                "name": "conditions"
              },
              {
                "kind": "Literal",
                "name": "filterType",
                "value": "text"
              },
              {
                "kind": "Literal",
                "name": "name",
                "value": "tableId"
              },
              {
                "kind": "Literal",
                "name": "operator",
                "value": "AND"
              }
            ],
            "kind": "ObjectValue",
            "name": "filters.0"
          }
        ],
        "kind": "ListValue",
        "name": "filters"
      }
    ],
    "kind": "ObjectValue",
    "name": "options"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "DownloadDetails",
  "kind": "LinkedField",
  "name": "download",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "token",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InvoicesServiceCostsDownloadsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "ServiceCost",
                "kind": "LinkedField",
                "name": "serviceCosts",
                "plural": true,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "InvoicesServiceCostsDownloadsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "ServiceCost",
                "kind": "LinkedField",
                "name": "serviceCosts",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e1d307d65c287dc61faa63a5aed20806",
    "id": null,
    "metadata": {},
    "name": "InvoicesServiceCostsDownloadsQuery",
    "operationKind": "query",
    "text": "query InvoicesServiceCostsDownloadsQuery(\n  $serviceCostsId: String!\n  $companyId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      id\n      serviceCosts(options: {filters: [{filterType: text, name: \"tableId\", operator: AND, conditions: [{type: equals, value: [$serviceCostsId]}]}]}) {\n        download {\n          path\n          token\n          name\n        }\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3d066a844539e841a0fd4a7bd213e1f6';

module.exports = node;
