import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import Skeleton from '../Skeleton'
import StatusFilterButton from '../Status/StatusFilterButton'

import * as S from './StatusBox.styles'
import { FilterBadge } from '../Badges'
import updateUrl from '../../helper/updateUrl'

const StatusBox = (props) => {
  const {
    titleElements = [],
    rowElements = [],
    filters = [],
    saveFilters = () => {},
    removeFilter = () => {},
    variant,
    loading = false,
  } = props

  const WithSkeleton = ({ children, ...restOfProps }) => {
    return loading ? <Skeleton {...restOfProps} /> : children
  }

  const url = new URL(window.location)

  const handleClick = ({ filter = {}, id }) => {
    updateUrl(filter, url, 'set', id)
    saveFilters([filter])
  }

  const handleClose = ({ filter = {} }) => {
    updateUrl(filter, url, 'delete')
    removeFilter(filter)
  }

  return (
    <S.Container fluid>
      <S.Row>
        <S.Col lg={1} />
        <S.Col lg={3} />

        {titleElements.map((title) => {
          const selected = !!filters.find(
            (f) => f?.filterId === title?.filter?.filterId
          )

          if (title?.filter == null) {
            return (
              <S.Col key={title?.id}>
                <S.Field>{title?.label}</S.Field>
              </S.Col>
            )
          }

          return (
            <S.Col key={title?.id}>
              <FilterBadgeWrapperText
                selected={selected}
                handleClose={() => handleClose(title)}
              >
                <StatusFilterButton
                  onClick={() => handleClick(title)}
                  label={title?.label}
                  font='quicksand'
                />
              </FilterBadgeWrapperText>
            </S.Col>
          )
        })}
      </S.Row>

      {rowElements.map((row) => {
        const selected = !!filters.find(
          (f) => f?.filterId === row?.filter?.filterId
        )

        return (
          <S.Row key={row?.id}>
            <S.Col lg={1} />
            <S.Col lg={3}>
              <FilterBadgeWrapperText
                selected={selected}
                handleClose={() => handleClose(row)}
              >
                <StatusFilterButton
                  onClick={() => handleClick(row)}
                  label={row?.label}
                  font='quicksand'
                />
              </FilterBadgeWrapperText>
            </S.Col>

            {row.columns.map((col) => {
              const selected = !!filters.find(
                (f) => f?.filterId === col?.filter?.filterId
              )

              if (variant === 'div') {
                return (
                  <S.Col key={row?.id + col?.id}>
                    <WithSkeleton>
                      <StatusFilterButton
                        status={col?.status}
                        label={col?.label}
                        variant={variant}
                      />
                    </WithSkeleton>
                  </S.Col>
                )
              }

              return (
                <S.Col key={row?.id + col?.id}>
                  <WithSkeleton>
                    <FilterBadgeWrapper
                      selected={selected}
                      handleClose={() => handleClose(col)}
                    >
                      <StatusFilterButton
                        onClick={() => handleClick(col)}
                        status={col?.status}
                        label={col?.label}
                        variant={variant}
                      />
                    </FilterBadgeWrapper>
                  </WithSkeleton>
                </S.Col>
              )
            })}
          </S.Row>
        )
      })}
    </S.Container>
  )
}

const PureFilterBadgeWrapper = ({
  children,
  selected = false,
  handleClose = () => {},
  isDisabled = false,
  ...restOfProps
}) => {
  if (isDisabled) return children

  return selected ? (
    <FilterBadge handleClose={handleClose} {...restOfProps}>
      {children}
    </FilterBadge>
  ) : (
    children
  )
}

const FilterBadgeWrapper = styled(PureFilterBadgeWrapper)`
  position: absolute;
  //background-color: hsl(211, 22%, 90%);
  padding: 2px 6px;
  left: 9px;

  * > span {
    color: #000;
  }
`

const FilterBadgeWrapperText = styled(FilterBadgeWrapper)`
  padding: 2px 10px;
  left: 5px;
`

StatusBox.propTypes = {
  titleElements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.any,
    })
  ),
  rowElements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.any,
      filterModel: PropTypes.object,
      columns: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.any,
          status: PropTypes.string,
          filter: PropTypes.shape({
            filterId: PropTypes.string.isRequired,
            label: PropTypes.any,
            filterModel: PropTypes.object,
          }),
        })
      ),
    })
  ),
  filters: PropTypes.array,
  saveFilters: PropTypes.func,
  removeFilter: PropTypes.func,
  variant: PropTypes.oneOf(['button', 'link', 'anchor', 'div']),
  loading: PropTypes.bool,
}

export default StatusBox
