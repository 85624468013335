import React from 'react'
import PropTypes from 'prop-types'
import ReactPasswordStrength from 'react-password-strength'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { css } from 'styled-components'

import { Icon } from 'pyrexx-react-library'

import useToggle from '../../hooks/useToggle'
import { useUserContext } from '../../helper/userContext'
import deList from '../ReactPasswordStrength/deList'

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  padding-right: 16px;
  border-bottom: 1px solid #c6c6c6;
  background-color: #fff;
`

const StyledReactPasswordStrength = styled(ReactPasswordStrength)`
  &.ReactPasswordStrength {
    min-width: 400px;
    flex: 1;

    font-family: inherit;
    font-size: 1rem;
    border: none;
  }

  & > .ReactPasswordStrength-input {
    font-family: inherit;
    font-size: 1rem;

    width: 100%;
  }

  & > .ReactPasswordStrength-input:not(:focus).is-password-invalid {
    ${(props) =>
      !props.withFeedback &&
      css`
        color: inherit;
      `}
  }

  & > .ReactPasswordStrength-strength-bar {
    height: 3px;

    ${(props) =>
      !props.withFeedback &&
      css`
        display: none;
      `}
  }

  & > .ReactPasswordStrength-strength-desc {
    width: unset;

    ${(props) =>
      !props.withFeedback &&
      css`
        display: none;
      `}
  }

  &.ReactPasswordStrength.is-strength-1 .ReactPasswordStrength-strength-bar {
    background-color: var(--pyrexx-warning, #57b8ff);
  }

  &.ReactPasswordStrength.is-strength-1 .ReactPasswordStrength-strength-desc {
    color: var(--pyrexx-warning, #57b8ff);
  }
`

const PasswordStrengthInput = ({
  setPassword,
  inputProps = {},
  withFeedback = true,
}) => {
  const { currentLanguage } = useUserContext()
  const { t } = useTranslation()

  const [showPassword, togglePassword] = useToggle(false)

  const scoreWords = withFeedback
    ? [t('WEAK'), t('OKAY'), t('GOOD'), t('STRONG'), t('STRONGER')]
    : []

  const tooShortWord = withFeedback ? t('TOO SHORT') : ''
  const userInputs = currentLanguage === 'de' ? deList : []

  return (
    <Wrapper>
      <StyledReactPasswordStrength
        withFeedback={withFeedback}
        minLength={8}
        minScore={1}
        scoreWords={scoreWords}
        tooShortWord={tooShortWord}
        changeCallback={(state, results) =>
          setPassword({ ...state, feedback: results?.feedback })
        }
        userInputs={userInputs}
        inputProps={{
          autoComplete: 'new-password',
          type: showPassword ? 'text' : 'password',
          ...inputProps,
        }}
      />

      <Icon
        size='25px'
        icon={showPassword ? 'password-hide' : 'password-show'}
        onClick={togglePassword}
        aria-hidden='true'
        style={{ cursor: 'pointer' }}
      />
    </Wrapper>
  )
}

PasswordStrengthInput.propTypes = {
  setPassword: PropTypes.func,
  inputProps: PropTypes.object,
  withFeedback: PropTypes.bool,
}

export default PasswordStrengthInput
