import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import CreateNewInquiryBox from './CreateNewInquiryBox'
import TicketsOverview from './TicketsOverview'
import Spacer from '../../../components/Spacer'
import { useAgGridUltra } from '../../../components/AgGrid/AgGridUltra/hooks/useAgGridUltra'

import useAllOpenEnquiriesGrid from './grids/useAllOpenEnquiriesGrid'
import useMyOpenEnquiriesGrid from './grids/useMyOpenEnquiriesGrid'
import useClosedEnquiriesGrid from './grids/useClosedEnquiriesGrid'

import PyrexxOverviewProvider from '../../../providers/PyrexxOverviewProvider'
import {
  getDateFilterModel,
  getChannelFilterModel,
} from '../../../helper/getFilterModel'
import handleSearchParamsFromUrl from '../../../helper/handleSearchParamsFromUrl'

const Enquiries = () => {
  const { t } = useTranslation()

  const [mockForceRefresh, setMockForceRefresh] = useState(false)

  const grids = [
    {
      id: 'all-open-enquiries',
      label: t('ALL OPEN ENQUIRIES'),
      useGetParams: useAllOpenEnquiriesGrid({
        refreshGrid: setMockForceRefresh,
      }),
    },
    {
      id: 'my-open-enquiries',
      label: t('MY OPEN ENQUIRIES'),
      useGetParams: useMyOpenEnquiriesGrid({
        refreshGrid: setMockForceRefresh,
      }),
    },
    {
      id: 'closed-enquiries',
      label: t('CLOSED ENQUIRIES'),
      useGetParams: useClosedEnquiriesGrid(),
    },
  ]

  const { generateTabs, generateGrid, setForceRefresh, setFilterModel } =
    useAgGridUltra('Enquiries', grids, {}, false, false, 5)

  useEffect(() => {
    setForceRefresh(true)
    // eslint-disable-next-line
  }, [mockForceRefresh])

  const { from, to, channel, channelName } = handleSearchParamsFromUrl()

  const initialStateUiFilters = [
    getDateFilterModel({ from, to }, null, 'creationDate'),
    getChannelFilterModel({ tableId: channel, name: channelName }),
  ]

  return (
    <PyrexxOverviewProvider initialStateUiFilters={initialStateUiFilters}>
      <TopSection>
        <CreateNewInquiryBox />
        <div style={{ flex: 3 }}>
          <TicketsOverview setFilterModel={setFilterModel} />
        </div>
      </TopSection>

      <Spacer y={32} />

      <div>
        {generateTabs()}
        {generateGrid()}
      </div>
    </PyrexxOverviewProvider>
  )
}

const TopSection = styled.div`
  display: flex;
  gap: 16px;
`

export default Enquiries
