import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

import { ButtonSecondary } from 'pyrexx-react-library'

import { useUserContext } from '../../../helper/userContext'

const ReassemblyRenderer = (props) => {
  const { value = '' } = props

  const { t } = useTranslation()
  const history = useHistory()
  const { currentLanguage } = useUserContext()

  const handleClick = () => {
    const customUri = `?category=ORDER_WIZARD_CHANNEL_SMOKE_DETECTOR_SERVICES&topic=ORDER_WIZARD_TOPIC_REPAIR&reason=ORDER_WIZARD_REASON_REASSEMBLY_THIRD_PARTY_SMOKE_DETECTORS&reasonId=6&currentStep=2&connectedIds={"invoice":[],"usageUnit":[${value}],"tenant":[]}`

    history.push(
      '/' + currentLanguage + '/support/createOrder' + encodeURI(customUri)
    )
  }

  return (
    <StyledButtonSecondary onClick={handleClick}>
      {t('REASSEMBLY')}
    </StyledButtonSecondary>
  )
}

const StyledButtonSecondary = styled(ButtonSecondary)`
  height: 30px;
  padding-left: 2rem;
  padding-right: 2rem;
`

export default ReassemblyRenderer
