import React from 'react'
import { Col, Row } from 'react-grid-system'

import { MostUsedReasons } from './MostUsedReasons'
import { OrderReasonSelector } from './OrderReasonSelector'
import { RecentlyCreatedTickets } from './RecentlyCreatedTickets'

export function Commisioning(props) {
  const {
    startSelection = false,
    mostUsedReasons = [],
    recentlyCreatedTickets = [],
  } = props

  const showMostUsedReasons = !startSelection && mostUsedReasons.length > 0
  const showRecentlyCreatedTickets = !startSelection

  return (
    <Row justify='center' align='start' nogutter>
      <Col>
        <Row nogutter>
          <Col xs={3}>
            {showMostUsedReasons && (
              <MostUsedReasons mostUsedReasons={mostUsedReasons} />
            )}
          </Col>

          <Col>
            <OrderReasonSelector {...props} />
          </Col>
        </Row>
      </Col>

      <Col xs={3}>
        {showRecentlyCreatedTickets && (
          <RecentlyCreatedTickets
            recentlyCreatedTickets={recentlyCreatedTickets}
          />
        )}
      </Col>
    </Row>
  )
}
