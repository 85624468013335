import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query RecipientsGridQuery(
    $companyId: ID!
    $letterId: ID!
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
  ) {
    Me {
      Company(id: $companyId) {
        businessPost {
          letter(id: $letterId) {
            data: recipients(
              options: {
                startRow: $startRow
                endRow: $endRow
                sorting: $sortModel
                filters: $filters
              }
            ) {
              id
              uniqueReference
              salutation
              title
              firstname
              lastname
              street
              streetNumber
              city
              zip
              rawData
            }
          }
        }
      }
    }
  }
`
