import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { portalTheme } from 'pyrexx-react-library'
import SimpleButton from '../../../components/Simple/SimpleButton'

const WrapInAButton = ({ step, currentStep, setCurrentStep, children }) => {
  if (parseInt(currentStep) > step) {
    return (
      <SimpleButton onClick={() => setCurrentStep(step)}>
        {children}
      </SimpleButton>
    )
  } else {
    return children
  }
}

export const WizardProgressBar = (props) => {
  const { currentStep = 1, setCurrentStep = () => {} } = props

  const { t } = useTranslation()

  const getStatusColor = (step) => {
    if (parseInt(currentStep) > step || currentStep === 'success') {
      return portalTheme.color.successColor
    } else if (parseInt(currentStep) === step) {
      return portalTheme.color.warningColor
    } else {
      return portalTheme.color.disabledColor
    }
  }

  return (
    <Container fluid>
      <Row justify='center' align='center' nogutter>
        <Col xs={3}>
          <Row nogutter justify='center' align='center'>
            <Col xs='content'>
              <Dot color={getStatusColor(1)} />
            </Col>
            <Col>
              <Line />
            </Col>
          </Row>
          <Row nogutter justify='start' align='center'>
            <Col xs='content'>
              <WrapInAButton
                step={1}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              >
                {t('COMMISSION REASON')}
              </WrapInAButton>
            </Col>
          </Row>
        </Col>

        <Col xs={3}>
          <Row nogutter justify='center' align='center'>
            <Col>
              <Line />
            </Col>
            <Col xs='content'>
              <Dot color={getStatusColor(2)} />
            </Col>
            <Col>
              <Line />
            </Col>
          </Row>
          <Row nogutter justify='center' align='center'>
            <Col xs='content'>
              <WrapInAButton
                step={2}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              >
                {t('CONNECTIONS')}
              </WrapInAButton>
            </Col>
          </Row>
        </Col>

        <Col xs={3}>
          <Row nogutter justify='center' align='center'>
            <Col>
              <Line />
            </Col>
            <Col xs='content'>
              <Dot color={getStatusColor(3)} />
            </Col>
            <Col>
              <Line />
            </Col>
          </Row>
          <Row nogutter justify='center' align='center'>
            <Col xs='content'>
              <WrapInAButton
                step={3}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              >
                {t('COMMISSION DETAILS')}
              </WrapInAButton>
            </Col>
          </Row>
        </Col>

        <Col xs={3}>
          <Row nogutter justify='center' align='center'>
            <Col>
              <Line />
            </Col>
            <Col xs='content'>
              <Dot color={getStatusColor(4)} />
            </Col>
          </Row>
          <Row nogutter justify='end' align='center'>
            <Col xs='content'>{t('SEND COMMISSION')}</Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export const Dot = styled.div`
  height: 35px;
  width: 35px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
`

export const Line = styled.div`
  height: 3px;
  width: 100%;
  background-color: ${portalTheme.color.linkColorSecondaryActive};
`
