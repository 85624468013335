import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAgGridUltra } from '../../../../../../../components/AgGrid/AgGridUltra/hooks/useAgGridUltra'
import { useTenantsGrid } from './gridConfigHooks/useTenantsGrid'

const TenantsGrid = (props) => {
  const { setConnectedIds = () => {}, connectedIds, noRemove } = props
  const { t } = useTranslation()

  const grids = [
    {
      id: 'link-ids-tenants-grid',
      label: t('INHABITANTS'),
      downloadFileName: t('INHABITANTS'),
      useGetParams: useTenantsGrid(setConnectedIds, noRemove),
    },
  ]

  const { generateGrid, setFilterVariables, setSelectedRows } = useAgGridUltra(
    'LinkIdsTenants',
    grids,
    { connectedIds },
    false,
    false,
    3,
    true,
    true,
    true
  )
  useEffect(() => {
    console.log('REFRESH force', connectedIds)
    setSelectedRows([])
    setFilterVariables({
      connectedIds,
    })
  }, [connectedIds, setFilterVariables, setSelectedRows])

  return generateGrid()
}

export default TenantsGrid
