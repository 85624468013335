import {
  columnCheckbox,
  withFilterParams,
} from '../../../components/AgGrid/columnDefs'
import { CountUsageUnitsQuery } from '../queries'
import { useTranslation } from 'react-i18next'
import {
  UsageUnitLockPeriodReasonRenderer,
  UsageUnitLockPeriodTimeOfLockRenderer,
  UsageUnitReferenceNumberRenderer,
  UsageUnitTypeRenderer,
} from '../renderer'
import LockedUsageUnitsGridQuery from '../queries/LockedUsageUnitsGridQuery'
import { tenantFullNameValueGetter } from '../../Contracts/valueGetter'

export const useLockedUsageUnits = () => {
  const { t } = useTranslation()

  const getParams = (currentService) => {
    const LockedUsageUnitsColumnDefs = [
      columnCheckbox,
      {
        headerName: t('ALLOCATION NUMBER'),
        field: 'referenceNumber',
        cellRenderer: UsageUnitReferenceNumberRenderer,
        ...withFilterParams,
      },
      {
        headerName: t('USAGE UNIT TYPE'),
        field: 'type',
        width: 70,
        cellRenderer: UsageUnitTypeRenderer,
        filter: false,
      },
      {
        headerName: t('RESIDENT'),
        field: 'tenants',
        filter: false,
        sort: false,
        valueGetter: ({ data }) =>
          tenantFullNameValueGetter({ value: data.tenants }),
      },
      {
        headerName: t('LOCK REASON'),
        colId: 'lockPeriod.reason',
        ...withFilterParams,
        cellRenderer: UsageUnitLockPeriodReasonRenderer,
      },
      {
        headerName: t('LOCK INITIATOR'),
        colId: 'lockPeriod.initiator',
        ...withFilterParams,
        valueGetter: ({ data }) => data.lockPeriods[0]?.initiator,
      },
      {
        headerName: t('STORAGE DATE'),
        colId: 'lockPeriod.begin',
        field: 'lockPeriods',
        cellRenderer: UsageUnitLockPeriodTimeOfLockRenderer,
        filter: 'agDateColumnFilter',
        ...withFilterParams,
        sort: 'desc',
      },
    ]

    const LockedUsageUnitsCustomFilters = [
      {
        name: 'contract.serviceCategory.tableId',
        filterType: 'text',
        operator: 'AND',
        conditions: [
          {
            value: [currentService.tableId],
            type: 'equals',
          },
        ],
      },
      {
        name: 'lockStatus',
        filterType: 'text',
        operator: 'AND',
        conditions: [
          {
            value: 'COMPLETELY_LOCKED',
            type: 'equals',
          },
        ],
      },
    ]

    return {
      query: LockedUsageUnitsGridQuery,
      columnDefs: LockedUsageUnitsColumnDefs,
      customFilters: LockedUsageUnitsCustomFilters,
      countQuery: CountUsageUnitsQuery,
      extractor: (response) => response.Me?.Company?.data,
      customVariables: {},
    }
  }

  return { getParams }
}
