import React, { useContext } from 'react'

import SimpleLink from '../../../components/Simple/SimpleLink'

import UserContext from '../../../helper/userContext'
import { Routes } from '../../../configs/RouteConfig'

const UsageUnitAppointmentLinkRenderer = (props) => {
  const { value, data } = props

  const { currentLanguage } = useContext(UserContext)

  return (
    <SimpleLink
      to={
        '/' +
        currentLanguage +
        Routes.usageUnit +
        '/' +
        data?.usageUnit?.tableId
      }
      style={{ fontSize: 'inherit' }}
    >
      {value}
    </SimpleLink>
  )
}

export default UsageUnitAppointmentLinkRenderer
