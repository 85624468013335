import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { css } from 'styled-components'

import notificationImg from '../../../assets/overview/notification.png'

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  position: relative;
`

const Indicator = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  right: 0;

  width: 13px;
  height: 13px;

  background-color: var(--pyrexx-red);
  color: #fff;
  border-radius: 50%;

  font-size: 9px;
  font-weight: bold;

  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;

  ${(props) =>
    props.hasNewNotifications &&
    css`
      visibility: visible;
      opacity: 1;
      transition: visibility 0s linear 0s, opacity 300ms;
    `}
`

const Img = styled.img`
  width: 35px;
  height: 35px;
`

const HeaderNotifications = ({ hasNewNotifications = false }) => {
  return (
    <Wrapper>
      <Indicator hasNewNotifications={hasNewNotifications} />

      <Img src={notificationImg} width={35} height={35} alt='notifications' />
    </Wrapper>
  )
}

HeaderNotifications.propTypes = {
  hasNewNotifications: PropTypes.bool,
}

export default HeaderNotifications
