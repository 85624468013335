import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query TicketsOverviewQuery(
    $companyId: ID!
    $dateFilter: [Condition]!
    $channelFilter: [Condition]!
  ) {
    Me {
      Company(id: $companyId) {
        OrderWizard {
          tickets {
            creationDate
          }
          channels {
            tableId
            name
          }
          closed: countTickets(
            options: {
              filters: [
                {
                  name: "status"
                  filterType: text
                  operator: AND
                  conditions: [{ value: "CLOSED", type: equals }]
                }
                {
                  name: "creationDate"
                  filterType: date
                  operator: AND
                  conditions: $dateFilter
                }
                {
                  name: "channel.id"
                  filterType: text
                  operator: AND
                  conditions: $channelFilter
                }
              ]
            }
          )
          open: countTickets(
            options: {
              filters: [
                {
                  name: "status"
                  filterType: text
                  operator: AND
                  conditions: [{ value: "OPEN", type: equals }]
                }
                {
                  name: "creationDate"
                  filterType: date
                  operator: AND
                  conditions: $dateFilter
                }
                {
                  name: "channel.id"
                  filterType: text
                  operator: AND
                  conditions: $channelFilter
                }
              ]
            }
          )
        }
      }
    }
  }
`
