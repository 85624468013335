/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConditionType = "contains" | "endsWith" | "equals" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "lessThan" | "lessThanOrEqual" | "notContains" | "notEqual" | "startsWith" | "%future added value";
export type FilterType = "date" | "number" | "text" | "%future added value";
export type InvoiceStatusType = "CANCELLED" | "OPEN" | "PAID" | "REMINDED" | "%future added value";
export type OperatorType = "AND" | "OR" | "%future added value";
export type Sort = {|
  colId?: ?string,
  sort?: ?string,
|};
export type Filter = {|
  name: string,
  filterType: FilterType,
  operator: OperatorType,
  denySelection?: ?boolean,
  conditions: $ReadOnlyArray<?Condition>,
|};
export type Condition = {|
  value: $ReadOnlyArray<?string>,
  type: ConditionType,
|};
export type InvoicesLinkIdGridQueryVariables = {|
  startRow?: ?number,
  endRow?: ?number,
  sortModel?: ?$ReadOnlyArray<?Sort>,
  filters?: ?$ReadOnlyArray<?Filter>,
  companyId: string,
|};
export type InvoicesLinkIdGridQueryResponse = {|
  +Me: ?{|
    +id: string,
    +Company: ?{|
      +data: ?$ReadOnlyArray<?{|
        +id: string,
        +tableId: number,
        +name: ?string,
        +download: number,
        +status: ?InvoiceStatusType,
        +price: ?{|
          +net: number,
          +gross: number,
          +percentage: $ReadOnlyArray<number>,
          +vat: number,
        |},
        +type: ?number,
        +invoiceNumber: ?string,
        +description: ?string,
        +invoiceDate: ?any,
        +value: ?any,
        +paymentTarget: ?any,
        +remark: ?string,
        +contract: ?{|
          +id: string,
          +tableId: number,
          +date: ?any,
          +name: ?string,
          +serviceCategory: ?{|
            +id: string,
            +tableId: number,
            +name: string,
          |},
        |},
      |}>
    |},
  |}
|};
export type InvoicesLinkIdGridQuery = {|
  variables: InvoicesLinkIdGridQueryVariables,
  response: InvoicesLinkIdGridQueryResponse,
|};
*/


/*
query InvoicesLinkIdGridQuery(
  $startRow: Int
  $endRow: Int
  $sortModel: [Sort]
  $filters: [Filter]
  $companyId: ID!
) {
  Me {
    id
    Company(id: $companyId) {
      data: Invoices(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {
        id
        tableId
        name
        download: tableId
        status
        price {
          net
          gross
          percentage
          vat
        }
        type
        invoiceNumber
        description
        invoiceDate
        value
        paymentTarget
        remark
        contract: Contract {
          id
          tableId
          date
          name
          serviceCategory {
            id
            tableId
            name
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endRow"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortModel"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startRow"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": "data",
  "args": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "endRow",
          "variableName": "endRow"
        },
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters"
        },
        {
          "kind": "Variable",
          "name": "sorting",
          "variableName": "sortModel"
        },
        {
          "kind": "Variable",
          "name": "startRow",
          "variableName": "startRow"
        }
      ],
      "kind": "ObjectValue",
      "name": "options"
    }
  ],
  "concreteType": "OutgoingInvoice",
  "kind": "LinkedField",
  "name": "Invoices",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    {
      "alias": "download",
      "args": null,
      "kind": "ScalarField",
      "name": "tableId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceInfo",
      "kind": "LinkedField",
      "name": "price",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "net",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gross",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "percentage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vat",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentTarget",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remark",
      "storageKey": null
    },
    {
      "alias": "contract",
      "args": null,
      "concreteType": "Contract",
      "kind": "LinkedField",
      "name": "Contract",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        },
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ServiceCategory",
          "kind": "LinkedField",
          "name": "serviceCategory",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InvoicesLinkIdGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "InvoicesLinkIdGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bb28b5a696e08f7238511571836f625b",
    "id": null,
    "metadata": {},
    "name": "InvoicesLinkIdGridQuery",
    "operationKind": "query",
    "text": "query InvoicesLinkIdGridQuery(\n  $startRow: Int\n  $endRow: Int\n  $sortModel: [Sort]\n  $filters: [Filter]\n  $companyId: ID!\n) {\n  Me {\n    id\n    Company(id: $companyId) {\n      data: Invoices(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {\n        id\n        tableId\n        name\n        download: tableId\n        status\n        price {\n          net\n          gross\n          percentage\n          vat\n        }\n        type\n        invoiceNumber\n        description\n        invoiceDate\n        value\n        paymentTarget\n        remark\n        contract: Contract {\n          id\n          tableId\n          date\n          name\n          serviceCategory {\n            id\n            tableId\n            name\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8eb28808b6f174efda1d632b2625e405';

module.exports = node;
