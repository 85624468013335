import React, { useContext } from 'react'

import SimpleLink from '../../../../../../../../components/Simple/SimpleLink'

import { Routes } from '../../../../../../../../configs/RouteConfig'
import UserContext from '../../../../../../../../helper/userContext'
import handleLocalStorage from '../../../../../../../../helper/handleLocalStorage'

const UsageUnitIdRenderer = (props) => {
  const { value } = props

  const { currentLanguage } = useContext(UserContext)

  // TODO improve
  const saveOrigin = () => {
    handleLocalStorage('set', 'usageUnitOrigin', 'usageUnit')
  }

  return (
    <SimpleLink
      to={'/' + currentLanguage + Routes.usageUnit + '/' + value}
      style={{ fontSize: 'inherit' }}
      onClick={saveOrigin}
    >
      {value}
    </SimpleLink>
  )
}

export default UsageUnitIdRenderer
