export const UN_LOCKING = {
  channel: 'Inventory Channel',
  topic: 'Usage Unit locking Topic',
}

export const CHANGE_TENANT = {
  channel: 'Inventory Channel',
  topic: 'Inhabitants Topic',
  reason: 'Inhabitant change',
  reasonId: '7',
}
