import React from 'react'
import PropTypes from 'prop-types'
import { Col, Container, Row } from 'react-grid-system'
import styled from 'styled-components/macro'

import PropertyManagementMap from './PropertyManagementMap'
import PropertyManagementSidebar from './PropertyManagementSidebar'

const OperationsOverview = (props) => {
  const { loading = true, houseEntranceCoordinates = {} } = props

  return (
    <Container>
      <Row>
        <DefaultCol>
          <Row style={{ height: '100%' }}>
            <MapCol xs={9}>
              <PropertyManagementMap
                loading={loading}
                houseEntranceCoordinates={houseEntranceCoordinates}
              />
            </MapCol>

            <Col xs={3}>
              <PropertyManagementSidebar />
            </Col>
          </Row>
        </DefaultCol>
      </Row>
    </Container>
  )
}

const DefaultCol = styled(Col)`
  background-color: #fff;
`

const MapCol = styled(Col)`
  padding: 0 !important;
  border-right: 1px solid var(--pyrexx-dropdown-badge-color, #c5cfda);
`

OperationsOverview.propTypes = {
  loading: PropTypes.bool,
}

export default OperationsOverview
