import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../../../../environments/pom/Environment'

const mutation = graphql`
  mutation DiscardTicketMutation($companyId: ID!, $ticketId: ID!) {
    Me {
      Company(id: $companyId) {
        OrderWizard {
          ticket(id: $ticketId) {
            discard
          }
        }
      }
    }
  }
`

export default ({ companyId, ticketId }, accessToken, onCompleted, onError) => {
  commitMutation(environment(accessToken), {
    mutation,
    variables: { companyId, ticketId },
    onCompleted,
    onError,
  })
}
