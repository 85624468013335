import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'react-grid-system'

import {
  ButtonPrimary,
  ButtonSecondary,
  Icon,
  SimpleModal,
} from 'pyrexx-react-library'

import { useAgGridUltra } from '../../components/AgGrid/AgGridUltra/hooks/useAgGridUltra'
import { useOpenOperationsGrid } from './gridConfigHooks/useOpenOperationsGrid'
import { useClosedOperationsGrid } from './gridConfigHooks/useClosedOperationsGrid'

import FireLoadsMarkAsDoneMutation from './mutations/FireLoadsMarkAsDoneMutation'
import { LinkButtonStyled } from '../../styles/HelperStylesComponents.styles'
import { useUserContext } from '../../helper/userContext'

const OperationsGrids = () => {
  const { t } = useTranslation()
  const { user } = useUserContext()

  const [modalOoen, setModalOpen] = useState(false)
  const [updatingRows, setUpdatingRows] = useState(false)

  const generateSetAllDoneLink = (selectedRows, setForceRefresh, gridApi) => {
    return (
      <LinkButtonStyled
        onClick={() => {
          gridApi.forEachNode((node) => {
            node.setSelected(true)
          })
          setModalOpen(true)
        }}
        style={{ marginRight: '1rem' }}
      >
        {t('MARK AS DONE')}
        <Icon
          icon='bullet-point'
          style={{ marginLeft: '.25rem' }}
          size='16px'
        />
      </LinkButtonStyled>
    )
  }

  const grids = [
    {
      id: 'open-operations',
      label: t('OPEN'),
      useGetParams: useOpenOperationsGrid(),
      customActionCenterItems: [
        { type: 'support' },
        {
          type: 'labelSeparator',
          label: t('FIRE LOAD STATUS CHANGE'),
        },
        /**
         {
          type: 'custom',
          disabled: false,
          icon: 'bullet-point',
          label: t("FORWARDED TO EXECUTOR"),
        },
         {
          type: 'custom',
          disabled: false,
          icon: 'bullet-point',
          label: t("PROCESSING PENDING"),
        },
         {
          type: 'custom',
          disabled: false,
          icon: 'bullet-point',
          label: t("FIXED"),
        },
         **/
        {
          type: 'custom',
          disabled: false,
          icon: 'bullet-point',
          label: t('DONE'),
          onClick: (selectedRows, setForceRefresh) => {
            setModalOpen(true)
          },
        },
        {
          type: 'labelSeparator',
          label: t('LIST EXPORT'),
        },
        { type: 'exportWholeList' },
        { type: 'exportSelected' },
      ],
      extraItems: generateSetAllDoneLink,
    },
    {
      id: 'closed-operations',
      label: t('COMPLETED'),
      useGetParams: useClosedOperationsGrid(),
      customActionCenterItems: [
        /**
         { type: 'support' },
         {
          type: 'labelSeparator',
          label: t("FIRE LOAD STATUS CHANGE"),
        },

         {
          type: 'custom',
          disabled: false,
          icon: 'bullet-point',
          label: t("FORWARDED TO EXECUTOR"),
        },

         {
          type: 'custom',
          disabled: false,
          icon: 'bullet-point',
          label: t("PROCESSING PENDING"),
          onClick: (selectedRows, setForceRefresh) => {
            const ids = selectedRows.map((item) => {
              return item.id.split("::")[2]
            })
            if (ids.length === 0) {
              return
            }
            setUpdatingRows(true)
            const variables = {
              ids: ids,
              companyId: user.companyId,
            }
            FireLoadsMarkAsPendingMutation(
              variables,
              user.accessToken,
              (data) => {
                setForceRefresh(true)
                setUpdatingRows(false)
                console.log('finished set as pending', ids, data)
              }
            )
            console.log('click', selectedRows, ids)
          },
        },
         {
          type: 'custom',
          disabled: false,
          icon: 'bullet-point',
          label: t("FIXED"),
        },
         **/
        {
          type: 'labelSeparator',
          label: t('LIST EXPORT'),
        },
        { type: 'exportWholeList' },
        { type: 'exportSelected' },
      ],
    },
  ]

  const {
    generateGrid,
    generatePills,
    selectedRows,
    setForceRefresh,
    setSelectedRows,
  } = useAgGridUltra('Operations', grids)

  const handleConfirm = () => {
    const ids = selectedRows.map((item) => {
      return item.id.split('::')[2]
    })

    if (ids.length === 0) {
      return
    }

    setUpdatingRows(true)

    const variables = {
      ids: ids,
      companyId: user.companyId,
    }

    FireLoadsMarkAsDoneMutation(variables, user.accessToken, (data) => {
      setSelectedRows([])
      setForceRefresh(true)
      setUpdatingRows(false)
    })

    setModalOpen(false)
  }

  return (
    <>
      {generatePills()}
      {generateGrid(updatingRows)}

      <SimpleModal
        isOpen={modalOoen}
        closeOnOutsideClick
        handleModalClose={() => setModalOpen(false)}
        style={{ maxWidth: '75%' }}
      >
        <Row justify='center'>
          <Col xs='content'>
            <h3>{t('SET SELECTED FIRELOADS AS DONE?')}</h3>
          </Col>
        </Row>

        <Row>
          <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonPrimary onClick={handleConfirm}>
              {t('CONFIRM')}
            </ButtonPrimary>
          </Col>

          <Col>
            <ButtonSecondary onClick={() => setModalOpen(false)}>
              {t('CANCEL')}
            </ButtonSecondary>
          </Col>
        </Row>
      </SimpleModal>
    </>
  )
}

export default OperationsGrids
