import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import PyrexxOverview from '../../../components/PyrexxOverview'
import QueryRendererContainer from '../../../components/QueryRendererContainer'

import TicketsOverviewQuery from './queries/TicketsOverviewQuery'

import { useUserContext } from '../../../helper/userContext'
import { getCreationDateTimeframes } from './helpers/getCreationDateTimeframes'

const TicketsOverview = ({ setFilterModel = () => {} }) => {
  const { user } = useUserContext()
  const { t } = useTranslation()

  const defaultFilters = {
    companyId: user.companyId,
    dateFilter: [],
    channelFilter: [],
  }

  const customKeys = {
    date: 'creationDate',
  }

  const [variables, setVariables] = useState({})

  const { titleElements, rowElements } = getElements(null, t)

  return (
    <QueryRendererContainer
      query={TicketsOverviewQuery}
      variables={{
        ...defaultFilters,
        ...variables,
      }}
      loading={
        <PyrexxOverview
          overviewFilterType='date-channel'
          customKeys={customKeys}
          titleElements={titleElements}
          rowElements={rowElements}
          setFilterModel={setFilterModel}
          setVariables={setVariables}
          variant='div'
          loading
        />
      }
      render={(data) => {
        const tickets = data?.Me?.Company?.OrderWizard?.tickets || []
        const channels = data?.Me?.Company?.OrderWizard?.channels || []
        const statuses = data?.Me?.Company?.OrderWizard

        const { titleElements, rowElements } = getElements(statuses, t)

        const timeframes = getCreationDateTimeframes(tickets)

        const overviewFilterData = {
          date: timeframes,
          channel: channels,
        }

        return (
          <PyrexxOverview
            overviewFilterType='date-channel'
            overviewFilterData={overviewFilterData}
            customKeys={customKeys}
            titleElements={titleElements}
            rowElements={rowElements}
            setFilterModel={setFilterModel}
            setVariables={setVariables}
            variant='div'
          />
        )
      }}
    />
  )
}

const getElements = (statuses = {}, t) => {
  const {
    closed = 0,
    open = 0,
    // needWork = 0
  } = statuses || {}

  const titleElements = [
    { id: 'closed', label: t('CLOSED') },
    { id: 'open', label: t('OPEN') },
    // { id: 'need-work', label: t('NEED WORK') },
  ]

  const rowElements = [
    {
      id: 'default',
      columns: [
        {
          id: 'closed',
          label: closed,
          status: 'success',
          // filter: {
          //   filterId: 'box-closed',
          //   filterRow: 'default',
          //   label: t('CLOSED'),
          //   // filterModel: filterPaid,
          // },
        },
        {
          id: 'open',
          label: open,
          status: 'warning',
          // filter: {
          //   filterId: 'box-open',
          //   filterRow: 'default',
          //   label: t('OPEN'),
          //   // filterModel: filterOpen,
          // },
        },
        // {
        //   id: 'needWork',
        //   label: needWork,
        //   status: 'error',
        //   filter: {
        //     filterId: 'box-needWork',
        //     filterRow: 'default',
        //     label: t('NEED WORK'),
        //     // filterModel: filterReminded,
        //   },
        // },
      ],
    },
  ]

  return {
    titleElements,
    rowElements,
  }
}

export default TicketsOverview
