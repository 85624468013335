import React from 'react'

import PageContainer from '../../../components/PageContainer'
import QuotaOverview from './QuotaOverview'
import QuotaGrid from './QuotaGrid'
import Spacer from '../../../components/Spacer'

const Quota = () => {
  return (
    <PageContainer>
      <QuotaOverview />
      <Spacer />
      <QuotaGrid />
    </PageContainer>
  )
}

export default Quota
