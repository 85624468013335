import React, { useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { Icon, portalTheme } from 'pyrexx-react-library'
import EmptyButton from '../../../../components/Simple/EmptyButton'
import SimpleLink from '../../../../components/Simple/SimpleLink'

import formatDateLocale from '../../../../helper/formatDateLocale'
import { useUserContext } from '../../../../helper/userContext'

export const OrderBox = (props) => {
  const { creationDate, description, createdBy, ticketNumber } = props

  const { currentLanguage } = useUserContext()
  const { t } = useTranslation()

  const formattedCreationDate = formatDateLocale(
    currentLanguage,
    creationDate,
    'Pp'
  )

  return (
    <Container
      style={{
        backgroundColor: portalTheme.color.basicColorLightGrey1,
        marginBottom: '0.5rem',
        borderRadius: '10px',
      }}
      fluid
    >
      <Row justify='end' style={{ paddingTop: '0.5rem' }}>
        <Col xs='content'>{formattedCreationDate}</Col>
      </Row>

      <Row justify='start' style={{ marginTop: '0.5rem', fontWeight: 'bold' }}>
        <Col xs='content'>{t('COMMISSIONING REASON')}</Col>
      </Row>
      <Row justify='start'>
        <Col xs='content'>
          <SimpleLink
            to={`/de/support/ticket/${ticketNumber}`}
            font='quicksand'
          >
            {description}
          </SimpleLink>
        </Col>
      </Row>

      <Row justify='start' style={{ marginTop: '0.5rem', fontWeight: 'bold' }}>
        <Col xs='content'>{t('CLIENT')}</Col>
      </Row>
      <Row justify='start' style={{ paddingBottom: '0.5rem' }}>
        <Col xs='content'>{createdBy}</Col>
      </Row>
    </Container>
  )
}

export const RecentlyCreatedTickets = (props) => {
  const { recentlyCreatedTickets = [] } = props

  const { t } = useTranslation()

  const [isDropdownOpen, setIsDropdownOpen] = useState(true)
  const toggleDropdown = () => setIsDropdownOpen((prevState) => !prevState)

  return (
    <div
      style={{
        backgroundColor: portalTheme.color.basicColorWhite,
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
      }}
    >
      <Container fluid style={{ padding: '0.5rem' }}>
        <Row
          nogutter
          style={{ paddingTop: '0.5rem', marginBottom: '0.5rem' }}
          justify='start'
          align='center'
        >
          <Col
            xs='content'
            style={{
              paddingRight: '0.3rem',
              display: 'flex',
            }}
          >
            <Icon
              icon='bell'
              size='30px'
              color={portalTheme.color.basicColorBlack}
            />
          </Col>
          <Col
            xs='content'
            style={{
              display: 'flex',
            }}
          >
            {t('LAST CREATED')}
          </Col>
          <Col
            style={{
              paddingRight: '0.3rem',
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <LabelContainer
              onClick={toggleDropdown}
              aria-haspopup='true'
              aria-expanded={isDropdownOpen}
            >
              <Label>
                {isDropdownOpen ? (
                  <Icon
                    icon='arrow-down'
                    size='30px'
                    color={portalTheme.color.linkColorPrimary}
                  />
                ) : (
                  <Icon
                    icon='arrow-up'
                    size='30px'
                    color={portalTheme.color.linkColorPrimary}
                  />
                )}
              </Label>
            </LabelContainer>
          </Col>
        </Row>

        <Row nogutter>
          <Col>
            {isDropdownOpen &&
              recentlyCreatedTickets.map((ticket) => (
                <OrderBox
                  key={ticket.id}
                  creationDate={ticket.creationDate}
                  ticketNumber={ticket.tableId}
                  description={
                    ticket.channel.name +
                    ' - ' +
                    ticket.topic.name +
                    ' - ' +
                    ticket.reason.name
                  }
                  createdBy={ticket.user.firstname + ' ' + ticket.user.lastname}
                />
              ))}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const LabelContainer = styled(EmptyButton)`
  // Do this so the button uses all available width
`

const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  transition: 200ms ease-out;
`
