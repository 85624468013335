/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LetterRecipientsQueryVariables = {|
  companyId: string,
  letterId: string,
|};
export type LetterRecipientsQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +businessPost: ?{|
        +letter: ?{|
          +layout: string,
          +variables: ?$ReadOnlyArray<?string>,
          +data: ?$ReadOnlyArray<?{|
            +id: string,
            +uniqueReference: string,
            +salutation: string,
            +title: string,
            +firstname: string,
            +lastname: string,
            +street: string,
            +streetNumber: string,
            +city: string,
            +zip: string,
            +rawData: ?any,
          |}>,
        |}
      |}
    |}
  |}
|};
export type LetterRecipientsQuery = {|
  variables: LetterRecipientsQueryVariables,
  response: LetterRecipientsQueryResponse,
|};
*/


/*
query LetterRecipientsQuery(
  $companyId: ID!
  $letterId: ID!
) {
  Me {
    Company(id: $companyId) {
      businessPost {
        letter(id: $letterId) {
          layout
          variables
          data: recipients {
            id
            uniqueReference
            salutation
            title
            firstname
            lastname
            street
            streetNumber
            city
            zip
            rawData
          }
          id
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "letterId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "letterId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "layout",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "variables",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": "data",
  "args": null,
  "concreteType": "LetterRecipient",
  "kind": "LinkedField",
  "name": "recipients",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uniqueReference",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salutation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "street",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "streetNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zip",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rawData",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LetterRecipientsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BusinessPost",
                "kind": "LinkedField",
                "name": "businessPost",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "concreteType": "Letter",
                    "kind": "LinkedField",
                    "name": "letter",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LetterRecipientsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BusinessPost",
                "kind": "LinkedField",
                "name": "businessPost",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "concreteType": "Letter",
                    "kind": "LinkedField",
                    "name": "letter",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v6/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "79e23902e08bf34d9f46b894d8d6e104",
    "id": null,
    "metadata": {},
    "name": "LetterRecipientsQuery",
    "operationKind": "query",
    "text": "query LetterRecipientsQuery(\n  $companyId: ID!\n  $letterId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      businessPost {\n        letter(id: $letterId) {\n          layout\n          variables\n          data: recipients {\n            id\n            uniqueReference\n            salutation\n            title\n            firstname\n            lastname\n            street\n            streetNumber\n            city\n            zip\n            rawData\n          }\n          id\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2f96f13dabc0e321bf81084ba522a0ed';

module.exports = node;
