import React from 'react'
import styled from 'styled-components/macro'
import { css } from 'styled-components'

const Dropleft = ({ children, ...rest }) => {
  return (
    <DropleftContainer {...rest}>
      <DropleftBox>{children}</DropleftBox>
    </DropleftContainer>
  )
}

export const DropleftContainer = styled.div`
  border-top: 2px solid var(--pyrexx-link, #4AB4E2);

  position: absolute;
  top: 65%;
  ${({ align }) => {
    if (align === 'right') return 'left: calc(-300px + 5px);'
    if (align === 'left') return 'right: calc(-300px + 5px);'
  }}

  box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);

  opacity: 0;
  transform: scale(0);
  transition: 250ms ease-in-out;
  transform-origin: ${({ align }) => {
    if (align === 'right') return 'right top;'
    if (align === 'left') return 'left top;'
  }};

  ${(props) =>
    props.dropleft === props.filterId &&
    css`
      opacity: 1;
      transform: scale(1);
    `};
`

export const DropleftBox = styled.div`
  display: flex;
  flex-direction: column;

  background-color: #fff;
  padding: 24px;

  width: 300px;
`

export default Dropleft
