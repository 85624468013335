import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Container, Row } from 'react-grid-system'
import styled from 'styled-components/macro'
import * as Throttle from 'promise-parallel-throttle'
import { fetchQuery } from 'react-relay'

import PropertyManagementMap from './PropertyManagementMap'
import PropertyManagementSidebar from './PropertyManagementSidebar'

import { useUserContext } from '../../helper/userContext'
import environment from '../../environments/pom/Environment'
import PropertyManagementGetHouseEntranceCoordinatesQuery from './queries/PropertyManagementGetHouseEntranceCoordinates'

const PropertyUnitOverview = (props) => {
  const { loading = true, houseEntrancesCount = 0 } = props

  const { user } = useUserContext()

  const [houseEntranceCoordinates, setHouseEntranceCoordinates] = useState({
    loading: true,
    coordinates: [],
  })

  const getHouseEntranceCoordinates = useCallback(() => {
    const promises = []
    const chunkSize = 5000

    const chunks = Math.ceil(houseEntrancesCount / chunkSize)
    for (let i = 0; i < chunks; i++) {
      const start = i * (chunkSize + 1)
      const end = start + chunkSize
      const CoordinatesVariables = {
        companyId: user.companyId,
        startRow: start,
        endRow: end,
      }

      promises.push(() => {
        return fetchQuery(
          environment(user.accessToken),
          PropertyManagementGetHouseEntranceCoordinatesQuery,
          CoordinatesVariables,
          {
            force: true,
          }
        )
          .then((res) => {
            return res.Me?.Company?.HouseEntrances
          })
          .catch((e) => {
            console.log(e)
          })
      })
    }

    const currentCoordinates = { coordinates: [], address: [] }

    Throttle.all(promises)
      .then((houses) => {
        houses.flat(1).forEach((house) => {
          currentCoordinates.coordinates.push([
            house.coordinates.lat,
            house.coordinates.lng,
          ])
          currentCoordinates.address.push({
            address: house.address,
            tableId: house.tableId,
            countUnits: house.countUsageUnits,
          })
        })
      })
      .then(() => {
        setHouseEntranceCoordinates({
          loading: false,
          coordinates: currentCoordinates,
        })
      })
      .catch((e) => {
        console.log(e)
      })
  }, [houseEntrancesCount, user.accessToken, user.companyId])

  useEffect(() => {
    if (!loading && houseEntranceCoordinates.loading) {
      getHouseEntranceCoordinates()
    }
  }, [getHouseEntranceCoordinates, houseEntranceCoordinates, loading])

  return (
    <Container>
      <Row>
        <DefaultCol>
          <Row style={{ height: '100%' }}>
            <MapCol xs={9}>
              <PropertyManagementMap
                loading={loading || houseEntranceCoordinates.loading}
                houseEntranceCoordinates={houseEntranceCoordinates.coordinates}
              />
            </MapCol>

            <Col xs={3}>
              <PropertyManagementSidebar />
            </Col>
          </Row>
        </DefaultCol>
      </Row>
    </Container>
  )
}

const DefaultCol = styled(Col)`
  background-color: #fff;
`

const MapCol = styled(Col)`
  padding: 0 !important;
  border-right: 1px solid var(--pyrexx-dropdown-badge-color, #c5cfda);
`

PropertyUnitOverview.propTypes = {
  loading: PropTypes.bool,
}

export default PropertyUnitOverview
