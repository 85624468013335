import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { ButtonSecondary } from 'pyrexx-react-library'
import CloseTicketMutation from '../../mutations/CloseTicketMutation'
import { useUserContext } from '../../../../../helper/userContext'

const CloseTicketRenderer = ({ data = {}, refreshGrid = () => {} }) => {
  const { t } = useTranslation()
  const { user } = useUserContext()

  const [loading, setLoading] = useState(false)

  const companyId = user.companyId || ''
  const accessToken = user.accessToken || ''
  const ticketId = data?.tableId

  const handleClick = () => {
    setLoading(true)

    CloseTicketMutation(
      { companyId, ticketId },
      accessToken,
      () => {
        setLoading(false)
        refreshGrid(true)
      },
      () => {
        setLoading(false)
      }
    )
  }

  return (
    <StyledButtonSecondary onClick={handleClick} isLoading={loading}>
      {t('CLOSE TICKET')}
    </StyledButtonSecondary>
  )
}

const StyledButtonSecondary = styled(ButtonSecondary)`
  padding: 0 24px;
  margin: 0 auto;

  height: 32px;

  & > div {
    scale: 0.75;
  }
`

export default CloseTicketRenderer
