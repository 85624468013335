/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type InvoiceDownloadQueryVariables = {|
  invoiceId: string,
  companyId: string,
|};
export type InvoiceDownloadQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +id: string,
      +Invoice: ?{|
        +id: string,
        +download: ?{|
          +name: string,
          +path: string,
          +token: string,
        |},
      |},
    |}
  |}
|};
export type InvoiceDownloadQuery = {|
  variables: InvoiceDownloadQueryVariables,
  response: InvoiceDownloadQueryResponse,
|};
*/


/*
query InvoiceDownloadQuery(
  $invoiceId: ID!
  $companyId: ID!
) {
  Me {
    Company(id: $companyId) {
      id
      Invoice(id: $invoiceId) {
        id
        download {
          name
          path
          token
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "invoiceId"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "companyId"
    }
  ],
  "concreteType": "Company",
  "kind": "LinkedField",
  "name": "Company",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "invoiceId"
        }
      ],
      "concreteType": "OutgoingInvoice",
      "kind": "LinkedField",
      "name": "Invoice",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "DownloadDetails",
          "kind": "LinkedField",
          "name": "download",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "path",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "token",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InvoiceDownloadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "InvoiceDownloadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e1d1bb5f236c68cfb9eb72086c38cb6a",
    "id": null,
    "metadata": {},
    "name": "InvoiceDownloadQuery",
    "operationKind": "query",
    "text": "query InvoiceDownloadQuery(\n  $invoiceId: ID!\n  $companyId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      id\n      Invoice(id: $invoiceId) {\n        id\n        download {\n          name\n          path\n          token\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '72de9e4a97c83c73fadc6c82d3b5561e';

module.exports = node;
