import React from 'react'
import { useTranslation } from 'react-i18next'

import SimpleLink from '../../components/Simple/SimpleLink'

import { useAgGridUltra } from '../../components/AgGrid/AgGridUltra/hooks/useAgGridUltra'
import { columnCheckbox } from '../../components/AgGrid/columnDefs'
import ChannelFilter from '../Support/Enquiries/grids/filters/ChannelFilter'
import TicketStatusRenderer from '../Support/Enquiries/grids/renderers/TicketStatusRenderer'
import { translateOrderWizard } from '../Support/Enquiries/translations/translateOrderWizard'
import AppointmentTicketsQuery from './queries/AppointmentTickets'

import formatDateLocale from '../../helper/formatDateLocale'
import { useUserContext } from '../../helper/userContext'

const useAppointmentTickets = (appointmentId) => {
  const { t } = useTranslation()
  const { currentLanguage } = useUserContext()

  const AppointmentTicketsColumnDefs = [
    columnCheckbox,
    {
      headerName: t('STATUS'),
      field: 'status',
      sortable: false,
      filter: false,
      cellRenderer: TicketStatusRenderer,
    },
    {
      headerName: t('TICKET NUMBER'),
      field: 'zammadTicketId',
      sortable: false,
      filter: false,
      type: 'rightAligned',
      cellRenderer: ({ value }) => (
        <SimpleLink to={`/${currentLanguage}/support/ticket/${value}`}>
          {value}
        </SimpleLink>
      ),
    },
    {
      headerName: t('CREATED DATE'),
      field: 'creationDate',
      filter: 'agDateColumnFilter',
      sort: 'desc',
      filterParams: {
        buttons: ['apply', 'clear', 'reset'],
        closeOnApply: true,
        suppressAndOrCondition: true,
      },
      valueFormatter: (params) =>
        params.value
          ? formatDateLocale(currentLanguage, params.value, 'Pp')
          : '',
    },
    {
      headerName: t('ENQUIRY REASON'),
      field: 'reason',
      sortable: false,
      filter: false,
      valueGetter: (params) =>
        t(translateOrderWizard(params.data.reason.name, t)),
    },
    {
      headerName: t('CATEGORY'),
      field: 'channel',
      sortable: false,
      filter: ChannelFilter,
      valueGetter: (params) =>
        t(translateOrderWizard(params.data.channel.name, t)),
    },
    {
      headerName: t('CREATOR NAME'),
      sortable: false,
      filter: false,
      valueGetter: (params) =>
        params.data.user.firstname + ' ' + params.data.user.lastname,
    },
  ]

  const getParams = () => {
    return {
      query: AppointmentTicketsQuery,
      columnDefs: AppointmentTicketsColumnDefs,
      // customFilters: [],
      extractor: (response) => response.Me?.Company?.Appointment?.data,
      // customGridOptions: {},
      customVariables: { appointmentId },
    }
  }

  return { getParams }
}

export const AppointmentTickets = ({ appointmentId }) => {
  const { t } = useTranslation()

  const grids = [
    {
      id: 'appointment-tickets',
      label: t('APPOINTMENT TICKETS'),
      useGetParams: useAppointmentTickets(appointmentId),
    },
  ]

  const { generateGrid } = useAgGridUltra(
    'AppointmentTickets',
    grids,
    {},
    false,
    false,
    5
  )

  return generateGrid()
}
