import React from 'react'
import styled from 'styled-components/macro'
import { css } from 'styled-components'

import { Icon, portalTheme } from 'pyrexx-react-library'
import SimpleLink from '../../../../components/Simple/SimpleLink'

export const ExternalLinkToOrderWizard = ({
  to = '/',
  label = '',
  disabled = false,
}) => {
  if (disabled) {
    return (
      <Wrapper>
        <DisabledLabel>{label}</DisabledLabel>
        <Icon
          icon='arrow-right'
          size='15px'
          color={portalTheme.color.disabledColor}
        />
      </Wrapper>
    )
  }

  return (
    <WrapperWithLink to={to}>
      <Label>{label}</Label>
      <Icon
        icon='arrow-right'
        size='15px'
        color={portalTheme.color.linkColorPrimary}
      />
    </WrapperWithLink>
  )
}

export const LinksTitle = ({ title = '' }) => {
  return <Label>{title}</Label>
}

const WrapperCSS = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  width: 100%;
  padding: 1rem 0;
`

const Wrapper = styled.div`
  ${WrapperCSS}
`

const WrapperWithLink = styled(SimpleLink)`
  ${WrapperCSS}
`

const Label = styled.div`
  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.regular};
  //font-size: 14px;
`

const DisabledLabel = styled(Label)`
  color: ${portalTheme.color.disabledColor};
`
