import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAgGridUltra } from '../../../../components/AgGrid/AgGridUltra/hooks/useAgGridUltra'
import { useRecipientsGrid } from './gridConfigHooks/useRecipientsGrid'

import defaultColumns from './helpers/defaultColumns'
import {
  generateColumns,
  filterKeysFromRawData,
} from './helpers/rawDataMethods'

const Recipients = (props) => {
  const { letterId, recipients = [] } = props
  const { t } = useTranslation()

  const getNewColumnDefinitions = useCallback(() => {
    const rawDataString = recipients[0]?.rawData || ''
    const newColumns =
      filterKeysFromRawData(rawDataString, defaultColumns) || {}
    return generateColumns(newColumns)
  }, [recipients])

  const grids = [
    {
      id: 'recipientsUpload-grid',
      label: t('UPLOADED RECIPIENTS'),
      useGetParams: useRecipientsGrid(letterId),
    },
  ]

  const { generateGrid, customGridFields, setCustomGridFields } =
    useAgGridUltra(
      'recipientsUploadGrid',
      grids,
      {},
      false,
      true,
      5,
      true,
      true
    )

  const columnDefinitionsChanged =
    JSON.stringify(getNewColumnDefinitions()) !==
    JSON.stringify(customGridFields)

  useEffect(
    () => {
      if (columnDefinitionsChanged) {
        setCustomGridFields(getNewColumnDefinitions())
      }
    }, // eslint-disable-next-line
    [customGridFields, getNewColumnDefinitions]
  )

  return generateGrid(columnDefinitionsChanged)
}

export default Recipients
