import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query HouseEntranceUsageUnitsGridQuery(
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
    $companyId: ID!
  ) {
    Me {
      Company(id: $companyId) {
        data: UsageUnits(
          options: {
            startRow: $startRow
            endRow: $endRow
            sorting: $sortModel
            filters: $filters
          }
        ) {
          id
          tableId
          referenceNumber
          floor
          position
          tenants {
            id
            tableId
            firstname
            lastname
          }
          CAN {
            one
            two
            three
            four
            five
          }
        }
      }
    }
  }
`
