import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  columnCheckbox,
  withFilterParams,
  withHiddenParams,
} from '../../../components/AgGrid/columnDefs'

import {
  AppointmentAccessRenderer,
  AppointmentBulletinWithDownloadRenderer,
  AppointmentIdRenderer,
  AppointmentStatusRenderer,
  AppointmentTypeRenderer,
  AppointmentUsageUnitReferenceNumberLinkRenderer,
  ContractServiceCategoryRenderer,
} from '../renderers'
import { DateRenderer } from '../../../components/AgGrid/renderers'

import {
  appointmentAccessReasonValueGetter,
  appointmentStatusLabelValueGetter,
  appointmentTypeValueGetter,
  dateTimeValueGetter,
  serviceCategoryValueGetter,
} from '../valueGetter'

import SimpleGridFilter from '../../../components/AgGrid/filters/SimpleGridFilter'
import {
  AppointmentAccessReasonFilter,
  AppointmentStatusFilter,
  AppointmentTypeFilter,
  ContractServiceCategoryFilter,
} from '../filters'

import { AppointmentsGridQuery, CountAppointmentsQuery } from '../queries'

import { useUserContext } from '../../../helper/userContext'
import { Routes } from '../../../configs/RouteConfig'

export const useUsageUnitGrid = () => {
  const { currentLanguage, redirectRoute } = useUserContext()
  const { t } = useTranslation()

  const getParams = useCallback(() => {
    const valueGetter = (params) => {
      const field = params.column.getColDef().field
      const colId = params.column.getColDef().colId
      const search = colId || field

      switch (search) {
        case 'status':
          return t(appointmentStatusLabelValueGetter(params))
        case 'contract':
          return t(serviceCategoryValueGetter({ data: params.node.data }))
        case 'type':
          return t(appointmentTypeValueGetter(params))
        case 'calendarweek':
          return dateTimeValueGetter({
            ...params,
            currentLanguage: currentLanguage,
            formatStr: 'I',
          })
        case 'date':
          return dateTimeValueGetter({
            ...params,
            currentLanguage: currentLanguage,
            formatStr: 'P',
          })
        case 'accessReason':
          return t(appointmentAccessReasonValueGetter(params))
        default:
          return params.value
      }
    }

    const UsageUnitGridColumnDefs = [
      columnCheckbox,
      {
        field: 'contract.tableId',
        colId: 'contract',
        width: 100,
        filter: 'SimpleGridFilter',
        ...withHiddenParams,
      },
      {
        headerName: t('STATUS'),
        field: 'status',
        width: 100,
        filter: 'AppointmentStatusFilter',
        cellRenderer: AppointmentStatusRenderer,
      },
      {
        headerName: t('ALLOCATION NUMBER'),
        field: 'usageUnit.referenceNumber',
        width: 100,
        cellRenderer: AppointmentUsageUnitReferenceNumberLinkRenderer,
        ...withFilterParams,
      },
      {
        headerName: t('APPOINTMENT ID'),
        field: 'tableId',
        width: 70,
        hide: true,
        cellRenderer: AppointmentIdRenderer,
        ...withFilterParams,
        type: 'rightAligned',
      },
      {
        headerName: t('CONTRACT PARTITION'),
        field: 'billingPartition.name',
        width: 80,
        hide: true,
        ...withFilterParams,
      },
      {
        headerName: t('STREET'),
        field: 'address.street',
        width: 80,
        ...withFilterParams,
      },
      {
        headerName: t('STREET NR.'),
        field: 'houseEntrance.number',
        width: 80,
        sortable: false,
        ...withFilterParams,
        type: 'rightAligned',
      },
      {
        headerName: t('ZIP'),
        field: 'address.zip',
        width: 60,
        ...withFilterParams,
        type: 'rightAligned',
      },
      {
        headerName: t('TOWN'),
        field: 'address.city',
        width: 70,
        ...withFilterParams,
      },
      {
        headerName: t('SERVICE TYPE'),
        colId: 'contract.serviceCategory.tableId',
        field: 'contract',
        width: 80,
        filter: 'ContractServiceCategoryFilter',
        cellRenderer: ContractServiceCategoryRenderer,
        ...withFilterParams,
        sortable: false,
      },
      {
        headerName: t('INHABITANT SURNAME'),
        field: 'tenant.lastname',
        width: 100,
        sortable: false, // TODO
        ...withFilterParams,
      },
      {
        headerName: t('INHABITANT FIRST NAME'),
        field: 'tenant.firstname',
        width: 100,
        hide: true,
        sortable: false, // TODO
        ...withFilterParams,
      },
      {
        headerName: t('APPOINTMENT TYPE'),
        field: 'type',
        width: 110,
        filter: 'AppointmentTypeFilter',
        cellRenderer: AppointmentTypeRenderer,
      },
      {
        headerName: t('CALENDAR WEEK'),
        colId: 'calendarweek',
        field: 'date',
        width: 70,
        filter: false,
        sortable: false,
        cellRenderer: DateRenderer,
        cellRendererParams: {
          formatStr: 'I',
        },
        type: 'rightAligned',
      },
      {
        headerName: t('DATE'),
        field: 'date',
        width: 80,
        filter: 'agDateColumnFilter',
        ...withFilterParams,
        sort: 'desc',
        cellRenderer: DateRenderer,
      },
      {
        headerName: t('TIME FRAME'),
        field: 'timeframe',
        width: 100,
        ...withFilterParams,
      },
      {
        headerName: t('BULLETIN'),
        field: 'bulletin.tableId',
        width: 80,
        valueGetter: (params) => params?.data?.bulletin?.tableId,
        cellRenderer: AppointmentBulletinWithDownloadRenderer,
        type: 'rightAligned',
      },
      {
        headerName: t('FLOOR'),
        field: 'usageUnit.floor',
        width: 50,
        sortable: false,
        type: 'rightAligned',
      },
      {
        headerName: t('ACCESS'),
        field: 'accessReason',
        width: 300,
        filter: 'AppointmentAccessReasonFilter',
        valueGetter: ({ data = {} }) => data?.accessReason?.msgId,
        cellRenderer: AppointmentAccessRenderer,
        ...withFilterParams,
      },
    ]

    return {
      query: AppointmentsGridQuery, // Using the same query like useAppointmentsGrid
      columnDefs: UsageUnitGridColumnDefs,
      customFilters: [],
      countQuery: CountAppointmentsQuery,
      extractor: (response) => response.Me?.Company?.data,
      customGridOptions: {
        getContextMenuItems: (params) => {
          return [
            {
              // custom item
              name: t('GO TO USAGE UNIT {REFERENCENUMBER}', {
                REFERENCENUMBER: params?.node?.data?.usageUnit.referenceNumber,
              }),
              action: function () {
                redirectRoute(
                  Routes.usageUnit + '/' + params?.node?.data?.usageUnit.tableId
                )
              },
            },
            'copy',
          ]
        },
        components: {
          AppointmentStatusFilter,
          SimpleGridFilter,
          AppointmentTypeFilter,
          ContractServiceCategoryFilter,
          AppointmentAccessReasonFilter,
        },
        autoGroupColumnDef: {
          minWidth: 200,
          headerName: t('USAGE UNIT'),
        },
        processCellForClipboard: valueGetter,
        defaultCsvExportParams: {
          processCellCallback: valueGetter,
          skipColumns: ['bulletin.tableId'],
        },
      },
      customVariables: {},
    }
  }, [currentLanguage, redirectRoute, t])

  return { getParams }
}
