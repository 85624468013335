import React from 'react'
import { useTranslation } from 'react-i18next'

import QueryRendererContainer from '../../components/QueryRendererContainer/QueryRendererContainer'
import PropertyManagement from './PropertyManagement'

import PropertyManagementContainerQuery from './queries/PropertyManagementContainer'
import { useUserContext } from '../../helper/userContext'
import getServices from '../../helper/getServices'
import { serviceCategoryEnumList } from '../../components/AgGrid/enumLists'

const PropertyManagementContainer = ({ pageSection = '' }) => {
  const { user } = useUserContext()
  const { t } = useTranslation()

  const variables = {
    companyId: user.companyId,
  }

  return (
    <QueryRendererContainer
      query={PropertyManagementContainerQuery}
      variables={variables}
      loading={<PropertyManagement loading pageSection={pageSection} />}
      render={(data) => {
        const contracts = data.Me?.Company?.Contracts || []
        const services = getServices(contracts)
        const houseEntrancesCount = data.Me?.Company?.countHouseEntrances

        const translatedServices = services.map((service) => ({
          ...service,
          label: t(serviceCategoryEnumList(service.name)),
        }))

        return (
          <PropertyManagement
            contracts={contracts}
            services={translatedServices}
            houseEntrancesCount={houseEntrancesCount}
            pageSection={pageSection}
          />
        )
      }}
    />
  )
}
export default PropertyManagementContainer
