import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  ButtonIcon,
  ButtonSecondary,
  portalTheme,
  Searchbox,
  StyledInput,
} from 'pyrexx-react-library'
import { Col, Container, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { fetchQuery } from 'react-relay'

import LoadingBox from '../../../../components/LoadingSpinner/LoadingBox'

import environment from '../../../../environments/pom/Environment'
import OrderSelectorQuery from './queries/OrderSelector'
import { translateOrderWizard } from '../../Enquiries/translations/translateOrderWizard'
import { useUserContext } from '../../../../helper/userContext'
import {
  clearQueryParamsFromUrl,
  pushNewUrlToHistory,
} from '../../../../helper/urlHelpers'

export const OrderReasonSelector = (props) => {
  const {
    mainCat,
    setMainCat,
    subCat,
    setSubCat,
    reason,
    setReason,
    setReasonId,
    searchText,
    setSearchText,
    startSelection,
    setStartSelection,
    setCurrentStep,
    orderStructure = [],
    resetConnectedIds,
    mainCatIndex,
    setMainCatIndex,
    subCatIndex,
    setSubCatIndex,
    // reasonIndex,
    setReasonIndex,
    setPipelineObject,
    reasonId,
  } = props

  const { t } = useTranslation()

  const [searchSelect, setSearchSelect] = useState({
    channel: false,
    topic: false,
    reason: false,
  })

  useEffect(() => {
    if (searchSelect.channel) {
      setMainCat(searchSelect.channel)
    }

    if (searchSelect.topic && mainCatIndex !== false && mainCat) {
      setSubCat(searchSelect.topic)
    }

    if (
      searchSelect.reason &&
      mainCatIndex !== false &&
      mainCat &&
      subCatIndex !== false &&
      subCat
    ) {
      setReason(searchSelect.reason)
    }
  }, [
    mainCat,
    mainCatIndex,
    searchSelect,
    setMainCat,
    setReason,
    setSubCat,
    subCat,
    subCatIndex,
  ])

  useEffect(() => {
    if (mainCat !== '' && !startSelection) {
      setStartSelection(true)
    }

    setMainCatIndex(
      orderStructure.findIndex((findItem) => findItem.name === mainCat)
    )
  }, [
    mainCat,
    orderStructure,
    setMainCatIndex,
    setStartSelection,
    startSelection,
  ])

  useEffect(() => {
    if (orderStructure[mainCatIndex]) {
      setSubCatIndex(
        orderStructure[mainCatIndex]?.topics.findIndex(
          (findItem) => findItem.name === subCat
        )
      )
    }
  }, [mainCatIndex, orderStructure, setSubCatIndex, subCat])

  useEffect(() => {
    if (orderStructure[mainCatIndex]?.topics[subCatIndex]) {
      setReasonIndex(
        orderStructure[mainCatIndex]?.topics[subCatIndex]?.reasons.findIndex(
          (findItem) => findItem.name === reason
        )
      )
    }
  }, [mainCatIndex, orderStructure, reason, setReasonIndex, subCatIndex])

  const generateMainCatOptions = useCallback(() => {
    return orderStructure.map((mainCatItem, index) => {
      return (
        <option key={'mainCat' + mainCatItem.name} value={mainCatItem.name}>
          {translateOrderWizard(mainCatItem.name, t)}
        </option>
      )
    })
  }, [orderStructure, t])

  const generateSubCatOptions = useCallback(() => {
    if (orderStructure[mainCatIndex]) {
      return orderStructure[mainCatIndex]?.topics.map((subCatItem, index) => {
        return (
          <option key={'subCat' + subCatItem.name} value={subCatItem.name}>
            {translateOrderWizard(subCatItem.name, t)}
          </option>
        )
      })
    }
  }, [mainCatIndex, orderStructure, t])

  const generateReasonOptions = useCallback(() => {
    if (orderStructure[mainCatIndex]?.topics[subCatIndex]) {
      return orderStructure[mainCatIndex]?.topics[subCatIndex]?.reasons.map(
        (reasonItem, index) => {
          return (
            <option
              key={
                'reason_' +
                reasonItem.name +
                '___' +
                reasonItem.id.split('::')[2]
              }
              value={reasonItem.name + '___' + reasonItem.id.split('::')[2]}
            >
              {translateOrderWizard(reasonItem.name, t)}
            </option>
          )
        }
      )
    }
  }, [mainCatIndex, orderStructure, subCatIndex, t])

  return (
    <Container
      style={{
        marginLeft: '0.5rem',
        marginRight: '0.5rem',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {!startSelection && (
        <Row justify='center' align='center'>
          <Col xs='content'>
            <p>{t('SELECT ONE COMMISSIONING')}</p>
          </Col>
        </Row>
      )}

      <Row
        justify='center'
        align='center'
        style={{
          flex: '1',
        }}
      >
        <Col
          style={{
            minHeight: '450px',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            backgroundColor: portalTheme.color.basicColorWhite,
            position: 'relative',
          }}
        >
          {startSelection && (
            <div style={{ position: 'absolute', top: '5px', right: '5px' }}>
              <ButtonIcon
                icon='close'
                size='25px'
                onClick={() => {
                  setSearchSelect({
                    channel: false,
                    topic: false,
                    reason: false,
                  })
                  setMainCat('')
                  setSubCat('')
                  setReason('')
                  setReasonId('')
                  setMainCatIndex(false)
                  setSubCatIndex(false)
                  setReasonIndex(false)
                  resetConnectedIds()
                  setStartSelection(false)
                  setPipelineObject(false)
                  setSearchText('')

                  clearQueryParamsFromUrl()
                }}
              />
            </div>
          )}

          <Row>
            <Col>
              <CustomStyledInput
                type='select'
                name='mainCatSelect'
                id='mainCatSelect'
                onChange={(event) => {
                  const currentCat = event.target.value
                  setSearchSelect({
                    channel: false,
                    topic: false,
                    reason: false,
                  })
                  setMainCat(currentCat)
                  setSubCat('')
                  setReason('')
                  setReasonId('')
                  setSubCatIndex(false)
                  setReasonIndex(false)
                  setPipelineObject(false)
                  // resetConnectedIds()
                  pushNewUrlToHistory('category', currentCat)
                  pushNewUrlToHistory('topic', '', 'delete')
                  pushNewUrlToHistory('reason', '', 'delete')
                  pushNewUrlToHistory('reasonId', '', 'delete')
                }}
                value={mainCat}
                label={t('MAIN CATEGORY')}
                feedbackMessage=''
                setFieldValue={(event) => {
                  setMainCat(event.target.value)
                }}
                optionsHtmlElemets={generateMainCatOptions()}
              />
            </Col>
          </Row>

          {mainCat && (
            <Row style={{ marginTop: '1rem' }}>
              <Col>
                <CustomStyledInput
                  type='select'
                  name='subCatSelect'
                  id='subCatSelect'
                  onChange={(event) => {
                    const currentTopic = event.target.value
                    setSearchSelect({
                      channel: false,
                      topic: false,
                      reason: false,
                    })
                    setSubCat(currentTopic)
                    setReason('')
                    setReasonId('')
                    setReasonIndex(false)
                    setPipelineObject(false)
                    // resetConnectedIds()

                    pushNewUrlToHistory('topic', currentTopic)
                    pushNewUrlToHistory('reason', '', 'delete')
                    pushNewUrlToHistory('reasonId', '', 'delete')
                  }}
                  value={subCat}
                  label={t('TOPIC')}
                  feedbackMessage=''
                  setFieldValue={(event) => {
                    setSubCat(event.target.value)
                  }}
                  optionsHtmlElemets={generateSubCatOptions()}
                />
              </Col>
            </Row>
          )}

          {subCat && (
            <Row style={{ marginTop: '1rem' }}>
              <Col>
                <CustomStyledInput
                  type='select'
                  name='reasonSelect'
                  id='reasonSelect'
                  onChange={(event) => {
                    const currentReason = event.target.value.split('___')[0]
                    const currentReasonId = event.target.value.split('___')[1]
                    setSearchSelect({
                      channel: false,
                      topic: false,
                      reason: false,
                    })
                    // resetConnectedIds()
                    setPipelineObject(false)
                    setReason(currentReason)
                    setReasonId(currentReasonId)

                    pushNewUrlToHistory('reason', currentReason)
                    pushNewUrlToHistory('reasonId', currentReasonId)
                  }}
                  value={reason ? reason + '___' + reasonId : ''}
                  label={t('REASON')}
                  feedbackMessage=''
                  setFieldValue={(event) => {
                    setReason(event.target.value.split('___')[0])
                  }}
                  optionsHtmlElemets={generateReasonOptions()}
                />
              </Col>
            </Row>
          )}
        </Col>

        {!startSelection && (
          <Search
            searchText={searchText}
            setSearchText={setSearchText}
            setSearchSelect={setSearchSelect}
            setReasonId={setReasonId}
          />
        )}
      </Row>

      {startSelection && (
        <Row justify='center' style={{ marginTop: '1rem' }}>
          <Col xs='content'>
            <ButtonSecondary
              disabled={reason === ''}
              onClick={() => {
                setCurrentStep(2)
              }}
              style={{
                margin: '0px',
                height: '3rem',
                paddingLeft: '4rem',
                paddingRight: '4rem',
              }}
            >
              {t('NEXT')}
            </ButtonSecondary>
          </Col>
        </Row>
      )}
    </Container>
  )
}

const Search = ({
  searchText = '',
  setSearchText = () => {},
  setSearchSelect = () => {},
  setReasonId = () => {},
}) => {
  const { t } = useTranslation()
  const { user } = useUserContext()

  const [searchLoading, setSearchLoading] = useState(false)
  const [searchResults, setSearchResults] = useState([])

  const ref = useRef({
    searchStarted: false,
  })

  const generateSearchResults = () => {
    if (searchLoading) {
      return <LoadingBox spinner backgroundColor='white' />
    }

    if (
      !searchLoading &&
      searchResults.length < 1 &&
      searchText.length > 2 &&
      ref.current.searchStarted
    ) {
      return (
        <Row>
          <Col>{t('NO RESULTS')}</Col>
        </Row>
      )
    }

    return searchResults.map((result, index) => {
      return (
        <>
          <Row style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
            {/* <Col xs={12}>{t('CATEGORY')}&#x2192;</Col> */}
            {/* <Col xs={12}> */}
            {/*  <StyledContainerLink */}
            {/*    key={'searchResult' + index} */}
            {/*    fluid */}
            {/*    onClick={() => { */}
            {/*      setSearchSelect({ */}
            {/*        channel: 'ORDER_WIZARD_CHANNEL_INVENTORY', */}
            {/*        topic: false, */}
            {/*        reason: false, */}
            {/*      }) */}
            {/*    }} */}
            {/*  > */}
            {/*    {getHighlightedText(result?.channel, searchText)} */}
            {/*  </StyledContainerLink> */}
            {/* </Col> */}
            {/* <Col xs={12}>{t('TOPIC')}&#x2192;</Col> */}
            {/* <Col xs={12}> */}
            {/*  <StyledContainerLink */}
            {/*    key={'searchResult' + index} */}
            {/*    fluid */}
            {/*    onClick={() => { */}
            {/*      setSearchSelect({ */}
            {/*        channel: 'ORDER_WIZARD_CHANNEL_INVENTORY', */}
            {/*        topic: 'ORDER_WIZARD_TOPIC_TENANT_CHANGE', */}
            {/*        reason: false, */}
            {/*      }) */}
            {/*    }} */}
            {/*  > */}
            {/*    {getHighlightedText(result?.topic, searchText)} */}
            {/*  </StyledContainerLink> */}
            {/* </Col> */}
            {/* <Col xs={12}>{t('REASON')}&#x2192;</Col> */}
            <Col xs={12}>
              <StyledContainerLink
                key={'searchResult' + index}
                fluid
                onClick={() => {
                  setSearchSelect({
                    channel: result.channel,
                    topic: result.topic,
                    reason: result.reason,
                  })
                  setReasonId(result.pipeline)
                }}
              >
                {getHighlightedText(
                  result?.channel + '/' + result?.reason,
                  searchText
                )}
              </StyledContainerLink>
            </Col>
          </Row>

          <Row
            justify='center'
            style={{
              paddingTop: '0.2rem',
              paddingBottom: '0.2rem',
              margin: '0',
            }}
            nogutter
          >
            <Col xs={11}>
              <hr />
            </Col>
          </Row>
        </>
      )
    })
  }

  useEffect(() => {
    if (searchText.length === 0) {
      ref.current.searchStarted = false
      return setSearchResults([])
    }

    const timeOutId = setTimeout(() => {
      if (searchText.length > 2) {
        // pushNewUrlToHistory('searchText', searchText)
        setSearchLoading(true)
        ref.current.searchStarted = true

        return fetchQuery(
          environment(user.accessToken),
          OrderSelectorQuery,
          {
            companyId: user.companyId,
            search: searchText,
          },
          {
            force: false,
          }
        )
          .then((res) => {
            setSearchLoading(false)
            const search = res.Me.Company.OrderWizard.search || ''
            return setSearchResults(search)
          })
          .catch((e) => {
            setSearchLoading(false)
            console.log(e)
          })
      }
    }, 1000)
    return () => clearTimeout(timeOutId)
    // TODO improve
    // eslint-disable-next-line
  }, [searchText])

  return (
    <Col
      xs={6}
      style={{
        minHeight: '450px',
        marginLeft: '0.5rem',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: portalTheme.color.basicColorWhite,
      }}
    >
      <Row>
        <Col
          style={{
            position: 'relative',
          }}
        >
          <CustomSearchbox
            disabledborder
            clearSearchAfterSubmit={false}
            handleChange={setSearchText}
            handleSubmit={() => {}}
            searchText={searchText}
            placeholder={t('SEARCH')}
          />

          <CustomDivForSearchResults>
            {generateSearchResults()}
          </CustomDivForSearchResults>
        </Col>
      </Row>
    </Col>
  )
}

const getHighlightedText = (text, highlight) => {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
  return (
    <span>
      {' '}
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? {
                  fontWeight: 'bold',
                  color: portalTheme.color.linkColorPrimary,
                }
              : {}
          }
        >
          {part}
        </span>
      ))}{' '}
    </span>
  )
}

const CustomSearchbox = styled(Searchbox)`
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #666;
  padding: 0;
  padding-bottom: 2px;
  margin-top: 5px;
  margin-bottom: 5px;

  & input {
    padding-left: 0.5rem;
  }

  height: unset;
`

export const StyledContainerLink = styled(Container)`
  padding: 0px !important;
  cursor: pointer;
  max-width: 100%;

  &:hover,
  &:focus {
    background-color: #dbe7f3;
  }
`

const CustomDivForSearchResults = styled.div`
  position: absolute;
  border-left: 1px solid #666;
  border-right: 1px solid #666;
  border-bottom: 1px solid #666;
  max-height: 8rem;
  width: 265px;
  top: 30px;
  left: 43px;
  overflow-x: hidden; /* "overflow"-Wert darf nicht "visible" sein */
  overflow-y: auto;

  text-overflow: ellipsis;
`

const CustomStyledInput = styled(StyledInput)`
  display: flex;
  align-content: center;
  justify-content: center;
  margin-bottom: 0.3rem;

  .floating-text {
    padding: 4px 0px 13px 0px;
  }
`
