import React from 'react'
import PropTypes from 'prop-types'
import { Col, Container, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { portalTheme } from 'pyrexx-react-library'
import Tabs from '../../components/Tabs'
import SkeletonList from '../../components/SkeletonList'
import { ExternalLinkToOrderWizard } from '../Support/CreateOrder/components/ExternalLinkToOrderWizard'

import { UsageUnitTypeRenderer } from './renderers/'
import formatValue from '../../helper/formatValue'
import { CHANGE_TENANT, UN_LOCKING } from '../../constants/OrderWizardConstants'
import {
  OW_CATEGORY,
  OW_CONNECTED_IDS,
  OW_CURRENT_STEP,
  OW_REASON,
  OW_REASON_ID,
  OW_TOPIC,
} from '../../configs/FilterConfig'

const UsageUnitOverview = (props) => {
  const { usageUnit = {}, usageUnitId = '', loading = false } = props

  const {
    tableId = '',
    referenceNumber = '',
    rooms = 0,
    size = 0,
    type = '',
    floor = '',
    position = '',
    address = {},
    tenants = [],
  } = usageUnit

  const { t } = useTranslation()

  const { street = '', number = '', zip = '', city = '' } = address || {}

  const firstTenant = tenants.find((t) => t?.firstname && t?.lastname)
  const isVacant = tenants.length === 0 || !firstTenant

  const formattedTenantName = (
    firstTenant?.firstname +
    ' ' +
    firstTenant?.lastname
  ).trim()
  const currentTenant = isVacant ? t('VACANCY') : formattedTenantName

  const formattedArea = typeof size === 'number' ? size.toFixed(0) : 0
  const area = formattedArea + '㎡'
  const streetNumberValue =
    !street && !number ? '-' : formatValue(street) + ' ' + formatValue(number)
  const zipCityValue =
    !zip && !city ? '-' : formatValue(zip) + ' ' + formatValue(city)
  const floorPositionValue =
    !floor && !position
      ? '-'
      : formatValue(floor) + '. - ' + formatValue(position)

  return (
    <Container>
      <Row>
        <UsageUnitDefaultCol xs={3} style={{ marginRight: '1rem' }}>
          {loading ? (
            <SkeletonList />
          ) : (
            <>
              <Row>
                <LabelCol>{t('ALLOCATION NUMBER')}</LabelCol>
              </Row>
              <Row style={{ marginBottom: '1rem' }}>
                <ValueCol>{referenceNumber || '-'}</ValueCol>
              </Row>
              <Row>
                <LabelCol>{t('PYREXX USAGE UNIT NUMBER')}</LabelCol>
              </Row>
              <Row style={{ marginBottom: '1rem' }}>
                <ValueCol>{tableId || '-'}</ValueCol>
              </Row>
              <Row>
                <LabelCol>{t('CURRENT USAGE UNIT TYPE')}</LabelCol>
              </Row>
              <Row style={{ marginBottom: '1rem' }}>
                <ValueCol>
                  <UsageUnitTypeRenderer value={type} />
                </ValueCol>
              </Row>
              <Row>
                <LabelCol>{t('AREA / ROOM')}</LabelCol>
              </Row>
              <Row style={{ marginBottom: '1rem' }}>
                <ValueCol>
                  <span>{area || '-'}</span> / <span>{rooms || '-'}</span>
                </ValueCol>
              </Row>
              <Row>
                <LabelCol>{t('CURRENT INHABITANT')}</LabelCol>
              </Row>
              <Row style={{ marginBottom: '1rem' }}>
                <ValueCol>{currentTenant}</ValueCol>
              </Row>
            </>
          )}
        </UsageUnitDefaultCol>

        <UsageUnitDefaultCol>
          <Row style={{ height: '100%' }}>
            <TabsCol xs={8}>
              <Tabs
                borderBottomStyle={
                  '1px solid ' + portalTheme.color.linkColorSecondary
                }
                tabs={[
                  {
                    key: 'address',
                    label: t('ADDRESS'),
                    content: loading ? (
                      <SkeletonList numberOfItems={2} />
                    ) : (
                      <Container style={{ marginTop: '2rem' }}>
                        <Row>
                          <LabelCol>{t('ADDRESS')}</LabelCol>
                        </Row>
                        <Row>
                          <ValueCol>{streetNumberValue}</ValueCol>
                        </Row>
                        <Row style={{ marginBottom: '2rem' }}>
                          <ValueCol>{zipCityValue}</ValueCol>
                        </Row>
                        <Row>
                          <LabelCol>{t('FLOOR AND POSITION')}</LabelCol>
                        </Row>
                        <Row>
                          <ValueCol>{floorPositionValue}</ValueCol>
                        </Row>
                      </Container>
                    ),
                  },
                  {
                    key: 'tenantinfo',
                    label: t('INHABITANT INFORMATION'),
                    content: loading ? (
                      <SkeletonList numberOfItems={2} />
                    ) : (
                      <Container style={{ marginTop: '2rem' }}>
                        {isVacant ? (
                          <Row>
                            <ValueCol>{t('VACANCY')}</ValueCol>
                          </Row>
                        ) : (
                          <>
                            <Row>
                              <LabelCol xs={5}>{t('START OF LEASE')}</LabelCol>
                              <LabelCol>{t('END OF LEASE')}</LabelCol>
                            </Row>
                            <Row style={{ marginBottom: '2rem' }}>
                              <ValueCol xs={5}>
                                {firstTenant?.begin ? firstTenant?.begin : '-'}
                              </ValueCol>
                              <ValueCol>
                                {firstTenant?.end ? firstTenant?.end : '-'}
                              </ValueCol>
                            </Row>

                            {tenants.map((tenant, index) => {
                              return (
                                <React.Fragment key={'tenantInfo' + index}>
                                  <Row
                                    key={'tenantLabelRow' + index}
                                    style={{ marginTop: '2rem' }}
                                  >
                                    <LabelCol xs={5}>
                                      {t('CURRENT INHABITANT {NUMBER}', {
                                        NUMBER: index + 1,
                                      })}
                                    </LabelCol>
                                    <LabelCol>{t('CONTACT')}</LabelCol>
                                  </Row>
                                  <Row key={'tenantValueRow' + index}>
                                    <ValueCol xs={5}>
                                      {tenant?.firstname} {tenant?.lastname}
                                    </ValueCol>
                                    <ValueCol>
                                      {tenant?.contact?.map(
                                        (cont, conIndex) => {
                                          return (
                                            <div
                                              key={
                                                'contactInfo' + index + conIndex
                                              }
                                            >
                                              {cont.value}
                                            </div>
                                          )
                                        }
                                      )}
                                    </ValueCol>
                                  </Row>
                                </React.Fragment>
                              )
                            })}
                          </>
                        )}
                      </Container>
                    ),
                  },
                ]}
              />
            </TabsCol>

            <Col>
              {loading ? (
                <SkeletonList numberOfItems={2} />
              ) : (
                <Container
                  style={{
                    padding: '0 1.5rem',
                  }}
                >
                  <BorderBottomRow
                    justify='between'
                    style={{ padding: '1rem 0' }}
                  >
                    <ExternalLinkToOrderWizard
                      to='/de/support/createOrder'
                      label={t('ENQUIRIES')}
                    />
                  </BorderBottomRow>

                  <Row style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                    <SmallTitleCol>{t('COMMON ACTIONS')}</SmallTitleCol>
                  </Row>

                  <Row
                    justify='between'
                    style={{ marginTop: '1rem', marginBottom: '1rem' }}
                  >
                    <ExternalLinkToOrderWizard
                      to={`/de/support/createOrder?${OW_CONNECTED_IDS}={"invoice":[],"usageUnit":[${usageUnitId}],"tenant":[]}`}
                      label={t('ORDER SERVICE')}
                    />
                  </Row>

                  <Row
                    justify='between'
                    style={{ marginTop: '1rem', marginBottom: '1rem' }}
                  >
                    <ExternalLinkToOrderWizard
                      to={`/de/support/createOrder?${OW_CATEGORY}=${UN_LOCKING.channel}&${OW_TOPIC}=${UN_LOCKING.topic}`}
                      label={t('LOCK / UNLOCK SERVICE FOR RESIDENCE')}
                    />
                  </Row>

                  <Row
                    justify='between'
                    style={{ marginTop: '1rem', marginBottom: '1rem' }}
                  >
                    <ExternalLinkToOrderWizard
                      to={`/de/support/createOrder?${OW_CATEGORY}=${CHANGE_TENANT.channel}&${OW_TOPIC}=${CHANGE_TENANT.topic}&${OW_REASON}=${CHANGE_TENANT.reason}&${OW_REASON_ID}=${CHANGE_TENANT.reasonId}&${OW_CURRENT_STEP}=2`}
                      label={t('PERFORM CHANGE OF TENANT')}
                    />
                  </Row>
                </Container>
              )}
            </Col>
          </Row>
        </UsageUnitDefaultCol>
      </Row>
    </Container>
  )
}

UsageUnitOverview.propTypes = {
  loading: PropTypes.bool,
  usageUnit: PropTypes.shape({
    address: PropTypes.shape({
      street: PropTypes.string,
      number: PropTypes.string,
      zip: PropTypes.string,
      city: PropTypes.string,
    }),
    floor: PropTypes.any,
    position: PropTypes.string,
    referenceNumber: PropTypes.any,
    rooms: PropTypes.number,
    size: PropTypes.number,
    tableId: PropTypes.any,
    tenants: PropTypes.arrayOf(
      PropTypes.shape({
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        begin: PropTypes.string,
        end: PropTypes.string,
        status: PropTypes.string,
        contact: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.any,
          })
        ),
      })
    ),
    type: PropTypes.string,
  }),
}

const UsageUnitDefaultCol = styled(Col)`
  padding: 1rem;
  background-color: ${portalTheme.color.basicColorWhite};
`

const BorderBottomRow = styled(Row)`
  border-bottom: 1px solid #979797;
`

const SmallTitleCol = styled.div`
  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.regular};
  font-size: 12px;
`

const LabelCol = styled(Col)`
  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.regular};
  font-size: 13px;
`

const ValueCol = styled(Col)`
  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.bold};
  font-size: 16px;
`

const TabsCol = styled(Col)`
  border-right: 1px solid ${portalTheme.color.linkColorSecondary};
`

export default UsageUnitOverview
