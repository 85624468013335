import React from 'react'
import styled from 'styled-components/macro'

function VariablePreview(props) {
  // eslint-disable-next-line
  const {type, id, value, label, onClick, handleRemove, notAvailable = false} = props

  if (type === 'list') {
    if (!notAvailable) {
      return (
        <EmptyButton
          className='xvariable-preview__add'
          onClick={() => onClick(id)}
          title='Add variable'
        >
          <BadgeList>
            <span className='xvariable-preview__label'>{label}</span>
          </BadgeList>
        </EmptyButton>
      )
    }
  } else {
    return (
      <Badge className='xvariable-preview' notAvailable={notAvailable}>
        <span className='xvariable-preview__label'>{label}</span>
        <CloseButton
          className='xvariable-preview__add'
          onClick={handleRemove}
          tabIndex={-1}
        >
          <span>x</span>
        </CloseButton>
      </Badge>
    )
  }
  return ''
}

const Badge = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  background-color: ${(props) => (props.notAvailable ? 'red' : '#c3cfdb')};
  padding: 0 8px;
  border-radius: 17.5px;
`

const EmptyButton = styled.button`
  border: none;
  cursor: pointer;
  background-color: unset;
  padding: 0;
`

const BadgeList = styled(Badge)`
  padding: 4px 8px;
`

const CloseButton = styled(EmptyButton)`
  color: #fff;
  padding: 0 0 0 6px;
`

export default VariablePreview
