/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractStatusType = "ACTIVE" | "DISCARDED" | "DONE_BUT_NOT_FINAL_BILLED" | "EXPIRED_AND_FINAL_BILLED" | "FINAL_BILLED_NOT_EXPIRED_NO_MAINTENANCE" | "MERGED_INTO_OTHER_CONTRACT" | "NONE" | "READY_FOR_ACTIVATION" | "REGULAR_APPOINTMENTS_SUSPENDED_TEMPORARILY" | "TEMP_SUSPENDED" | "UNDER_CONSTRUCTION" | "%future added value";
export type InvoiceItemStatusType = "ACTIVE" | "DEACTIVATED" | "INVOICED" | "NONE" | "%future added value";
export type InvoiceStatusType = "CANCELLED" | "OPEN" | "PAID" | "REMINDED" | "%future added value";
export type InvoiceContainerQueryVariables = {|
  companyId: string,
  invoiceNumber: $ReadOnlyArray<?string>,
|};
export type InvoiceContainerQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +Invoices: ?$ReadOnlyArray<?{|
        +status: ?InvoiceStatusType,
        +name: ?string,
        +tableId: number,
        +description: ?string,
        +invoiceDate: ?any,
        +discount: number,
        +discountReason: string,
        +paymentTarget: ?any,
        +value: ?any,
        +invoiceNumber: ?string,
        +price: ?{|
          +net: number,
          +gross: number,
          +vat: number,
          +percentage: $ReadOnlyArray<number>,
        |},
        +remark: ?string,
        +type: ?number,
        +Contract: ?{|
          +name: ?string,
          +status: ContractStatusType,
          +date: ?any,
        |},
        +Items: ?$ReadOnlyArray<?{|
          +name: ?string,
          +status: InvoiceItemStatusType,
          +reason: ?string,
          +unit: ?string,
          +vat: ?number,
          +quantity: ?number,
          +price: ?{|
            +net: number,
            +gross: number,
            +vat: number,
            +percentage: $ReadOnlyArray<number>,
          |},
          +id: string,
        |}>,
        +preview: ?string,
      |}>
    |}
  |}
|};
export type InvoiceContainerQuery = {|
  variables: InvoiceContainerQueryVariables,
  response: InvoiceContainerQueryResponse,
|};
*/


/*
query InvoiceContainerQuery(
  $companyId: ID!
  $invoiceNumber: [String]!
) {
  Me {
    Company(id: $companyId) {
      Invoices(options: {filters: [{name: "invoiceNumber", filterType: text, operator: AND, conditions: [{value: $invoiceNumber, type: equals}]}]}) {
        status
        name
        tableId
        description
        invoiceDate
        discount
        discountReason
        paymentTarget
        value
        invoiceNumber
        price {
          net
          gross
          vat
          percentage
        }
        remark
        type
        Contract {
          name
          status
          date
          id
        }
        Items {
          name
          status
          reason
          unit
          vat
          quantity
          price {
            net
            gross
            vat
            percentage
          }
          id
        }
        preview
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "invoiceNumber"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = [
  {
    "fields": [
      {
        "items": [
          {
            "fields": [
              {
                "items": [
                  {
                    "fields": [
                      {
                        "kind": "Literal",
                        "name": "type",
                        "value": "equals"
                      },
                      {
                        "kind": "Variable",
                        "name": "value",
                        "variableName": "invoiceNumber"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "conditions.0"
                  }
                ],
                "kind": "ListValue",
                "name": "conditions"
              },
              {
                "kind": "Literal",
                "name": "filterType",
                "value": "text"
              },
              {
                "kind": "Literal",
                "name": "name",
                "value": "invoiceNumber"
              },
              {
                "kind": "Literal",
                "name": "operator",
                "value": "AND"
              }
            ],
            "kind": "ObjectValue",
            "name": "filters.0"
          }
        ],
        "kind": "ListValue",
        "name": "filters"
      }
    ],
    "kind": "ObjectValue",
    "name": "options"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invoiceDate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountReason",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentTarget",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invoiceNumber",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vat",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "PriceInfo",
  "kind": "LinkedField",
  "name": "price",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "net",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gross",
      "storageKey": null
    },
    (v13/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "percentage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remark",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "InvoiceItem",
  "kind": "LinkedField",
  "name": "Items",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unit",
      "storageKey": null
    },
    (v13/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantity",
      "storageKey": null
    },
    (v14/*: any*/),
    (v18/*: any*/)
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preview",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvoiceContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "OutgoingInvoice",
                "kind": "LinkedField",
                "name": "Invoices",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Contract",
                    "kind": "LinkedField",
                    "name": "Contract",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v3/*: any*/),
                      (v17/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v19/*: any*/),
                  (v20/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvoiceContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "OutgoingInvoice",
                "kind": "LinkedField",
                "name": "Invoices",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Contract",
                    "kind": "LinkedField",
                    "name": "Contract",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v3/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v18/*: any*/)
                ],
                "storageKey": null
              },
              (v18/*: any*/)
            ],
            "storageKey": null
          },
          (v18/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6cfbca857f1f8785a5933e83fefd1717",
    "id": null,
    "metadata": {},
    "name": "InvoiceContainerQuery",
    "operationKind": "query",
    "text": "query InvoiceContainerQuery(\n  $companyId: ID!\n  $invoiceNumber: [String]!\n) {\n  Me {\n    Company(id: $companyId) {\n      Invoices(options: {filters: [{name: \"invoiceNumber\", filterType: text, operator: AND, conditions: [{value: $invoiceNumber, type: equals}]}]}) {\n        status\n        name\n        tableId\n        description\n        invoiceDate\n        discount\n        discountReason\n        paymentTarget\n        value\n        invoiceNumber\n        price {\n          net\n          gross\n          vat\n          percentage\n        }\n        remark\n        type\n        Contract {\n          name\n          status\n          date\n          id\n        }\n        Items {\n          name\n          status\n          reason\n          unit\n          vat\n          quantity\n          price {\n            net\n            gross\n            vat\n            percentage\n          }\n          id\n        }\n        preview\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '082879149f1e1f1689cfe721c32f1519';

module.exports = node;
