import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Box } from '../shared'
import SimpleButton from '../../../../components/Simple/SimpleButton'
import ProfilePic from './ProfilePic'

import calculateIndexes from '../helpers/calculateIndexes'
import { SimpleCSS } from '../../../../components/Simple/Simple.styles'

const CONDITION = { begin: 2, end: 1 }

const ChatItem = ({ c = {}, hidden = false }) => {
  return (
    <ChatItemWrapper sender={c.sender} hidden={hidden}>
      <ProfilePic sender={c.sender} />
      <ChatMsg dangerouslySetInnerHTML={{ __html: c.msg }} />
    </ChatItemWrapper>
  )
}

const TicketChat = ({ chat = [] }) => {
  const { t } = useTranslation()

  const [isListHidden, setIsListHidden] = useState(true)

  const { indexBegin, indexEnd, renderButtons, defaultNumberOfChatMsgs } =
    calculateIndexes(chat.length, CONDITION)

  if (chat.length <= 0) {
    return (
      <Box>
        <p>No chat</p>
      </Box>
    )
  }

  return (
    <ChatWrapper>
      {chat.map((c, index) => {
        if (index >= indexBegin && index <= indexEnd) {
          if (index === indexEnd && isListHidden && renderButtons) {
            return (
              <HideChatButton
                key='more-button'
                onClick={() => setIsListHidden(false)}
              >
                {t('SHOW WHOLE CONVERSATION ({chatMsgs})', {
                  chatMsgs: defaultNumberOfChatMsgs,
                })}
              </HideChatButton>
            )
          }

          return <ChatItem key={c.id} c={c} hidden={isListHidden} />
        }

        return <ChatItem key={c.id} c={c} />
      })}

      {!isListHidden && renderButtons && (
        <HideChatButton onClick={() => setIsListHidden(true)}>
          {t('HIDE CHAT')}
        </HideChatButton>
      )}
    </ChatWrapper>
  )
}

const ChatWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;

  //max-width: 700px;
`

const ChatItemWrapper = styled.div`
  display: flex;
  //align-items: center;
  gap: 16px;

  ${(props) =>
    props.sender === 'pyrexx' &&
    css`
      flex-direction: row-reverse;
    `}

  ${(props) =>
    props.hidden &&
    css`
      display: none;
    `}
`

const ChatMsg = styled.div`
  padding: 16px;
  margin: 0;
  background-color: #eee;
  border-radius: 5px;

  hyphens: auto;

  & p {
    margin: 0;
  }

  & a {
    ${SimpleCSS};
    font-family: 'Quicksand', sans-serif;
  }
`

const HideChatButton = styled(SimpleButton)``

export default TicketChat
