import { appointmentAccessReasonEnumList } from '../../../components/AgGrid/enumLists'
const notEquippedUsageUnitAppointmentAccessReasonValueGetter = (props) => {
  const { value } = props

  if (!value || !value.length > 0) {
    return ''
  }
  return appointmentAccessReasonEnumList(value[0]?.accessReason?.msgId)
}

export default notEquippedUsageUnitAppointmentAccessReasonValueGetter
