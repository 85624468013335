/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TicketStatusEnum = "CLOSED" | "OPEN" | "%future added value";
export type Sort = {|
  colId?: ?string,
  sort?: ?string,
|};
export type AllOpenEnquiriesQueryVariables = {|
  companyId: string,
  startRow?: ?number,
  endRow?: ?number,
  sortModel?: ?$ReadOnlyArray<?Sort>,
|};
export type AllOpenEnquiriesQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +OrderWizard: ?{|
        +data: ?$ReadOnlyArray<?{|
          +id: string,
          +tableId: number,
          +zammadTicketId: number,
          +creationDate: ?any,
          +lastUpdate: ?any,
          +status: TicketStatusEnum,
          +channel: ?{|
            +id: string,
            +name: string,
          |},
          +topic: ?{|
            +id: string,
            +name: string,
          |},
          +reason: ?{|
            +id: string,
            +name: string,
          |},
          +subject: string,
          +content: string,
          +user: ?{|
            +tableId: number,
            +firstname: ?string,
            +lastname: ?string,
            +name: ?string,
          |},
        |}>
      |}
    |}
  |}
|};
export type AllOpenEnquiriesQuery = {|
  variables: AllOpenEnquiriesQueryVariables,
  response: AllOpenEnquiriesQueryResponse,
|};
*/


/*
query AllOpenEnquiriesQuery(
  $companyId: ID!
  $startRow: Int
  $endRow: Int
  $sortModel: [Sort]
) {
  Me {
    Company(id: $companyId) {
      OrderWizard {
        data: tickets(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: [{filterType: text, operator: AND, name: "status", conditions: [{type: equals, value: "OPEN"}]}]}) {
          id
          tableId
          zammadTicketId
          creationDate
          lastUpdate
          status
          channel {
            id
            name
          }
          topic {
            id
            name
          }
          reason {
            id
            name
          }
          subject
          content
          user {
            tableId
            firstname
            lastname
            name
            id
          }
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endRow"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortModel"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startRow"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v5 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "endRow",
        "variableName": "endRow"
      },
      {
        "kind": "Literal",
        "name": "filters",
        "value": [
          {
            "conditions": [
              {
                "type": "equals",
                "value": "OPEN"
              }
            ],
            "filterType": "text",
            "name": "status",
            "operator": "AND"
          }
        ]
      },
      {
        "kind": "Variable",
        "name": "sorting",
        "variableName": "sortModel"
      },
      {
        "kind": "Variable",
        "name": "startRow",
        "variableName": "startRow"
      }
    ],
    "kind": "ObjectValue",
    "name": "options"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "zammadTicketId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creationDate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastUpdate",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v13 = [
  (v6/*: any*/),
  (v12/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderWizardChannel",
  "kind": "LinkedField",
  "name": "channel",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderWizardTopic",
  "kind": "LinkedField",
  "name": "topic",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderWizardReason",
  "kind": "LinkedField",
  "name": "reason",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subject",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AllOpenEnquiriesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderWizardQueries",
                "kind": "LinkedField",
                "name": "OrderWizard",
                "plural": false,
                "selections": [
                  {
                    "alias": "data",
                    "args": (v5/*: any*/),
                    "concreteType": "OrderWizardTicket",
                    "kind": "LinkedField",
                    "name": "tickets",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserInfo",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "AllOpenEnquiriesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrderWizardQueries",
                "kind": "LinkedField",
                "name": "OrderWizard",
                "plural": false,
                "selections": [
                  {
                    "alias": "data",
                    "args": (v5/*: any*/),
                    "concreteType": "OrderWizardTicket",
                    "kind": "LinkedField",
                    "name": "tickets",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserInfo",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v12/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8ce9caf0e8ac3a8bd2798a276825f17c",
    "id": null,
    "metadata": {},
    "name": "AllOpenEnquiriesQuery",
    "operationKind": "query",
    "text": "query AllOpenEnquiriesQuery(\n  $companyId: ID!\n  $startRow: Int\n  $endRow: Int\n  $sortModel: [Sort]\n) {\n  Me {\n    Company(id: $companyId) {\n      OrderWizard {\n        data: tickets(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: [{filterType: text, operator: AND, name: \"status\", conditions: [{type: equals, value: \"OPEN\"}]}]}) {\n          id\n          tableId\n          zammadTicketId\n          creationDate\n          lastUpdate\n          status\n          channel {\n            id\n            name\n          }\n          topic {\n            id\n            name\n          }\n          reason {\n            id\n            name\n          }\n          subject\n          content\n          user {\n            tableId\n            firstname\n            lastname\n            name\n            id\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '98d0b4c11122b708a1ac9eaf2b76a7ea';

module.exports = node;
