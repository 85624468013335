import React from 'react'
import {
  columnCheckbox,
  withFilterParams,
} from '../../../components/AgGrid/columnDefs'
import { CountUsageUnitsQuery } from '../queries'
import { useTranslation } from 'react-i18next'
import {
  ReassemblyRenderer,
  // UsageUnitLockPeriodTimeOfLockRenderer,
  UsageUnitReferenceNumberRenderer,
} from '../renderer'
import SmokeAlarmsInStorageGridQuery from '../queries/SmokeAlarmsInStorageGridQuery'
import { tenantFullNameValueGetter } from '../../Contracts/valueGetter'
import { DateRenderer } from '../../../components/AgGrid/renderers'

export const useSmokeAlarmsInStorage = () => {
  const { t } = useTranslation()

  const getParams = (currentService) => {
    const SmokeAlarmsInStorageColumnDefs = [
      columnCheckbox,
      {
        headerName: t('ALLOCATION NUMBER'),
        field: 'referenceNumber',
        cellRenderer: UsageUnitReferenceNumberRenderer,
        ...withFilterParams,
      },
      {
        headerName: t('ADDRESS'),
        field: 'address.street',
        ...withFilterParams,
      },
      {
        headerName: t('HOUSE NUMBER'),
        field: 'address.number',
        type: 'rightAligned',
        ...withFilterParams,
      },
      {
        headerName: t('ZIP'),
        field: 'address.zip',
        ...withFilterParams,
      },
      {
        headerName: t('FLOOR'),
        field: 'floor',
        type: 'rightAligned',
        ...withFilterParams,
      },
      {
        headerName: t('RESIDENT'),
        field: 'tenants',
        filter: false,
        sort: false,
        valueGetter: ({ data }) =>
          tenantFullNameValueGetter({ value: data.tenants }),
      },
      {
        headerName: t('PX-1 IN STORAGE'),
        field: 'px1',
        type: 'rightAligned',
        ...withFilterParams,
      },
      {
        headerName: t('PX-1C IN STORAGE'),
        field: 'px1c',
        type: 'rightAligned',
        ...withFilterParams,
      },
      {
        headerName: t('STORAGE DATE'),
        colId: 'registrations.appointment.executionDate',
        field: 'registrations',
        // filter: 'agDateColumnFilter',
        ...withFilterParams,
        // sort: 'desc',
        sort: false,
        filter: false,
        cellRenderer: ({ value = [] }) => {
          return value && value[0] ? (
            <DateRenderer value={value[0].appointment.executionDate} />
          ) : (
            ''
          )
        },
      },
      {
        headerName: t('COMMISIONING'),
        field: 'tableId',
        ...withFilterParams,
        cellRenderer: ReassemblyRenderer,
      },
    ]

    const SmokeAlarmsInStorageCustomFilters = [
      {
        name: 'contract.serviceCategory.tableId',
        filterType: 'text',
        operator: 'AND',
        conditions: [
          {
            value: [currentService.tableId],
            type: 'equals',
          },
        ],
      },
      {
        name: 'lockPeriod.reason',
        filterType: 'text',
        operator: 'AND',
        conditions: [
          {
            value: ['SMOKE_DETECTORS_IN_SAFE_KEEPING'],
            type: 'equals',
          },
        ],
      },
    ]

    return {
      query: SmokeAlarmsInStorageGridQuery,
      columnDefs: SmokeAlarmsInStorageColumnDefs,
      customFilters: SmokeAlarmsInStorageCustomFilters,
      countQuery: CountUsageUnitsQuery,
      extractor: (response) => response.Me?.Company?.data,
      customVariables: {},
    }
  }

  return { getParams }
}
