import React from 'react'
import PropTypes from 'prop-types'
import { Col, Container, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { Icon } from 'leaflet'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'

import { portalTheme } from 'pyrexx-react-library'
import Skeleton from '../../components/Skeleton'
import { ExternalLinkToOrderWizard } from '../Support/CreateOrder/components/ExternalLinkToOrderWizard'

import markerIconPng from 'leaflet/dist/images/marker-icon.png'
import 'leaflet/dist/leaflet.css'

const PropertyUnitOverview = (props) => {
  const { propertyUnit = {}, loading = false } = props

  const {
    tableId,
    countUsageUnits,
    address = {},
    coordinates = {},
  } = propertyUnit

  const { t } = useTranslation()

  const interactionOptions = {
    zoomControl: false,
    doubleClickZoom: false,
    closePopupOnClick: false,
    dragging: false,
    zoomSnap: false,
    zoomDelta: false,
    trackResize: false,
    touchZoom: false,
    scrollWheelZoom: false,
  }

  return (
    <Container
      style={{
        padding: '0 1.5rem',
      }}
    >
      <Row>
        <PropertyUnitDefaultCol xs={3} style={{ marginRight: '1rem' }}>
          {loading ? (
            <SkeletonList />
          ) : (
            <>
              <Row>
                <LabelCol>{t('ALLOCATION NUMBER')}</LabelCol>
              </Row>
              <Row style={{ marginBottom: '1rem' }}>
                <ValueCol>TBA</ValueCol>
              </Row>
              <Row>
                <LabelCol>{t('PYREXX PROPERTY ID')}</LabelCol>
              </Row>
              <Row style={{ marginBottom: '1rem' }}>
                <ValueCol>{tableId}</ValueCol>
              </Row>
              <Row>
                <LabelCol>{t('ADDRESS')}</LabelCol>
              </Row>
              <Row style={{ marginBottom: '1rem' }}>
                <ValueCol>
                  {address.street + ' ' + address.number}
                  <br />
                  {address.zip + ' ' + address.city}
                </ValueCol>
              </Row>
              <Row>
                <LabelCol>{t('NUMBER OF USAGE UNITS')}</LabelCol>
              </Row>
              <Row style={{ marginBottom: '1rem' }}>
                <ValueCol>{countUsageUnits}</ValueCol>
              </Row>
            </>
          )}
        </PropertyUnitDefaultCol>

        <PropertyUnitDefaultCol>
          <Row style={{ height: '100%' }}>
            <MapCol xs={8}>
              {loading ? (
                <Skeleton variant='fill' />
              ) : (
                <MapContainerStyled
                  {...interactionOptions}
                  center={[coordinates.lat, coordinates.lng]}
                  zoom={17}
                  scrollWheelZoom={false}
                >
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                  />
                  <Marker
                    position={[coordinates.lat, coordinates.lng]}
                    icon={
                      new Icon({
                        iconUrl: markerIconPng,
                        iconSize: [25, 41],
                        iconAnchor: [12, 41],
                      })
                    }
                  />
                </MapContainerStyled>
              )}
            </MapCol>

            <Col
              style={{
                padding: '0 2rem',
              }}
            >
              <Container
                style={{
                  padding: '0 1.5rem',
                }}
              >
                <BorderBottomRow
                  justify='between'
                  style={{ padding: '1rem 0' }}
                >
                  <ExternalLinkToOrderWizard
                    to='/de/support/createOrder'
                    label={t('ENQUIRIES')}
                  />
                </BorderBottomRow>

                <Row style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                  <SmallTitleCol>{t('COMMON ACTIONS')}</SmallTitleCol>
                </Row>
                <Row
                  justify='between'
                  style={{ marginTop: '1rem', marginBottom: '1rem' }}
                >
                  <ExternalLinkToOrderWizard
                    // IMPLEMENT THIS WHEN WE HAVE PROPERTY UNITS AS FILTERS
                    // to={`/de/support/createOrder?${OW_CONNECTED_IDS}={"invoice":[],"usageUnit":[${tableId}],"tenant":[]}`}
                    to='/de/support/createOrder'
                    label={t('ORDER SERVICE')}
                  />
                </Row>

                <Row
                  justify='between'
                  style={{ marginTop: '1rem', marginBottom: '1rem' }}
                >
                  <ExternalLinkToOrderWizard
                    label={t('FIRE LOAD PROCESSING')}
                    disabled
                  />
                </Row>
              </Container>
            </Col>
          </Row>
        </PropertyUnitDefaultCol>
      </Row>
    </Container>
  )
}

const PropertyUnitDefaultCol = styled(Col)`
  padding: 1rem;
  background-color: ${portalTheme.color.basicColorWhite};
`

const BorderBottomRow = styled(Row)`
  border-bottom: 1px solid #979797;
`

const SmallTitleCol = styled.div`
  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.regular};
  font-size: 12px;
`

const LabelCol = styled(Col)`
  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.regular};
  font-size: 13px;
`

const ValueCol = styled(Col)`
  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.bold};
  font-size: 16px;
`

const MapCol = styled(Col)`
  border-right: 1px solid ${portalTheme.color.linkColorSecondary};

  padding-left: 0 !important;
  padding-right: 0 !important;
  margin: -16px;
`

const MapContainerStyled = styled(MapContainer)`
  height: 100%;
  min-height: 400px;
  isolation: isolate;
`

const SkeletonRow = styled(Row)`
  display: flex;
  flex-direction: column;

  margin-bottom: 32px !important;
`

const SkeletonList = ({ numberOfItems = 3 }) => {
  return (
    <div style={{ margin: '32px 16px' }}>
      <SkeletonRow>
        <Skeleton xl style={{ paddingBottom: '12px' }} />
        <Skeleton large />
      </SkeletonRow>

      <SkeletonRow>
        <Skeleton xl style={{ paddingBottom: '12px' }} />
        <Skeleton large />
      </SkeletonRow>

      <SkeletonRow>
        <Skeleton xl style={{ paddingBottom: '12px' }} />
        <Skeleton large />
        <Skeleton large />
      </SkeletonRow>

      <SkeletonRow>
        <Skeleton xl style={{ paddingBottom: '12px' }} />
        <Skeleton small />
      </SkeletonRow>
    </div>
  )
}

PropertyUnitOverview.propTypes = {
  PropertyUnit: PropTypes.shape({
    tableId: PropTypes.number,
    address: PropTypes.object,
    coordinates: PropTypes.object,
    countUsageUnits: PropTypes.number,
  }),
  loading: PropTypes.bool,
}

export default PropertyUnitOverview
