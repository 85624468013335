import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query LinkIdsContainerQuery($companyId: ID!, $pipelineId: ID!) {
    Me {
      Company(id: $companyId) {
        OrderWizard {
          pipeline(id: $pipelineId)
        }
      }
    }
  }
`
