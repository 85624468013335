import { useCallback, useContext } from 'react'
import { withFilterParams } from '../../../../../../../../components/AgGrid/columnDefs'
import { CountUsageUnitsLinkIdsQuery, UsageUnitsLinkIdsGrid } from '../queries'
import UserContext from '../../../../../../../../helper/userContext'
import { useTranslation } from 'react-i18next'
import { Routes } from '../../../../../../../../configs/RouteConfig'
import UsageUnitIdRenderer from '../renderer/UsageUnitIdRenderer'
import {
  TenantFullNameRenderer,
  UsageUnitReferenceNumberRenderer,
} from '../renderer'
import RemoveLink from '../../renderers/RemoveLink'

export const useUsageUnitsGrid = (setConnectedIds, noRemove) => {
  const { redirectRoute } = useContext(UserContext)
  const { t } = useTranslation()

  const getParams = useCallback(
    (currentService, filterVariables) => {
      const { usageUnitsIds, connectedIds } = filterVariables
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          default:
            return params.value
        }
      }
      const UsageUnitsColumnDefs = [
        {
          headerName: t('PYREXX USAGE UNIT ID'),
          field: 'tableId',
          width: 50,
          cellRenderer: UsageUnitIdRenderer,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('ALLOCATION NUMBER'),
          field: 'tableId',
          cellRenderer: UsageUnitReferenceNumberRenderer,
          width: 100,
          ...withFilterParams,
        },
        {
          headerName: t('RESIDENT'),
          field: 'tenants',
          width: 110,
          cellRenderer: TenantFullNameRenderer,
          filter: false, // TODO: make filterable?
        },
        {
          headerName: t('STREET'),
          field: 'address.street',
          width: 100,
          ...withFilterParams,
        },
        {
          headerName: t('STREET NR.'),
          field: 'address.number',
          width: 70,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('ZIP'),
          field: 'address.zip',
          width: 70,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('CITY'),
          field: 'address.city',
          width: 70,
          ...withFilterParams,
        },
      ]
      if (!noRemove) {
        UsageUnitsColumnDefs.push({
          headerName: t('REMOVE FROM LINKS'),
          field: 'tableId',
          hide: false,
          width: 150,
          type: 'rightAligned',
          cellRenderer: RemoveLink,
          cellRendererParams: {
            setConnectedIds: setConnectedIds,
            removeLinkIdFromState: (value) => {
              console.log('DEBUG FUNCTION REMOVE connectedIds', connectedIds)
              setConnectedIds({
                ...connectedIds,
                usageUnit: connectedIds.usageUnit.filter((id) => id !== value),
              })
            },
          },
        })
      }
      const UsageUnitsColumnDefsCustomFilter = [
        {
          name: 'tableId',
          filterType: 'text',
          operator: 'AND',
          conditions: [
            {
              value: usageUnitsIds,
              type: 'equals',
            },
          ],
        },
      ]
      return {
        query: UsageUnitsLinkIdsGrid,
        columnDefs: UsageUnitsColumnDefs,
        customFilters: UsageUnitsColumnDefsCustomFilter,
        countQuery: CountUsageUnitsLinkIdsQuery,
        extractor: (response) => response.Me?.Company?.data,
        customGridOptions: {
          getContextMenuItems: (params) => {
            return [
              {
                // custom item
                name: t('GO TO USAGE UNIT {TABLEID}', {
                  TABLEID: params?.node?.data?.tableId,
                }),
                action: function () {
                  redirectRoute(
                    Routes.usageUnit + '/' + params?.node?.data?.tableId
                  )
                },
              },
              'copy',
            ]
          },
          frameworkComponents: {},
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
          },
        },
        customVariables: {},
      }
    },
    [noRemove, redirectRoute, setConnectedIds, t]
  )
  return { getParams }
}
