import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query SmokeAlarmsInStorageGridQuery(
    $companyId: ID!
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
  ) {
    Me {
      Company(id: $companyId) {
        data: UsageUnits(
          options: {
            startRow: $startRow
            endRow: $endRow
            sorting: $sortModel
            filters: $filters
          }
        ) {
          id
          tableId
          referenceNumber
          address {
            street
            number
            zip
          }
          floor
          tenants {
            firstname
            lastname
            id
          }
          px1: countPX1InSafeKeeping
          px1c: countPX1CInSafeKeeping
          registrations {
            appointment {
              executionDate
            }
          }
          lockPeriods(
            options: {
              endRow: -1
              sorting: [{ colId: "begin", sort: "DESC" }]
              filters: [
                {
                  name: "status"
                  filterType: text
                  operator: AND
                  conditions: [{ value: "ACTIVE", type: equals }]
                }
                {
                  name: "reason"
                  filterType: text
                  operator: AND
                  conditions: [
                    { value: "SMOKE_DETECTORS_IN_SAFE_KEEPING", type: equals }
                  ]
                }
              ]
            }
          ) {
            id
            status
            begin
            end
            reason
          }
        }
        id
      }
      id
    }
  }
`
