import formatDateLocale from './formatDateLocale'
import { serviceCategoryEnumList } from '../components/AgGrid/enumLists'
import {
  filterOpen,
  filterPaid,
  filterReminded,
} from '../constants/invoiceFilterModels'
import {
  filterExecuted,
  filterNotExecuted,
  filterPending,
  filterRegular,
  filterRegularExecuted,
  filterRegularNotExecuted,
  filterRegularPending,
  filterService,
  filterServiceExecuted,
  filterServiceNotExecuted,
  filterServicePending,
  filterSpecial,
  filterSpecialExecuted,
  filterSpecialNotExecuted,
  filterSpecialPending,
} from '../constants/appointmentFilterModels'
import {
  BOX_FILTER,
  CHANNEL,
  CONTRACT,
  DATE_FROM,
  DATE_TO,
  SERVICE,
} from '../configs/FilterConfig'

import { translateOrderWizard } from '../routes/Support/Enquiries/translations/translateOrderWizard'

// DATE
export const getDateFilterModel = (
  { tableId = 'date filter', from = '', to = '' } = {},
  t = (v) => v,
  customDateFilterKey = 'date',
  currentLanguage = 'de'
) => {
  const formattedFrom = formatDateLocale(currentLanguage, from, 'P')
  const formattedTo = formatDateLocale(currentLanguage, to, 'P')

  const label = formattedFrom + ' - ' + formattedTo
  const value = [from, to]

  // Api compatible filter condition
  const apiFilterConditions =
    value.length > 0
      ? [
          {
            value,
            type: 'inRange',
          },
        ]
      : []

  // AgGrid compatible filter mdoel
  const filterModel = {
    [customDateFilterKey]: {
      filterType: 'date',
      type: 'inRange',
      dateFrom: from,
      dateTo: to,
    },
  }

  if (value[0] === '' || value[1] === '') {
    return null
  }

  return {
    filterId: 'date',
    tableId: tableId.toString(),
    label,
    value,
    apiFilterModel: {
      // IMPORTANT: dateFilter equals the defaultFilters object in every Overview page.
      // Those are important for the filter Query
      dateFilter: apiFilterConditions,
    },
    filterModel,
    urlSearchParams: [DATE_FROM, DATE_TO],
  }
}

// SERVICE
export const getServiceFilterModel = (
  { tableId = '', name = '' } = {},
  t = (v) => v,
  customServiceFilterKey = 'contract.serviceCategory.tableId'
) => {
  const label = t(serviceCategoryEnumList(name)) || ''
  const value = tableId.toString()

  const apiFilterConditions = [
    {
      value,
      type: 'equals',
    },
  ]

  const filterModel = {
    [customServiceFilterKey]: {
      filterType: 'text',
      operator: 'AND',
      condition1: {
        filterType: 'text',
        type: 'equals',
        filter: value,
      },
    },
  }

  if (value === '') {
    return null
  }

  return {
    filterId: 'service',
    tableId: tableId.toString(),
    label,
    value,
    apiFilterModel: {
      // IMPORTANT: serviceFilter equals the defaultFilters object in every Overview page
      // Those are important for the filter Query
      serviceFilter: apiFilterConditions,
    },
    filterModel,
    urlSearchParams: [SERVICE],
  }
}

// CONTRACT
export const getContractFilterModel = (
  { tableId = '', combinedIds = [], name = '' } = {},
  t,
  customContractFilterKey = 'contract'
) => {
  const label = name

  const value =
    combinedIds.length > 0
      ? combinedIds.map((id) => id.toString())
      : tableId.toString()

  const apiFilterConditions = [
    {
      value,
      type: 'equals',
    },
  ]

  const filterModel = {
    [customContractFilterKey]: {
      filterType: 'text',
      operator: 'AND',
      condition1: {
        filterType: 'text',
        type: 'equals',
        filter: value,
      },
    },
  }

  if (value === '' || value.length <= 0) {
    return null
  }

  return {
    filterId: 'contract',
    tableId: tableId.toString(),
    label,
    value,
    apiFilterModel: {
      // IMPORTANT: contractFilter equals the defaultFilters object in every Overview page
      // Those are important for the filter Query
      contractFilter: apiFilterConditions,
    },
    filterModel,
    urlSearchParams: [CONTRACT],
  }
}

// CHANNEL
export const getChannelFilterModel = (
  { tableId = '', name = '' } = {},
  t = (v) => v,
  customChannelFilterKey = 'channel'
) => {
  const label = translateOrderWizard(name, t)
  const value = tableId.toString()

  const apiFilterConditions = [
    {
      value,
      type: 'equals',
    },
  ]

  const filterModel = {
    [customChannelFilterKey]: {
      filterType: 'text',
      operator: 'AND',
      condition1: {
        filterType: 'text',
        type: 'equals',
        filter: value,
      },
    },
  }

  if (value === '') {
    return null
  }

  return {
    filterId: 'channel',
    tableId: tableId.toString(),
    label,
    value,
    apiFilterModel: {
      // IMPORTANT: channelFilter equals the defaultFilters object in every Overview page
      // Those are important for the filter Query
      channelFilter: apiFilterConditions,
    },
    filterModel,
    urlSearchParams: [CHANNEL],
  }
}

export const getAppointmentsFilterModel = (filter = '', label = '') => {
  switch (filter) {
    case 'executed':
      return {
        filterId: 'box-executed',
        label,
        filterModel: filterExecuted,
        urlSearchParams: [BOX_FILTER],
      }

    case 'pending':
      return {
        filterId: 'box-pending',
        label,
        filterModel: filterPending,
        urlSearchParams: [BOX_FILTER],
      }

    case 'not-executed':
      return {
        filterId: 'box-not-executed',
        label,
        filterModel: filterNotExecuted,
        urlSearchParams: [BOX_FILTER],
      }

    case 'regular':
      return {
        filterId: 'box-regular',
        filterRow: 'regular',
        label,
        filterModel: filterRegular,
        urlSearchParams: [BOX_FILTER],
      }

    case 'regularExecuted':
      return {
        filterId: 'box-regularExecuted',
        filterRow: 'regular',
        label,
        filterModel: filterRegularExecuted,
        urlSearchParams: [BOX_FILTER],
      }

    case 'regularPending':
      return {
        filterId: 'box-regularPending',
        filterRow: 'regular',
        label,
        filterModel: filterRegularPending,
        urlSearchParams: [BOX_FILTER],
      }

    case 'regularNotExecuted':
      return {
        filterId: 'box-regularNotExecuted',
        filterRow: 'regular',
        label,
        filterModel: filterRegularNotExecuted,
        urlSearchParams: [BOX_FILTER],
      }

    case 'service':
      return {
        filterId: 'box-service',
        filterRow: 'service',
        label,
        filterModel: filterService,
        urlSearchParams: [BOX_FILTER],
      }

    case 'serviceExecuted':
      return {
        filterId: 'box-serviceExecuted',
        filterRow: 'service',
        label,
        filterModel: filterServiceExecuted,
        urlSearchParams: [BOX_FILTER],
      }

    case 'servicePending':
      return {
        filterId: 'box-servicePending',
        filterRow: 'service',
        label,
        filterModel: filterServicePending,
        urlSearchParams: [BOX_FILTER],
      }

    case 'serviceNotExecuted':
      return {
        filterId: 'box-serviceNotExecuted',
        filterRow: 'service',
        label,
        filterModel: filterServiceNotExecuted,
        urlSearchParams: [BOX_FILTER],
      }

    case 'special':
      return {
        filterId: 'box-special',
        filterRow: 'special',
        label,
        filterModel: filterSpecial,
        urlSearchParams: [BOX_FILTER],
      }

    case 'specialExecuted':
      return {
        filterId: 'box-specialExecuted',
        filterRow: 'special',
        label,
        filterModel: filterSpecialExecuted,
        urlSearchParams: [BOX_FILTER],
      }

    case 'specialPending':
      return {
        filterId: 'box-specialPending',
        filterRow: 'special',
        label,
        filterModel: filterSpecialPending,
        urlSearchParams: [BOX_FILTER],
      }

    case 'specialNotExecuted':
      return {
        filterId: 'box-specialNotExecuted',
        filterRow: 'special',
        label,
        filterModel: filterSpecialNotExecuted,
        urlSearchParams: [BOX_FILTER],
      }

    default:
      return null
  }
}

export const getInvoicesFilterModel = (filter = '', label = '') => {
  switch (filter) {
    case 'paid':
      return {
        filterId: 'box-paid',
        filterRow: 'default',
        label,
        filterModel: filterPaid,
        urlSearchParams: [BOX_FILTER],
      }

    case 'open':
      return {
        filterId: 'box-open',
        filterRow: 'default',
        label,
        filterModel: filterOpen,
        urlSearchParams: [BOX_FILTER],
      }

    case 'reminded':
      return {
        filterId: 'box-reminded',
        filterRow: 'default',
        label,
        filterModel: filterReminded,
        urlSearchParams: [BOX_FILTER],
      }

    default:
      return null
  }
}
