import React, { useContext } from 'react'

import SimpleLink from '../../../../../../../../components/Simple/SimpleLink'

import { Routes } from '../../../../../../../../configs/RouteConfig'
import UserContext from '../../../../../../../../helper/userContext'

const UsageUnitReferenceNumberRenderer = (props) => {
  const { value } = props

  const { currentLanguage } = useContext(UserContext)

  return (
    <SimpleLink
      to={'/' + currentLanguage + Routes.usageUnit + '/' + value}
      style={{ fontSize: 'inherit' }}
    >
      {props.data.referenceNumber}
    </SimpleLink>
  )
}

export default UsageUnitReferenceNumberRenderer
