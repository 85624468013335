import React from 'react'
import { useTranslation } from 'react-i18next'

import PageContainer from '../../components/PageContainer'
import PageTitle from '../../components/PageTitle'
import AppointmentsOverview from './AppointmentsOverview'

import PyrexxOverviewProvider from '../../providers/PyrexxOverviewProvider'
import { useAgGridUltra } from '../../components/AgGrid/AgGridUltra/hooks/useAgGridUltra'
import { usePropertyGrid } from './gridConfigHooks/usePropertyGrid'
import { useUsageUnitGrid } from './gridConfigHooks/useUsageUnitGrid'
import Spacer from '../../components/Spacer'

import handleSearchParamsFromUrl from '../../helper/handleSearchParamsFromUrl'
import {
  getDateFilterModel,
  getServiceFilterModel,
  getContractFilterModel,
  getAppointmentsFilterModel,
} from '../../helper/getFilterModel'

const Appointments = () => {
  const { t } = useTranslation()

  const grids = [
    {
      id: 'properties-grid',
      label: t('PROPERTIES'),
      useGetParams: usePropertyGrid(),
    },
    {
      id: 'usage-unit-grid',
      label: t('USAGE UNITS'),
      useGetParams: useUsageUnitGrid(),
    },
  ]

  const { generateGrid, setFilterModel, generateTabs } = useAgGridUltra(
    'Appointments',
    grids
  )

  const { from, to, service, serviceName, contract, contractName, boxFilter } =
    handleSearchParamsFromUrl()

  const initialStateUiFilters = [
    getDateFilterModel({ from, to }),
    getServiceFilterModel({ tableId: service, name: serviceName }, t),
    getContractFilterModel({ tableId: contract, name: contractName }),
  ]
  const initialStateBoxFilters = [getAppointmentsFilterModel(boxFilter)]

  return (
    <PyrexxOverviewProvider
      initialStateUiFilters={initialStateUiFilters}
      initialStateBoxFilters={initialStateBoxFilters}
    >
      <PageContainer>
        <PageTitle>{t('APPOINTMENTS')}</PageTitle>
        {/* <Tabs tabs={OverviewTabs} /> */}
        <AppointmentsOverview setFilterModel={setFilterModel} />
        <Spacer />
        {generateTabs({
          description: t('APPOINTMENTS BY'),
          tabStyles: { marginTop: '32px', marginBottom: '24px' },
        })}
        {generateGrid()}
      </PageContainer>
    </PyrexxOverviewProvider>
  )
}
export default Appointments
