import React from 'react'

import QueryRendererContainer from '../../../../components/QueryRendererContainer/QueryRendererContainer'
import LoadingBox from '../../../../components/LoadingSpinner/LoadingBox'
import { LinkIds } from './LinkIds'

import LinkIdsContainerQuery from './queries/LinkIdsContainer'
import { useUserContext } from '../../../../helper/userContext'
import { hasLinkables } from './helpers/hasLinkables'

export const LinkIdsContainer = (props) => {
  const {
    reasonId,
    pipelineObject,
    setPipelineObject,
    setCurrentStep,
    connectedIds,
    setConnectedIds,
    completeCatObject,
    currentStep,
  } = props
  const { user } = useUserContext()

  console.log('LinkIdsContainer - reasonID: ', reasonId)

  return (
    <QueryRendererContainer
      query={LinkIdsContainerQuery}
      variables={{
        companyId: user.companyId,
        pipelineId: reasonId,
      }}
      loading={<LoadingBox spinner />}
      render={(data) => {
        const rawPipeline = data.Me?.Company?.OrderWizard.pipeline || {}
        const pipeline = JSON.parse(rawPipeline)

        if (hasLinkables(pipeline)) {
          return (
            <LinkIds
              pipelineObject={pipelineObject}
              setPipelineObject={setPipelineObject}
              setCurrentStep={setCurrentStep}
              currentStep={currentStep}
              completeCatObject={completeCatObject}
              connectedIds={connectedIds}
              setConnectedIds={setConnectedIds}
              reasonId={reasonId}
              pipeline={pipeline}
            />
          )
        } else {
          setCurrentStep(3)
          return null
        }
      }}
    />
  )
}
