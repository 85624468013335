import graphql from 'babel-plugin-relay/macro'

const TicketQuery = graphql`
  query TicketQuery($companyId: ID!, $ticket: ID!) {
    Me {
      Company(id: $companyId) {
        OrderWizard {
          ticket(id: $ticket) {
            id
            tableId
            zammadTicketId
            referenceNumber
            status
            subject
            content
            user {
              id
              username
              salutation
              firstname
              lastname
              last_activity
              email
            }
            creationDate
            lastUpdate
            reason {
              name
              pipeline
            }
            topic {
              name
            }
            channel {
              name
            }
            relations {
              tenant {
                id
                tableId
                fullname
                usageUnit {
                  referenceNumber
                  address {
                    street
                    number
                    zip
                    city
                  }
                }
              }
              usageUnit {
                id
                tableId
                tenants {
                  status
                  firstname
                  lastname
                }
                address {
                  street
                  number
                  zip
                  city
                }
              }
              invoice {
                id
                tableId
                invoiceNumber
              }
              contract {
                id
                tableId
              }
              appointment {
                id
                tableId
              }
              houseEntrance {
                id
                tableId
                address {
                  street
                  number
                  zip
                  city
                }
              }
            }
            attachments {
              id
              tableId
              name
              download {
                name
                path
                token
              }
            }
          }
        }
      }
    }
  }
`

export default TicketQuery
