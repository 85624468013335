import React from 'react'

import PortfolioOverview from './PortfolioOverview'
import Spacer from '../../components/Spacer'
import PortfolioGrids from './PortfolioGrids'

const Portfolio = (props) => {
  const { services = [], loading = false, houseEntrancesCount = 0 } = props

  return (
    <>
      <PortfolioOverview
        houseEntrancesCount={houseEntrancesCount}
        loading={loading}
      />
      <Spacer />
      <PortfolioGrids services={services} loading={loading} />
    </>
  )
}

export default Portfolio
