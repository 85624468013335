import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query LetterQuery($companyId: ID!, $letterId: ID!) {
    Me {
      Company(id: $companyId) {
        businessPost {
          letter(id: $letterId) {
            id
            tableId
            companyId
            type
            sender {
              id
              tableId
              firstname
              name
              salutation
              title
              username
            }
            attachments {
              id
              tableId
              filename
              extension
              mimeType
              name
            }
            replacements {
              name
              value
            }
            recipients {
              id
              salutation
              title
              firstname
              lastname
              street
              streetNumber
              zip
              city
              country
              status
              uniqueReference
              tableId
              rawData
            }
            status
            internalTitle
            title
            createdAt
            sentAt
            pageCount
            recipient1Salutation
            recipient1Title
            recipient1FirstName
            recipient1LastName
            recipient2Salutation
            recipient2Title
            recipient2FirstName
            recipient2LastName
            recipientCompany
            addressStreetAndNo
            addressAdditional
            addressZip
            enqueuePreCheck
            addressCity
            addressCountry
            layout
            preview
            variables
          }
        }
      }
    }
  }
`
