import React from 'react'
import { Col, Row } from 'react-grid-system'
import { ButtonIcon, ButtonSecondary, SimpleNote } from 'pyrexx-react-library'
import styled from 'styled-components/macro'

const StyledConfirmOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.99;
  background-color: #fff;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding: 10px;
`

const ConfirmOverlay = (props) => {
  const {
    cancelClick,
    confirmClick,
    confirmButtonLabel,
    name,
    infoText,
    noteColor,
  } = props

  return (
    <StyledConfirmOverlay>
      <div style={{ position: 'sticky', top: '0' }}>
        <Row justify='end'>
          <Col xs='content'>
            <ButtonIcon
              icon='close'
              size='20px'
              style={{ color: 'black' }}
              onClick={() => {
                cancelClick()
              }}
            />
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: '1rem' }}>
          <Col xs='content'>{name}</Col>
        </Row>
        <Row justify='center' style={{ marginTop: '1rem' }}>
          <Col xs='content'>
            <SimpleNote
              text={infoText}
              noteStatus={noteColor}
              style={{ fontSize: '14px' }}
            />
          </Col>
        </Row>
        <Row justify='center' style={{ marginTop: '1rem' }}>
          <Col xs='content'>
            <ButtonSecondary
              onClick={() => {
                confirmClick()
              }}
              style={{
                margin: '0px',
                height: '2rem',
                paddingLeft: '1rem',
                paddingRight: '1rem',
              }}
            >
              {confirmButtonLabel}
            </ButtonSecondary>
          </Col>
        </Row>
      </div>
    </StyledConfirmOverlay>
  )
}
export default ConfirmOverlay
