// https://onlinehtmleditor.dev/
export const ticketBasis = {
  ticketinfo: {
    ticketinfoowner: 'New owner',
    ticketinfodate: '28.12.2022',
    ticketinfocompanyName: 'Büth Gmbh',
    ticketinfocompanyAdress: 'Adresse 01, Berlin 11111',
    ticketinfocompanyEmail: 'buth@buth.com',
    ticketinfocompanyTelephone: '11223344',
    ticketinfoconsultationData: {
      ticketinfoconsultationfirstname: 'Muster',
      ticketinfoconsultationlastname: 'Braun',
      ticketinfoconsultationemail: 'braun@email.com',
      ticketinfoconsultationtelephone: '55667788',
    },
  },
  ticketstatus: {
    ticketstatus: 'Bearbeitung',
    ticketnumber: '123456789',
    ticketcreationDate: '19.10.2021 13:34',
    ticketlastUpdated: '20.10.2021 13:34',
    ticketcategory: 'Rachwarnmelder / Instansetzug',
    ticketcommissionNumber: '42022.6003',
    ticketcommissionReason: 'abfgefallen-RWM',
    ticketcontractGiver: {
      firstname: 'Sebastino',
      lastname: 'Lastname',
      email: 'mustermann@email.com',
      phone: '+49 223 4231 234',
    },
  },
  chat: [
    {
      id: '1',
      sender: 'user',
      msg:
        'Sehr geehrte Damen und Herren,\n' +
        ' \n' +
        'mit heutigem Datum haben wir eine große Anzahl Schlussrechnungen erhalten, die von uns geprüft werden müssen,\n' +
        'daher bitten wir um Verlängerung der Zahlungsfrist bis zum 31.01.2023.\n' +
        ' \n' +
        'Vielen Dank.\n' +
        ' \n' +
        'Mit freundlichen Grüßen aus Moers\n' +
        'Elke Roeber\n' +
        ' \n' +
        'STADTBAU MOERS\n' +
        'Entwicklungs-, Erschließungs- und Verwaltungsgesellschaft mbH\n' +
        'Vinzenzstr. 37\n' +
        'DE-47441 Moers\n',
    },
    {
      id: '2',
      sender: 'pyrexx',
      msg:
        'Pyrexx:\n' +
        '\n' +
        '\n' +
        'vielen Dank für Ihre Nachricht.\n' +
        ' \n' +
        'Nach unteren Unterlagen sind aktuell Rechnungen über insgesamt 2.323,98 EUR offen. Wir bitten um Ausgleich mindestens des halben Betrages bis 15.01.2023, den Rest bis 31.01.2023\n',
    },
    { id: '3', sender: 'user', msg: 'Third message' },
    // { id: '4', sender: 'user', msg: 'Forth message' },
  ],
  ticketlinking: [
    {
      ticketlinkinglabel: 'Invoice',
      ticketlinkinglabelstatus: 'blue',
      ticketlinkingnumber: '852518',
    },
    {
      ticketlinkinglabel: 'performance',
      ticketlinkinglabelstatus: 'green',
      ticketlinkingnumber: '852518',
      ticketlinkingname: 'Muster mann',
      ticketlinkingaddress: 'Musterstr. 100, 101111 Mustercity',
    },
  ],
}

export const longChat = {
  chat: [
    { id: '1', sender: 'user', msg: '1st message' },
    { id: '2', sender: 'pyrexx', msg: '2nd message' },
    { id: '3', sender: 'user', msg: '3rd message' },
    { id: '4', sender: 'pyrexx', msg: '4th message' },
    { id: '5', sender: 'user', msg: '5th message' },
    { id: '6', sender: 'pyrexx', msg: '6th message' },
    { id: '7', sender: 'user', msg: '7th message' },
    { id: '8', sender: 'pyrexx', msg: '8th message' },
    { id: '9', sender: 'user', msg: '9th message' },
  ],
}

const thirdExample = {
  chat: [
    {
      id: '1',
      sender: 'user',
      msg:
        '<p>Sehr geehrte Damen und Herren,</p>\n' +
        '\n' +
        '<p>&nbsp;</p>\n' +
        '\n' +
        '<p>mit heutigem Datum haben wir eine gro&szlig;e Anzahl Schlussrechnungen erhalten, die von uns gepr&uuml;ft werden m&uuml;ssen,</p>\n' +
        '\n' +
        '<p>daher bitten wir um Verl&auml;ngerung der Zahlungsfrist bis zum 31.01.2023.</p>\n' +
        '\n' +
        '<p>&nbsp;</p>\n' +
        '\n' +
        '<p>Vielen Dank.</p>\n' +
        '\n' +
        '<p>&nbsp;</p>\n' +
        '\n' +
        '<p>Mit freundlichen Gr&uuml;&szlig;en aus Moers</p>\n' +
        '\n' +
        '<p>Elke Roeber</p>\n' +
        '\n' +
        '<p>&nbsp;</p>\n' +
        '\n' +
        '<p>STADTBAU MOERS</p>\n' +
        '\n' +
        '<p>Entwicklungs-, Erschlie&szlig;ungs- und Verwaltungsgesellschaft mbH</p>\n' +
        '\n' +
        '<p>Vinzenzstr. 37</p>\n' +
        '\n' +
        '<p>DE-47441 Moers</p>',
    },
    {
      id: '2',
      sender: 'pyrexx',
      msg:
        '<p>vielen Dank f&uuml;r Ihre Nachricht.</p>\n' +
        '\n' +
        '<p>&nbsp;</p>\n' +
        '\n' +
        '<p>Nach unteren Unterlagen sind aktuell Rechnungen &uuml;ber insgesamt 2.323,98 EUR offen. Wir bitten um Ausgleich mindestens des halben Betrages bis 15.01.2023, den Rest bis 31.01.2023</p>',
    },
  ],
}

const fourthExample = {
  chat: [
    {
      id: '1',
      sender: 'user',
      msg:
        '<p>Sehr geehrte Damen und Herren,</p>\n' +
        '\n' +
        '<p>die Forderung aus Ihrer o. g. Rechnung weisen wir zur&uuml;ck.</p>\n' +
        '\n' +
        '<p>Die ordnungsgem&auml;&szlig;e Funktion der Rauchwarnmelder liegt gem. Vertrag in Ihrem Verantwortungsbereich.</p>\n' +
        '\n' +
        '<p>Es handelte sich hier um einen Fehlalarm, der in Abwesenheit der Mieter ausgel&ouml;st wurde.</p>\n' +
        '\n' +
        '<p>Wir bitten um Stornierung der o. g. Rechnung.</p>\n' +
        '\n' +
        '<p>&nbsp;</p>\n' +
        '\n' +
        '<p>Mit freundlichen Gr&uuml;&szlig;en</p>\n' +
        '\n' +
        '<p>Verena Schulze</p>\n' +
        '\n' +
        '<p>CONCORDE Grundbesitz- und Verwaltungsges. mbH</p>\n' +
        '\n' +
        '<p>Wenckebachstra&szlig;e 21</p>\n' +
        '\n' +
        '<p>12099 Berlin</p>\n' +
        '\n' +
        '<p>Telefon: (030) 75 44 33 20</p>\n' +
        '\n' +
        '<p>Telefax: (030) 75 44 33 21</p>',
    },
    {
      id: '2',
      sender: 'pyrexx',
      msg:
        '<p>Sehr geehrte Frau Schulze,</p>\n' +
        '\n' +
        '<p>&nbsp;</p>\n' +
        '\n' +
        '<p>vielen Dank f&uuml;r Ihre Nachricht.</p>\n' +
        '\n' +
        '<p>&nbsp;</p>\n' +
        '\n' +
        '<p>Im Anhang &uuml;bersenden wir Ihnen ein Foto des betroffenen Rauchwarnmelders.</p>\n' +
        '\n' +
        '<p>Das Ger&auml;t wurde fremdge&ouml;ffnet und dabei zerst&ouml;rt. Da es sich also nicht um einen Gew&auml;hrleistungsfall handelt, bitten wir um Ausgleich der Rechnung.</p>\n' +
        '\n' +
        '<p>&nbsp;</p>\n' +
        '\n' +
        '<p>Wir haben Ihnen aus Kulanz und ohne Anerkennung einer Rechtspflicht haben wir Ihnen f&uuml;r diese Rechnung einen Mahnstopp bis 20.12.2022 eingerichtet.</p>\n' +
        '\n' +
        '<p>&nbsp;</p>\n' +
        '\n' +
        '<p>F&uuml;r R&uuml;ckfragen stehen wir stets zur Verf&uuml;gung.</p>\n' +
        '\n' +
        '<p>&nbsp;</p>\n' +
        '\n' +
        '<p>Mit freundlichen Gr&uuml;&szlig;en</p>',
    },
  ],
}

const fifthExample = {
  chat: [
    {
      id: '1',
      sender: 'user',
      msg:
        '<p>Sehr geehrter Herr M&auml;dtke,</p>\n' +
        '\n' +
        '<p>erg&auml;nzend zu unserem Widerspruch zur o. g . Rechnung &uuml;bersenden wir Ihnen den Polizeibericht mit der Bitte um erneute Pr&uuml;fung des Sachverhaltes.</p>\n' +
        '\n' +
        '<p>&nbsp;</p>\n' +
        '\n' +
        '<p>Mit freundlichen Gr&uuml;&szlig;en</p>\n' +
        '\n' +
        '<p>Verena Schulze</p>\n' +
        '\n' +
        '<p>CONCORDE Grundbesitz- und Verwaltungsges. mbH</p>\n' +
        '\n' +
        '<p>Wenckebachstra&szlig;e 21</p>\n' +
        '\n' +
        '<p>12099 Berlin</p>\n' +
        '\n' +
        '<p>Telefon: (030) 75 44 33 20</p>\n' +
        '\n' +
        '<p>Telefax: (030) 75 44 33 21</p>\n' +
        '\n' +
        '<p>&nbsp;</p>\n' +
        '\n' +
        '<p>&nbsp;\n' +
        '<p>1 ANH&Auml;NGENDE DATEI(EN)</p>\n' +
        '</p>\n' +
        '\n' +
        '<p><a href="https://ticket.dp-pyrexx.de/api/v1/ticket_attachment/11299/32848/95724?disposition=attachment">Feuerwehreinsatz.pdf</a></p>\n' +
        '\n' +
        '<p><a href="https://ticket.dp-pyrexx.de/api/v1/ticket_attachment/11299/32848/95724?disposition=attachment">207 KB</a></p>',
    },
  ],
}

const simpleService = {
  chat: [
    {
      id: '1',
      sender: 'user',
      msg:
        '<p>Guten Tag,</p>\n' +
        '\n' +
        '<p>&nbsp;</p>\n' +
        '\n' +
        '<p>als Anlage erhalten Sie einen neuen Auftrag.</p>\n' +
        '\n' +
        '<p>&nbsp;</p>\n' +
        '\n' +
        '<p>Freundliche Gr&uuml;&szlig;e</p>\n' +
        '\n' +
        '<p>Ihre LEG</p>\n' +
        '\n' +
        '<p>&nbsp;</p>\n' +
        '\n' +
        '<p>LEG Wohnen NRW GmbH</p>\n' +
        '\n' +
        '<p>Ein Unternehmen der LEG-Immobilien-Gruppe</p>\n' +
        '\n' +
        '<p>&nbsp;</p>\n' +
        '\n' +
        '<p>Tel. 02 11 / 740 740-0</p>\n' +
        '\n' +
        '<p>Flughafenstra&szlig;e 99</p>\n' +
        '\n' +
        '<p>40474 D&uuml;sseldorf</p>\n' +
        '\n' +
        '<p><a href="http://www.leg-se.com/">www.leg-se.com</a></p>\n' +
        '\n' +
        '<p>&nbsp;</p>\n' +
        '\n' +
        '<p>Sitz der Gesellschaft: D&uuml;sseldorf, Registergericht: D&uuml;sseldorf HRB 49024</p>\n' +
        '\n' +
        '<p>Aufsichtsratsvorsitzender: Lars von Lackum, Gesch&auml;ftsf&uuml;hrung: Dr. Volker</p>\n' +
        '\n' +
        '<p>Wiegel, Ulrike Janssen, Dr. Kathrin K&ouml;hling, Markus Goretzky, Andreas</p>\n' +
        '\n' +
        '<p>Miltz</p>\n' +
        '\n' +
        '<p>&nbsp;</p>\n' +
        '\n' +
        '<p>Als Verantwortliche m&ouml;chten wir Sie auf Folgendes hinweisen: die Dienstleistungen der LEG erfordern, personenbezogene Daten von Ihnen zu verarbeiten. Details zu der in der LEG-Gruppe erfolgenden Datenverarbeitung, Ihren Rechten - einschlie&szlig;lich der M&ouml;glichkeit zum Widerspruch / Widerruf - und den Datenschutz-Ansprechpartnern erhalten Sie unter <a href="http://www.leg-wohnen.de/unternehmen/datenschutz%3Chttp://www.leg-wohnen.de/unternehmen/datenschutz%3E">www.leg-wohnen.de/unternehmen/datenschutz</a>. Die Informationen zur Datenverarbeitung k&ouml;nnen auch telefonisch unter 0211 740 7400 per Post angefordert werden.</p>\n' +
        '\n' +
        '<p>&nbsp;</p>\n' +
        '\n' +
        '<p>&nbsp;\n' +
        '<p>1 ANH&Auml;NGENDE DATEI(EN)</p>\n' +
        '</p>\n' +
        '\n' +
        '<p><a href="https://ticket.dp-pyrexx.de/api/v1/ticket_attachment/11313/32772/95521?disposition=attachment">Bestellung 1001435777.pdf</a></p>\n' +
        '\n' +
        '<p><a href="https://ticket.dp-pyrexx.de/api/v1/ticket_attachment/11313/32772/95521?disposition=attachment">17 KB</a></p>\n',
    },
  ],
}

const inhabitantChange = {
  chat: [
    {
      id: '1',
      sender: 'user',
      msg:
        '<p>Nutzereinheit 953250 &nbsp; (Bornholmer Str. 81a; HH 4.OG re.): {{{ USAGE UNIT: KZN}} {{FULL ADRESS AND POSITION}}</p>\n' +
        '\n' +
        '<p>Alte Bewohner/in: Laura Graf</p>\n' +
        '\n' +
        '<p>Neue Bewohner/in: Eva Deutler</p>',
    },
  ],
}

const ticketData = {
  1: ticketBasis,
  2: { ...ticketBasis, ...longChat },
  3: { ...ticketBasis, ...thirdExample },
  4: { ...ticketBasis, ...fourthExample },
  5: { ...ticketBasis, ...fifthExample },
  6: { ...ticketBasis, ...simpleService },
  7: { ...ticketBasis, ...inhabitantChange },
}

export default ticketData
