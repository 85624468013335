import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { css } from 'styled-components'

import EmptyButton from '../Simple/EmptyButton'

const isSelectedCSS = css`
  border-bottom-color: #83a8cb;

  &:before {
    border-left-color: #83a8cb;
  }

  &:after {
    border-right-color: #83a8cb;
  }
`

const Wrapper = styled(EmptyButton)`
  position: relative;

  display: inline-flex;

  padding: 4px;
  padding-top: 0;
  border-bottom: 5px solid transparent;
  border-radius: 2px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: calc(100% + 5px);
    width: 0;
    height: 0;
    border-bottom: 6px solid transparent;
    transition: 200ms ease-in;
  }

  &:before {
    left: 50%;
    border-left: 6px solid transparent;
  }

  &:after {
    right: 50%;
    border-right: 6px solid transparent;
  }

  outline: none;
  transition: 200ms ease-in;

  &:hover,
  &:focus {
    ${isSelectedCSS}
  }

  &:active {
    ${isSelectedCSS}
  }

  ${(props) => props.isSelected && isSelectedCSS}
`

const Tab = ({
  id = '',
  label = 'provide a label',
  isSelected = false,
  ...restOfProps
}) => {
  return (
    <Wrapper data-testid={id} isSelected={isSelected} {...restOfProps}>
      {label}
    </Wrapper>
  )
}

Tab.propTypes = {
  label: PropTypes.string,
  isSelected: PropTypes.any,
  onClick: PropTypes.func,
}

export default Tab
