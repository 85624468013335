import React from 'react'
import { useTranslation } from 'react-i18next'

import PageContainer from '../../components/PageContainer'
import PageTitle from '../../components/PageTitle'
import Tabs from '../../components/Tabs'
import InvoicesOverviewPage from './InvoicesOverviewPage'
import InvoicesServiceCosts from './InvoicesServiceCosts'

import QueryRendererContainer from '../../components/QueryRendererContainer'
import { ContractsQuery } from '../Contracts/queries'

import useUrlRedirection from '../../hooks/useUrlRedirection'
import getTimeframes from '../../helper/getTimeframes'
import getServices from '../../helper/getServices'
import getContracts from '../../helper/getContracts'
import { useUserContext } from '../../helper/userContext'

const PureInvoices = ({ pageSection = '', contracts = [] }) => {
  const { t } = useTranslation()
  const urlRedirectionCallback = useUrlRedirection()

  const contractsProps = {
    timeframesList: getTimeframes(contracts),
    servicesList: getServices(contracts),
    contractsList: getContracts(contracts),
  }

  console.log(contractsProps)

  const tabs = [
    {
      key: 'overview',
      label: t('INVOICES OVERVIEW'),
      content: <InvoicesOverviewPage contractsProps={contractsProps} />,
    },
    {
      key: 'service-costs',
      label: t('PROOF OF PERFORMANCE'),
      content: <InvoicesServiceCosts contractsProps={contractsProps} />,
    },
  ]

  return (
    <Tabs
      tabs={tabs}
      pageSection={pageSection}
      urlRedirectionCallback={urlRedirectionCallback}
    />
  )
}

const Invoices = ({ pageSection = '' }) => {
  const { t } = useTranslation()
  const { user } = useUserContext()

  const variables = {
    companyId: user.companyId,
  }

  return (
    <PageContainer>
      <PageTitle>{t('INVOICES')}</PageTitle>

      <QueryRendererContainer
        query={ContractsQuery}
        variables={variables}
        // loading={<p>Loading...</p>}
        render={(data) => {
          const contracts = data.Me.Company.Contracts || []

          return (
            <PureInvoices pageSection={pageSection} contracts={contracts} />
          )
        }}
      />
    </PageContainer>
  )
}

export default Invoices
