import {
  getDateFilterModel,
  getServiceFilterModel,
  getContractFilterModel,
  getChannelFilterModel,
} from './getFilterModel'

// TO-ADD-NEW-FILTER
const generateFilterDefinitions = ({
  types = 'date-service-contract',
  customKeys = {},
  t = (v) => v,
  currentLanguage = 'de',
} = {}) => {
  // Create a new filter definition here with its own filterModelGenerator to add a new filter component to the OverviewFilter
  const filterDefinitionMap = {
    date: {
      filterId: 'date',
      title: t('PERIOD'),
      placeholderLabel: t('SELECT TIME PERIOD'),
      // generates ui, api, and grid filter models
      filterModelGenerator: (d) =>
        getDateFilterModel(d, t, customKeys?.date, currentLanguage),
    },
    service: {
      filterId: 'service',
      title: t('SERVICE TYPE'),
      placeholderLabel: t('SELECT SERVICE'),
      filterModelGenerator: (d) =>
        getServiceFilterModel(d, t, customKeys?.service),
    },
    contract: {
      filterId: 'contract',
      title: t('CONTRACT'),
      placeholderLabel: t('SELECT CONTRACT'),
      filterModelGenerator: (d) =>
        getContractFilterModel(d, t, customKeys?.contract),
    },
    channel: {
      filterId: 'channel',
      title: t('CATEGORY'),
      placeholderLabel: t('SELECT CATEGORY'),
      filterModelGenerator: (d) =>
        getChannelFilterModel(d, t, customKeys?.channel),
    },
    testFilter: {
      filterId: 'testFilter',
      title: t('TEST FILTER'),
      placeholderLabel: t('SELECT TEST'),
      filterModelGenerator: (d) => ({
        filterId: 'testFilter',
        tableId: '99',
        label: 'TEST LABEL',
        value: 'TEST VALUE',
      }), // use test generator too
    },
  }

  const filterDefinitions = types.split('-').reduce((acc, type) => {
    const definition = filterDefinitionMap?.[type]
    if (definition) acc.push(definition)
    return acc
  }, [])

  // console.log(filterDefinitions)
  return filterDefinitions
}

export default generateFilterDefinitions
