import * as Throttle from 'promise-parallel-throttle'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { fetchQuery } from 'react-relay'

import environment from '../../../environments/pom/Environment'
import { InvoicesServiceCostsDownloadsQuery } from '../queries/InvoicesServiceCostsDownloadsQuery'

function getPDFDownloadPromise(serviceCostsId, { companyId, accessToken }) {
  const variables = {
    serviceCostsId: serviceCostsId.toString(),
    companyId,
  }

  return fetchQuery(
    environment(accessToken),
    InvoicesServiceCostsDownloadsQuery,
    variables,
    {
      force: true,
    }
  ).then((data) => {
    const { path, token, name } =
      data?.Me?.Company?.serviceCosts[0]?.download || {}

    return fetch(process.env.REACT_APP_DOWNLOAD_PATH + path, {
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/pdf',
      },
    })
      .then((response) => response.blob())
      .then((blobFile) => ({
        blobFile,
        name,
      }))
  })
}

function handleServiceCostsPDFPromises(
  rows = [],
  { companyId, accessToken },
  setPdfFilesDownload = () => {},
  filename = 'ServiceCostsPDF.zip',
  maxInProgress = 100
) {
  const promises = rows.map((row) => {
    // IMPORTANT
    // When selecting rows manually, "row" is an object containing all data of the row,
    // When downloading ALL data, "row" is a number representing the tableId

    const serviceCostsId = row.tableId || row

    return () => {
      return getPDFDownloadPromise(serviceCostsId, {
        companyId,
        accessToken,
      })
    }
  })

  Throttle.all(promises, {
    maxInProgress,
    progressCallback: ({ amountDone }) => {
      setPdfFilesDownload((prev) => ({ ...prev, complete: amountDone })) // Need to set total here direct because in async function is pdfFilesDownload still not updated mean zero
    },
  })
    .then((values) => {
      const zip = new JSZip()

      values.forEach((file) => {
        zip.file(file.name, file.blobFile)
      })

      zip.generateAsync({ type: 'blob' }).then(function (blob) {
        saveAs(blob, filename)
      })
    })
    .catch((errors) => {
      console.error('Error downloading files', errors)
    })
}

export { handleServiceCostsPDFPromises }
