/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AppointmentsCardQueryVariables = {|
  companyId: string
|};
export type AppointmentsCardQueryResponse = {|
  +Me: ?{|
    +appointmentsStatus: ?{|
      +done: number,
      +pending: number,
    |}
  |}
|};
export type AppointmentsCardQuery = {|
  variables: AppointmentsCardQueryVariables,
  response: AppointmentsCardQueryResponse,
|};
*/


/*
query AppointmentsCardQuery(
  $companyId: ID!
) {
  Me {
    appointmentsStatus: Company(id: $companyId) {
      done: countAppointments(options: {filters: [{name: "status", filterType: text, operator: AND, conditions: [{value: ["DONE", "CLOSED", "CHECKED"], type: equals}]}]})
      pending: countAppointments(options: {filters: [{name: "status", filterType: text, operator: AND, conditions: [{value: ["OPEN", "DIAGNOSE_PENDING"], type: equals}]}]})
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": "done",
  "args": [
    {
      "kind": "Literal",
      "name": "options",
      "value": {
        "filters": [
          {
            "conditions": [
              {
                "type": "equals",
                "value": [
                  "DONE",
                  "CLOSED",
                  "CHECKED"
                ]
              }
            ],
            "filterType": "text",
            "name": "status",
            "operator": "AND"
          }
        ]
      }
    }
  ],
  "kind": "ScalarField",
  "name": "countAppointments",
  "storageKey": "countAppointments(options:{\"filters\":[{\"conditions\":[{\"type\":\"equals\",\"value\":[\"DONE\",\"CLOSED\",\"CHECKED\"]}],\"filterType\":\"text\",\"name\":\"status\",\"operator\":\"AND\"}]})"
},
v3 = {
  "alias": "pending",
  "args": [
    {
      "kind": "Literal",
      "name": "options",
      "value": {
        "filters": [
          {
            "conditions": [
              {
                "type": "equals",
                "value": [
                  "OPEN",
                  "DIAGNOSE_PENDING"
                ]
              }
            ],
            "filterType": "text",
            "name": "status",
            "operator": "AND"
          }
        ]
      }
    }
  ],
  "kind": "ScalarField",
  "name": "countAppointments",
  "storageKey": "countAppointments(options:{\"filters\":[{\"conditions\":[{\"type\":\"equals\",\"value\":[\"OPEN\",\"DIAGNOSE_PENDING\"]}],\"filterType\":\"text\",\"name\":\"status\",\"operator\":\"AND\"}]})"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppointmentsCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": "appointmentsStatus",
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppointmentsCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": "appointmentsStatus",
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d5926d1220627fd25403f2ebc0286099",
    "id": null,
    "metadata": {},
    "name": "AppointmentsCardQuery",
    "operationKind": "query",
    "text": "query AppointmentsCardQuery(\n  $companyId: ID!\n) {\n  Me {\n    appointmentsStatus: Company(id: $companyId) {\n      done: countAppointments(options: {filters: [{name: \"status\", filterType: text, operator: AND, conditions: [{value: [\"DONE\", \"CLOSED\", \"CHECKED\"], type: equals}]}]})\n      pending: countAppointments(options: {filters: [{name: \"status\", filterType: text, operator: AND, conditions: [{value: [\"OPEN\", \"DIAGNOSE_PENDING\"], type: equals}]}]})\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '904e9bafde198671eaf6bd8d1e6c1ab2';

module.exports = node;
