import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query GetTenantIdQuery($companyId: ID!, $id: ID!) {
    Me {
      Company(id: $companyId) {
        data: tenant(id: $id) {
          tableId
          firstname
          lastname
          status
          contact {
            type
            value
          }
          usageUnit {
            tableId
          }
        }
      }
    }
  }
`
