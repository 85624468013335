import React, { useCallback, useEffect, useState } from 'react'
import { fetchQuery } from 'react-relay'
import * as Throttle from 'promise-parallel-throttle'

import OperationsOverview from './OperationsOverview'
import OperationsGrids from './OperationsGrids'
import Spacer from '../../components/Spacer'

import environment from '../../environments/pom/Environment'
import { CountOperationsQuery, OperationsGridQuery } from './queries'
import { useUserContext } from '../../helper/userContext'

const Operations = () => {
  const { user } = useUserContext()

  const [houseEntranceCoordinates, setHouseEntranceCoordinates] = useState({
    loading: true,
    coordinates: [],
  })

  const getHouseEntranceForOperationsCoordinates = useCallback(
    (status) => {
      setHouseEntranceCoordinates({
        loading: true,
        coordinates: [],
      })
      const CountCoordinatesVariables = {
        companyId: user.companyId,
        filters: [
          {
            name: 'status',
            filterType: 'text',
            operator: 'AND',
            conditions: [
              {
                value: status,
                type: 'equals',
              },
            ],
          },
        ],
      }

      fetchQuery(
        environment(user.accessToken),
        CountOperationsQuery,
        CountCoordinatesVariables,
        {
          force: true,
        }
      )
        .then((res) => {
          const houseEntrancesCount = res.Me?.Company?.data

          const promises = []
          const chunkSize = 5000
          const chunks = Math.ceil(houseEntrancesCount / chunkSize)

          for (let i = 0; i < chunks; i++) {
            const start = i * (chunkSize + 1)
            const end = start + chunkSize

            promises.push(() => {
              return fetchQuery(
                environment(user.accessToken),
                OperationsGridQuery,
                {
                  sortModel: [],
                  startRow: start,
                  endRow: end,
                  ...CountCoordinatesVariables,
                },
                {
                  force: true,
                }
              )
                .then((res) => {
                  return res.Me?.Company?.data
                })
                .catch((e) => {
                  console.log(e)
                })
            })
          }

          const currentCoordinates = { coordinates: [], address: [] }

          Throttle.all(promises)
            .then((houses) => {
              houses.flat(1).forEach((houseTmp) => {
                const house = houseTmp.houseEntrance
                currentCoordinates.coordinates.push([
                  house.coordinates.lat,
                  house.coordinates.lng,
                ])
                currentCoordinates.address.push({
                  address: house.address,
                  tableId: house.tableId,
                  countUnits: house.countUsageUnits,
                })
              })
            })
            .then(() => {
              setHouseEntranceCoordinates({
                loading: false,
                coordinates: currentCoordinates,
              })
            })
            .catch((e) => {
              console.log(e)
            })
        })
        .catch((e) => {
          console.log(e)
        })
    },
    [user.accessToken, user.companyId]
  )

  useEffect(() => {
    if (houseEntranceCoordinates.loading) {
      getHouseEntranceForOperationsCoordinates('OPEN')
    }
  }, [
    getHouseEntranceForOperationsCoordinates,
    houseEntranceCoordinates.loading,
  ])

  return (
    <>
      <OperationsOverview
        loading={houseEntranceCoordinates.loading}
        houseEntranceCoordinates={houseEntranceCoordinates.coordinates}
      />
      <Spacer />
      <OperationsGrids />
    </>
  )
}

export default Operations
