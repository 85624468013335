import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row } from 'react-grid-system'
import styled from 'styled-components/macro'

import { WizardProgressBar } from './WizardProgressBar'
import { Commisioning } from './Step1Commisioning/Commisioning'
import { LinkIdsContainer } from './Step2Connections/LinkIdsContainer'
import { CommissionDetails } from './Step3CommissionDetails/CommissionDetails'
import { SendOrder } from './Step4SendOrder/SendOrder'
import TicketCreationSuccessPage from './TicketCreationSuccessPage'

import LoadingBox from '../../../components/LoadingSpinner/LoadingBox'

import getSearchParamsFromUrl from '../../../helper/getSearchParamsFromUrl'
import { pushNewUrlToHistory } from '../../../helper/urlHelpers'

const CreateOrder = (props) => {
  const {
    channels = [],
    mostUsedReasons = [],
    recentlyCreatedTickets = [],
    loading = false,
  } = props

  const {
    category: initCat = '',
    topic: initTopic = '',
    reason: initReason = '',
    reasonId: initReasonId = '',
    currentStep: initCurrentStep = 1,
    connectedIds: initConnectedIds = {
      invoice: [],
      usageUnit: [],
      tenant: [],
    },
    pipeline: initPipelineObject = false,
    searchText: initSearchText = '',
  } = getSearchParamsFromUrl() || {}

  const [mainCat, setMainCat] = useState(initCat)
  const [subCat, setSubCat] = useState(initTopic)
  const [reason, setReason] = useState(initReason)
  const [reasonId, setReasonId] = useState(initReasonId)

  const [pipelineObject, setPipelineObject] = useState(() => {
    if (typeof initPipelineObject === 'string') {
      return JSON.parse(initPipelineObject)
    } else {
      return initPipelineObject
    }
  })
  const [completeCatObject, setCompleteCatObject] = useState({
    mainCat: {},
    subCat: {},
    reason: {},
  })
  const [searchText, setSearchText] = useState(initSearchText)
  const [startSelection, setStartSelection] = useState(false)
  const [currentStep, setCurrentStep] = useState(initCurrentStep)
  const [connectedIds, setConnectedIds] = useState(() => {
    if (typeof initConnectedIds === 'string') {
      return JSON.parse(initConnectedIds)
    } else {
      return initConnectedIds
    }
  })

  const [componentData, setComponentData] = useState({})

  const [mainCatIndex, setMainCatIndex] = useState(false)
  const [subCatIndex, setSubCatIndex] = useState(false)
  const [reasonIndex, setReasonIndex] = useState(false)

  const resetConnectedIds = useCallback(() => {
    setConnectedIds({
      invoice: [],
      usageUnit: [],
      tenant: [],
    })
  }, [])

  const handleCurrentStep = (step) => {
    pushNewUrlToHistory('currentStep', step)
    setCurrentStep(step)
  }

  // console.log({ mainCat, subCat, reason, completeCatObject })
  // console.log(connectedIds)

  useEffect(() => {
    const newCatObject = {
      mainCat: {
        name: mainCat,
      },
      subCat: {
        name: subCat,
      },
      reason: {
        name: reason,
        id: reasonId,
      },
    }

    setCompleteCatObject(newCatObject)
  }, [mainCat, reason, reasonId, subCat])

  const generateComponentShowBySteps = useCallback(() => {
    switch (currentStep) {
      case 1:
      case '1':
        return (
          <Commisioning
            mainCatIndex={mainCatIndex}
            setMainCatIndex={setMainCatIndex}
            subCatIndex={subCatIndex}
            setSubCatIndex={setSubCatIndex}
            reasonIndex={reasonIndex}
            setReasonIndex={setReasonIndex}
            mainCat={mainCat}
            setMainCat={setMainCat}
            subCat={subCat}
            setSubCat={setSubCat}
            reason={reason}
            setReason={setReason}
            reasonId={reasonId}
            setReasonId={setReasonId}
            searchText={searchText}
            setSearchText={setSearchText}
            setStartSelection={setStartSelection}
            startSelection={startSelection}
            orderStructure={channels}
            setCurrentStep={handleCurrentStep}
            currentStep={currentStep}
            resetConnectedIds={resetConnectedIds}
            setPipelineObject={setPipelineObject}
            mostUsedReasons={mostUsedReasons}
            recentlyCreatedTickets={recentlyCreatedTickets}
          />
        )

      case 2:
      case '2':
        return (
          <LinkIdsContainer
            setPipelineObject={setPipelineObject}
            pipelineObject={pipelineObject}
            reasonId={reasonId}
            setCurrentStep={handleCurrentStep}
            currentStep={currentStep}
            completeCatObject={completeCatObject}
            connectedIds={connectedIds}
            setConnectedIds={setConnectedIds}
          />
        )

      case 3:
      case '3':
      case '4':
        return (
          <CommissionDetails
            pipelineObject={pipelineObject}
            connectedIds={connectedIds}
            componentData={componentData}
            setComponentData={setComponentData}
            completeCatObject={completeCatObject}
            setCurrentStep={handleCurrentStep}
            currentStep={currentStep}
          />
        )

      case 4:
        // case '4':
        return (
          <SendOrder
            pipelineId={reasonId}
            pipeline={pipelineObject}
            setCurrentStep={handleCurrentStep}
            currentStep={currentStep}
            componentData={componentData}
            completeCatObject={completeCatObject}
            connectedIds={connectedIds}
          />
        )

      case 'success':
        return <TicketCreationSuccessPage />

      default:
        return <div>NOT AVAILABLE</div>
    }
  }, [
    channels,
    completeCatObject,
    componentData,
    connectedIds,
    currentStep,
    mainCat,
    mainCatIndex,
    mostUsedReasons,
    pipelineObject,
    reason,
    reasonId,
    reasonIndex,
    recentlyCreatedTickets,
    resetConnectedIds,
    searchText,
    startSelection,
    subCat,
    subCatIndex,
  ])

  return (
    <div>
      <Row
        justify='center'
        align='center'
        nogutter
        style={{ marginTop: '1.5rem', marginBottom: '2rem' }}
      >
        <Col>
          <WizardProgressBar
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        </Col>
      </Row>

      <Row justify='center' align='start' nogutter>
        <Col>{loading ? <CustomLoader /> : generateComponentShowBySteps()}</Col>
      </Row>
    </div>
  )
}

const CustomLoader = styled(LoadingBox)`
  height: 100%;
  background-color: transparent;
`

export default CreateOrder
