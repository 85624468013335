import graphql from 'babel-plugin-relay/macro'

export const InvoicesServiceCostsQuery = graphql`
  query InvoicesServiceCostsQuery(
    $companyId: ID!
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
  ) {
    Me {
      Company(id: $companyId) {
        data: serviceCosts(
          options: {
            startRow: $startRow
            endRow: $endRow
            sorting: $sortModel
            filters: $filters
          }
        ) {
          id
          tableId
          status
          usageUnit {
            tableId
          }
          contract {
            id
            tableId
            billingPeriods {
              id
              tableId
            }
            billingPartitions {
              name
            }
          }
          appointments {
            id
            tableId
            referenceNumber
            registrations {
              id
              actionPosition {
                id
                name
              }
            }
          }
          value
          isGross
          tenant {
            id
            fullname
            usageUnit {
              address {
                street
                number
                city
                zip
              }
              CAN {
                one
                two
                three
                four
                five
              }
            }
          }
          fromDate
          toDate
          costPosition {
            id
            name
          }
        }
      }
    }
  }
`
