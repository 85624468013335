import React from 'react'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import graphql from 'babel-plugin-relay/macro'
import { ErrorBoundary } from 'react-error-boundary'

import { ServiceCard } from 'pyrexx-react-library'
import StatusFilterButton from '../Status/StatusFilterButton'
import WithSkeleton from './WithSkeleton'
import SimpleLink from '../Simple/SimpleLink'
import QueryRendererContainer from '../QueryRendererContainer'
import NotAvailableContent from './NotAvailableContent'

import { Routes } from '../../configs/RouteConfig'
import { useUserContext } from '../../helper/userContext'
import { BOX_FILTER, DATE_ALL, GRID_ID } from '../../configs/FilterConfig'

import appointmentImg from '../../assets/overview/appointment.png'
import appointmentInactiveImg from '../../assets/overview/appointment-inactive.png'

const AppointmentsCardContent = (props) => {
  const { data = {}, loading = false } = props

  const { t } = useTranslation()
  const { generateUrl } = useUserContext()

  const boxFilterUrl = (filter) => {
    return generateUrl(
      Routes.appointments +
        '?' +
        DATE_ALL +
        '&' +
        BOX_FILTER +
        '=' +
        filter +
        '&' +
        GRID_ID +
        '=usage-unit-grid'
    )
  }

  return (
    <Row align='start' style={{ width: '100%' }}>
      <Col align='center'>
        <WithSkeleton variant='lg' loading={loading}>
          <p style={{ marginTop: '0' }}>{t('PENDING')}</p>
        </WithSkeleton>

        {/* TODO implement that P status */}
        <WithSkeleton loading={loading}>
          <Row justify='center'>
            <StatusFilterButton
              variant='link'
              to={boxFilterUrl('pending')}
              status='warning'
              label={data?.pending}
              border={false}
            />
          </Row>
        </WithSkeleton>
      </Col>

      <Col align='center'>
        <WithSkeleton variant='lg' loading={loading}>
          <p style={{ marginTop: '0' }}>{t('EXECUTED')}</p>
        </WithSkeleton>

        <WithSkeleton loading={loading}>
          <Row justify='center'>
            <StatusFilterButton
              variant='link'
              to={boxFilterUrl('executed')}
              status='success'
              label={data?.done}
              border={false}
            />
          </Row>
        </WithSkeleton>
      </Col>
    </Row>
  )
}

const AppointmentsCard = ({ data = {}, loading = false, error = false }) => {
  const { currentLanguage } = useUserContext()
  const { t } = useTranslation()

  if (error) {
    return (
      <ServiceCard
        imgSrc={appointmentInactiveImg}
        title={t('APPOINTMENTS')}
        content={<NotAvailableContent imgSrc={appointmentInactiveImg} />}
        isDisabled
      />
    )
  }

  return (
    <ServiceCard
      imgSrc={appointmentImg}
      title={t('APPOINTMENTS')}
      content={<AppointmentsCardContent data={data} loading={loading} />}
      link={
        !loading && (
          <SimpleLink
            to={'/' + currentLanguage + Routes.appointments}
            font='quicksand'
          >
            {t('ALL') + ' >'}
          </SimpleLink>
        )
      }
    />
  )
}

const query = graphql`
  query AppointmentsCardQuery($companyId: ID!) {
    Me {
      appointmentsStatus: Company(id: $companyId) {
        done: countAppointments(
          options: {
            filters: [
              {
                name: "status"
                filterType: text
                operator: AND
                conditions: [
                  { value: ["DONE", "CLOSED", "CHECKED"], type: equals }
                ]
              }
            ]
          }
        )
        pending: countAppointments(
          options: {
            filters: [
              {
                name: "status"
                filterType: text
                operator: AND
                conditions: [
                  { value: ["OPEN", "DIAGNOSE_PENDING"], type: equals }
                ]
              }
            ]
          }
        )
      }
    }
  }
`

export default ({ isHidden = true }) => {
  const { user } = useUserContext()

  const variables = {
    companyId: user.companyId,
  }

  if (isHidden) {
    return null
  }

  return (
    <ErrorBoundary fallbackRender={() => null}>
      <QueryRendererContainer
        query={query}
        variables={variables}
        loading={<AppointmentsCard loading />}
        render={(data) => {
          const appointmentsStatus = data?.Me?.appointmentsStatus

          return <AppointmentsCard data={appointmentsStatus} />
        }}
      />
    </ErrorBoundary>
  )
}
