import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { Icon } from 'pyrexx-react-library'
import Faq from '../Faq'
import DownloadCenter from '../DownloadCenter/DownloadCenter'
import FaqSelector from '../Faq/FaqSelector'
import EmptyButton from '../../../components/Simple/EmptyButton'
import ArrowDropdown from '../../../components/ArrowDropdown'

import { SupportColumn, SupportContainer } from '../Support.styles'
import { useUserContext } from '../../../helper/userContext'

const query = `
  query Faq($lang: String) {
    services: getFaqItems(portal: "pom", lang: $lang) {
      id
      categories {
        id
        label: name
        questions {
          id
          question
          answer
        }
      }
    }
  }
`

async function fetchData(query, variables) {
  const res = await fetch(process.env.REACT_APP_PSP_API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({ query, variables }),
  })
  const json = await res.json()
  return json
}

const FaqBox = () => {
  const { currentLanguage } = useUserContext()
  const { t } = useTranslation()

  const variables = {
    lang: currentLanguage,
  }

  const [currentFaqCategory, setCurrentFaqCategory] = useState({})

  const clearCategory = () => setCurrentFaqCategory({})

  const showFaq = !!currentFaqCategory?.id

  const { isLoading, error, data } = useQuery(
    'faq-questions',
    () => fetchData(query, variables),
    {
      refetchOnWindowFocus: false,
    }
  )

  const categories = data?.data?.services?.categories || []

  if (isLoading) {
    return <p>{t('LOADING')}</p>
  }

  if (error) {
    return <p>{t('NO ITEMS AVAILABLE')}</p>
  }

  if (categories.length === 0) {
    return <p>{t('NO ITEMS AVAILABLE')}</p>
  }

  return (
    <>
      <FaqSelectorWrapper showFaq={showFaq}>
        <FaqSelector
          categories={categories}
          handleClick={setCurrentFaqCategory}
        />
      </FaqSelectorWrapper>
      <FaqWrapper showFaq={showFaq}>
        <FaqOptionsRow>
          <NavBackButton onClick={clearCategory}>
            <Icon
              icon='arrow-left'
              style={{ marginRight: '.25rem' }}
              size='15px'
            />
            <span
              // eslint-disable-next-line
              css={`
                font-size: 18px;
              `}
            >
              {t('BACK')}
            </span>
          </NavBackButton>

          <ArrowDropdown
            dropdownItems={categories}
            label={currentFaqCategory?.label}
            selected={currentFaqCategory}
            setSelected={setCurrentFaqCategory}
          />
        </FaqOptionsRow>

        <Faq category={currentFaqCategory} />
      </FaqWrapper>
    </>
  )
}

const FaqDownload = () => {
  const { t } = useTranslation()

  return (
    <SupportContainer>
      <SupportColumn
        css={`
          height: 665px;
          overflow: hidden;
        `}
      >
        <h3>{t('FAQ')}</h3>
        <FaqBox />
      </SupportColumn>

      <SupportColumn>
        <DownloadCenter />
      </SupportColumn>
    </SupportContainer>
  )
}

const FaqSelectorWrapper = styled.div`
  position: absolute;
  will-change: transform;

  transition-property: transform, visibility, opacity;
  transition-duration: 400ms;
  transition-timing-function: ease-out;
  transform: translateX(0);
  visibility: visible;
  opacity: 1;

  ${(props) =>
    props.showFaq &&
    css`
      transition-timing-function: ease-in;
      transform: translateX(-100%);
      visibility: hidden;
      opacity: 0;
    `}

  width: 100%;
`

const FaqWrapper = styled.div`
  position: absolute;
  will-change: transform;

  transition-property: transform, visibility, opacity;
  transition-duration: 400ms;
  transition-timing-function: ease-out;
  transform: translateX(100%);
  visibility: hidden;
  opacity: 0;

  ${(props) =>
    props.showFaq &&
    css`
      transition-timing-function: ease-in;
      transform: translateX(0);
      visibility: visible;
      opacity: 1;
    `}

  height: 89%;
  overflow-y: auto;
  width: 100%;
`

const FaqOptionsRow = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 16px;
`

const NavBackButton = styled(EmptyButton)`
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--pyrexx-link);
`

export default FaqDownload
