import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { AgGridReact } from 'ag-grid-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import AddColumnNotification from '../../Notifications/AddColumnNotification'
import NoRowsOverlay from '../NoRowsOverlay'
// import PerfectScrollbar from 'perfect-scrollbar'
// import 'perfect-scrollbar/css/perfect-scrollbar.css'

import '../../../styles/ag-grid.css'
import '../../../styles/ag-theme-pyrexx.css'

// Default styles for testing
// import 'ag-grid-community/dist/styles/ag-grid.css'
// import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

const gridHeightNoRows = '200px'
const defaultGridHeight = '650px'

const GridContainerWrapper = styled.div`
  position: ${(props) => (props.isFullScreen ? 'fixed' : 'relative')};
  left: 0;
  top: 0;
  ${(props) =>
    props.isFullScreen && 'z-index: 1001; height: 100vh;  width: 100%;'};
`

const GridContainer = styled.div`
  height: ${(props) => props.gridHeight};
  width: 100%;
`

const AgGrid = (props) => {
  const {
    onGridReady,
    handleSelectionChanged = (selectedRows) => {},
    headerHeight,
    onColumnResized = (params) => {},
    onPaginationChanged = (params) => {},
    createGridOption,
    generateActionCenter = () => {},
    onStoreUpdated = (params) => {},
    isFullScreen,
    setFilterModel,
    filterModel,
    minimalVersion,
    boxedVersion,
    noActionDropDown,
    noFilterMenu,
    actionCenterHeight,
    setGridState,
    setOwnFilterChanged,
    currentFilter,
    rowCountHeight,
    ...restOfProps
  } = props

  const overrideHeight = rowCountHeight
    ? rowCountHeight * 48 + 57 + 20 + 'px'
    : defaultGridHeight

  const { t } = useTranslation(['translation', 'agGridLocale'])
  const getWindowHeight = () => {
    const { innerHeight: height } = window
    return height
  }

  const ref = useRef({
    timerOut: false,
    highLightOwnFilterTimeout: false,
    filterModelTimeout: false,
  })
  const [windowHeight, setWindowHeight] = useState(getWindowHeight())
  const [gridHeight, setGridHeight] = useState(overrideHeight)
  const [showNoRowsInfo, setShowNoRowsInfo] = useState(false)
  // const [perfectScrollbarX, setPerfectScrollbarX] = useState(() => {})
  // const [perfectScrollbarY, setPerfectScrollbarY] = useState(() => {})

  // Handle selection changed
  const onSelectionChanged = (params) => {
    const selectedRows = params.api.getSelectedRows()
    handleSelectionChanged(selectedRows, params)
  }

  // Handle add column
  const [columnAdded, setColumnAdded] = useState(false)
  const [gridApi, setGridApi] = useState(null)
  const [gridColumnApi, setGridColumnApi] = useState(null)

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(getWindowHeight())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [windowHeight])

  useEffect(() => {
    if (isFullScreen) {
      document.querySelector('body').style.overflow = 'hidden'
    } else {
      document.querySelector('body').style.overflow = 'auto'
    }
  }, [isFullScreen])

  useEffect(() => {
    if (isFullScreen) {
      setGridHeight(windowHeight - actionCenterHeight + 'px')
    } else {
      if (showNoRowsInfo) {
        setGridHeight(gridHeightNoRows)
        if (gridApi) {
          gridApi.showNoRowsOverlay()
        }
      } else {
        setGridHeight(overrideHeight)
        if (gridApi) {
          gridApi.hideOverlay()
        }
      }
    }
    if (gridColumnApi) {
      gridColumnApi.autoSizeAllColumns()
    }
  }, [
    isFullScreen,
    windowHeight,
    showNoRowsInfo,
    gridApi,
    gridColumnApi,
    actionCenterHeight,
    overrideHeight,
  ])

  // const updatePerfectScrolls = useCallback(() => {
  //   if (perfectScrollbarX && perfectScrollbarY) {
  //     perfectScrollbarX.update()
  //     perfectScrollbarY.update()
  //   }
  // }, [perfectScrollbarX, perfectScrollbarY])

  // useEffect(() => {
  //   updatePerfectScrolls()
  // }, [gridHeight, updatePerfectScrolls])

  useEffect(() => {}, [gridColumnApi])

  const showNewColumnNotification = () => {
    setColumnAdded(true)
  }

  const hideNewColumnNotification = () => {
    setColumnAdded(false)
  }

  const getGridApi = useCallback(
    (params = {}) => {
      if (gridApi) {
        return gridApi
      }
      if (params?.api) {
        return params?.api
      }
      return false
    },
    [gridApi]
  )

  const getGridColumnApi = useCallback(
    (params = {}) => {
      if (gridColumnApi) {
        return gridColumnApi
      }
      if (params?.columnApi) {
        return params?.columnApi
      }
      return false
    },
    [gridColumnApi]
  )

  const delayedFilterModelSave = useCallback(
    (params) => {
      clearTimeout(ref.current.filterModelTimeout)
      ref.current.filterModelTimeout = setTimeout(() => {
        const currentGridApi = getGridApi(params)
        setFilterModel(currentGridApi.getFilterModel())
      }, 1000)
    },
    [getGridApi, setFilterModel]
  )

  const delayedSaveGridState = useCallback(
    (params) => {
      clearTimeout(ref.current.timerOut)
      clearTimeout(ref.current.highLightOwnFilterTimeout)
      if (gridApi && gridColumnApi) {
        setOwnFilterChanged(true)
        ref.current.highLightOwnFilterTimeout = setTimeout(() => {
          setOwnFilterChanged(false)
        }, 10000)
        ref.current.timerOut = setTimeout(() => {
          const currentGridColumnApi = getGridColumnApi(params)
          const currentGridApi = getGridApi(params)
          setGridState({
            state: currentGridColumnApi.getColumnState(),
            filter: currentGridApi.getFilterModel(),
          })
        }, 2000)
      }
    },
    [
      getGridApi,
      getGridColumnApi,
      gridApi,
      gridColumnApi,
      setGridState,
      setOwnFilterChanged,
    ]
  )

  const onColumnVisible = (params) => {
    if (gridApi) {
      gridApi.resetRowHeights()
    }

    const column = params.columns[params.columns.length - 1]
    const { colId, visible } = column
    const currentGridColumnApi = getGridColumnApi(params)
    delayedSaveGridState(params)
    const newColumnNotInViewport = currentGridColumnApi
      .getAllDisplayedVirtualColumns()
      .every((column) => column.colId !== colId)
    const condition = visible && newColumnNotInViewport

    if (condition) {
      // Restarts the timeout
      if (columnAdded) {
        hideNewColumnNotification()
      }

      showNewColumnNotification()
    }
    currentGridColumnApi.autoSizeAllColumns()
    // updatePerfectScrolls()
  }

  // Hides notification after at timeout
  useEffect(() => {
    if (columnAdded) {
      const timer = setTimeout(hideNewColumnNotification, 2000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [columnAdded])

  // const onToolPanelVisibleChanged = (params) => {
  //   if (!gridApi.isToolPanelShowing()) {
  //     updatePerfectScrolls()
  //   }
  // }

  const onColumnMoved = useCallback(
    (params) => {
      delayedSaveGridState(params)
    },
    [delayedSaveGridState]
  )

  const onFilterChanged = useCallback(
    (params) => {
      const currentGridApi = getGridApi(params)
      if (currentGridApi) {
        if (
          JSON.stringify(currentGridApi.getFilterModel()) !==
          JSON.stringify(filterModel)
        ) {
          delayedFilterModelSave(params)
        }
      }
      delayedSaveGridState(params)
    },
    [delayedFilterModelSave, delayedSaveGridState, filterModel, getGridApi]
  )

  const onSortChanged = useCallback(
    (params) => {
      delayedSaveGridState(params)
    },
    [delayedSaveGridState]
  )

  const onModelUpdated = useCallback(
    (params) => {
      const currentGridApi = getGridApi(params)
      if (currentGridApi) {
        const emptyRows = currentGridApi.getRenderedNodes().length === 0
        if (emptyRows) {
          setShowNoRowsInfo(true)
        } else {
          setShowNoRowsInfo(false)
        }
      }
    },
    [getGridApi]
  )

  const onColumnResizedMerged = (params) => {
    onColumnResized(params)
    // updatePerfectScrolls()
  }

  const onGridReadyMerged = (params) => {
    onGridReady(params)

    setGridApi(params.api)
    setGridColumnApi(params.columnApi)

    params.columnApi.autoSizeAllColumns()

    // setPerfectScrollbarX(
    //   new PerfectScrollbar('.ag-body-horizontal-scroll-viewport')
    // )
    // setPerfectScrollbarY(
    //   new PerfectScrollbar('.ag-body-viewport.ag-layout-normal')
    // )
    // updatePerfectScrolls()
  }

  const onPaginationChangedMerged = (params) => {
    onPaginationChanged(params)
  }

  const onStoreUpdatedMerged = (params) => {
    onStoreUpdated(params)
    const currentGridColumnApi = getGridColumnApi(params)
    if (currentGridColumnApi) {
      setTimeout(() => {
        currentGridColumnApi.autoSizeAllColumns()
        // updatePerfectScrolls()
      }, 200)
    }
  }

  /**
   const onViewportChangedMerged = (params) => {
    onViewportChanged(params)
  }

   const onFirstDataRenderedMerged = (params) => {
    onFirstDataRendered(params)
  }

   const onRowDataChangedMerged = (params) => {
    onRowDataChanged(params)
  }
   **/

  return (
    <GridContainerWrapper isFullScreen={isFullScreen}>
      {generateActionCenter(
        minimalVersion,
        boxedVersion,
        noFilterMenu,
        noActionDropDown
      )}
      <GridContainer
        className='ag-theme-pyrexx'
        gridHeight={gridHeight}
        {...restOfProps}
      >
        <AddColumnNotification
          show={columnAdded}
          message={t('NEW COLUMN ADDED')}
        />
        <AgGridReact
          gridOptions={createGridOption()}
          onGridReady={onGridReadyMerged}
          onSelectionChanged={onSelectionChanged}
          onColumnVisible={onColumnVisible}
          // onToolPanelVisibleChanged={onToolPanelVisibleChanged}
          onPaginationChanged={onPaginationChangedMerged}
          onColumnMoved={onColumnMoved}
          onFilterChanged={onFilterChanged}
          onSortChanged={onSortChanged}
          headerHeight={headerHeight}
          popupParent={
            isFullScreen
              ? document.querySelector('ag-theme-pyrexx')
              : document.querySelector('body')
          } // https://www.ag-grid.com/react-grid/context-menu/#popup-parent
          noRowsOverlayComponent={NoRowsOverlay} // https://www.ag-grid.com/react-grid/component-overlay/
          onModelUpdated={onModelUpdated}
          onColumnResized={onColumnResizedMerged}
          onStoreUpdated={onStoreUpdatedMerged}
        />
      </GridContainer>
    </GridContainerWrapper>
  )
}

AgGrid.propTypes = {
  onGridReady: PropTypes.any.isRequired,
  handleSelectionChanged: PropTypes.func,
  headerHeight: PropTypes.number,
  getRowHeight: PropTypes.func,
}

export default AgGrid
