import React from 'react'

import QueryRendererContainer from '../../../components/QueryRendererContainer'
import QuotaOverviewTable from './QuotaOverviewTable'

import QuotaOverviewQuery from './queries/QuotaOverviewQuery'
import { useUserContext } from '../../../helper/userContext'

const QuotaOverview = () => {
  const { user } = useUserContext()

  const variables = {
    companyId: user.companyId,
    rowGroupCols: [
      {
        id: 'quota',
        field: 'quota',
      },
    ],
  }

  return (
    <QueryRendererContainer
      query={QuotaOverviewQuery}
      loading={<QuotaOverviewTable loading />}
      variables={variables}
      render={(data) => {
        const quotas = data?.Me?.Company?.Quotas

        return <QuotaOverviewTable quotas={quotas} />
      }}
    />
  )
}

export default QuotaOverview
