import { useTranslation } from 'react-i18next'

import AllOpenEnquiriesQuery from '../queries/AllOpenEnquiriesQuery'
import CloseTicketRenderer from './renderers/CloseTicketRenderer'
import generateSharedColumnDefs from './generateSharedColumnDefs'

import { useUserContext } from '../../../../helper/userContext'

const useAllOpenEnquiriesGrid = ({ refreshGrid }) => {
  const { currentLanguage } = useUserContext()
  const { t } = useTranslation()

  const AllOpenEnquiriesColumnDefs = generateSharedColumnDefs(
    t,
    currentLanguage,
    [
      {
        headerName: t('CHANGE STATUS'),
        sortable: false,
        filter: false,
        cellRenderer: (props) => CloseTicketRenderer({ ...props, refreshGrid }),
      },
    ]
  )

  const getParams = () => {
    return {
      query: AllOpenEnquiriesQuery,
      columnDefs: AllOpenEnquiriesColumnDefs,
      // customFilters: [],
      extractor: (response) => response.Me?.Company?.OrderWizard?.data,
      // customGridOptions: {},
      // customVariables: {},
    }
  }

  return { getParams }
}

export default useAllOpenEnquiriesGrid
