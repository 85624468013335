import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { css } from 'styled-components'

const Wrapper = styled.div`
  position: sticky;
  top: calc(-1px + 73px); // Take into account MainHeader height: 73px
  z-index: 2;
  transition: 0.2s ease-out;

  border-radius: 10px;

  & > *:first-child {
    ${(props) =>
      props.isSticky &&
      props.shadow &&
      css`
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
          0 2px 4px -1px rgba(0, 0, 0, 0.3);
      `};
  }
`

const Sticky = ({ rootMargin = '-73px', shadow = true, children, ...rest }) => {
  const [isSticky, setIsSticky] = useState(false)
  const ref = React.createRef()

  // src: https://codepen.io/vsync/pen/bGbmqZe?editors=0110
  useEffect(() => {
    const cachedRef = ref.current
    const observer = new IntersectionObserver(
      ([e]) => setIsSticky(e.intersectionRatio < 1),
      { rootMargin, threshold: [1] }
    )

    observer.observe(cachedRef)

    return function () {
      observer.unobserve(cachedRef)
    }
  }, [ref, rootMargin])

  return (
    <Wrapper isSticky={isSticky} ref={ref} shadow={shadow} {...rest}>
      {children}
    </Wrapper>
  )
}

export default Sticky
