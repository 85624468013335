/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AppointmentDownloadQueryVariables = {|
  appointmentId: string,
  companyId: string,
|};
export type AppointmentDownloadQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +Appointment: ?{|
        +id: ?string,
        +download: ?{|
          +name: string,
          +path: string,
          +token: string,
        |},
      |}
    |}
  |}
|};
export type AppointmentDownloadQuery = {|
  variables: AppointmentDownloadQueryVariables,
  response: AppointmentDownloadQueryResponse,
|};
*/


/*
query AppointmentDownloadQuery(
  $appointmentId: ID!
  $companyId: ID!
) {
  Me {
    Company(id: $companyId) {
      Appointment(id: $appointmentId) {
        id
        download {
          name
          path
          token
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "appointmentId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "appointmentId"
    }
  ],
  "concreteType": "Appointment",
  "kind": "LinkedField",
  "name": "Appointment",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "DownloadDetails",
      "kind": "LinkedField",
      "name": "download",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "token",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppointmentDownloadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppointmentDownloadQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "baa4ffd39a1e02639699d135b16ba9a2",
    "id": null,
    "metadata": {},
    "name": "AppointmentDownloadQuery",
    "operationKind": "query",
    "text": "query AppointmentDownloadQuery(\n  $appointmentId: ID!\n  $companyId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      Appointment(id: $appointmentId) {\n        id\n        download {\n          name\n          path\n          token\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e76dd931fa56dc0cf73729710695e96a';

module.exports = node;
