/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CloseTicketMutationVariables = {|
  companyId: string,
  ticketId: number,
|};
export type CloseTicketMutationResponse = {|
  +Me: ?{|
    +Company: ?{|
      +OrderWizard: ?{|
        +updateTicketStatus: ?boolean
      |}
    |}
  |}
|};
export type CloseTicketMutation = {|
  variables: CloseTicketMutationVariables,
  response: CloseTicketMutationResponse,
|};
*/


/*
mutation CloseTicketMutation(
  $companyId: ID!
  $ticketId: Int!
) {
  Me {
    Company(id: $companyId) {
      OrderWizard {
        updateTicketStatus(ticketId: $ticketId, status: CLOSED)
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ticketId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MeMutations",
    "kind": "LinkedField",
    "name": "Me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": "CompanyMutations",
        "kind": "LinkedField",
        "name": "Company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrderWizardMutations",
            "kind": "LinkedField",
            "name": "OrderWizard",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "status",
                    "value": "CLOSED"
                  },
                  {
                    "kind": "Variable",
                    "name": "ticketId",
                    "variableName": "ticketId"
                  }
                ],
                "kind": "ScalarField",
                "name": "updateTicketStatus",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CloseTicketMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CloseTicketMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "544a37bba72112a08f8c98f3282cb3f3",
    "id": null,
    "metadata": {},
    "name": "CloseTicketMutation",
    "operationKind": "mutation",
    "text": "mutation CloseTicketMutation(\n  $companyId: ID!\n  $ticketId: Int!\n) {\n  Me {\n    Company(id: $companyId) {\n      OrderWizard {\n        updateTicketStatus(ticketId: $ticketId, status: CLOSED)\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f36a0a9e99bffc836d5c7e71a7c022cb';

module.exports = node;
