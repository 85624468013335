import styled from 'styled-components/macro'
import { createGlobalStyle } from 'styled-components'
import { Container } from 'react-grid-system'
import { helperStyles, portalTheme } from 'pyrexx-react-library'

export const ContainerStyled = styled(Container)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main {
    flex-grow: 1 !important;

    .main-col {
      padding-top: 15px;
      padding-bottom: 15px;

      > :first-child {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }
`

export const GlobalStyle = createGlobalStyle`
  :root {
    --pyrexx-bg-color: #F7FAF5;
    --pyrexx-red: #E8221B;
    --pyrexx-link: #4AB4E2;
    --pyrexx-dark-blue: #8ba8cb;
    --pyrexx-light-blue: #eff4f7;
    --pyrexx-success: #7ED321;
    --pyrexx-warning: #F5A623;
    --pyrexx-error: #D0021B;
    --pyrexx-badge-color: #8aa8c8;
    --pyrexx-dropdown-badge-color: #c5cfda;
  }

  body {
    margin: 0;
    font-family: ${portalTheme.font.family.quicksand};
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    color: ${portalTheme.color.basicColorBlack};
    text-align: left;
    background-color: ${portalTheme.color.basicColorLightGrey1};
  }

  h1, h2, h3, h4, h5 {
    font-family: ${portalTheme.font.family.quicksand};
  }

  ${helperStyles.allBorderBox}
  .variable.ck-widget {
    display: inline-flex;
    /* margin-bottom: 10px; */
    /* width: max-content; */
  }

  .simple-box-placeholder {
    color: #999;
    cursor: pointer;
  }
`
