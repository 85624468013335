import styled from 'styled-components/macro'
import { css } from 'styled-components'

export const EmptyButtonCSS = css`
  // Its removes default button styles
  // src: https://gist.github.com/MoOx/9137295
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  cursor: pointer;
  user-select: auto;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize \`line-height\`. Cannot be changed from \`normal\` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable \`input\` types in iOS */
  -webkit-appearance: none;
`

const EmptyButton = styled.button`
  ${EmptyButtonCSS}
`

export default EmptyButton
