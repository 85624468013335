import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

import { ButtonPrimary, Icon } from 'pyrexx-react-library'

import { useUserContext } from '../../../helper/userContext'
import { Routes } from '../../../configs/RouteConfig'

const CreateNewInquiryBox = () => {
  const { t } = useTranslation()
  const { currentLanguage } = useUserContext()
  const history = useHistory()

  const handleClick = () => {
    history.push('/' + currentLanguage + '/support' + Routes.createOrder)
  }

  return (
    <div
      style={{
        padding: '16px',
        backgroundColor: 'white',
        flex: '1',
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '8px',
          marginBottom: '16px',
        }}
      >
        <span
          style={{
            margin: '0',
          }}
        >
          {t('DO YOU HAVE ANOTHER CONCERN AND WANT TO ORDER SOMETHING?')}
        </span>
      </div>

      <ButtonPrimaryStyled onClick={handleClick}>
        <span>{t('CREATE A NEW ORDER NOW')}</span>
        <Icon icon='create_order' size='32px' color='#fff' />
      </ButtonPrimaryStyled>
    </div>
  )
}

const ButtonPrimaryStyled = styled(ButtonPrimary)`
  padding: 24px;
  margin: 0 auto;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }
`

export default CreateNewInquiryBox
