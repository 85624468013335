import { forwardRef, useImperativeHandle, useState } from 'react'
import useUpdateEffect from '../../../../../hooks/useUpdateEffect'
// import GridEnumFilter from '../../../../../components/GridEnumFilter'
// import { tenantStatusEnumList } from '../../../../../components/AgGrid/enumLists'

export default forwardRef((props, ref) => {
  const { filterChangedCallback } = props

  const [filterModel, setFilterModel] = useState({})

  useUpdateEffect(() => {
    filterChangedCallback()
  }, [filterModel])

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return filterModel != null && Object.keys(filterModel).length > 0
      },
      getModel() {
        if (filterModel == null || Object.keys(filterModel).length <= 0) {
          return null
        }

        return filterModel
      },
      setModel(model) {
        setFilterModel(model)
      },
    }
  })

  return null

  // return (
  //   <GridEnumFilter
  //     // map={map}
  //     translateLabel={(value, t) => {
  //       return t(tenantStatusEnumList(value))
  //     }}
  //     setFilterModel={setFilterModel}
  //   />
  // )
})
