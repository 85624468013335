/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConditionType = "contains" | "endsWith" | "equals" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "lessThan" | "lessThanOrEqual" | "notContains" | "notEqual" | "startsWith" | "%future added value";
export type FilterType = "date" | "number" | "text" | "%future added value";
export type LockReasonType = "CONTRACT_CANCELLED" | "CONTRACT_EXPIRED" | "CUSTOMER_TEMP_DEFERRED_INSTALLATION" | "CUSTOMER_WISH" | "DECENTRAL_WARM_WATER" | "HIGH_CEILING" | "LOCKED_TIL_INDIVIDUAL_ORDER" | "MODERNIZATION" | "NONE" | "OWN_SMOKE_DETECTOR" | "SMOKE_DETECTORS_IN_SAFE_KEEPING" | "SMOKE_DETECTORS_NOT_USEFUL" | "SYSTEM" | "TEMP_FOR_CLEARIFICATION" | "TEMP_FOR_RENOVATION" | "TENANT_REJECTED_SMOKE_DETECTORS" | "UNEXPECTED_BUSINESS_UNIT" | "UNIT_DEMOLITION" | "UNIT_DOESNT_EXIST" | "UNIT_SOLD" | "UNKNOWN" | "%future added value";
export type OperatorType = "AND" | "OR" | "%future added value";
export type UsageUnitTypeType = "BUSINESS" | "COMMON" | "NONE" | "PRIVATE" | "%future added value";
export type Sort = {|
  colId?: ?string,
  sort?: ?string,
|};
export type Filter = {|
  name: string,
  filterType: FilterType,
  operator: OperatorType,
  denySelection?: ?boolean,
  conditions: $ReadOnlyArray<?Condition>,
|};
export type Condition = {|
  value: $ReadOnlyArray<?string>,
  type: ConditionType,
|};
export type LockedUsageUnitsGridQueryVariables = {|
  companyId: string,
  startRow?: ?number,
  endRow?: ?number,
  sortModel?: ?$ReadOnlyArray<?Sort>,
  filters?: ?$ReadOnlyArray<?Filter>,
|};
export type LockedUsageUnitsGridQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +data: ?$ReadOnlyArray<?{|
        +id: string,
        +tableId: number,
        +referenceNumber: string,
        +type: UsageUnitTypeType,
        +tenants: $ReadOnlyArray<?{|
          +firstname: string,
          +lastname: string,
        |}>,
        +lockPeriods: $ReadOnlyArray<?{|
          +id: string,
          +begin: ?any,
          +end: ?any,
          +reason: LockReasonType,
          +initiator: string,
        |}>,
      |}>
    |},
    +id: string,
  |}
|};
export type LockedUsageUnitsGridQuery = {|
  variables: LockedUsageUnitsGridQueryVariables,
  response: LockedUsageUnitsGridQueryResponse,
|};
*/


/*
query LockedUsageUnitsGridQuery(
  $companyId: ID!
  $startRow: Int
  $endRow: Int
  $sortModel: [Sort]
  $filters: [Filter]
) {
  Me {
    Company(id: $companyId) {
      data: UsageUnits(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {
        id
        tableId
        referenceNumber
        type
        tenants {
          firstname
          lastname
          id
        }
        lockPeriods(options: {endRow: -1, sorting: [{colId: "begin", sort: "DESC"}], filters: [{name: "status", filterType: text, operator: AND, conditions: [{value: "ACTIVE", type: equals}]}]}) {
          id
          begin
          end
          reason
          initiator
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endRow"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortModel"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startRow"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v6 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "endRow",
        "variableName": "endRow"
      },
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      },
      {
        "kind": "Variable",
        "name": "sorting",
        "variableName": "sortModel"
      },
      {
        "kind": "Variable",
        "name": "startRow",
        "variableName": "startRow"
      }
    ],
    "kind": "ObjectValue",
    "name": "options"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referenceNumber",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "options",
      "value": {
        "endRow": -1,
        "filters": [
          {
            "conditions": [
              {
                "type": "equals",
                "value": "ACTIVE"
              }
            ],
            "filterType": "text",
            "name": "status",
            "operator": "AND"
          }
        ],
        "sorting": [
          {
            "colId": "begin",
            "sort": "DESC"
          }
        ]
      }
    }
  ],
  "concreteType": "LockPeriod",
  "kind": "LinkedField",
  "name": "lockPeriods",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "begin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "end",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "initiator",
      "storageKey": null
    }
  ],
  "storageKey": "lockPeriods(options:{\"endRow\":-1,\"filters\":[{\"conditions\":[{\"type\":\"equals\",\"value\":\"ACTIVE\"}],\"filterType\":\"text\",\"name\":\"status\",\"operator\":\"AND\"}],\"sorting\":[{\"colId\":\"begin\",\"sort\":\"DESC\"}]})"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LockedUsageUnitsGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": "data",
                "args": (v6/*: any*/),
                "concreteType": "UsageUnit",
                "kind": "LinkedField",
                "name": "UsageUnits",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tenant",
                    "kind": "LinkedField",
                    "name": "tenants",
                    "plural": true,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "LockedUsageUnitsGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": "data",
                "args": (v6/*: any*/),
                "concreteType": "UsageUnit",
                "kind": "LinkedField",
                "name": "UsageUnits",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tenant",
                    "kind": "LinkedField",
                    "name": "tenants",
                    "plural": true,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d74299b529f90bbfd8cadb2fddd0e203",
    "id": null,
    "metadata": {},
    "name": "LockedUsageUnitsGridQuery",
    "operationKind": "query",
    "text": "query LockedUsageUnitsGridQuery(\n  $companyId: ID!\n  $startRow: Int\n  $endRow: Int\n  $sortModel: [Sort]\n  $filters: [Filter]\n) {\n  Me {\n    Company(id: $companyId) {\n      data: UsageUnits(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {\n        id\n        tableId\n        referenceNumber\n        type\n        tenants {\n          firstname\n          lastname\n          id\n        }\n        lockPeriods(options: {endRow: -1, sorting: [{colId: \"begin\", sort: \"DESC\"}], filters: [{name: \"status\", filterType: text, operator: AND, conditions: [{value: \"ACTIVE\", type: equals}]}]}) {\n          id\n          begin\n          end\n          reason\n          initiator\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fb530bdd90465d252e0ea62076e65cd9';

module.exports = node;
