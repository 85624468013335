// Source: https://www.ag-grid.com/documentation/react/filter-provided-simple/
const TYPE_REGULAR_SQUEMA = {
  type: {
    filterType: 'text',
    operator: 'AND',
    condition1: {
      filterType: 'text',
      type: 'equals',
      filter: ['T'],
    },
  },
}

const TYPE_SERVICE_SQUEMA = {
  type: {
    filterType: 'text',
    operator: 'AND',
    condition1: {
      filterType: 'text',
      type: 'equals',
      filter: ['TI', 'TS'],
    },
  },
}

const TYPE_SPECIAL_SQUEMA = {
  type: {
    filterType: 'text',
    operator: 'AND',
    condition1: {
      filterType: 'text',
      type: 'equals',
      filter: ['TX', 'TA'],
    },
  },
}

const STATUS_DONE_SQUEMA = {
  status: {
    filterType: 'text',
    operator: 'AND',
    condition1: {
      filterType: 'text',
      type: 'equals',
      filter: ['DONE', 'CLOSED', 'CHECKED'],
    },
  },
}

const STATUS_PENDING_SQUEMA = {
  status: {
    filterType: 'text',
    operator: 'AND',
    condition1: {
      filterType: 'text',
      type: 'equals',
      filter: ['OPEN', 'DIAGNOSE_PENDING'],
    },
  },
}

const STATUS_NOT_DONE_SQUEMA = {
  status: {
    filterType: 'text',
    operator: 'AND',
    condition1: {
      filterType: 'text',
      type: 'equals',
      filter: ['DEACTIVATED'],
    },
  },
}

export const filterRegular = {
  ...TYPE_REGULAR_SQUEMA,
}

export const filterService = {
  ...TYPE_SERVICE_SQUEMA,
}

export const filterSpecial = {
  ...TYPE_SPECIAL_SQUEMA,
}

export const filterExecuted = {
  ...STATUS_DONE_SQUEMA,
}

export const filterPending = {
  ...STATUS_PENDING_SQUEMA,
}

export const filterNotExecuted = {
  ...STATUS_NOT_DONE_SQUEMA,
}

export const filterRegularExecuted = {
  ...TYPE_REGULAR_SQUEMA,
  ...STATUS_DONE_SQUEMA,
}

export const filterServiceExecuted = {
  ...TYPE_SERVICE_SQUEMA,
  ...STATUS_DONE_SQUEMA,
}

export const filterSpecialExecuted = {
  ...TYPE_SPECIAL_SQUEMA,
  ...STATUS_DONE_SQUEMA,
}

export const filterRegularPending = {
  ...TYPE_REGULAR_SQUEMA,
  ...STATUS_PENDING_SQUEMA,
}

export const filterServicePending = {
  ...TYPE_SERVICE_SQUEMA,
  ...STATUS_PENDING_SQUEMA,
}

export const filterSpecialPending = {
  ...TYPE_SPECIAL_SQUEMA,
  ...STATUS_PENDING_SQUEMA,
}

export const filterRegularNotExecuted = {
  ...TYPE_REGULAR_SQUEMA,
  ...STATUS_NOT_DONE_SQUEMA,
}

export const filterServiceNotExecuted = {
  ...TYPE_SERVICE_SQUEMA,
  ...STATUS_NOT_DONE_SQUEMA,
}

export const filterSpecialNotExecuted = {
  ...TYPE_SPECIAL_SQUEMA,
  ...STATUS_NOT_DONE_SQUEMA,
}
