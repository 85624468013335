function getCurrentYear(date) {
  return new Date(date).getFullYear()
}

const FIRST_DAY = '-01-01'
const LAST_DAY = '-12-31'

function getCreationDateTimeframes(tickets = []) {
  const allYears = tickets?.map((t) => getCurrentYear(t.creationDate))
  const nonRepeatedYears = [...new Set(allYears)]

  return nonRepeatedYears.map((currentYear) => {
    const from = currentYear + FIRST_DAY
    const to = currentYear + LAST_DAY

    return { from, to }
  })
}

export { getCreationDateTimeframes }
