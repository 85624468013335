/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConditionType = "contains" | "endsWith" | "equals" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "lessThan" | "lessThanOrEqual" | "notContains" | "notEqual" | "startsWith" | "%future added value";
export type FilterType = "date" | "number" | "text" | "%future added value";
export type OperatorType = "AND" | "OR" | "%future added value";
export type Filter = {|
  name: string,
  filterType: FilterType,
  operator: OperatorType,
  denySelection?: ?boolean,
  conditions: $ReadOnlyArray<?Condition>,
|};
export type Condition = {|
  value: $ReadOnlyArray<?string>,
  type: ConditionType,
|};
export type CountPropertyUnitInventoryGridQueryVariables = {|
  filters?: ?$ReadOnlyArray<?Filter>,
  companyId: string,
  propertyUnitId: string,
|};
export type CountPropertyUnitInventoryGridQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +HouseEntrance: ?{|
        +data: number
      |}
    |}
  |}
|};
export type CountPropertyUnitInventoryGridQuery = {|
  variables: CountPropertyUnitInventoryGridQueryVariables,
  response: CountPropertyUnitInventoryGridQueryResponse,
|};
*/


/*
query CountPropertyUnitInventoryGridQuery(
  $filters: [Filter]
  $companyId: ID!
  $propertyUnitId: ID!
) {
  Me {
    Company(id: $companyId) {
      HouseEntrance(id: $propertyUnitId) {
        data: countInventory(options: {filters: $filters})
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "propertyUnitId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "propertyUnitId"
  }
],
v5 = {
  "alias": "data",
  "args": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters"
        }
      ],
      "kind": "ObjectValue",
      "name": "options"
    }
  ],
  "kind": "ScalarField",
  "name": "countInventory",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CountPropertyUnitInventoryGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "HouseEntrance",
                "kind": "LinkedField",
                "name": "HouseEntrance",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "CountPropertyUnitInventoryGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "HouseEntrance",
                "kind": "LinkedField",
                "name": "HouseEntrance",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "91d1161a08d689c5a8b0a0ec91cba71f",
    "id": null,
    "metadata": {},
    "name": "CountPropertyUnitInventoryGridQuery",
    "operationKind": "query",
    "text": "query CountPropertyUnitInventoryGridQuery(\n  $filters: [Filter]\n  $companyId: ID!\n  $propertyUnitId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      HouseEntrance(id: $propertyUnitId) {\n        data: countInventory(options: {filters: $filters})\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4edb30de64c4bc32050f744c842e1edd';

module.exports = node;
