import React, { forwardRef, useImperativeHandle, useState } from 'react'
import GridEnumFilter from '../../../../../../../../components/GridEnumFilter'
import useUpdateEffect from '../../../../../../../../hooks/useUpdateEffect'
import { invoiceTypeEnumList } from '../../../../../../../../components/AgGrid/enumLists'

// TODO add real types
const map = {
  DELIVERY_INVOICE: ['1'],
  SERVICE_ACCOUNTING: ['2'],
  PARTIAL_INVOICE: ['3'],
  INTERIM_BILLING: ['4'],
  FINAL_SETTLEMENT: ['5'],
  CANCELLATION_INVOICE: ['6'],
  PRO_FORMA_INVOICE: ['7'],
  RECEIPT: ['8'],
}

export default forwardRef((props, ref) => {
  const { filterChangedCallback } = props

  const [filterModel, setFilterModel] = useState({})

  useUpdateEffect(() => {
    filterChangedCallback()
  }, [filterModel])

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return filterModel != null && Object.keys(filterModel).length > 0
      },
      getModel() {
        if (filterModel == null || Object.keys(filterModel).length <= 0) {
          return null
        }

        return filterModel
      },
      setModel(model) {
        setFilterModel(model)
      },
    }
  })

  return (
    <GridEnumFilter
      map={map}
      translateLabel={(value, t) => {
        return t(invoiceTypeEnumList(value))
      }}
      setFilterModel={setFilterModel}
    />
  )
})
