import { fetchQuery } from 'react-relay'

import environment from '../../../environments/pom/Environment'
import InvoiceDownloadQuery from './queries/InvoiceDownload'

function getInvoicePDFDownloadPromise(invoiceId, { companyId, accessToken }) {
  const variables = {
    invoiceId,
    companyId,
  }

  return fetchQuery(environment(accessToken), InvoiceDownloadQuery, variables, {
    force: true,
  }).then((data) => {
    const { path, token, name } = data?.Me?.Company?.Invoice?.download || {}

    return fetch(process.env.REACT_APP_DOWNLOAD_PATH + path, {
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/pdf',
      },
    })
      .then((response) => response.blob())
      .then((blobFile) => ({
        blobFile,
        name: name,
      }))
  })
}

export { getInvoicePDFDownloadPromise }
