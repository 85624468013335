import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import { portalTheme } from 'pyrexx-react-library'

const EnabledNavItem = styled(NavLink).attrs(() => ({
  activeClassName: 'navItem-active',
}))`
  display: inline-block;

  cursor: pointer;
  text-decoration: none;

  background-color: #fff;
  border-bottom: 5px solid transparent;

  font-family: ${portalTheme.font.family.quicksand};
  font-size: ${portalTheme.font.size.navigationMenu};
  font-weight: ${portalTheme.font.weight.light};
  color: ${portalTheme.color.navigationMenu};

  padding: 0.6rem 5px;

  &:hover,
  &:focus {
    border-bottom: 5px solid ${portalTheme.color.basicColorAccentRed};
  }

  &:active,
  &.navItem-active {
    border-bottom: 5px solid ${portalTheme.color.basicColorAccentRed};
    font-weight: ${portalTheme.font.weight.bold};
  }
`

const DisabledNavItem = styled.div`
  cursor: not-allowed;

  border-bottom: 5px solid transparent;

  opacity: 0.25;

  padding: 0.6rem 5px;
`

const AltNavItem = styled(EnabledNavItem)`
  background-color: rgba(230, 230, 230, 1);
  border-bottom: 5px solid transparent;

  padding: 0.6rem 50px;
  margin-right: -16px;
`

const NavItem = (props) => {
  const {
    label = '',
    to = '/',
    isDisabled = false,
    isAlternative = false,
  } = props

  const NavItemSelector = isAlternative ? AltNavItem : EnabledNavItem
  const NavItemStateSelector = isDisabled ? DisabledNavItem : NavItemSelector

  return <NavItemStateSelector to={to}>{label}</NavItemStateSelector>
}

export default NavItem

NavItem.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isAlternative: PropTypes.bool,
}
