import React from 'react'
import styled from 'styled-components/macro'
import { css } from 'styled-components'

function Characters({ currentChars = 0, maxChars = 140 }) {
  return (
    <Wrapper>
      <p style={{ margin: 0 }}>
        <Current currentChars={currentChars} maxChars={maxChars}>
          {currentChars}
        </Current>
        <span>/</span>
        <strong>{maxChars}</strong>
      </p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;
`

const Current = styled.span`
  color: #000;

  ${(props) => {
    const currentChars = props.currentChars
    const maxChars = props.maxChars

    if (currentChars >= maxChars) {
      return css`
        color: var(--pyrexx-error, red);
      `
    }

    if (currentChars >= (maxChars * 3) / 4) {
      return css`
        color: var(--pyrexx-warning, orange);
      `
    }
  }}
`

export default Characters
