import React from 'react'
import styled from 'styled-components/macro'
import { css } from 'styled-components'

const BoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  border-radius: 10px;

  background-color: #fff;
  padding: 24px;

  ${(props) =>
    !props.fullWidth &&
    css`
      width: 75%;
      margin: 0 auto;
    `}
`

const BoxInnerWrapper = styled.div`
  width: ${(props) => props.width || '50%'};
`

const Box = ({ fullWidth = true, children, style, className }) => {
  return (
    <BoxWrapper fullWidth={fullWidth} style={style} className={className}>
      {children}
    </BoxWrapper>
  )
}

const BoxInner = ({ width = '50%', children, style, className }) => {
  return (
    <BoxInnerWrapper width={width} style={style} className={className}>
      {children}
    </BoxInnerWrapper>
  )
}

export { Box, BoxInner }
