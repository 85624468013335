import React, { useCallback, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { ButtonSecondary, MessageNofi } from 'pyrexx-react-library'
import { useTranslation } from 'react-i18next'

import { SearchLinkedIds } from './SearchLinkedIds'
import { ConnectedIds } from './ConnectedIds'

import { pushNewUrlToHistory } from '../../../../helper/urlHelpers'

export const LinkIds = (props) => {
  const {
    pipelineObject,
    setPipelineObject,
    setCurrentStep,
    connectedIds,
    setConnectedIds,
    completeCatObject,
    pipeline,
  } = props

  const { t } = useTranslation()

  const [messageNofiStatus, setMessageNofiStatus] = useState({
    msg: '',
    open: false,
    status: 'error',
  })

  const countDataLink = getCountDataLink(connectedIds)

  const [subStep, setSubStep] = useState(() => {
    if (countDataLink > 0) {
      return 2
    } else {
      return 1
    }
  })

  // console.log({ subStep, countDataLink, connectedIds, pipeline })

  const initFiltersForSearch = [
    {
      search: 'linkIdHouseEntrance',
      label: t('PROPERTY UNIT'),
      linkable: 'propertyUnit',
    },
    {
      search: 'linkIdUsageUnit',
      label: t('USAGE UNIT'),
      linkable: 'usageUnit',
    },
    { search: 'linkIdInvoice', label: t('INVOICE'), linkable: 'invoice' },
    { search: 'linkIdTenant', label: t('TENANT'), linkable: 'tenant' },
  ]

  useEffect(() => {
    if (!pipelineObject) {
      console.log('DEBUGXXX setPipelineObject', pipeline)

      pushNewUrlToHistory('pipeline', JSON.stringify(pipeline))
      setPipelineObject(pipeline)
    }
  }, [pipeline, pipelineObject, setPipelineObject])

  const requiredWithout = {}
  Object.keys(pipeline?.linkables).forEach((key) => {
    requiredWithout[key] = []
    const matches = pipeline?.linkables[key]?.rules?.filter((value) =>
      /^required_without:links/.test(value)
    )
    if (matches) {
      requiredWithout[key] = matches.map((tableName) => {
        return tableName?.split('.')[1]
      })
    }
  })
  console.log('DEBUG requiredWithout', requiredWithout)

  const validateLinkables = useCallback(() => {
    const errorMsgArray = []
    console.log('DEBUG VALIDATE 1 ', pipeline.linkables)
    if (pipeline?.linkables) {
      const requiredWithoutCheck = {
        equalCheck: 0,
        hits: 0,
        labels: [],
      }
      Object.keys(pipeline.linkables).forEach((key) => {
        console.log(
          'DEBUG VALIDATE 2 pipeline.linkables, key , pipeline.linkables[key], connectedIds, connectedIds[key]',
          pipeline.linkables,
          key,
          pipeline.linkables[key],
          connectedIds,
          connectedIds[key]
        )
        if (pipeline.linkables[key].required && connectedIds[key]?.length < 1) {
          const msg = t('AT LEAST ADD ONE {LABEL}', {
            LABEL: initFiltersForSearch.find(
              (search) => search.linkable === key
            ).label,
          })
          errorMsgArray.push(msg)
        }
        if (
          !pipeline.linkables[key].multiple &&
          connectedIds[key]?.length > 1
        ) {
          const msg = t('DONT ADD MORE THEN ONE {LABEL}', {
            LABEL: initFiltersForSearch.find(
              (search) => search.linkable === key
            ).label,
          })
          errorMsgArray.push(msg)
        }

        if (requiredWithout[key].length > 0) {
          requiredWithoutCheck.equalCheck++
          requiredWithoutCheck.labels.push(
            initFiltersForSearch.find((search) => search.linkable === key).label
          )
          let hit = 0
          requiredWithout[key].forEach((tableName) => {
            if (connectedIds[tableName]?.length < 1) {
              hit++
            }
          })
          if (
            hit === requiredWithout[key]?.length &&
            connectedIds[key]?.length < 1
          ) {
            requiredWithoutCheck.hits++
          }
        }
      })
      if (
        requiredWithoutCheck.hits > 0 &&
        requiredWithoutCheck.hits === requiredWithoutCheck.equalCheck
      ) {
        const msg = t('ADD ONE OF NEEDED DEPENDENCY {LABEL}', {
          LABEL: requiredWithoutCheck.labels.join(', '),
        })
        errorMsgArray.push(msg)
      }
    }

    if (errorMsgArray.length > 0) {
      setMessageNofiStatus({
        ...messageNofiStatus,
        msg: errorMsgArray.map((singleMsg, index) => {
          return (
            <Row key={singleMsg + index}>
              <Col>{singleMsg}</Col>
            </Row>
          )
        }),
        open: true,
      })
      return false
    }
    return true
  }, [
    connectedIds,
    initFiltersForSearch,
    messageNofiStatus,
    pipeline.linkables,
    requiredWithout,
    t,
  ])

  return (
    <Container>
      {subStep === 1 ? (
        <SearchLinkedIds
          initFiltersForSearch={initFiltersForSearch}
          pipeline={pipeline}
          connectedIds={connectedIds}
          setConnectedIds={setConnectedIds}
          requiredWithout={requiredWithout}
        />
      ) : (
        <ConnectedIds
          connectedIds={connectedIds}
          setConnectedIds={setConnectedIds}
          setSubStep={setSubStep}
          countDataLink={countDataLink}
          completeCatObject={completeCatObject}
        />
      )}

      <Row justify='center' style={{ marginTop: '1rem' }}>
        <Col xs='content'>
          <ButtonSecondary
            onClick={() => {
              if (subStep === 1) {
                setCurrentStep(1)
              } else {
                pushNewUrlToHistory('subStep', 1)
                setSubStep(1)
              }
            }}
            style={{
              margin: '0px',
              height: '3rem',
              paddingLeft: '4rem',
              paddingRight: '4rem',
            }}
          >
            {t('BACK')}
          </ButtonSecondary>
        </Col>

        <Col xs='content'>
          <ButtonSecondary
            onClick={() => {
              if (validateLinkables()) {
                if (subStep === 1) {
                  pushNewUrlToHistory('subStep', 2)
                  setSubStep(2)
                } else {
                  pushNewUrlToHistory('subStep', null, 'delete')
                  setCurrentStep(3)
                }
              }
            }}
            style={{
              margin: '0px',
              height: '3rem',
              paddingLeft: '4rem',
              paddingRight: '4rem',
            }}
          >
            {t('NEXT')}
          </ButtonSecondary>
        </Col>
      </Row>

      <MessageNofi
        style={{ backgroundColor: '#DCF5FFFF', fontSize: '26px' }}
        isVisible={messageNofiStatus?.open}
        closeMessageBox={() => {
          setMessageNofiStatus({ ...messageNofiStatus, open: false })
        }}
        statusColor={messageNofiStatus?.status}
        updated={new Date()}
      >
        {messageNofiStatus?.msg}
      </MessageNofi>
    </Container>
  )
}

const getCountDataLink = (connectedIds) => {
  let countData = 0
  Object.keys(connectedIds).forEach((arrayItem) => {
    countData = parseInt(countData) + parseInt(connectedIds[arrayItem].length)
  })
  return countData
}
