import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { ButtonPrimary } from 'pyrexx-react-library'
import Spacer from '../../../components/Spacer'

// import {
//   clearQueryParamsFromUrl,
//   pushNewUrlToHistory,
// } from '../../../helper/urlHelpers'
import { Box, BoxInner, CheckIcon } from '../../PasswordManagement/styles'
import { CenterRow } from '../../../styles/HelperStylesComponents.styles'
import { Routes } from '../../../configs/RouteConfig'
import { useUserContext } from '../../../helper/userContext'

const TicketCreationSuccessPage = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { currentLanguage } = useUserContext()

  // TODO IMPROVE
  // React.useEffect(() => {
  //   clearQueryParamsFromUrl()
  //   pushNewUrlToHistory('currentStep', 'success')
  // }, [])

  return (
    <Box fullWidth>
      <BoxInner>
        <h3>{t('YOUR TICKET WAS SUCCESSFULLY CREATED')}</h3>

        <Spacer />

        <CenterRow>
          <CheckIcon icon='bullet-point' size='30' />
          <p>
            {t(
              'WE JUST SENT A NOTIFICATION TO YOUR ADMINISTRATOR -> placeholder'
            )}
          </p>
        </CenterRow>

        <Spacer />

        <ButtonPrimary
          onClick={() =>
            history.push(
              '/' + currentLanguage + Routes.support + Routes.enquiries
            )
          }
        >
          {t('GO BACK TO ENQUIRIES')}
        </ButtonPrimary>
        <Spacer />
      </BoxInner>
    </Box>
  )
}

export default TicketCreationSuccessPage
