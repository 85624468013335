/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TicketStatusEnum = "CLOSED" | "OPEN" | "%future added value";
export type AppointmentTicketsQueryVariables = {|
  companyId: string,
  appointmentId: string,
|};
export type AppointmentTicketsQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +Appointment: ?{|
        +data: ?$ReadOnlyArray<?{|
          +id: string,
          +tableId: number,
          +status: TicketStatusEnum,
          +zammadTicketId: number,
          +creationDate: ?any,
          +reason: ?{|
            +id: string,
            +name: string,
          |},
          +channel: ?{|
            +id: string,
            +name: string,
          |},
          +user: ?{|
            +tableId: number,
            +name: ?string,
            +firstname: ?string,
            +lastname: ?string,
          |},
        |}>
      |}
    |}
  |}
|};
export type AppointmentTicketsQuery = {|
  variables: AppointmentTicketsQueryVariables,
  response: AppointmentTicketsQueryResponse,
|};
*/


/*
query AppointmentTicketsQuery(
  $companyId: ID!
  $appointmentId: ID!
) {
  Me {
    Company(id: $companyId) {
      Appointment(id: $appointmentId) {
        data: tickets {
          id
          tableId
          status
          zammadTicketId
          creationDate
          reason {
            id
            name
          }
          channel {
            id
            name
          }
          user {
            tableId
            name
            firstname
            lastname
            id
          }
        }
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "appointmentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "appointmentId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "zammadTicketId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creationDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = [
  (v4/*: any*/),
  (v9/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderWizardReason",
  "kind": "LinkedField",
  "name": "reason",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "OrderWizardChannel",
  "kind": "LinkedField",
  "name": "channel",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppointmentTicketsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "Appointment",
                "kind": "LinkedField",
                "name": "Appointment",
                "plural": false,
                "selections": [
                  {
                    "alias": "data",
                    "args": null,
                    "concreteType": "OrderWizardTicket",
                    "kind": "LinkedField",
                    "name": "tickets",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserInfo",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v9/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AppointmentTicketsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "Appointment",
                "kind": "LinkedField",
                "name": "Appointment",
                "plural": false,
                "selections": [
                  {
                    "alias": "data",
                    "args": null,
                    "concreteType": "OrderWizardTicket",
                    "kind": "LinkedField",
                    "name": "tickets",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserInfo",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v9/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8034ebf19a170f15f3199e99da822000",
    "id": null,
    "metadata": {},
    "name": "AppointmentTicketsQuery",
    "operationKind": "query",
    "text": "query AppointmentTicketsQuery(\n  $companyId: ID!\n  $appointmentId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      Appointment(id: $appointmentId) {\n        data: tickets {\n          id\n          tableId\n          status\n          zammadTicketId\n          creationDate\n          reason {\n            id\n            name\n          }\n          channel {\n            id\n            name\n          }\n          user {\n            tableId\n            name\n            firstname\n            lastname\n            id\n          }\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2f8ddec39b11474c7dd1f6527c82103e';

module.exports = node;
