import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { portalTheme, StyledInput } from 'pyrexx-react-library'
import { trim } from 'lodash/string'
import { useTranslation } from 'react-i18next'

import formatDateLocale from '../../../../../helper/formatDateLocale'
import { useUserContext } from '../../../../../helper/userContext'
import { usageUnitLockPeriodReasonEnumList } from '../../../../../components/AgGrid/enumLists'

const map = {
  OWN_SMOKE_DETECTOR: 1,
  TENANT_REJECTED_SMOKE_DETECTORS: 2,
  SMOKE_DETECTORS_NOT_USEFUL: 3,
  UNIT_DOESNT_EXIST: 4,
  TEMP_FOR_RENOVATION: 5,
  UNIT_DEMOLITION: 6,
  UNIT_SOLD: 7,
  MODERNIZATION: 8,
  LOCKED_TIL_INDIVIDUAL_ORDER: 9,
  CUSTOMER_WISH: 10,
  SYSTEM: 11,
  UNKNOWN: 12,
  TEMP_FOR_CLEARIFICATION: 13,
  CONTRACT_CANCELLED: 14,
  CONTRACT_EXPIRED: 15,
  HIGH_CEILING: 16,
  SMOKE_DETECTORS_IN_SAFE_KEEPING: 17,
  CUSTOMER_TEMP_DEFERRED_INSTALLATION: 18,
  DECENTRAL_WARM_WATER: 19,
  UNEXPECTED_BUSINESS_UNIT: 20,
}

const serviceCategories = [
  {
    tableId: 6,
    name: 'Stromzähler',
  },
  {
    tableId: 7,
    name: 'Messdienstleistung (Wärme/Wasser)',
  },
  {
    tableId: 5,
    name: 'Oberflächenreinigung',
  },
  {
    tableId: 3,
    name: 'Trinkwasseruntersuchung',
  },
  {
    tableId: 1,
    name: 'Dienstleistung Rauchwarnmelder',
  },
]

const FormView = ({ formik }) => {
  const { t } = useTranslation()
  const { currentLanguage } = useUserContext()

  const getLockReasons = (data) => {
    return Object.entries(data).map(([value, tableId]) => ({
      value,
      tableId,
      label: t(usageUnitLockPeriodReasonEnumList(value)),
    }))
  }

  const lockReasons = getLockReasons(map)

  return (
    <Container
      style={{
        backgroundColor: portalTheme.color.basicColorWhite,
        padding: '1rem',
      }}
    >
      <Row justify='between'>
        <Col xs='content'>{t('USAGE UNIT LOCK INFORMATION')}</Col>
      </Row>

      <Row>
        <Col>
          <StyledInput
            type='reactSelect'
            name='components.usageUnitLock.lockReason'
            id='components.usageUnitLock.lockReason'
            onChange={formik.handleChange}
            value={formik.values.components?.usageUnitLock?.lockReason}
            label={t('LOCK REASON')}
            feedbackMessage={
              formik.touched.components?.usageUnitLock?.lockReason &&
              formik.errors.components?.usageUnitLock?.lockReason
            }
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
            reactSelectOptions={lockReasons.map((optionElement, index) => {
              console.log('DEBUG RT', optionElement)
              return {
                value: optionElement.tableId,
                label: trim(optionElement.label),
              }
            })}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <StyledInput
            locale={currentLanguage}
            type='dateUi'
            name='components.usageUnitLock.lockDate'
            id='components.usageUnitLock.lockDate'
            onChange={formik.handleChange}
            value={formik.values.components?.usageUnitLock?.lockDate}
            label={t('LOCK DATE')}
            feedbackMessage={
              formik.touched.components?.usageUnitLock?.lockDate &&
              formik.errors.components?.usageUnitLock?.lockDate
            }
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <StyledInput
            type='reactSelect'
            name='components.usageUnitLock.serviceCategory'
            id='components.usageUnitLock.serviceCategory'
            onChange={formik.handleChange}
            value={formik.values.components?.usageUnitLock?.serviceCategory}
            label={t('SERVICE CATEGORY')}
            feedbackMessage={
              formik.touched.components?.usageUnitLock?.serviceCategory &&
              formik.errors.components?.usageUnitLock?.serviceCategory
            }
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
            reactSelectOptions={serviceCategories.map(
              (optionElement, index) => {
                console.log('DEBUG RT', optionElement)
                return {
                  value: optionElement.tableId,
                  label: trim(optionElement.name),
                }
              }
            )}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <StyledInput
            type='textarea'
            name='components.usageUnitLock.additionalNotes'
            id='components.usageUnitLock.additionalNotes'
            onChange={formik.handleChange}
            value={formik.values.components?.usageUnitLock?.additionalNotes}
            label={t('ADDITIONAL NOTES')}
            feedbackMessage={
              formik.touched.components?.usageUnitLock?.additionalNotes &&
              formik.errors.components?.usageUnitLock?.additionalNotes
            }
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>
      </Row>
    </Container>
  )
}

const SummaryView = ({ componentData = {} }) => {
  const { t } = useTranslation()
  const { currentLanguage } = useUserContext()

  return (
    <Container>
      <Row style={{ fontWeight: 'bold' }}>
        <Col>{t('USAGE UNIT LOCK INFORMATION')}</Col>
      </Row>

      <Row>
        <Col
          style={{
            backgroundColor: portalTheme.color.basicColorLightGrey2,
          }}
        >
          <Row>
            <Col>
              <strong>{t('LOCK REASON')}</strong>
            </Col>
            <Col>
              <p>{componentData?.components?.usageUnitLock?.lockReason}</p>
            </Col>
          </Row>

          <Row>
            <Col>
              <strong>{t('LOCK DATE')}</strong>
            </Col>
            <Col>
              {formatDateLocale(
                currentLanguage,
                componentData?.components?.usageUnitLock?.lockDate,
                'E P'
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              <strong>{t('SERVICE CATEGORY')}</strong>
            </Col>
            <Col>
              <p>{componentData?.components?.usageUnitLock?.serviceCategory}</p>
            </Col>
          </Row>

          <Row>
            <Col>
              <strong>{t('ADDITIONAL NOTES')}</strong>
            </Col>
            <Col>
              <p>{componentData?.components?.usageUnitLock?.additionalNotes}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

// STEP 3
const UsageUnitLock = (props) => {
  const { formik, componentData = {}, showSummaryView = false } = props
  console.log('USAGE UNIT LOCK', props)

  if (showSummaryView) {
    return <SummaryView componentData={componentData} />
  } else {
    return <FormView formik={formik} />
  }
}

export default UsageUnitLock
