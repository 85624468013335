import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-grid-system'
import { useUserContext } from '../../../helper/userContext'
import { Routes } from '../../../configs/RouteConfig'
import NavBack from '../../../components/NavBack'
import LayoutPreview from '../../../components/LayoutPreview/LayoutPreview'
import { EnqueueLetterMutation, UpdateLetterMutation } from './mutations'
import {
  ButtonPrimary,
  Icon,
  LoadingSpinner,
  ModalPdfViewer,
  portalTheme,
  SimpleModal,
  SimpleNote,
  StyledInput,
} from 'pyrexx-react-library'
import * as Yup from 'yup'
import { setNestedObjectValues } from 'formik'
import { Title } from '../../User/UserInfo/UserInfo.styles'
import ReactDOMServer from 'react-dom/server'
import { fetchQuery } from 'react-relay'
import environment from '../../../environments/pom/Environment'
import { useParams } from 'react-router-dom'
import DotLoader from '../../../components/LoadingSpinner/DotLoader'
import { LetterPreviewQuery } from '../Letters/queries'
import { LinkButtonStyled } from '../../../styles/HelperStylesComponents.styles'
import replaceTableTag from './helperFunctions/replaceTableTag'
import UpdateSaveButton from '../../../components/UpdateSavedButton/UpdateSaveButton'
import ReactDOM from 'react-dom'
import VariablePreview from '../../../editor/VariablePreview'
import { availableVariables } from '../../../editor/availableVariables'
import { CKEditor as CKEditorReact } from '@ckeditor/ckeditor5-react'
import VariableList from '../../../editor/VariableList'
import styled from 'styled-components/macro'
import CustomEditor from '../../../editor/build/customEditor'
import SubjectOverride from './SubjectOverride'

const BusinessMailCreateSingleLetter = (props) => {
  const { layoutData, pageCount, setPageCount, pdfPreview, setPdfPreview } =
    props
  const { t } = useTranslation()
  const { user, currentLanguage, redirectRoute } = useUserContext()
  const { id } = useParams()
  const [formikValuesClone, setFormikValuesClone] = useState(false)
  const [enqueueLetterLoader, setEnqueueLetterLoader] = useState(false)
  const [enqueueLetterStatus, setEnqueueLetterStatus] = useState(false)
  const [openModal, setOpenModal] = useState(true)
  const [modalState, setModalState] = useState(0) // 0 none; 1:Send confirmation 2:Valiation check 3: no cost position 4: letterSent
  const [shipConfirm, setShipConfirm] = useState(false)
  const [pdfBase64Loading, setPdfBase64Loading] = useState(false)
  const [containerFormErrors, setContainerFormErrors] = useState([])

  const [layoutChangeStatus, setLayoutChangeStatus] = useState({
    msg: '',
    status: '',
    open: false,
  })

  useEffect(() => {}, [pdfPreview])

  const enqueueLetter = useCallback(
    (id, callBack = () => {}) => {
      setEnqueueLetterLoader(true)
      EnqueueLetterMutation(
        {
          companyId: user.companyId,
          letterId: id,
        },
        user.accessToken,
        (finishedLetterData) => {
          setEnqueueLetterStatus(
            finishedLetterData?.Me?.Company?.businessPost?.enqueueLetter
          )
          setModalState(4)
          setOpenModal(true)
          callBack()
          setEnqueueLetterLoader(false)
        }
      )
    },
    [user.accessToken, user.companyId]
  )

  const recipientContainerKeyId = {
    containerKey: 'addressWindow',
    id: 'letterRecipient',
  }
  const contentContainerKeyId = { containerKey: 'content', id: 'letterContent' }

  const submitMutation = (
    id,
    containers,
    values,
    overRideSetLoader,
    updateStatus,
    callBack = () => {}
  ) => {
    if (overRideSetLoader) {
      overRideSetLoader(true)
    }
    UpdateLetterMutation(
      {
        companyId: user.companyId,
        name: values.name,
        letterId: id,
        layout: JSON.stringify(containers, null, 2),
        internalName:
          values?.[recipientContainerKeyId.containerKey]?.[
            recipientContainerKeyId.id
          ]?.name,
        recipient1Title:
          values?.[recipientContainerKeyId.containerKey]?.[
            recipientContainerKeyId.id
          ]?.recipient1Title,
        recipient1Salutation:
          values?.[recipientContainerKeyId.containerKey]?.[
            recipientContainerKeyId.id
          ]?.recipient1Salutation,
        recipient1FirstName:
          values?.[recipientContainerKeyId.containerKey]?.[
            recipientContainerKeyId.id
          ]?.recipient1FirstName,
        recipient1LastName:
          values?.[recipientContainerKeyId.containerKey]?.[
            recipientContainerKeyId.id
          ]?.recipient1LastName,
        recipient2Title:
          values?.[recipientContainerKeyId.containerKey]?.[
            recipientContainerKeyId.id
          ]?.recipient2Title,
        recipient2Salutation:
          values?.[recipientContainerKeyId.containerKey]?.[
            recipientContainerKeyId.id
          ]?.recipient2Salutation,
        recipient2FirstName:
          values?.[recipientContainerKeyId.containerKey]?.[
            recipientContainerKeyId.id
          ]?.recipient2FirstName,
        recipient2LastName:
          values?.[recipientContainerKeyId.containerKey]?.[
            recipientContainerKeyId.id
          ]?.recipient2LastName,
        recipientCompanyName:
          values?.[recipientContainerKeyId.containerKey]?.[
            recipientContainerKeyId.id
          ]?.recipientCompanyName,
        streetAndNumber:
          values?.[recipientContainerKeyId.containerKey]?.[
            recipientContainerKeyId.id
          ]?.streetAndNumber,
        additionalAddressInfo:
          values?.[recipientContainerKeyId.containerKey]?.[
            recipientContainerKeyId.id
          ]?.additionalAddressInfo,
        zip: values?.[recipientContainerKeyId.containerKey]?.[
          recipientContainerKeyId.id
        ]?.zip,
        city: values?.[recipientContainerKeyId.containerKey]?.[
          recipientContainerKeyId.id
        ]?.city,
        country:
          values?.[recipientContainerKeyId.containerKey]?.[
            recipientContainerKeyId.id
          ]?.country,
      },
      user.accessToken,
      (finishedLetterData) => {
        setPageCount(
          finishedLetterData?.Me?.Company?.businessPost?.updateLetter?.pageCount
        )
        setPdfPreview(
          finishedLetterData?.Me?.Company?.businessPost?.updateLetter?.preview
        )
        setLayoutChangeStatus({
          msg: t('LAYOUT UPDATED SUCCESFULLY'),
          status: 'success',
          open: true,
        })
        callBack(finishedLetterData)
        if (overRideSetLoader) {
          overRideSetLoader(false)
        }
      },
      () => {
        setLayoutChangeStatus({
          msg: t('UNKNOWN ERROR'),
          status: 'error',
          open: true,
        })
      }
    )
  }

  const getRecipientOverride = useCallback(() => {
    return {
      containerKey: recipientContainerKeyId.containerKey,
      id: recipientContainerKeyId.id,
      formikIncludeSchema: {
        [recipientContainerKeyId.containerKey]: Yup.object().shape({
          [recipientContainerKeyId.id]: Yup.object().shape({
            recipient1FirstName: Yup.string()
              .min(2, t('FIRST NAME MUST BE AT LEAST {CHARACTER} CHARACTERS'), {
                CHARACTER: 2,
              })
              .required(
                t('FIRST NAME MUST BE AT LEAST {CHARACTER} CHARACTERS'),
                {
                  CHARACTER: 2,
                }
              ),
            recipient1Title: Yup.string(),
            recipient1Salutation: Yup.string().required(
              t('SALUTATION IS REQUIRED')
            ),
            recipient1LastName: Yup.string()
              .min(2, t('LAST NAME MUST BE AT LEAST {CHARACTER} CHARACTERS'), {
                CHARACTER: 2,
              })
              .required(
                t('LAST NAME MUST BE AT LEAST {CHARACTER} CHARACTERS'),
                {
                  CHARACTER: 2,
                }
              ),
            recipient2FirstName: Yup.string(),
            recipient2Title: Yup.string(),
            recipient2Salutation: Yup.string(),
            recipient2LastName: Yup.string(),
            recipientCompanyName: Yup.string(),
            streetAndNumber: Yup.string().required(
              t('STREET AND HOUSE NUMBER IS REQUIRED')
            ),
            additionalAddressInfo: Yup.string(),
            zip: Yup.string().required(t('ZIP IS REQUIRED')),
            city: Yup.string().required(t('CITY IS REQUIRED')),
            country: Yup.string().required(t('COUNTRY IS REQUIRED')),
          }),
        }),
      },
      formikIncludeInitValues: {
        [recipientContainerKeyId.containerKey]: {
          [recipientContainerKeyId.id]: {
            recipient1Title: layoutData.recipient1Title,
            recipient1Salutation: layoutData.recipient1Salutation,
            recipient1FirstName: layoutData.recipient1FirstName,
            recipient1LastName: layoutData.recipient1LastName,
            recipient2Title: layoutData.recipient2Title,
            recipient2Salutation: layoutData.recipient2Salutation,
            recipient2FirstName: layoutData.recipient2FirstName,
            recipient2LastName: layoutData.recipient2LastName,
            recipientCompanyName: layoutData.recipientCompany,
            streetAndNumber: layoutData.addressStreetAndNo,
            additionalAddressInfo: layoutData.addressAdditional,
            zip: layoutData.addressZip,
            city: layoutData.addressCity,
            country: layoutData.addressCountry || t('GERMANY'),
          },
        },
      },
      getForm: (formikTmp, updateFunction, loading) => {
        if (!formikValuesClone) {
          setFormikValuesClone(formikTmp.values)
        }

        const noChangedContent = () => {
          return (
            formikValuesClone?.[recipientContainerKeyId.containerKey]?.[
              recipientContainerKeyId.id
            ]?.recipient1FirstName ===
              formikTmp.values?.[recipientContainerKeyId.containerKey]?.[
                recipientContainerKeyId.id
              ]?.recipient1FirstName &&
            formikValuesClone?.[recipientContainerKeyId.containerKey]?.[
              recipientContainerKeyId.id
            ]?.recipient1Title ===
              formikTmp.values?.[recipientContainerKeyId.containerKey]?.[
                recipientContainerKeyId.id
              ]?.recipient1Title &&
            formikValuesClone?.[recipientContainerKeyId.containerKey]?.[
              recipientContainerKeyId.id
            ]?.recipient1Salutation ===
              formikTmp.values?.[recipientContainerKeyId.containerKey]?.[
                recipientContainerKeyId.id
              ]?.recipient1Salutation &&
            formikValuesClone?.[recipientContainerKeyId.containerKey]?.[
              recipientContainerKeyId.id
            ]?.recipient1LastName ===
              formikTmp.values?.[recipientContainerKeyId.containerKey]?.[
                recipientContainerKeyId.id
              ]?.recipient1LastName &&
            formikValuesClone?.[recipientContainerKeyId.containerKey]?.[
              recipientContainerKeyId.id
            ]?.recipient2Title ===
              formikTmp.values?.[recipientContainerKeyId.containerKey]?.[
                recipientContainerKeyId.id
              ]?.recipient2Title &&
            formikValuesClone?.[recipientContainerKeyId.containerKey]?.[
              recipientContainerKeyId.id
            ]?.recipient2Salutation ===
              formikTmp.values?.[recipientContainerKeyId.containerKey]?.[
                recipientContainerKeyId.id
              ]?.recipient2Salutation &&
            formikValuesClone?.[recipientContainerKeyId.containerKey]?.[
              recipientContainerKeyId.id
            ]?.recipient2LastName ===
              formikTmp.values?.[recipientContainerKeyId.containerKey]?.[
                recipientContainerKeyId.id
              ]?.recipient2LastName &&
            formikValuesClone?.[recipientContainerKeyId.containerKey]?.[
              recipientContainerKeyId.id
            ]?.recipientCompanyName ===
              formikTmp.values?.[recipientContainerKeyId.containerKey]?.[
                recipientContainerKeyId.id
              ]?.recipientCompanyName &&
            formikValuesClone?.[recipientContainerKeyId.containerKey]?.[
              recipientContainerKeyId.id
            ]?.streetAndNumber ===
              formikTmp.values?.[recipientContainerKeyId.containerKey]?.[
                recipientContainerKeyId.id
              ]?.streetAndNumber &&
            formikValuesClone?.[recipientContainerKeyId.containerKey]?.[
              recipientContainerKeyId.id
            ]?.additionalAddressInfo ===
              formikTmp.values?.[recipientContainerKeyId.containerKey]?.[
                recipientContainerKeyId.id
              ]?.additionalAddressInfo &&
            formikValuesClone?.[recipientContainerKeyId.containerKey]?.[
              recipientContainerKeyId.id
            ]?.zip ===
              formikTmp?.values?.[recipientContainerKeyId.containerKey]?.[
                recipientContainerKeyId.id
              ]?.zip &&
            formikValuesClone?.[recipientContainerKeyId.containerKey]?.[
              recipientContainerKeyId.id
            ]?.city ===
              formikTmp.values?.[recipientContainerKeyId.containerKey]?.[
                recipientContainerKeyId.id
              ]?.city &&
            formikValuesClone?.[recipientContainerKeyId.containerKey]?.[
              recipientContainerKeyId.id
            ]?.country ===
              formikTmp.values?.[recipientContainerKeyId.containerKey]?.[
                recipientContainerKeyId.id
              ]?.country
          )
        }

        return (
          <>
            <h3 style={{ margin: '0' }}>{t('ADD RECIPIENTS')}</h3>
            <Row>
              <Col>
                <Title>{t('RECIPIENT 1')}</Title>
              </Col>
            </Row>
            <Row style={{ backgroundColor: '#F0F0F0', paddingTop: '20px' }}>
              <Col>
                <Row>
                  <Col>
                    <StyledInput
                      type='select'
                      name={
                        recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'recipient1Title'
                      }
                      id={
                        recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'recipient1Title'
                      }
                      disabled={loading}
                      onChange={formikTmp.handleChange}
                      onBlur={() => {
                        formikTmp.setFieldTouched(
                          recipientContainerKeyId.containerKey +
                            '.' +
                            recipientContainerKeyId.id +
                            '.' +
                            'recipient1Title',
                          true
                        )
                      }}
                      value={
                        formikTmp.values?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient1Title
                      }
                      label={t('TITLE')}
                      feedbackMessage={
                        formikTmp.touched?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient1Title &&
                        formikTmp.errors?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient1Title
                      }
                      feedbackStyle='invalid'
                      setFieldValue={formikTmp.setFieldValue}
                      optionsHtmlElemets={[
                        <option key='empty' value='' />,
                        <option key='dr' value='DR'>
                          {t('DR.')}
                        </option>,
                        <option key='prof' value='PROF'>
                          {t('PROF.')}
                        </option>,
                        <option key='prof_dr' value='PROF_DR'>
                          {t('PROF. DR.')}
                        </option>,
                      ]}
                    />
                  </Col>
                  <Col>
                    <StyledInput
                      style={{ backgroundColor: '#F0F0F0' }}
                      type='select'
                      name={
                        recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'recipient1Salutation'
                      }
                      id={
                        recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'recipient1Salutation'
                      }
                      disabled={loading}
                      onChange={formikTmp.handleChange}
                      onBlur={() => {
                        formikTmp.setFieldTouched(
                          recipientContainerKeyId.containerKey +
                            '.' +
                            recipientContainerKeyId.id +
                            '.' +
                            'recipient1Salutation',
                          true
                        )
                      }}
                      value={
                        formikTmp.values?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient1Salutation
                      }
                      label={t('SALUTATION')}
                      feedbackMessage={
                        formikTmp.touched?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient1Salutation &&
                        formikTmp.errors?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient1Salutation
                      }
                      feedbackStyle='invalid'
                      setFieldValue={formikTmp.setFieldValue}
                      optionsHtmlElemets={[
                        <option key='empty' value='' />,
                        <option key='mr' value='MR'>
                          {t('MR.')}
                        </option>,
                        <option key='mrs' value='MRS'>
                          {t('MRS.')}
                        </option>,
                        <option key='company' value='COMPANY'>
                          {t('COMPANY')}
                        </option>,
                      ]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <StyledInput
                      style={{ backgroundColor: '#F0F0F0' }}
                      type='input'
                      name={
                        recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'recipient1FirstName'
                      }
                      id={
                        recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'recipient1FirstName'
                      }
                      disabled={loading}
                      onChange={formikTmp.handleChange}
                      onBlur={() => {
                        formikTmp.setFieldTouched(
                          recipientContainerKeyId.containerKey +
                            '.' +
                            recipientContainerKeyId.id +
                            '.' +
                            'recipient1FirstName',
                          true
                        )
                      }}
                      value={
                        formikTmp.values?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient1FirstName
                      }
                      label={t('FIRST NAME')}
                      feedbackMessage={
                        formikTmp.touched?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient1FirstName &&
                        formikTmp.errors?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient1FirstName
                      }
                      feedbackStyle='invalid'
                      setFieldValue={formikTmp.setFieldValue}
                    />
                  </Col>
                  <Col>
                    <StyledInput
                      style={{ backgroundColor: '#F0F0F0' }}
                      type='input'
                      name={
                        recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'recipient1LastName'
                      }
                      id={
                        recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'recipient1LastName'
                      }
                      disabled={loading}
                      onChange={formikTmp.handleChange}
                      onBlur={() => {
                        formikTmp.setFieldTouched(
                          recipientContainerKeyId.containerKey +
                            '.' +
                            recipientContainerKeyId.id +
                            '.' +
                            'recipient1LastName',
                          true
                        )
                      }}
                      value={
                        formikTmp.values?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient1LastName
                      }
                      label={t('LAST NAME')}
                      feedbackMessage={
                        formikTmp.touched?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient1LastName &&
                        formikTmp.errors?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient1LastName
                      }
                      feedbackStyle='invalid'
                      setFieldValue={formikTmp.setFieldValue}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Title>{t('RECIPIENT 2')}</Title>
              </Col>
            </Row>
            <Row style={{ backgroundColor: '#F0F0F0', paddingTop: '20px' }}>
              <Col>
                <Row>
                  <Col>
                    <StyledInput
                      type='select'
                      name={
                        recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'recipient2Title'
                      }
                      id={
                        recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'recipient2Title'
                      }
                      disabled={loading}
                      onChange={formikTmp.handleChange}
                      onBlur={() => {
                        formikTmp.setFieldTouched(
                          recipientContainerKeyId.containerKey +
                            '.' +
                            recipientContainerKeyId.id +
                            '.' +
                            'recipient2Title',
                          true
                        )
                      }}
                      value={
                        formikTmp.values?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient2Title
                      }
                      label={t('TITLE')}
                      feedbackMessage={
                        formikTmp.touched?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient2Title &&
                        formikTmp.errors?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient2Title
                      }
                      feedbackStyle='invalid'
                      setFieldValue={formikTmp.setFieldValue}
                      optionsHtmlElemets={[
                        <option key='empty' value='' />,
                        <option key='dr' value='DR'>
                          {t('DR.')}
                        </option>,
                        <option key='prof' value='PROF'>
                          {t('PROF.')}
                        </option>,
                        <option key='prof_dr' value='PROF_DR'>
                          {t('PROF. DR.')}
                        </option>,
                      ]}
                    />
                  </Col>
                  <Col>
                    <StyledInput
                      style={{ backgroundColor: '#F0F0F0' }}
                      type='select'
                      name={
                        recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'recipient2Salutation'
                      }
                      id={
                        recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'recipient2Salutation'
                      }
                      disabled={loading}
                      onChange={formikTmp.handleChange}
                      onBlur={() => {
                        formikTmp.setFieldTouched(
                          recipientContainerKeyId.containerKey +
                            '.' +
                            recipientContainerKeyId.id +
                            '.' +
                            'recipient2Salutation',
                          true
                        )
                      }}
                      value={
                        formikTmp.values?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient2Salutation
                      }
                      label={t('SALUTATION')}
                      feedbackMessage={
                        formikTmp.touched?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient2Salutation &&
                        formikTmp.errors?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient2Salutation
                      }
                      feedbackStyle='invalid'
                      setFieldValue={formikTmp.setFieldValue}
                      optionsHtmlElemets={[
                        <option key='empty' value='' />,
                        <option key='mr' value='MR'>
                          {t('MR.')}
                        </option>,
                        <option key='mrs' value='MRS'>
                          {t('MRS.')}
                        </option>,
                        <option key='company' value='COMPANY'>
                          {t('COMPANY')}
                        </option>,
                      ]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <StyledInput
                      style={{ backgroundColor: '#F0F0F0' }}
                      type='input'
                      name={
                        recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'recipient2FirstName'
                      }
                      id={
                        recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'recipient2FirstName'
                      }
                      disabled={loading}
                      onChange={formikTmp.handleChange}
                      onBlur={() => {
                        formikTmp.setFieldTouched(
                          recipientContainerKeyId.containerKey +
                            '.' +
                            recipientContainerKeyId.id +
                            '.' +
                            'recipient2FirstName',
                          true
                        )
                      }}
                      value={
                        formikTmp.values?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient2FirstName
                      }
                      label={t('FIRST NAME')}
                      feedbackMessage={
                        formikTmp.touched?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient2FirstName &&
                        formikTmp.errors?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient2FirstName
                      }
                      feedbackStyle='invalid'
                      setFieldValue={formikTmp.setFieldValue}
                    />
                  </Col>
                  <Col>
                    <StyledInput
                      style={{ backgroundColor: '#F0F0F0' }}
                      type='input'
                      name={
                        recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'recipient2LastName'
                      }
                      id={
                        recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'recipient2LastName'
                      }
                      disabled={loading}
                      onChange={formikTmp.handleChange}
                      onBlur={() => {
                        formikTmp.setFieldTouched(
                          recipientContainerKeyId.containerKey +
                            '.' +
                            recipientContainerKeyId.id +
                            '.' +
                            'recipient2LastName',
                          true
                        )
                      }}
                      value={
                        formikTmp.values?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient2LastName
                      }
                      label={t('LAST NAME')}
                      feedbackMessage={
                        formikTmp.touched?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient2LastName &&
                        formikTmp.errors?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipient2LastName
                      }
                      feedbackStyle='invalid'
                      setFieldValue={formikTmp.setFieldValue}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ paddingTop: '20px' }}>
              <Col>
                <Row>
                  <Col>
                    <StyledInput
                      type='input'
                      name={
                        recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'recipientCompanyName'
                      }
                      id={
                        recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'recipientCompanyName'
                      }
                      disabled={loading}
                      onChange={formikTmp.handleChange}
                      onBlur={() => {
                        formikTmp.setFieldTouched(
                          recipientContainerKeyId.containerKey +
                            '.' +
                            recipientContainerKeyId.id +
                            '.' +
                            'recipientCompanyName',
                          true
                        )
                      }}
                      value={
                        formikTmp.values?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipientCompanyName
                      }
                      label={t('COMPANY')}
                      feedbackMessage={
                        formikTmp.touched?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipientCompanyName &&
                        formikTmp.errors?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.recipientCompanyName
                      }
                      feedbackStyle='invalid'
                      setFieldValue={formikTmp.setFieldValue}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <StyledInput
                      type='input'
                      name={
                        recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'streetAndNumber'
                      }
                      id={
                        recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'streetAndNumber'
                      }
                      disabled={loading}
                      onChange={formikTmp.handleChange}
                      onBlur={() => {
                        formikTmp.setFieldTouched(
                          recipientContainerKeyId.containerKey +
                            '.' +
                            recipientContainerKeyId.id +
                            '.' +
                            'streetAndNumber',
                          true
                        )
                      }}
                      value={
                        formikTmp.values?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.streetAndNumber
                      }
                      label={t('STREET AND HOUSE NUMBER')}
                      feedbackMessage={
                        formikTmp.touched?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.streetAndNumber &&
                        formikTmp.errors?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.streetAndNumber
                      }
                      feedbackStyle='invalid'
                      setFieldValue={formikTmp.setFieldValue}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <StyledInput
                      type='input'
                      name={
                        recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'additionalAddressInfo'
                      }
                      id={
                        recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'additionalAddressInfo'
                      }
                      disabled={loading}
                      onChange={formikTmp.handleChange}
                      onBlur={() => {
                        formikTmp.setFieldTouched(
                          recipientContainerKeyId.containerKey +
                            '.' +
                            recipientContainerKeyId.id +
                            '.' +
                            'additionalAddressInfo',
                          true
                        )
                      }}
                      value={
                        formikTmp.values?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.additionalAddressInfo
                      }
                      label={t('ADDITIONAL ADDRESS DATA')}
                      feedbackMessage={
                        formikTmp.touched?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]
                          ?.additionalAddressInfo &&
                        formikTmp.errors?.[
                          recipientContainerKeyId.containerKey
                        ]?.[recipientContainerKeyId.id]?.additionalAddressInfo
                      }
                      feedbackStyle='invalid'
                      setFieldValue={formikTmp.setFieldValue}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <StyledInput
                  type='input'
                  name={
                    recipientContainerKeyId.containerKey +
                    '.' +
                    recipientContainerKeyId.id +
                    '.' +
                    'zip'
                  }
                  id={
                    recipientContainerKeyId.containerKey +
                    '.' +
                    recipientContainerKeyId.id +
                    '.' +
                    'zip'
                  }
                  disabled={loading}
                  onChange={formikTmp.handleChange}
                  onBlur={() => {
                    formikTmp.setFieldTouched(
                      recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'zip',
                      true
                    )
                  }}
                  value={
                    formikTmp.values?.[recipientContainerKeyId.containerKey]?.[
                      recipientContainerKeyId.id
                    ]?.zip
                  }
                  label={t('ZIP')}
                  feedbackMessage={
                    formikTmp.touched?.[recipientContainerKeyId.containerKey]?.[
                      recipientContainerKeyId.id
                    ]?.zip &&
                    formikTmp.errors?.[recipientContainerKeyId.containerKey]?.[
                      recipientContainerKeyId.id
                    ]?.zip
                  }
                  feedbackStyle='invalid'
                  setFieldValue={formikTmp.setFieldValue}
                />
              </Col>
              <Col>
                <StyledInput
                  type='input'
                  name={
                    recipientContainerKeyId.containerKey +
                    '.' +
                    recipientContainerKeyId.id +
                    '.' +
                    'city'
                  }
                  id={
                    recipientContainerKeyId.containerKey +
                    '.' +
                    recipientContainerKeyId.id +
                    '.' +
                    'city'
                  }
                  disabled={loading}
                  onChange={formikTmp.handleChange}
                  onBlur={() => {
                    formikTmp.setFieldTouched(
                      recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'city',
                      true
                    )
                  }}
                  value={
                    formikTmp.values?.[recipientContainerKeyId.containerKey]?.[
                      recipientContainerKeyId.id
                    ]?.city
                  }
                  label={t('CITY')}
                  feedbackMessage={
                    formikTmp.touched?.[recipientContainerKeyId.containerKey]?.[
                      recipientContainerKeyId.id
                    ]?.city &&
                    formikTmp.errors?.[recipientContainerKeyId.containerKey]?.[
                      recipientContainerKeyId.id
                    ]?.city
                  }
                  feedbackStyle='invalid'
                  setFieldValue={formikTmp.setFieldValue}
                />
              </Col>
              <Col>
                <StyledInput
                  type='input'
                  name={
                    recipientContainerKeyId.containerKey +
                    '.' +
                    recipientContainerKeyId.id +
                    '.' +
                    'country'
                  }
                  id={
                    recipientContainerKeyId.containerKey +
                    '.' +
                    recipientContainerKeyId.id +
                    '.' +
                    'country'
                  }
                  disabled
                  onChange={formikTmp.handleChange}
                  onBlur={() => {
                    formikTmp.setFieldTouched(
                      recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'country',
                      true
                    )
                  }}
                  value={
                    formikTmp.values?.[recipientContainerKeyId.containerKey]?.[
                      recipientContainerKeyId.id
                    ]?.country
                  }
                  label={t('country')}
                  feedbackMessage={
                    formikTmp.touched?.[recipientContainerKeyId.containerKey]?.[
                      recipientContainerKeyId.id
                    ]?.country &&
                    formikTmp.errors?.[recipientContainerKeyId.containerKey]?.[
                      recipientContainerKeyId.id
                    ]?.country
                  }
                  feedbackStyle='invalid'
                  setFieldValue={formikTmp.setFieldValue}
                />
              </Col>
            </Row>

            <Row justify='end'>
              <Col style={{ paddingBottom: '20px' }}>
                <UpdateSaveButton
                  loading={loading}
                  handleSave={() => {
                    formikTmp.setFieldTouched(
                      recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'recipient1FirstName',
                      true
                    )
                    formikTmp.setFieldTouched(
                      recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'recipient1Salutation',
                      true
                    )
                    formikTmp.setFieldTouched(
                      recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'recipient1LastName',
                      true
                    )
                    formikTmp.setFieldTouched(
                      recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'streetAndNumber',
                      true
                    )
                    formikTmp.setFieldTouched(
                      recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'zip',
                      true
                    )
                    formikTmp.setFieldTouched(
                      recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'city',
                      true
                    )
                    formikTmp.setFieldTouched(
                      recipientContainerKeyId.containerKey +
                        '.' +
                        recipientContainerKeyId.id +
                        '.' +
                        'country',
                      true
                    )
                    if (
                      !formikTmp.errors?.[
                        recipientContainerKeyId.containerKey
                      ]?.[recipientContainerKeyId.id]?.recipient1FirstName &&
                      !formikTmp.errors?.[
                        recipientContainerKeyId.containerKey
                      ]?.[recipientContainerKeyId.id]?.recipient1Salutation &&
                      !formikTmp.errors?.[
                        recipientContainerKeyId.containerKey
                      ]?.[recipientContainerKeyId.id]?.recipient1LastName &&
                      !formikTmp.errors?.[
                        recipientContainerKeyId.containerKey
                      ]?.[recipientContainerKeyId.id]?.streetAndNumber &&
                      !formikTmp.errors?.[
                        recipientContainerKeyId.containerKey
                      ]?.[recipientContainerKeyId.id]?.zip &&
                      !formikTmp.errors?.[
                        recipientContainerKeyId.containerKey
                      ]?.[recipientContainerKeyId.id]?.city &&
                      !formikTmp.errors?.[
                        recipientContainerKeyId.containerKey
                      ]?.[recipientContainerKeyId.id]?.country
                    ) {
                      const newContainerFormErrors = containerFormErrors.reduce(
                        (prev, current) => {
                          if (
                            recipientContainerKeyId.containerKey +
                              '_' +
                              recipientContainerKeyId.id !==
                            current
                          ) {
                            return [...prev, current]
                          } else {
                            return [...prev]
                          }
                        },
                        []
                      )
                      setContainerFormErrors(newContainerFormErrors)
                      updateFunction(
                        ReactDOMServer.renderToString(
                          <div style={{ fontSize: '12px' }}>
                            <div>
                              {
                                formikTmp.values?.[
                                  recipientContainerKeyId.containerKey
                                ]?.[recipientContainerKeyId.id]
                                  ?.recipientCompanyName
                              }
                            </div>
                            <div>
                              {formikTmp.values?.[
                                recipientContainerKeyId.containerKey
                              ]?.[recipientContainerKeyId.id]
                                ?.recipient1FirstName +
                                ' ' +
                                formikTmp.values?.[
                                  recipientContainerKeyId.containerKey
                                ]?.[recipientContainerKeyId.id]
                                  ?.recipient1LastName}
                            </div>
                            <div>
                              {
                                formikTmp.values?.[
                                  recipientContainerKeyId.containerKey
                                ]?.[recipientContainerKeyId.id]?.streetAndNumber
                              }
                            </div>
                            <div>
                              {
                                formikTmp.values?.[
                                  recipientContainerKeyId.containerKey
                                ]?.[recipientContainerKeyId.id]
                                  ?.additionalAddressInfo
                              }
                            </div>
                            <div>
                              {formikTmp.values?.[
                                recipientContainerKeyId.containerKey
                              ]?.[recipientContainerKeyId.id]?.zip +
                                ' ' +
                                formikTmp.values?.[
                                  recipientContainerKeyId.containerKey
                                ]?.[recipientContainerKeyId.id]?.city}
                            </div>
                            <div>
                              {
                                formikTmp.values?.[
                                  recipientContainerKeyId.containerKey
                                ]?.[recipientContainerKeyId.id]?.country
                              }
                            </div>
                          </div>
                        )
                      )
                      setFormikValuesClone(formikTmp.values)
                    }
                  }}
                  noChangedContent={noChangedContent}
                />
              </Col>
            </Row>
          </>
        )
      },
    }
  }, [
    containerFormErrors,
    formikValuesClone,
    layoutData.addressAdditional,
    layoutData.addressCity,
    layoutData.addressCountry,
    layoutData.addressStreetAndNo,
    layoutData.addressZip,
    layoutData.recipient1FirstName,
    layoutData.recipient1LastName,
    layoutData.recipient1Salutation,
    layoutData.recipient1Title,
    layoutData.recipient2FirstName,
    layoutData.recipient2LastName,
    layoutData.recipient2Salutation,
    layoutData.recipient2Title,
    layoutData.recipientCompany,
    recipientContainerKeyId.containerKey,
    recipientContainerKeyId.id,
    t,
  ])

  const editorRef = useRef(null)

  const getContentOverride = useCallback(() => {
    return {
      containerKey: 'content',
      id: 'letterContent',
      formikIncludeSchema: {
        [contentContainerKeyId.containerKey]: Yup.object().shape({
          [contentContainerKeyId.id]: Yup.object().shape({
            data: Yup.string()
              .min(
                3,
                t('LETTER CONTENT MUST BE AT LEAST {CHARACTER} CHARACTERS'),
                {
                  CHARACTER: 3,
                }
              )
              .required(
                t('LETTER CONTENT MUST BE AT LEAST {CHARACTER} CHARACTERS'),
                {
                  CHARACTER: 3,
                }
              ),
          }),
        }),
      },
      formikIncludeInitValues: {
        [contentContainerKeyId.containerKey]: {
          [contentContainerKeyId.id]: {
            data: JSON.parse(layoutData.layout)[
              contentContainerKeyId.containerKey
            ].find((object) => object.id === contentContainerKeyId.id).content,
          },
        },
      },
      getForm: (formikTmp, updateFunction, loading) => {
        if (!formikValuesClone) {
          setFormikValuesClone(formikTmp.values)
        }

        const editorConfig = {
          // The configuration of the Products plugin. It specifies a function that will allow
          // the editor to render a React <VariablePreview> component inside a product widget.
          variables: {
            variablesRenderer: (id, domElement) => {
              const variable = availableVariables.find(
                (variable) => variable.id === id
              )

              const handleRemove = (id) => {
                editorRef.current.execute('removeVariable', id)
                editorRef.current.editing.view.focus()
              }

              // const variableElement = createRoot(domElement)
              ReactDOM.render(
                <VariablePreview
                  id={id}
                  handleRemove={handleRemove}
                  {...variable}
                />,
                domElement
              )
            },
          },
        }

        const handleEditorDataChange = (evt, editor) => {
          formikTmp.setFieldValue(
            contentContainerKeyId.containerKey +
              '.' +
              contentContainerKeyId.id +
              '.data',
            editor.getData()
          )
        }

        const handleEditorReady = (editor) => {
          editorRef.current = editor

          editor.editing.view.change((writer) => {
            writer.setStyle(
              'height',
              '400px',
              editor.editing.view.document.getRoot()
            )
          })
        }

        const handleSave = () => {
          if (editorRef.current) {
            setFormikValuesClone(formikTmp.values)
            const newContainerFormErrors = containerFormErrors.reduce(
              (prev, current) => {
                if (
                  contentContainerKeyId.containerKey +
                    '_' +
                    contentContainerKeyId.id !==
                  current
                ) {
                  return [...prev, current]
                } else {
                  return [...prev]
                }
              },
              []
            )
            setContainerFormErrors(newContainerFormErrors)
            const data = editorRef.current.getData()
            const newData = replaceTableTag(data)

            formikTmp.validateForm()
            if (newData) {
              updateFunction(newData)
            } else {
              updateFunction('')
            }
          }
        }

        const noChangedContent = () => {
          return (
            formikTmp.values?.[contentContainerKeyId.containerKey]?.[
              contentContainerKeyId.id
            ]?.data ===
            formikValuesClone?.[contentContainerKeyId.containerKey]?.[
              contentContainerKeyId.id
            ]?.data
          )
        }

        return (
          <div>
            <Row>
              <Col>
                <h4 style={{ margin: ' 0 0 15px 0' }}>{t('WRITE TEXT')} </h4>
              </Col>
              <Col xs={1}>
                <Icon
                  icon='info'
                  size='18px'
                  color={portalTheme.color.linkColorPrimary}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {formikTmp.errors?.[contentContainerKeyId.containerKey]?.[
                  contentContainerKeyId.id
                ]?.data && (
                  <SimpleNote
                    style={{ fontSize: '14px' }}
                    noteStatus='error'
                    text={
                      formikTmp.errors?.[contentContainerKeyId.containerKey]?.[
                        contentContainerKeyId.id
                      ]?.data
                    }
                  />
                )}
              </Col>
            </Row>
            <VariablesEditorWrapper>
              <CKEditorReact
                editor={CustomEditor.EditorMassLetter}
                data={
                  formikTmp.values?.[contentContainerKeyId.containerKey]?.[
                    contentContainerKeyId.id
                  ]?.data
                }
                config={editorConfig}
                onChange={handleEditorDataChange}
                onReady={handleEditorReady}
              />

              <div>
                <h4 style={{ margin: 0 }}>{t('VARIABLEN')}</h4>
                <VariablesBox>
                  <VariableList
                    key='variables-list'
                    variables={availableVariables}
                    onClick={(id) => {
                      // console.log(editorRef.current)
                      editorRef.current.execute('insertVariable', id)
                      editorRef.current.editing.view.focus()
                    }}
                  />
                </VariablesBox>
              </div>
            </VariablesEditorWrapper>

            <Row
              justify='end'
              align='end'
              style={{
                paddingBottom: '20px',
                paddingTop: '20px',
              }}
            >
              <Col>
                <UpdateSaveButton
                  loading={loading}
                  handleSave={handleSave}
                  noChangedContent={noChangedContent}
                />
              </Col>
            </Row>
          </div>
        )
      },
    }
  }, [
    containerFormErrors,
    contentContainerKeyId.containerKey,
    contentContainerKeyId.id,
    formikValuesClone,
    layoutData.layout,
    t,
  ])

  const subjectContainerKeyId = {
    containerKey: 'content',
    id: 'subject',
  }

  const getSubjectOverride = useCallback(() => {
    return {
      containerKey: 'content',
      id: 'subject',
      formikIncludeSchema: {
        [subjectContainerKeyId.containerKey]: Yup.object().shape({
          [subjectContainerKeyId.id]: Yup.object().shape({
            data: Yup.string(),
          }),
        }),
      },
      formikIncludeInitValues: {
        [subjectContainerKeyId.containerKey]: {
          [subjectContainerKeyId.id]: {
            data: JSON.parse(layoutData.layout)[
              subjectContainerKeyId.containerKey
            ].find((object) => object.id === subjectContainerKeyId.id).content,
          },
        },
      },
      getForm: (formikTmp, updateFunction, loading) => {
        if (!formikValuesClone) {
          setFormikValuesClone(formikTmp.values)
        }
        return (
          <SubjectOverride
            formikTmp={formikTmp}
            setFormikValuesClone={setFormikValuesClone}
            setContainerFormErrors={setContainerFormErrors}
            containerFormErrors={containerFormErrors}
            formikValuesClone={formikValuesClone}
            updateFunction={updateFunction}
            loading={loading}
            subjectContainerKeyId={subjectContainerKeyId}
            allVariables={availableVariables}
            setUndefinedVariables={() => {}}
            undefinedVariables={[]}
            customVariables={[]}
            returnUndefinedContentVariables={() => {
              return []
            }}
          />
        )
      },
    }
  }, [
    containerFormErrors,
    formikValuesClone,
    layoutData.layout,
    subjectContainerKeyId,
  ])

  const downloadPdf = useCallback(() => {
    setPdfBase64Loading(true)
    fetchQuery(
      environment(user.accessToken),
      LetterPreviewQuery,
      {
        letterId: id,
        companyId: user.companyId,
      },
      {
        force: true,
      }
    )
      .then((data) => {
        const base64 = data.Me?.Company?.businessPost.data.preview
        const linkSource = `data:application/pdf;base64,${base64}`
        const downloadLink = document.createElement('a')
        const fileName =
          'letter_' + data.Me?.Company?.businessPost.data.tableId + '.pdf'
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
        setPdfBase64Loading(false)
      })
      .catch((e) => {
        console.log('PREVIEW PDF: letterPreview ERROR CATCH', e)
        setPdfBase64Loading(false)
      })
  }, [id, user.accessToken, user.companyId])

  const generateModalContentNoCostPosition = useCallback(() => {
    return (
      <>
        <SimpleModal
          isOpen={openModal}
          showButtonClose
          closeOnOutsideClick={false}
          handleModalClose={() => setOpenModal(false)}
        >
          <Row justify='start'>
            <Col xs='content'>
              <SimpleNote noteStatus='error' text={t('COULD NOT SEND')} />
            </Col>
          </Row>
          <Row justify='start' style={{ marginTop: '20px' }}>
            <Col xs='content'>
              <Container>
                <Row>
                  <Col
                    style={{
                      fontSize: '12px',
                      padding: '10px',
                    }}
                  >
                    {t(
                      'LETTERS ARE CALCULATED ACCORDING TO THE CONTRACTUALLY STORED COST ITEM. IF A WARNING APPEARS, THIS COST ITEM DOES NOT YET EXIST. PLEASE CONTACT VERTRAG@PYREXX.COM SO THAT THEY CAN BE ACTIVATED FOR YOU.'
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      fontSize: '12px',
                      padding: '10px',
                    }}
                  >
                    {t('YOU CAN STILL DOWNLOAD THE LETTER.')}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row justify='end' style={{ marginTop: '20px' }}>
            <Col xs='content'>
              {pdfBase64Loading ? (
                <Row nogutter>
                  <Col xs='content'>
                    <div style={{ height: '30px', width: '30px' }}>
                      <LoadingSpinner
                        theme={{
                          components: {
                            spinner: {
                              /**
                               * Has to be a { css } oject from styled-components
                               */
                              fontSize: portalTheme.font.size.bodySmall,
                              fontWeight: portalTheme.font.weight.regular,
                              size: '20px',
                            },
                          },
                        }}
                        style={{
                          position: 'unset',
                          backgroundColor: 'white',
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs='content'>{t('PLEASE WAIT')}</Col>
                </Row>
              ) : (
                <LinkButtonStyled
                  style={{ textAlign: 'left' }}
                  type='button'
                  onClick={() => {
                    downloadPdf()
                  }}
                >
                  <Row nogutter align='center' justify='center'>
                    <Col xs='content'>
                      <Icon icon='download' size='20px' />
                    </Col>
                    <Col xs='content' style={{ paddingLeft: '5px' }}>
                      {t('DOWNLOAD PDF')}
                    </Col>
                  </Row>
                </LinkButtonStyled>
              )}
            </Col>
          </Row>
        </SimpleModal>
      </>
    )
  }, [downloadPdf, openModal, pdfBase64Loading, t])

  const generateModalContentValidation = useCallback(() => {
    return (
      <>
        <SimpleModal
          isOpen={openModal}
          showButtonClose
          closeOnOutsideClick={false}
          handleModalClose={() => setOpenModal(false)}
        >
          <Row justify='center'>
            <Col xs='content'>
              <SimpleNote
                noteStatus='warning'
                text={t('PLEASE WAIT FOR VALIDATION AND DONT CLOSE THE WINDOW')}
              />
            </Col>
          </Row>
          <Row justify='center' style={{ marginTop: '40px' }}>
            <Col xs='content'>
              <DotLoader />
            </Col>
          </Row>
          <Row justify='center' style={{ marginTop: '20px' }}>
            <Col xs='content'>
              <ButtonPrimary
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                {t('CONFIRM')}
              </ButtonPrimary>
            </Col>
          </Row>
        </SimpleModal>
      </>
    )
  }, [openModal, t])

  const generateModalContentSendLetter = useCallback(() => {
    return (
      <>
        <SimpleModal
          isOpen={openModal}
          showButtonClose
          closeOnOutsideClick={false}
          handleModalClose={() => setOpenModal(false)}
        >
          <Row justify='start'>
            <Col xs='content'>
              <SimpleNote noteStatus='success' text={t('READY TO SEND')} />
            </Col>
          </Row>
          <Row justify='start' style={{ marginTop: '20px' }}>
            <Col xs='content'>
              <Container>
                <Row>
                  <Col
                    style={{
                      backgroundColor: '#B0DDF0',
                      borderRadius: '10px',
                      padding: '10px',
                    }}
                  >
                    {t(
                      'WHEN THIS LETTER IS SENT, THE COST POSITION IN THE CONTRACT WILL BE ACTIVATED'
                    )}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col>
              <StyledInput
                label={t('I CONFIRM PAID SHIPPING')}
                type='checkbox'
                value={shipConfirm}
                onChange={() => setShipConfirm(!shipConfirm)}
                id='shipConfirm'
                name='shipConfirm'
                setFieldValue={() => {}}
              />
            </Col>
          </Row>
          <Row justify='end' style={{ marginTop: '10px' }}>
            <Col xs='content'>
              {enqueueLetterLoader ? (
                <Row nogutter>
                  <Col xs='content'>
                    <div style={{ height: '30px', width: '30px' }}>
                      <LoadingSpinner
                        theme={{
                          components: {
                            spinner: {
                              /**
                               * Has to be a { css } oject from styled-components
                               */
                              fontSize: portalTheme.font.size.bodySmall,
                              fontWeight: portalTheme.font.weight.regular,
                              size: '20px',
                            },
                          },
                        }}
                        style={{
                          position: 'unset',
                          backgroundColor: 'white',
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs='content'>{t('PLEASE WAIT')}</Col>
                </Row>
              ) : (
                <ButtonPrimary
                  disabled={!shipConfirm}
                  onClick={() => {
                    enqueueLetter(id)
                  }}
                >
                  {t('SEND')}
                </ButtonPrimary>
              )}
            </Col>
          </Row>
        </SimpleModal>
      </>
    )
  }, [enqueueLetter, enqueueLetterLoader, id, openModal, shipConfirm, t])

  const generateModalContentFormError = useCallback(() => {
    return (
      <>
        <SimpleModal
          isOpen={openModal}
          showButtonClose
          closeOnOutsideClick={false}
          handleModalClose={() => setOpenModal(false)}
        >
          <Row justify='start'>
            <Col xs='content'>
              <SimpleNote noteStatus='error' text={t('MISSING CONTENT')} />
            </Col>
          </Row>
          <Row justify='start' style={{ marginTop: '20px' }}>
            <Col xs='content'>
              <Container>
                <Row>
                  <Col
                    style={{
                      borderRadius: '10px',
                      padding: '10px',
                    }}
                  >
                    {t(
                      'PLEASE CHECK THE RED MARKED CONTAINER ON THE LETTER. YOU NEED TO FILL THE REQUIRED FIELD BEFORE YOU CAN SEND THE LETTER'
                    )}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row justify='end' style={{ marginTop: '10px' }}>
            <Col xs='content'>
              <ButtonPrimary
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                {t('CLOSE')}
              </ButtonPrimary>
            </Col>
          </Row>
        </SimpleModal>
      </>
    )
  }, [openModal, t])

  const generateModalContentSent = useCallback(() => {
    const getNoteStatus = () => {
      switch (enqueueLetterStatus) {
        case 'SUCCESS':
          return {
            msg: t('LETTER SUCCESSFULLY SENT'),
            noteStatus: 'success',
          }
        case 'NO_COST_POSITION':
          return {
            msg: t('COULD NOT SEND LETTER. NO COST POSITION FOUND.'),
            noteStatus: 'error',
          }
        case 'LETTER_ALREADY_SENT':
          return {
            msg: t('LETTER WAS ALREADY SENT'),
            noteStatus: 'warning',
          }
        case 'INVALID_LAYOUT':
          return {
            msg: t(
              'COULD NOT SEND LETTER. INVALID LAYOUT. PLEASE ASK FOR SUPPORT!'
            ),
            noteStatus: 'warning',
          }
        case 'QUOTA_EXCEEDED':
          return {
            msg: t(
              'COULD NOT SEND LETTER. MAX QUOTA EXCEEDED. ONLY 3 LETTER CAN BE SEND!'
            ),
            noteStatus: 'error',
          }
        default:
          return {
            msg: t('UNKNOWN ERROR. PLEASE ASK SUPPORT'),
            noteStatus: 'error',
          }
      }
    }
    const notStatus = getNoteStatus()
    return (
      <>
        <SimpleModal
          isOpen={openModal}
          showButtonClose={false}
          closeOnOutsideClick={false}
          handleModalClose={() => setOpenModal(false)}
        >
          <Row justify='start'>
            <Col xs='content'>
              <SimpleNote
                noteStatus={notStatus.noteStatus}
                text={notStatus.msg}
              />
            </Col>
          </Row>
          <Row justify='end' style={{ marginTop: '10px' }}>
            <Col xs='content'>
              <ButtonPrimary
                onClick={() => {
                  setOpenModal(false)
                  if (enqueueLetterStatus === 'SUCCESS') {
                    redirectRoute(
                      Routes.businessMail + Routes.businessMailsGrid
                    )
                  }
                }}
              >
                {t('CLOSE')}
              </ButtonPrimary>
            </Col>
          </Row>
        </SimpleModal>
      </>
    )
  }, [enqueueLetterStatus, openModal, redirectRoute, t])

  const generateModals = useCallback(() => {
    switch (modalState) {
      case 1:
        return generateModalContentSendLetter()
      case 2:
        return generateModalContentValidation()
      case 3:
        return generateModalContentNoCostPosition()
      case 4:
        return generateModalContentSent()
      case 5:
        return generateModalContentFormError()
      default:
        return ''
    }
  }, [
    generateModalContentFormError,
    generateModalContentNoCostPosition,
    generateModalContentSendLetter,
    generateModalContentSent,
    generateModalContentValidation,
    modalState,
  ])
  useEffect(() => {
    if (modalState > 0) {
      setOpenModal(true)
    }
  }, [modalState])

  const getContainersHasErrors = (errors) => {
    const keyify = (obj, prefix = '') =>
      Object.keys(obj).reduce((res, el) => {
        if (Array.isArray(obj[el])) {
          return res
        } else if (typeof obj[el] === 'object' && obj[el] !== null) {
          return [...res, ...keyify(obj[el], prefix + el + '_')]
        }
        return [...res, prefix + el]
      }, [])
    const firstStep = keyify(errors)
    const secondStep = firstStep.reduce((prev, current) => {
      const splitted = current.split('_')
      if (splitted.length === 3) {
        return [...prev, splitted[0] + '_' + splitted[1]]
      } else {
        return [...prev, current]
      }
    }, [])
    const finishedErrors = [...new Set(secondStep)]
    setContainerFormErrors(finishedErrors)
    return finishedErrors
  }

  const [modal, setModal] = useState({
    show: false,
    filename: 'example.pdf',
    loading: true,
    loadingText: t('PLEASE WAIT'),
    base64: false,
    primaryButton: {
      usePrimaryButton: true,
    },
    showButtonClose: true,
  })

  const handleModalClose = () => {
    setModal({
      show: false,
      filename: 'example.pdf',
      loading: true,
      loadingText: t('PLEASE WAIT'),
      base64: '',
      primaryButton: {
        usePrimaryButton: true,
      },
      showButtonClose: true,
    })
  }

  const triggerPreviewLetterQuery = useCallback(() => {
    setModal({
      show: true,
      loading: false,
      loadingText: t('PLEASE WAIT'),
      filename: t('LETTER') + '_' + layoutData.tableId + '.pdf',
      base64: pdfPreview,
      primaryButton: {
        usePrimaryButton: true,
      },
      showButtonClose: true,
    })
  }, [layoutData.tableId, pdfPreview, t])

  return (
    <Container>
      <Row>
        <Col>
          <NavBack
            title=''
            to={
              '/' +
              currentLanguage +
              Routes.businessMail +
              Routes.businessMailCreate
            }
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <LayoutPreview
            containerFormikOverride={[
              getContentOverride(),
              getRecipientOverride(),
              getSubjectOverride(),
            ]}
            nameLabel={t('INTERNAL NAME (OPTIONAL)')}
            name={layoutData.internalTitle}
            nameIsReq={false}
            replacements={layoutData.replacement}
            status={layoutData.status}
            adminEdit={false}
            submitMutation={submitMutation}
            containerFormErrors={containerFormErrors}
            overrideSendButtonMutation={(id, formikTmp) => {
              formikTmp.validateForm().then((errors) => {
                formikTmp.setTouched(setNestedObjectValues(errors, true))
                getContainersHasErrors(errors)
                if (
                  errors &&
                  Object.keys(errors).length === 0 &&
                  Object.getPrototypeOf(errors) === Object.prototype
                ) {
                  if (layoutData.enqueuePreCheck === 'NO_COST_POSITION') {
                    setModalState(3)
                    setOpenModal(true)
                  } else {
                    setModalState(1)
                    setOpenModal(true)
                  }
                } else {
                  setModalState(5)
                  setOpenModal(true)
                }
              })
            }}
            singleLetter
            saveButtonText={t('SEND')}
            saveButtonTextDraft={t('SEND LETTER')}
            saveButtonTextActive={t('LETTER SENT')}
            id={layoutData.tableId}
            initContainer={JSON.parse(layoutData.layout)}
            pageCount={pageCount}
            triggerPreview={triggerPreviewLetterQuery}
            setPageCount={setPageCount}
            setPdfPreview={setPdfPreview}
            layoutChangeStatus={layoutChangeStatus}
            setLayoutChangeStatus={setLayoutChangeStatus}
          />
        </Col>
      </Row>
      {generateModals()}
      {modal.show && (
        <ModalPdfViewer
          isOpen={modal.show}
          handleModalClose={handleModalClose}
          loading={modal.loading}
          loadingText={modal.loadingText}
          base64={modal.base64}
          filename={modal.filename}
          showButtonClose={modal.showButtonClose}
          primaryButton={{ usePrimaryButton: true }}
        />
      )}
    </Container>
  )
}

const VariablesEditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const VariablesBox = styled.div`
  border: 1px solid #ccced1;
  border-color: var(--ck-color-base-border, #ccced1);
  padding: 8px;

  & > div > div {
    flex-wrap: wrap;
    max-height: 200px;
  }
`

export default BusinessMailCreateSingleLetter
