import React from 'react'
import { useTranslation } from 'react-i18next'

import PageContainer from '../../components/PageContainer'
import PageTitle from '../../components/PageTitle'
import Tabs from '../../components/Tabs'
import Portfolio from './Portfolio'
import Operations from './Operations'
import InventoryDataUpload from './InventoryDataUpload'

import useUrlRedirection from '../../hooks/useUrlRedirection'

const PropertyManagement = (props) => {
  const {
    loading = false,
    services = [],
    contracts = [],
    houseEntrancesCount = 0,
    pageSection = 'portfolioOverview',
  } = props

  const { t } = useTranslation()
  const urlRedirectionCallback = useUrlRedirection()

  const OverviewTabs = [
    {
      key: 'portfolioOverview',
      label: t('PORTFOLIO OVERVIEW'),
      content: (
        <Portfolio
          services={services}
          loading={loading}
          houseEntrancesCount={houseEntrancesCount}
        />
      ),
    },
    {
      key: 'portfolioDataUpload',
      label: t('PORTFOLIO DATA UPLOAD'),
      content: <InventoryDataUpload contracts={contracts} loading={loading} />,
    },
    {
      key: 'operations',
      label: t('INCIDENTS'),
      content: <Operations />,
    },
  ]

  return (
    <PageContainer fluid>
      <PageTitle>{t('PORTFOLIO MANAGEMENT')}</PageTitle>
      <Tabs
        tabs={OverviewTabs}
        pageSection={pageSection}
        urlRedirectionCallback={urlRedirectionCallback}
      />
    </PageContainer>
  )
}

export default PropertyManagement
