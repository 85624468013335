import React from 'react'
import { ButtonIcon, portalTheme } from 'pyrexx-react-library'

export default function RemoveLink(props) {
  const { value, removeLinkIdFromState } = props

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ButtonIcon
        key='addMoreDataLinks'
        onClick={() => {
          removeLinkIdFromState(value)
        }}
        icon='trash'
        size='30px'
        color={portalTheme.color.errorColor}
      />
    </div>
  )
}
