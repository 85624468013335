import * as Throttle from 'promise-parallel-throttle'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

import { getInvoicePDFDownloadPromise } from './getInvoicePDFDownloadPromise'

function handleInvoicesPDFPromises(
  rows = [],
  { companyId, accessToken },
  setPdfFilesDownload = () => {},
  filename = 'InvoicesPDF.zip',
  maxInProgress = 100
) {
  const promises = rows.map((row) => {
    // IMPORTANT
    // When selecting rows manually, "row" is an object containing all data of the row,
    // When downloading ALL data, "row" is a number representing the tableId

    const invoiceId = row.tableId || row

    return () => {
      return getInvoicePDFDownloadPromise(invoiceId, {
        companyId,
        accessToken,
      })
    }
  })

  Throttle.all(promises, {
    maxInProgress,
    progressCallback: ({ amountDone }) => {
      setPdfFilesDownload((prev) => ({ ...prev, complete: amountDone })) // Need to set total here direct because in async function is pdfFilesDownload still not updated mean zero
    },
  })
    .then((values) => {
      const zip = new JSZip()

      values.forEach((file) => {
        zip.file(file.name, file.blobFile)
      })

      zip.generateAsync({ type: 'blob' }).then(function (blob) {
        saveAs(blob, filename)
      })
    })
    .catch((errors) => {
      console.error('Error downloading files', errors)
    })
}

export { handleInvoicesPDFPromises }
