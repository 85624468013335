import React from 'react'
import { useTranslation } from 'react-i18next'

import InvoicesOverview from './InvoicesOverview'
import Spacer from '../../components/Spacer'

import { useInvoicesGrid } from './gridConfigHooks/useInvoicesGrid'
import { useAgGridUltra } from '../../components/AgGrid/AgGridUltra/hooks/useAgGridUltra'
import { handleInvoicesPDFPromises } from '../Invoice/InvoiceDownload/handleInvoicesPDFPromises'

import handleSearchParamsFromUrl from '../../helper/handleSearchParamsFromUrl'
import {
  getContractFilterModel,
  getDateFilterModel,
  getInvoicesFilterModel,
  getServiceFilterModel,
} from '../../helper/getFilterModel'
import PyrexxOverviewProvider from '../../providers/PyrexxOverviewProvider'

const InvoicesOverviewPage = ({ contractsProps = {} }) => {
  const { t } = useTranslation()

  const { from, to, service, serviceName, contract, contractName, boxFilter } =
    handleSearchParamsFromUrl()

  const initialStateUiFilters = [
    getDateFilterModel({ from, to }, null, 'invoiceDate'),
    getServiceFilterModel({ tableId: service, name: serviceName }, t),
    getContractFilterModel({ tableId: contract, name: contractName }),
  ]
  const initialStateBoxFilters = [getInvoicesFilterModel(boxFilter)]

  const grids = [
    {
      id: 'invoices-grid',
      label: t('INVOICES GRID'),
      downloadFileName: t('INVOICES'),
      useGetParams: useInvoicesGrid(),
    },
  ]

  const { generateGrid, setFilterModel } = useAgGridUltra(
    'Invoices',
    grids,
    {},
    null,
    null,
    null,
    null,
    null,
    null,
    { withPDFDownload: true, handlePDFPromises: handleInvoicesPDFPromises }
  )

  return (
    <PyrexxOverviewProvider
      initialStateUiFilters={initialStateUiFilters}
      initialStateBoxFilters={initialStateBoxFilters}
    >
      <InvoicesOverview
        setFilterModel={setFilterModel}
        contractsProps={contractsProps}
      />
      <Spacer />
      {generateGrid()}
    </PyrexxOverviewProvider>
  )
}

export default InvoicesOverviewPage
