import React, { useCallback, useContext } from 'react'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { StyledInput } from 'pyrexx-react-library'
import { lowerCase } from 'lodash'
import UserContext from '../../../../../helper/userContext'

const FormField = (props) => {
  const {
    formik,
    nameRoot,
    typeRoot,
    labelRoot,
    haystackRoot = false,
    isLabelRoot,
  } = props
  console.log(
    'DEBUGXTOTAL A1',
    formik.values,
    nameRoot,
    typeRoot,
    labelRoot,
    haystackRoot,
    isLabelRoot
  )

  const { t } = useTranslation()
  const { currentLanguage } = useContext(UserContext)

  const generateField = useCallback(
    (name, type, label, haystack, isLabel) => {
      const getFormikValue = (formikTmpValues, nameTmp) => {
        console.log('DEBUGTOTAL insidereducer START ')
        const formikValueReduce = nameTmp
          .split('.')
          .reduce((accumulator, currentValue) => {
            console.log(
              'DEBUGTOTAL insidereducer only currentValue: ',
              currentValue
            )
            console.log(
              'DEBUGTOTAL insidereducer only accumulator: ',
              accumulator
            )
            console.log(
              'DEBUGTOTAL insidereducer only accumulator[currentValue]: ',
              accumulator?.[currentValue]
            )
            return accumulator?.[currentValue]
          }, formikTmpValues)
        console.log('DEBUGTOTAL insidereducer END ')
        return formikValueReduce
      }
      if (isLabel !== true) {
        switch (lowerCase(type)) {
          case 'string':
          case 'numeric':
          case 'url':
            return (
              <StyledInput
                type='input'
                name={name}
                id={name}
                onChange={formik.handleChange}
                value={getFormikValue(formik.values, name)}
                label={t(label)}
                feedbackMessage={
                  getFormikValue(formik.touched, name) &&
                  getFormikValue(formik.errors, name)
                }
                feedbackStyle='invalid'
                setFieldValue={formik.setFieldValue}
              />
            )
          case 'date':
            return (
              <StyledInput
                locale={currentLanguage}
                type='date'
                name={name}
                id={name}
                feedbackMessage={
                  getFormikValue(formik.touched, name) &&
                  getFormikValue(formik.errors, name)
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={getFormikValue(formik.values, name)}
                label={t(label)}
                setFieldValue={formik.setFieldValue}
              />
            )
          case 'enum':
            return (
              <StyledInput
                type='select'
                name={name}
                id={name}
                onChange={formik.handleChange}
                value={getFormikValue(formik.values, name)}
                label={t(label)}
                feedbackMessage={
                  getFormikValue(formik.touched, name) &&
                  getFormikValue(formik.errors, name)
                }
                feedbackStyle='invalid'
                setFieldValue={formik.setFieldValue}
                optionsHtmlElemets={haystack
                  .filter((item) => item !== 'NONE')
                  .map((optionElement, index) => {
                    return (
                      <option
                        key={name + '_optionvalue_' + index}
                        value={optionElement}
                      >
                        {t(optionElement)}
                      </option>
                    )
                  })}
              />
            )
          default:
            return (
              <StyledInput
                name={name}
                id={name}
                onChange={formik.handleChange}
                value={getFormikValue(formik.values, name)}
                label={t(label)}
                feedbackMessage={
                  getFormikValue(formik.touched, name) &&
                  getFormikValue(formik.errors, name)
                }
                feedbackStyle='invalid'
                setFieldValue={formik.setFieldValue}
              />
            )
        }
      }
    },
    [
      currentLanguage,
      formik.errors,
      formik.handleBlur,
      formik.handleChange,
      formik.setFieldValue,
      formik.touched,
      formik.values,
      t,
    ]
  )

  return (
    <Row key={'formRow' + nameRoot}>
      <Col>
        {generateField(
          nameRoot,
          typeRoot,
          labelRoot,
          haystackRoot,
          isLabelRoot
        )}
      </Col>
    </Row>
  )
}

export default FormField
