import React from 'react'
import { Container, Row } from 'react-grid-system'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { portalTheme } from 'pyrexx-react-library'
import { ExternalLinkToOrderWizard } from '../Support/CreateOrder/components/ExternalLinkToOrderWizard'

import { UN_LOCKING } from '../../constants/OrderWizardConstants'
import { OW_CATEGORY, OW_TOPIC } from "../../configs/FilterConfig";

const PropertyManagementSidebar = () => {
  const { t } = useTranslation()

  return (
    <Container
      style={{
        padding: '0 1.5rem',
      }}
    >
      <BorderBottomRow justify='between' style={{ padding: '1.5rem 0' }}>
        <ExternalLinkToOrderWizard
          // IMPLEMENT THIS WHEN WE HAVE PROPERTY UNITS AS FILTERS
          // to={`/de/support/createOrder?${OW_CONNECTED_IDS}={"invoice":[],"usageUnit":[${tableId}],"tenant":[]}`}
          to='/de/support/createOrder'
          label={t('ENQUIRIES')}
        />
      </BorderBottomRow>

      <Row style={{ marginTop: '2rem', marginBottom: '2rem' }}>
        <SmallTitleCol>{t('COMMON ACTIONS')}</SmallTitleCol>
      </Row>
      <Row
        justify='between'
        style={{ marginTop: '1rem', marginBottom: '1rem' }}
      >
        <ExternalLinkToOrderWizard
          to={`/de/support/createOrder?${OW_CATEGORY}=${UN_LOCKING.channel}&${OW_TOPIC}=${UN_LOCKING.topic}`}
          label={t('(UN)LOCK USAGE UNIT FOR SERVICES')}
        />
      </Row>

      <Row
        justify='between'
        style={{ marginTop: '1rem', marginBottom: '1rem' }}
      >
        <ExternalLinkToOrderWizard
          label={t('CHANGE FACILITY MANAGEMENT')}
          disabled
        />
      </Row>

      <Row
        justify='between'
        style={{ marginTop: '1rem', marginBottom: '1rem' }}
      >
        <ExternalLinkToOrderWizard label={t('CHANGE OF PORTFOLIO')} disabled />
      </Row>
    </Container>
  )
}

const BorderBottomRow = styled(Row)`
  border-bottom: 1px solid #979797;
`

const SmallTitleCol = styled.div`
  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.regular};
  font-size: 12px;
`

export default PropertyManagementSidebar
