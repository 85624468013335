import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { useParams } from 'react-router-dom'

import PageContainer from '../../../components/PageContainer'
import NavBack from '../../../components/NavBack'
import Spacer from '../../../components/Spacer'
import TicketStatus from './components/TicketStatus'
import TicketLinking from './components/TicketLinking'
import TicketProcessing from './components/TicketProcessing'
import TicketInfo from './components/TicketInfo'
import TicketChat from './components/TicketChat'
import QueryRendererContainer from '../../../components/QueryRendererContainer'

import TicketQuery from './queries/TicketQuery'

import { useUserContext } from '../../../helper/userContext'
import { Routes } from '../../../configs/RouteConfig'
import ticketData from './ticket-data'

const PureTicket = ({ ticket = {} }) => {
  console.log(ticket)

  if (!ticket) {
    return <p>TICKET DOES NOT EXIST</p>
  }

  return (
    <Grid>
      <GridStatus>
        <TicketStatus ticket={ticket} />
      </GridStatus>

      <GridChat>
        <TicketChat chat={ticketData[3].chat} />
        <Spacer y={16} />
        <TicketInfo ticket={ticket} />
      </GridChat>

      <GridProcessing>
        <TicketProcessing ticket={ticket} />
        <Spacer y={16} />
        <TicketLinking links={ticket.relations} />
      </GridProcessing>
    </Grid>
  )
}

const TicketContainer = ({ ticket }) => {
  const { user } = useUserContext()

  return (
    <QueryRendererContainer
      query={TicketQuery}
      variables={{ companyId: user.companyId, ticket }}
      loading='Loading...'
      render={(data) => (
        <PureTicket ticket={data.Me.Company.OrderWizard.ticket} />
      )}
    />
  )
}

const Ticket = () => {
  const { ticketNumber } = useParams()
  const { currentLanguage } = useUserContext()
  const { t } = useTranslation()

  return (
    <PageContainer>
      <NavBack
        title={t('TICKET {ticketNumber}', { ticketNumber })}
        to={'/' + currentLanguage + Routes.support}
      />
      <TicketContainer ticket={ticketNumber} />
      {/* <PureTicket ticket={ticketData[ticketNumber] || {}} /> */}
    </PageContainer>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 55% auto;
  grid-template-rows: auto auto;
  gap: 16px 16px;
  grid-template-areas:
    'TicketStatus TicketStatus'
    'TicketChat TicketProcessing';
`
const GridStatus = styled.div`
  grid-area: TicketStatus;
`
const GridChat = styled.div`
  grid-area: TicketChat;
`
const GridProcessing = styled.div`
  grid-area: TicketProcessing;
`

export default Ticket
