function translateOrderWizard(value, t) {
  switch (value) {
    case 'ORDER_WIZARD_CHANNEL_INVOICE':
      return t('INVOICE')

    case 'ORDER_WIZARD_CHANNEL_SMOKE_DETECTOR_SERVICES':
      return t('SMOKE DETECTOR SERVICES')

    case 'ORDER_WIZARD_CHANNEL_INVENTORY':
      return t('INVENTORY')

    case 'ORDER_WIZARD_CHANNEL_INQUIRIES':
      return t('INQUIRIES')

    case 'ORDER_WIZARD_TOPIC_INQUIRY_ABOUT_INVOICE':
      return t('INQUIRY ABOUT INVOICE')

    case 'ORDER_WIZARD_TOPIC_REPAIR':
      return t('REPAIR')

    case 'ORDER_WIZARD_TOPIC_TENANT':
      return t('TENANT')

    case 'ORDER_WIZARD_TOPIC_GENERAL_QUESTIONS':
      return t('GENERAL QUESTIONS')

    case 'ORDER_WIZARD_TOPIC_LOCK_UNLOCK_UNIT':
      return t('LOCK UNLOCK UNIT')

    case 'ORDER_WIZARD_REASON_INVOICE_DEMAND':
      return t('INVOICE DEMAND')

    case 'ORDER_WIZARD_REASON_REPLACE_THIRD_PARTY_SMOKE_DETECTOR':
      return t('REPLACE THIRD PARTY SMOKE DETECTOR')

    case 'ORDER_WIZARD_REASON_DROPPED_SMOKE_DETECTOR':
      return t('DROPPED SMOKE DETECTOR')

    case 'ORDER_WIZARD_REASON_BROKEN_SMOKE_DETECTOR':
      return t('BROKEN SMOKE DETECTOR')

    case 'ORDER_WIZARD_REASON_MISSING_SMOKE_DETECTOR':
      return t('MISSING SMOKE DETECTOR')

    case 'ORDER_WIZARD_REASON_REASSEMBLY_THIRD_PARTY_SMOKE_DETECTORS':
      return t('REASSEMBLY THIRD PARTY SMOKE DETECTORS')

    case 'ORDER_WIZARD_REASON_TENANT_CHANGE':
      return t('TENANT CHANGE')

    case 'ORDER_WIZARD_REASON_INQUIRY':
      return t('INQUIRY')

    case 'ORDER_WIZARD_REASON_LOCK':
      return t('LOCK')

    case 'ORDER_WIZARD_REASON_UNLOCK':
      return t('UNLOCK')

    default:
      return value
  }
}

export { translateOrderWizard }
