import React from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import { Icon } from 'leaflet'
import { Col, Container, Row } from 'react-grid-system'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import SimpleLink from '../../components/Simple/SimpleLink'
import LoadingBox from '../../components/LoadingSpinner/LoadingBox'

import { Routes } from '../../configs/RouteConfig'
import { useUserContext } from '../../helper/userContext'

import markerIconPng from 'leaflet/dist/images/marker-icon.png'
import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'

const MapPopup = ({ address = {}, index }) => {
  const { t } = useTranslation()
  const { currentLanguage } = useUserContext()

  const streetNumber =
    address[index].address.street + ' ' + address[index].address.number
  const zipCity = address[index].address.zip + ' ' + address[index].address.city
  const usageUnitCount =
    t('USAGE UNIT AMOUNT:') + ' ' + address[index].countUnits

  return (
    <Popup>
      <Container>
        <Row style={{ paddingTop: '1rem' }}>
          <Col style={{ fontSize: '1.5rem' }}>{streetNumber}</Col>
        </Row>
        <Row style={{ paddingTop: '1rem' }}>
          <Col>{zipCity}</Col>
        </Row>
        <Row style={{ paddingTop: '1rem' }}>
          <Col xs='content'>{usageUnitCount}</Col>
        </Row>
        <Row style={{ padding: '1rem' }}>
          <SimpleLink
            to={
              '/' +
              currentLanguage +
              Routes.propertyUnit +
              '/' +
              address[index].tableId
            }
          >
            {t('GO TO PROPERTY PAGE')}
          </SimpleLink>
        </Row>
      </Container>
    </Popup>
  )
}

const getMapProps = (coordinates) => {
  if (coordinates.length > 0) {
    return {
      bounds: coordinates,
    }
  } else {
    return {
      center: [51.164, 10.454],
      zoom: 6,
    }
  }
}

const PropertyManagementMap = ({
  loading = false,
  houseEntranceCoordinates = {},
}) => {
  const { coordinates = [], address = {} } = houseEntranceCoordinates

  const { t } = useTranslation()

  if (loading) {
    return <CustomLoader />
  }

  if (coordinates.length <= 0) {
    return <NoMapWrapper>{t('NO MAP AVAILABLE')}</NoMapWrapper>
  }

  return (
    <MapContainerStyled
      doubleClickZoom
      scrollWheelZoom
      {...getMapProps(coordinates)}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />

      <MarkerClusterGroup>
        {coordinates?.map((house, index) => {
          return (
            <Marker
              key={'marker' + index}
              position={[house[0], house[1]]}
              icon={
                new Icon({
                  iconUrl: markerIconPng,
                  iconSize: [25, 41],
                  iconAnchor: [12, 41],
                })
              }
            >
              <MapPopup address={address} index={index} />
            </Marker>
          )
        })}
      </MarkerClusterGroup>
    </MapContainerStyled>
  )
}

const MapContainerStyled = styled(MapContainer)`
  height: 100%;
  min-height: 500px;
  isolation: isolate;
`

const CustomLoader = styled(LoadingBox)`
  height: 100%;
  background-color: #fff;
`

const NoMapWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 2rem;
`

export default PropertyManagementMap
