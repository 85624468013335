import { useTranslation } from 'react-i18next'

import ClosedEnquiriesQuery from '../queries/ClosedEnquiriesQuery'
import generateSharedColumnDefs from './generateSharedColumnDefs'

import { useUserContext } from '../../../../helper/userContext'

const useClosedEnquiriesGrid = () => {
  const { currentLanguage } = useUserContext()
  const { t } = useTranslation()

  const ClosedEnquiriesColumnDefs = generateSharedColumnDefs(t, currentLanguage)

  const getParams = () => {
    return {
      query: ClosedEnquiriesQuery,
      columnDefs: ClosedEnquiriesColumnDefs,
      // customFilters: [],
      extractor: (response) => response.Me?.Company?.OrderWizard?.data,
      // customGridOptions: {},
      // customVariables: {},
    }
  }

  return { getParams }
}

export default useClosedEnquiriesGrid
