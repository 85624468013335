/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConditionType = "contains" | "endsWith" | "equals" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "lessThan" | "lessThanOrEqual" | "notContains" | "notEqual" | "startsWith" | "%future added value";
export type FilterType = "date" | "number" | "text" | "%future added value";
export type OperatorType = "AND" | "OR" | "%future added value";
export type ServiceCostStatusType = "DEACTIVATED" | "NONE" | "PSEUDOTRASH" | "SERVICE_NOT_RENDERED" | "SERVICE_RENDERED" | "%future added value";
export type Sort = {|
  colId?: ?string,
  sort?: ?string,
|};
export type Filter = {|
  name: string,
  filterType: FilterType,
  operator: OperatorType,
  denySelection?: ?boolean,
  conditions: $ReadOnlyArray<?Condition>,
|};
export type Condition = {|
  value: $ReadOnlyArray<?string>,
  type: ConditionType,
|};
export type InvoicesServiceCostsQueryVariables = {|
  companyId: string,
  startRow?: ?number,
  endRow?: ?number,
  sortModel?: ?$ReadOnlyArray<?Sort>,
  filters?: ?$ReadOnlyArray<?Filter>,
|};
export type InvoicesServiceCostsQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +data: $ReadOnlyArray<?{|
        +id: string,
        +tableId: number,
        +status: ServiceCostStatusType,
        +usageUnit: ?{|
          +tableId: number
        |},
        +contract: {|
          +id: string,
          +tableId: number,
          +billingPeriods: $ReadOnlyArray<?{|
            +id: string,
            +tableId: number,
          |}>,
          +billingPartitions: ?$ReadOnlyArray<?{|
            +name: string
          |}>,
        |},
        +appointments: ?$ReadOnlyArray<?{|
          +id: ?string,
          +tableId: ?number,
          +referenceNumber: ?string,
          +registrations: $ReadOnlyArray<?{|
            +id: string,
            +actionPosition: ?{|
              +id: string,
              +name: ?string,
            |},
          |}>,
        |}>,
        +value: number,
        +isGross: ?boolean,
        +tenant: ?{|
          +id: ?string,
          +fullname: ?string,
          +usageUnit: ?{|
            +address: ?{|
              +street: string,
              +number: string,
              +city: string,
              +zip: string,
            |},
            +CAN: ?{|
              +one: string,
              +two: string,
              +three: string,
              +four: string,
              +five: string,
            |},
          |},
        |},
        +fromDate: ?any,
        +toDate: ?any,
        +costPosition: ?{|
          +id: string,
          +name: string,
        |},
      |}>
    |}
  |}
|};
export type InvoicesServiceCostsQuery = {|
  variables: InvoicesServiceCostsQueryVariables,
  response: InvoicesServiceCostsQueryResponse,
|};
*/


/*
query InvoicesServiceCostsQuery(
  $companyId: ID!
  $startRow: Int
  $endRow: Int
  $sortModel: [Sort]
  $filters: [Filter]
) {
  Me {
    Company(id: $companyId) {
      data: serviceCosts(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {
        id
        tableId
        status
        usageUnit {
          tableId
          id
        }
        contract {
          id
          tableId
          billingPeriods {
            id
            tableId
          }
          billingPartitions {
            name
            id
          }
        }
        appointments {
          id
          tableId
          referenceNumber
          registrations {
            id
            actionPosition {
              id
              name
            }
          }
        }
        value
        isGross
        tenant {
          id
          fullname
          usageUnit {
            address {
              street
              number
              city
              zip
            }
            CAN {
              one
              two
              three
              four
              five
            }
            id
          }
        }
        fromDate
        toDate
        costPosition {
          id
          name
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endRow"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortModel"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startRow"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v6 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "endRow",
        "variableName": "endRow"
      },
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      },
      {
        "kind": "Variable",
        "name": "sorting",
        "variableName": "sortModel"
      },
      {
        "kind": "Variable",
        "name": "startRow",
        "variableName": "startRow"
      }
    ],
    "kind": "ObjectValue",
    "name": "options"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "BillingPeriod",
  "kind": "LinkedField",
  "name": "billingPeriods",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = [
  (v7/*: any*/),
  (v11/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Appointment",
  "kind": "LinkedField",
  "name": "appointments",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referenceNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Registration",
      "kind": "LinkedField",
      "name": "registrations",
      "plural": true,
      "selections": [
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ActionPosition",
          "kind": "LinkedField",
          "name": "actionPosition",
          "plural": false,
          "selections": (v12/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isGross",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullname",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "address",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "street",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zip",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "CustomerAllocationNumber",
  "kind": "LinkedField",
  "name": "CAN",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "one",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "two",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "three",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "four",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "five",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fromDate",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "toDate",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "CostPosition",
  "kind": "LinkedField",
  "name": "costPosition",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InvoicesServiceCostsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": "data",
                "args": (v6/*: any*/),
                "concreteType": "ServiceCost",
                "kind": "LinkedField",
                "name": "serviceCosts",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UsageUnit",
                    "kind": "LinkedField",
                    "name": "usageUnit",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Contract",
                    "kind": "LinkedField",
                    "name": "contract",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BillingPartition",
                        "kind": "LinkedField",
                        "name": "billingPartitions",
                        "plural": true,
                        "selections": [
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tenant",
                    "kind": "LinkedField",
                    "name": "tenant",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UsageUnit",
                        "kind": "LinkedField",
                        "name": "usageUnit",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/),
                          (v18/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "InvoicesServiceCostsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": "data",
                "args": (v6/*: any*/),
                "concreteType": "ServiceCost",
                "kind": "LinkedField",
                "name": "serviceCosts",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UsageUnit",
                    "kind": "LinkedField",
                    "name": "usageUnit",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Contract",
                    "kind": "LinkedField",
                    "name": "contract",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BillingPartition",
                        "kind": "LinkedField",
                        "name": "billingPartitions",
                        "plural": true,
                        "selections": [
                          (v11/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tenant",
                    "kind": "LinkedField",
                    "name": "tenant",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v16/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UsageUnit",
                        "kind": "LinkedField",
                        "name": "usageUnit",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cb64b0fc17dc61efbd837c0047c5cede",
    "id": null,
    "metadata": {},
    "name": "InvoicesServiceCostsQuery",
    "operationKind": "query",
    "text": "query InvoicesServiceCostsQuery(\n  $companyId: ID!\n  $startRow: Int\n  $endRow: Int\n  $sortModel: [Sort]\n  $filters: [Filter]\n) {\n  Me {\n    Company(id: $companyId) {\n      data: serviceCosts(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {\n        id\n        tableId\n        status\n        usageUnit {\n          tableId\n          id\n        }\n        contract {\n          id\n          tableId\n          billingPeriods {\n            id\n            tableId\n          }\n          billingPartitions {\n            name\n            id\n          }\n        }\n        appointments {\n          id\n          tableId\n          referenceNumber\n          registrations {\n            id\n            actionPosition {\n              id\n              name\n            }\n          }\n        }\n        value\n        isGross\n        tenant {\n          id\n          fullname\n          usageUnit {\n            address {\n              street\n              number\n              city\n              zip\n            }\n            CAN {\n              one\n              two\n              three\n              four\n              five\n            }\n            id\n          }\n        }\n        fromDate\n        toDate\n        costPosition {\n          id\n          name\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4fae62baf7a6d70adeacc868ccfc34c4';

module.exports = node;
