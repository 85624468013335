/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type InvoicesCardQueryVariables = {|
  companyId: string
|};
export type InvoicesCardQueryResponse = {|
  +Me: ?{|
    +invoicesStatus: ?{|
      +open: ?number,
      +reminded: ?number,
    |}
  |}
|};
export type InvoicesCardQuery = {|
  variables: InvoicesCardQueryVariables,
  response: InvoicesCardQueryResponse,
|};
*/


/*
query InvoicesCardQuery(
  $companyId: ID!
) {
  Me {
    invoicesStatus: Company(id: $companyId) {
      open: countInvoices(options: {filters: [{name: "status", filterType: number, operator: AND, conditions: [{value: ["OPEN"], type: equals}]}]})
      reminded: countInvoices(options: {filters: [{name: "status", filterType: number, operator: AND, conditions: [{value: ["REMINDED"], type: equals}]}]})
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": "open",
  "args": [
    {
      "kind": "Literal",
      "name": "options",
      "value": {
        "filters": [
          {
            "conditions": [
              {
                "type": "equals",
                "value": [
                  "OPEN"
                ]
              }
            ],
            "filterType": "number",
            "name": "status",
            "operator": "AND"
          }
        ]
      }
    }
  ],
  "kind": "ScalarField",
  "name": "countInvoices",
  "storageKey": "countInvoices(options:{\"filters\":[{\"conditions\":[{\"type\":\"equals\",\"value\":[\"OPEN\"]}],\"filterType\":\"number\",\"name\":\"status\",\"operator\":\"AND\"}]})"
},
v3 = {
  "alias": "reminded",
  "args": [
    {
      "kind": "Literal",
      "name": "options",
      "value": {
        "filters": [
          {
            "conditions": [
              {
                "type": "equals",
                "value": [
                  "REMINDED"
                ]
              }
            ],
            "filterType": "number",
            "name": "status",
            "operator": "AND"
          }
        ]
      }
    }
  ],
  "kind": "ScalarField",
  "name": "countInvoices",
  "storageKey": "countInvoices(options:{\"filters\":[{\"conditions\":[{\"type\":\"equals\",\"value\":[\"REMINDED\"]}],\"filterType\":\"number\",\"name\":\"status\",\"operator\":\"AND\"}]})"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvoicesCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": "invoicesStatus",
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvoicesCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": "invoicesStatus",
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7fa51d779a12343b7903e7219e86ddee",
    "id": null,
    "metadata": {},
    "name": "InvoicesCardQuery",
    "operationKind": "query",
    "text": "query InvoicesCardQuery(\n  $companyId: ID!\n) {\n  Me {\n    invoicesStatus: Company(id: $companyId) {\n      open: countInvoices(options: {filters: [{name: \"status\", filterType: number, operator: AND, conditions: [{value: [\"OPEN\"], type: equals}]}]})\n      reminded: countInvoices(options: {filters: [{name: \"status\", filterType: number, operator: AND, conditions: [{value: [\"REMINDED\"], type: equals}]}]})\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '39ac944ffd2dd44a8911c0df9d6aad13';

module.exports = node;
