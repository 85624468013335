import React, { useState } from 'react'
import { fetchQuery } from 'react-relay'
import styled from 'styled-components/macro'

import { Icon, LoadingProgressBar } from 'pyrexx-react-library'

import environment from '../../environments/pom/Environment'
import { useUserContext } from '../../helper/userContext'
import { StyledMountingAnimation } from '../../styles/HelperStylesComponents.styles'

import InvoiceDownload from '../../routes/Invoice/InvoiceDownload/queries/InvoiceDownload'
import AppointmentDownload from '../../routes/Appointment/queries/AppointmentDownload'

// Add new data here:
const downloadData = {
  invoice: {
    type: 'Invoice',
    fileRoute: '../../routes/Invoice/InvoiceDownload/queries/',
    fileName: 'InvoiceDownload',
    query: InvoiceDownload,
  },
  appointment: {
    type: 'Appointment',
    fileRoute: '../../routes/Appointment/queries/',
    fileName: 'AppointmentDownload',
    query: AppointmentDownload,
  },
}

const SinglePdfDownload = (props) => {
  const { type, value } = props

  const { user } = useUserContext()
  const [loading, setLoading] = useState(false)
  const [downloadProgress, setDownloadProgress] = useState(0)

  const { accessToken = '' } = user || {}

  const variables = {
    [type + 'Id']: value,
    companyId: user?.companyId,
  }

  const currentDownloadData = downloadData[type]

  const startDownload = () => {
    setLoading(true)

    fetchQuery(environment(accessToken), currentDownloadData.query, variables, {
      force: true,
    })
      .then((res) => {
        initBrowserDownload(
          res.Me?.Company?.[currentDownloadData.type]?.download
        )
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const initBrowserDownload = async (downloadDetails) => {
    const response = await fetch(
      process.env.REACT_APP_DOWNLOAD_PATH + downloadDetails.path,
      {
        headers: {
          Authorization: 'Bearer ' + downloadDetails.token,
          Accept: 'application/pdf',
        },
      }
    )

    const reader = response.body.getReader()

    // Step 2: get total length
    const contentLength = +response.headers.get('Content-Length')

    // Step 3: read the data
    let receivedLength = 0 // received that many bytes at the moment
    const chunks = [] // array of received binary chunks (comprises the body)
    while (true) {
      const { done, value } = await reader.read()

      if (done) {
        setLoading(false)
        setDownloadProgress(0)
        break
      }

      chunks.push(value)
      receivedLength += value.length
      setDownloadProgress(Math.floor(receivedLength / (contentLength / 100)))

      console.log(`Received ${receivedLength} of ${contentLength}`)
    }

    const blob = new Blob(chunks, { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)

    const tempLink = document.createElement('a')
    tempLink.href = url
    tempLink.setAttribute('download', downloadDetails.name)
    tempLink.click()
    window.URL.revokeObjectURL(url)
  }

  const content = props.children ?? <StyledIcon icon='detail' size='16px' />
  return (
    <StyledMountingAnimation
      transitionName={currentDownloadData.fileName}
      transitionEnterTimeout={500}
      transitionLeaveTimeout={2000}
    >
      {loading && <LoadingProgressBar boxSpinner complete={downloadProgress} />}
      <Container onClick={startDownload}>{content}</Container>
    </StyledMountingAnimation>
  )
}

const Container = styled.div`
  text-align: center;
`

const StyledIcon = styled(Icon)`
  cursor: pointer;
`

export default SinglePdfDownload
