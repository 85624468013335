import React from 'react'
import { useButton } from 'react-aria'
import styled from 'styled-components/macro'

import { Icon } from 'pyrexx-react-library'
import EmptyButton from '../Simple/EmptyButton'
import { css } from 'styled-components'

export function Button(props) {
  const ref = props.buttonRef
  const { buttonProps } = useButton(props, ref)

  return (
    <StyledButton {...buttonProps} ref={ref} isEmpty={props.isEmpty}>
      {props.children}
      <Icon
        icon='arrow-down'
        size='30'
        aria-hidden='true'
        style={{ paddingLeft: 5 }}
      />
    </StyledButton>
  )
}

const StyledButton = styled(EmptyButton)`
  font-size: 1rem;

  border-radius: 50px;
  background-color: var(--pyrexx-dropdown-badge-color, #c5cfda);
  color: #000;

  padding: 8px 16px;

  text-overflow: ellipsis;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid transparent;
  cursor: pointer;

  min-width: 300px;

  ${(props) =>
    props.isEmpty &&
    css`
      border: 1px solid var(--pyrexx-dropdown-badge-color, #c5cfda);
      background-color: #fff;
    `}
`
