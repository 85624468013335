import React, { useRef } from 'react'
import { useSelectState } from 'react-stately'
import { HiddenSelect, useSelect } from 'react-aria'
import { useTranslation } from 'react-i18next'

import { Button } from './Button'
import { Popover } from './Popover'
import { ListBox } from './ListBox'

export function Select(props) {
  const { label, name } = props

  const { t } = useTranslation()

  // Create state based on the incoming props
  const state = useSelectState(props)

  // Get props for child elements from useSelect
  const ref = useRef(null)

  const { labelProps, triggerProps, valueProps, menuProps } = useSelect(
    props,
    state,
    ref
  )

  return (
    <div style={{ display: 'inline-block' }}>
      <div {...labelProps} style={{ marginBottom: '4px' }}>
        {label}
      </div>

      <HiddenSelect state={state} triggerRef={ref} label={label} name={name} />

      <Button {...triggerProps} buttonRef={ref} isEmpty={!state.selectedItem}>
        <span {...valueProps}>
          {state.selectedItem
            ? state.selectedItem.rendered
            : t('SELECT AN OPTION')}
        </span>
      </Button>

      {state.isOpen && (
        <Popover state={state} triggerRef={ref} placement='bottom start'>
          <ListBox {...menuProps} state={state} />
        </Popover>
      )}
    </div>
  )
}
