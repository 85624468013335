import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { portalTheme, StyledInput } from 'pyrexx-react-library'
import { trim } from 'lodash/string'
import { useTranslation } from 'react-i18next'

const serviceCategories = [
  {
    tableId: 6,
    name: 'Stromzähler',
  },
  {
    tableId: 7,
    name: 'Messdienstleistung (Wärme/Wasser)',
  },
  {
    tableId: 5,
    name: 'Oberflächenreinigung',
  },
  {
    tableId: 3,
    name: 'Trinkwasseruntersuchung',
  },
  {
    tableId: 1,
    name: 'Dienstleistung Rauchwarnmelder',
  },
]

const FormView = ({ formik }) => {
  const { t } = useTranslation()

  return (
    <Container
      style={{
        backgroundColor: portalTheme.color.basicColorWhite,
        padding: '1rem',
      }}
    >
      <Row justify='between'>
        <Col xs='content'>{t('USAGE UNIT UNLOCK INFORMATION')}</Col>
      </Row>

      <Row>
        <Col>
          <StyledInput
            type='reactSelect'
            name='components.usageUnitUnlock.serviceCategory'
            id='components.usageUnitUnlock.serviceCategory'
            onChange={formik.handleChange}
            value={formik.values.components?.usageUnitUnlock?.serviceCategory}
            label={t('SERVICE CATEGORY')}
            feedbackMessage={
              formik.touched.components?.usageUnitUnlock?.serviceCategory &&
              formik.errors.components?.usageUnitUnlock?.serviceCategory
            }
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
            reactSelectOptions={serviceCategories.map(
              (optionElement, index) => {
                console.log('DEBUG RT', optionElement)
                return {
                  value: optionElement.tableId,
                  label: trim(optionElement.name),
                }
              }
            )}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <StyledInput
            type='textarea'
            name='components.usageUnitUnlock.additionalNotes'
            id='components.usageUnitUnlock.additionalNotes'
            onChange={formik.handleChange}
            value={formik.values.components?.usageUnitUnlock?.additionalNotes}
            label={t('ADDITIONAL NOTES')}
            feedbackMessage={
              formik.touched.components?.usageUnitUnlock?.additionalNotes &&
              formik.errors.components?.usageUnitUnlock?.additionalNotes
            }
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>
      </Row>
    </Container>
  )
}

const SummaryView = ({ componentData = {} }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <Row style={{ fontWeight: 'bold' }}>
        <Col>{t('USAGE UNIT LOCK INFORMATION')}</Col>
      </Row>

      <Row>
        <Col
          style={{
            backgroundColor: portalTheme.color.basicColorLightGrey2,
          }}
        >
          <Row>
            <Col>
              <strong>{t('SERVICE CATEGORY')}</strong>
            </Col>
            <Col>
              <p>
                {componentData?.components?.usageUnitUnlock?.serviceCategory}
              </p>
            </Col>
          </Row>

          <Row>
            <Col>
              <strong>{t('ADDITIONAL NOTES')}</strong>
            </Col>
            <Col>
              <p>
                {componentData?.components?.usageUnitUnlock?.additionalNotes}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

// STEP 3
const UsageUnitUnlock = (props) => {
  const { formik, componentData = {}, showSummaryView = false } = props

  if (showSummaryView) {
    return <SummaryView componentData={componentData} />
  } else {
    return <FormView formik={formik} />
  }
}

export default UsageUnitUnlock
