import React from 'react'
import styled from 'styled-components/macro'
import { css } from 'styled-components'

import { portalTheme } from 'pyrexx-react-library'
import SimpleButton from '../Simple/SimpleButton'
import SimpleLink from '../Simple/SimpleLink'
import SimpleAnchor from '../Simple/SimpleAnchor'
import StatusLightWithLabel from './StatusLightWithLabel'

const borderCSS = css`
  border-bottom-color: ${portalTheme.color.linkColorPrimary};
`

const Wrapper = styled.div`
  // padding-bottom: 0.5rem;
  // border-bottom: 2px solid;
  // border-bottom-color: transparent;
  //
  // &:hover,
  // &:focus {
  //   ${(props) => props.border && borderCSS}
  // }
  //
  // &:active {
  //   ${(props) => props.border && borderCSS}
  // }
  //
  // transition: border-bottom-color 0.3s;
  //
  // ${(props) => props.selected && props.border && borderCSS}
`

const tagSelector = (variant) => {
  switch (variant) {
    case 'button':
      return SimpleButton
    case 'link':
      return SimpleLink
    case 'anchor':
      return SimpleAnchor
    case 'div':
      return 'div'
    default:
      return SimpleButton
  }
}

const StatusFilterButton = (props) => {
  const {
    selected = false,
    border = false,
    status = '',
    label,
    variant = 'button',
    ...restOfProps
  } = props

  const tag = tagSelector(variant)

  return (
    <Wrapper
      as={tag}
      selected={selected}
      border={border ? true : undefined}
      {...restOfProps}
    >
      <StatusLightWithLabel status={status} label={label} />
    </Wrapper>
  )
}

export default StatusFilterButton
