import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { columnCheckbox } from '../../../../components/AgGrid/columnDefs'
import { DateRenderer } from '../../../../components/AgGrid/renderers'
import SimpleGridFilter from '../../../../components/AgGrid/filters/SimpleGridFilter'

import {
  AppointmentNumberRenderer,
  QuotaButtonRenderer,
  ReferenceNumberRenderer,
} from '../renderer'

import { QuotaCountQuery, QuotaGridQuery } from '../queries'

export const useQuotaGrid = (
  setModal,
  setAppointmentId,
  triggerQuotaReleaseMutation
) => {
  const { t } = useTranslation()

  const getParams = useCallback(() => {
    const QuotaGridColumnDef = [
      columnCheckbox,
      {
        headerName: t('APPOINTMENT ID'),
        field: 'appointment.id',
        width: 25,
        type: 'rightAligned',
        cellRenderer: AppointmentNumberRenderer,
      },
      {
        headerName: t('ALLOCATION NUMBER'),
        field: 'appointment.usageUnit.referenceNumber',
        width: 25,
        cellRenderer: ReferenceNumberRenderer,
      },
      {
        headerName: t('STREET'),
        field: 'appointment.houseEntrance.address.street',
        width: 75,
      },
      {
        headerName: t('HOUSE NUMBER'),
        field: 'appointment.houseEntrance.address.number',
        width: 10,
        type: 'rightAligned',
      },
      {
        headerName: t('CITY'),
        field: 'appointment.houseEntrance.address.city',
        width: 50,
      },
      {
        headerName: t('NAME'),
        field: 'appointment.name',
        width: 50,
        valueGetter: ({ data }) =>
          data.appointment?.forname + ' ' + data.appointment?.name,
      },
      {
        headerName: t('DATE'),
        field: 'appointment.date',
        width: 100,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['apply', 'clear', 'reset'],
          closeOnApply: true,
          suppressAndOrCondition: true,
        },
        cellRenderer: DateRenderer,
      },
      {
        headerName: t('DESCRIPTION'),
        field: 'name',
        width: 100,
      },
      {
        headerName: '',
        field: '',
        width: 100,
        cellRenderer: QuotaButtonRenderer,
        cellRendererParams: {
          setModal: setModal,
          setAppointmentId: setAppointmentId,
          triggerQuotaReleaseMutation: triggerQuotaReleaseMutation,
        },
      },
    ]

    return {
      query: QuotaGridQuery,
      columnDefs: QuotaGridColumnDef,
      customFilters: [],
      countQuery: QuotaCountQuery,
      extractor: (response) => response.Me?.Company?.Quotas,
      countExtractor: (response) => response.Me?.Company?.data,
      customGridOptions: {},
      components: {
        SimpleGridFilter,
      },
    }
  }, [setModal, t, setAppointmentId, triggerQuotaReleaseMutation])
  return { getParams }
}
